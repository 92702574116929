<template>
  <li
    class="c-compatibility-test-item"
    :class="{ 'test-failed': failed }"
  >
    <div class="c-compatibility-test-item__wrapper">
      <svg class="o-icon c-compatibility-test__icon c-compatibility-test__results-icon">
        <use :xlink:href="singleTestClass" />
      </svg>
      <p class="u-typo--text">
        {{ $t(test.nameKey) }}{{ downloadSpeed }}
      </p>
    </div>
  </li>
</template>
<script>
  export default {
    name: 'UiTestItem',
    props: {
      test: undefined
    },
    data () {
      return {
        show: false
      }
    },
    computed: {
      failed () {
        return !this.test.result
      },
      downloadSpeed () {
        return this.test.resultSpeed && ` - ${this.test.resultSpeed} Mb/s`
      },
      singleTestClass () {
        return this.test.result
          ? '#icon-check_mark'
          : '#icon-close'
      }
    },
    methods: {
      toggle () {
        this.show = !this.show
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
