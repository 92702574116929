<template>
  <section />
</template>

<script>
  export default {
    created () {
      this.$router.push({ name: this.$dict.routes.HOME })
    }
  }
</script>
