<template>
  <div class="o-input__wrapper">
    <input
      ref="input"
      :value="value"
      autocomplete="off"
      class="o-input"
      :type="type"
      :placeholder="placeholder"
      :class="[{ 'o-input--success': isValid, 'o-input--error': hasError }, pinInputClass]"
      @input="checkInput($event.target)"
      @paste="checkInput($event.target)"
    >
    <span
      class="o-input__icon"
      :class="{ 'o-input__icon--success o-icon--check': isValid, 'o-input__icon--error o-icon--caution': hasError }"
    />
    <tooltip
      v-if="tooltip"
      :position="tooltipPosition"
    >
      <template v-slot:tooltip>
        {{ tooltip }}
      </template>
    </tooltip>
  </div>
</template>
<script>
  import inputMixin from '@/mixins/input-mixin'
  import Tooltip from '@/components/tooltip/index'

  export default {
    name: 'VInput',
    components: { Tooltip },
    mixins: [
      inputMixin
    ],
    props: {
      maxLength: {
        type: Number,
        default: 0
      },
      placeholder: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'text'
      },
      pinInputClass: {
        type: String,
        default: ''
      },
      value: {
        type: String,
        default: ''
      },
      tooltip: {
        type: String,
        default: ''
      },
      tooltipPosition: {
        type: String,
        default: 'top'
      }
    },
    watch: {
      isValid: function () {
        this.$emit('changedValidity', this.isValid)
      }
    },
    mounted () {
      const input = this.$refs.input
      if (input.value) {
        this.checkInput(input)
      }
      if (this.maxLength) {
        input.setAttribute('maxlength', this.maxLength)
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
