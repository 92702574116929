<template>
  <section>
    <div v-if="!hasCancelledSubscription">
      <h1 class="c-cancel-subscription__title">
        {{ $t('views.cancelSubscription.title') }}
      </h1>
      <p class="c-cancel-subscription__content">
        {{ $t('views.cancelSubscription.content') }}, {{ endDate }}
      </p>
      <button
        ref="submit"
        type="button"
        class="c-cancel-subscription__submit o-button"
        @click="submit"
      >
        {{ $t('views.cancelSubscription.submit') }}
      </button>
    </div>
    <success-view v-else>
      <svg class="c-success__icon o-icon">
        <use xlink:href="#icon-success" />
      </svg>
      <h1 class="c-success__heading">
        {{ $t('views.cancelSubscription.success.heading') }}
      </h1>
      <p>{{ $t('views.cancelSubscription.success.subheading') }}</p>
    </success-view>
  </section>
</template>

<script>
  import SuccessView from '@/components/success-view'

  export default {
    components: {
      SuccessView
    },
    props: {
      cyclicPaymentId: {
        type: Number,
        required: true
      },
      endDate: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        active: true,
        hasCancelledSubscription: false
      }
    },
    methods: {
      close () {
        this.active = false
      },
      submit () {
        this.$loader.show(this.$refs.submit, { small: true })
        this.$store.dispatch('user/DELETE_CYCLIC_PAYMENT', { cyclicPaymentId: this.cyclicPaymentId })
          .then(() => {
            this.hasCancelledSubscription = true
          })
          .catch(() => {
            this.$loader.hide(this.$refs.submit, { small: true })
            this.$toast.error(this.$t('views.cancelSubscription.error'))
          })
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
