<template>
  <div
    class="c-player__controls row expanded align-middle"
    @click.stop.prevent=""
  >
    <div class="column shrink">
      <player-toggle-play />
    </div>
    <div class="column shrink">
      {{ progress | TimeFormatHourMinSec }}
    </div>
    <div class="column">
      <player-time-shift class="c-player__progress-bar" />
    </div>
    <div class="column shrink">
      {{ duration | TimeFormatHourMinSec }}
    </div>
    <div class="column shrink">
      <player-volume
        class="c-player__volume c-player__progress-bar row align-middle"
        direction="vertical"
        :show-volume-icon="true"
      />
    </div>
    <div
      v-if="showSettingsButton"
      class="column shrink"
      @click.stop=""
    >
      <player-opts-button class="c-player__option-btn" />
    </div>
    <div class="column shrink">
      <player-full-screen class="c-player__fullscreen-btn" />
    </div>
  </div>
</template>
<script>
  import PlayerFullScreen from 'atds-player-layout/src/components/controls/components/fullscreen'
  import PlayerVolume from 'atds-player-layout/src/components/controls/components/volume'
  import PlayerOptsButton from 'atds-player-layout/src/components/controls/components/options'
  import PlayerTimeShift from 'atds-player-layout/src/components/controls/components/progress'
  import PlayerTogglePlay from 'atds-player-layout/src/components/controls/components/toggle-play'
  import { TimeFormatHourMinSec } from 'atds-player-layout/src/filters/time-format'
  import showPlayerSettingsMixin from '@/mixins/show-player-settings-mixin'

  export default {
    name: 'PlayerControls',
    inject: [
      'playerStore'
    ],
    components: {
      PlayerTogglePlay,
      PlayerTimeShift,
      PlayerOptsButton,
      PlayerVolume,
      PlayerFullScreen
    },
    filters: {
      TimeFormatHourMinSec
    },
    mixins: [
      showPlayerSettingsMixin
    ],
    computed: {
      duration () {
        return this.playerStore.state.duration
      },
      progress () {
        return this.playerStore.state.progress
      }
    }
  }
</script>
