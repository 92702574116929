<template>
  <div class="c-banner-bullets row expanded">
    <div
      v-for="({id, item}, index) in section.elements"
      :key="id"
      class="column"
    >
      <div
        class="c-banner-bullets__bullet"
        :class="{ 'is-active': selectedIndex === parseInt(index, 10)}"
        @click="emitSelectedProduct(index)"
      >
        <img
          :src="thumbnailBullet(item)"
          :alt="item.title"
        >
      </div>
    </div>
  </div>
</template>
<script>
  import { ASPECT_16X9 } from '@/assets/javascript/enums/aspect-ratios'

  export default {
    name: 'BannerBullets',
    props: {
      section: {
        type: Object,
        required: true
      },
      selectedIndex: {
        type: Number,
        default: 0
      }
    },
    methods: {
      thumbnailBullet (product) {
        return product.images?.[ASPECT_16X9]?.[0]?.miniUrl
      },
      emitSelectedProduct (index) {
        this.$emit('selectProduct', window.parseInt(index))
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
