<template functional>
  <span
    :class="data.staticClass"
    class="o-separator"
  />
</template>
<script>
  export default {
    name: 'Separator'
  }
</script>
<style lang="scss" src="./styles.scss" />
