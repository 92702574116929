<template>
  <div class="row expanded collapse c-profile-edit">
    <form
      class="column small-12"
      @submit.prevent="save"
    >
      <profile-edit-form
        ref="form"
        :profile="profileToEdit"
        :errors="errors"
      />

      <div class="c-profile-edit__buttons-container">
        <button
          type="submit"
          class="o-button"
          :class="{ 'o-button--disabled' : disabled }"
          :disabled="disabled"
        >
          {{ $t('buttons.saveChanges') }}
        </button>
        <button
          type="button"
          class="o-button o-button--secondary"
          @click="goBack"
        >
          {{ $t('buttons.cancel') }}
        </button>
        <button
          v-if="profileToEdit.id !== user.activeProfileId"
          type="button"
          class="o-button o-button--secondary"
          @click="$popup.show($dict.popups.REMOVE_PROFILE, { profileToEdit })"
        >
          {{ $t('buttons.remove') }}
        </button>
      </div>
    </form>
  </div>
</template>
<script>
  import errorMapper from '@/assets/javascript/helpers/error-mapper'

  import ProfileEditForm from '@/components/profiles/form/index.vue'

  const MIN_INPUT_LENGTH = 3
  const MIN_ZIP_CODE_LENGTH = 6

  export default {
    name: 'ProfileEditPage',
    components: {
      ProfileEditForm
    },
    data () {
      return {
        avatarEdit: false,
        disabled: false,
        isCityValid: true,
        isNameValid: true,
        isZipCodeValid: true,
        errors: {}
      }
    },
    computed: {
      isBirthDateValid () {
        const { birthDay, birthMonth, birthYear } = this.profileData
        return (birthDay && birthMonth && birthYear) || (!birthDay && !birthMonth && !birthYear)
      },
      user () {
        return this.$store.state.user.data
      },
      activeProfile () {
        return this.$store.getters['user/ACTIVE_PROFILE']
      },
      profileToEdit () {
        return this.$route.params.selectedProfile || this.activeProfile
      },
      profileData () {
        return this.$refs.form.preProfile
      }
    },
    methods: {
      save () {
        this.errors = {}

        if (!this.isBirthDateValid) {
          this.$toast.error(this.$t('errors.dateOfBirth'))
          return this.tempDisableSubmit()
        }

        if (this.profileData.name.length < MIN_INPUT_LENGTH) {
          this.$toast.error(this.$t('errors.name'))
          this.isNameValid = false
          return this.tempDisableSubmit()
        }

        if (this.profileData.city.length && this.profileData.city.length < MIN_INPUT_LENGTH) {
          this.$toast.error(this.$t('errors.city'))
          this.isCityValid = false
          return this.tempDisableSubmit()
        }

        if (this.profileData.zipCode.length && this.profileData.zipCode.length < MIN_ZIP_CODE_LENGTH) {
          this.$toast.error(this.$t('errors.zipCode'))
          this.isZipCodeValid = false
          return this.tempDisableSubmit()
        }

        const profile = {
          ...this.profileData,
          birthMonth: this.profileData.birthMonth?.value || this.profileData?.birthMonth || '',
          sex: this.profileData.sex?.value
        }

        if (!profile.birthDay) {
          delete profile.birthDay
          delete profile.birthMonth
          delete profile.birthYear
        }

        this.$store.dispatch('profiles/UPDATE_PROFILE', { profile })
          .then(() => {
            this.$router.push({ name: this.$dict.routes.SHOW_PROFILES })
          })
          .catch(error => {
            return errorMapper(this.$t.bind(this), error)
              .then(errors => {
                const errorsValues = Object.values(errors)
                for (const error in errorsValues) {
                  this.$toast.error(errorsValues[error])
                }
              })
          })
      },
      tempDisableSubmit () {
        this.disabled = true
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.disabled = false
        }, 1000)
      },
      goBack () {
        return this.$router.back()
      }
    },
    head () {
      return {
        title: this.$t('profiles.edit')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
