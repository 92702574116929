<template>
  <div
    ref="progress"
    class="c-progress-circle"
  >
    <div class="c-progress-circle__wrapper">
      <svg
        v-if="ready"
        :height="radius * 2"
        :width="radius * 2"
      >
        <circle
          class="c-progress-circle__back"
          :cx="radius"
          :cy="radius"
          :r="normalizedRadius"
          :stroke-dasharray="circumference + ' ' + circumference"
          :stroke-width="stroke"
          :style="{ strokeDashoffset: backStrokeDashoffset }"
          fill="transparent"
        />
        <circle
          class="c-progress-circle__front"
          :cx="radius"
          :cy="radius"
          :r="normalizedRadius"
          :stroke-dasharray="circumference + ' ' + circumference"
          :stroke-width="stroke"
          :style="{ strokeDashoffset: strokeDashoffset }"
          fill="transparent"
        />
      </svg>
      <div class="c-progress-circle__content">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'UiProgressCircle',
    props: {
      progress: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        ready: false,
        normalizedRadius: undefined,
        circumference: undefined,
        radius: undefined,
        stroke: undefined,
        intervalId: undefined
      }
    },
    computed: {
      strokeDashoffset () {
        return this.circumference - this.progress / 100 * this.circumference
      },
      backStrokeDashoffset () {
        return this.strokeDashoffset - this.circumference
      }
    },
    mounted () {
      this.$refs.progress.style.height = `${this.$refs.progress.offsetWidth}px`
      this.radius = this.$el.offsetWidth / 2
      this.stroke = 2
      this.normalizedRadius = this.radius - (this.stroke / 2)
      this.circumference = this.normalizedRadius * 2 * Math.PI
      this.progress = 0
      this.ready = true
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
