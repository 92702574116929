<template>
  <section class="c-cmp__vendors">
    <div
      v-for="vendor in data.vendors"
      :key="`vendor-${vendor.id}`"
      class="c-cmp__vendor-wrapper"
    >
      <div
        class="c-cmp__content c-cmp__vendor c-cmp__vendor--1st-col"
        @click="showVendorData(vendor)"
      >
        <div class="c-cmp__vendor-name">
          {{ vendor.name }}
        </div>
      </div>
      <div
        class="c-cmp__vendor c-cmp__vendor--2nd-col"
        :class="{ 'c-cmp__hide' : !vendor.legIntPurposes.length && mobileOrTablet }"
      >
        <v-switch
          v-if="vendor.legIntPurposes.length"
          :item-id="vendor.id"
          :label="$t('cmp.legitimateInterest')"
          :checked="data.vendorLegitimateInterest.has(vendor.id)"
          @update="updateData($event, 'vendorLegitimateInterest')"
        />
      </div>
      <div
        :id="`vendor-consent-${vendor.id}`"
        class="c-cmp__vendor c-cmp__vendor--3rd-col"
      >
        <v-switch
          :item-id="vendor.id"
          :checked="data.vendorConsents.has(vendor.id)"
          @update="updateData($event, 'vendorConsents')"
        />
      </div>
      <vendor-details
        v-if="activeVendorId === vendor.id"
        class="c-cmp__vendor-details"
        :vendor="vendor"
      />
    </div>
  </section>
</template>

<script>
  import VSwitch from '@/components/switch'
  import VendorDetails from '@/components/popups/cmp/components/vendor-details'
  import mobileOrTabletMixin from '@/mixins/mobile-or-tablet-mixin'

  export default {
    components: {
      VSwitch,
      VendorDetails
    },
    mixins: [mobileOrTabletMixin],
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        activeVendorId: 0
      }
    },
    methods: {
      showVendorData (vendor) {
        const { id } = vendor
        this.activeVendorId = this.activeVendorId === id ? 0 : id
      },
      showMore (opts = {}) {
        const data = []
        const elementsList = this.data[opts.element]
        if (typeof elementsList === 'object' && elementsList !== null) {
          Object.entries(elementsList).map(item => {
            if (opts.ids.includes(parseInt(item?.[0]))) {
              data.push(item[1].name)
            }
          })
        }

        return data.length
          ? data.join(', ')
          : '-'
      },
      updateData (data, attr) {
        // @TODO refactor
        this.$parent.saveButtonDisabled = false
        this.$parent.$parent.updateData(data, attr)
      }
    }
  }
</script>
