<template>
  <div class="c-epg-program">
    <div class="c-epg-program__meta">
      <v-label
        v-if="isLive"
        class="c-epg-program__label"
      >
        {{ $t('program.live') }}
      </v-label>
      <span class="c-epg-program__time">
        {{ program.since | dateFormat('HH:mm') }} - {{ program.till | dateFormat('HH:mm') }}
      </span>
      <span class="c-epg-program__title u-typo--ellipsis">
        {{ program.title }}
      </span>
    </div>
    <div
      v-dompurify-html="program.description"
      class="c-epg-program__description"
    />
    <div v-if="isLoggedIn">
      <button
        v-if="!isLive"
        class="c-epg-program__action"
        :title="notificationButtonLabel"
        @click="notify"
      >
        <svg
          class="c-epg-program__icon o-icon"
          :class="{ 'c-epg-program__icon--active': isInNotifications }"
        >
          <use :xlink:href="notificationIcon" />
        </svg>
      </button>
      <!--      <button-->
      <!--        v-if="!isLive"-->
      <!--        class="c-epg-program__action"-->
      <!--        :title="recordButtonLabel"-->
      <!--        @click="record"-->
      <!--      >-->
      <!--        <i-->
      <!--          class="c-epg-program__icon o-icon o-icon&#45;&#45;downloads"-->
      <!--          :class="{ 'c-epg-program__icon&#45;&#45;active': isInRecordings }"-->
      <!--        />-->
      <!--      </button>-->
      <button
        v-if="isLive && broadcastAgreement && liveBroadcasting"
        class="c-epg-program__action"
        :title="$t('buttons.goLive')"
        @click="goLive"
      >
        <svg
          class="c-epg-program__icon c-epg-program__icon--broadcast o-icon"
          :class="{ 'c-epg-program__icon--broadcast--active': isBroadcasting }"
        >
          <use xlink:href="#icon-cast" />
        </svg>
      </button>
    </div>
  </div>
</template>
<script>
  import VLabel from '@/components/label'
  import isSafari from '@/assets/javascript/helpers/is-safari'

  export default {
    isSafari,
    components: {
      VLabel
    },
    props: {
      program: {
        type: Object,
        required: true
      }
    },
    computed: {
      notification () {
        return this.$store.getters['user/IS_IN_NOTIFICATIONS'](this.program.id)
      },
      notificationIcon () {
        if (this.isInNotifications) {
          return '#icon-dingdong_no'
        }

        return '#icon-dingdong'
      },
      isInNotifications () {
        return !!this.notification
      },
      isBroadcasting () {
        return false
      },
      isLive () {
        return this.$time().isBetween(this.program.since, this.program.till)
      },
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      },
      notificationButtonLabel () {
        if (this.isInNotifications) {
          return this.$t('buttons.removeFromNotifications')
        }

        return this.$t('buttons.addToNotifications')
      },
      broadcastAgreement () {
        if (this.isLoggedIn) {
          return this.$store.getters['user/BROADCAST_AGREEMENT']
        }
        return false
      },
      liveBroadcasting () {
        return this.program.liveBroadcasting
      }
    },
    methods: {
      notify () {
        this.$loader.show(this.$el.parentElement)

        if (this.notification) {
          return this.$store.dispatch('user/REMOVE_FROM_NOTIFICATIONS', this.program)
            .finally(() => this.$loader.hide(this.$el.parentElement))
        }

        if (!this.$options.isSafari() && Notification.permission !== 'granted') {
          this.$toast.permissionDenied(this.$t('notifications.programmes'))
        }

        this.$store.dispatch('user/ADD_TO_NOTIFICATIONS', this.program)
          .finally(() => this.$loader.hide(this.$el.parentElement))
      },
      goLive () {
        this.$popup.show(this.$dict.popups.BROADCAST_CONFIRMATION)
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
