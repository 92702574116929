<template>
  <nav
    class="c-header"
    :class="{ 'search-visible': searchVisible }"
  >
    <ul
      class="menu"
      :class="{ active: isMenuOpen }"
    >
      <li class="logo">
        <nuxt-link :to="{ name: $dict.routes.HOME }">
          <img
            src="~assets/images/logo-festival-group.svg"
            :alt="$t('imgAlt.logo')"
          >
        </nuxt-link>
      </li>
      <li
        v-for="(item, index) in menuItems"
        :key="index"
        class="item"
      >
        <nuxt-link
          :to="{ path: item.url }"
          class="c-header__link"
        >
          {{ item.name }}
        </nuxt-link>
      </li>

      <li
        v-if="canBroadcast"
        class="item"
      >
        <button
          v-if="isLarge"
          class="c-header__link c-header__link--transmission"
          @click="$popup.show($dict.popups.BROADCAST_CONFIRMATION)"
        >
          {{ $t("broadcast.menu.join") }}
        </button>
        <button
          v-else
          class="c-epg-program__action c-epg-program__action--small"
          :title="$t('broadcast.menu.join')"
          @click="$popup.show($dict.popups.BROADCAST_CONFIRMATION)"
        >
          <svg class="c-epg-program__icon o-icon o-icon--cast">
            <use xlink:href="#icon-broadcast" />
          </svg>
        </button>
      </li>
      <div class="placeholder item" />
      <li
        class="item button"
        @click="searchVisible = !searchVisible"
      >
        <div class="c-header__link  search-bar">
          <div @click.stop="">
            <search
              :search-visible="searchVisible"
              @toggle="searchVisible = !searchVisible"
              @open="searchVisible = true"
              @close="searchVisible = false"
            />
          </div>
        </div>
      </li>
      <div class="item button c-header__separator" />
      <li
        class="item button"
      >
        <profile-dropdown
          v-if="isLoggedIn"
          :is-parent-menu-open="isMenuOpen"
        />
        <div v-else>
          <nuxt-link
            :to="{
              name: $dict.routes.LOGIN,
              query: { redirect: this.$route.fullPath }
            }"
            class="c-header__link"
          >
            {{ $t("header.login") }}
          </nuxt-link>
        </div>
      </li>
      <li
        v-if="userNotifications.length"
        class="item button"
      >
        <notification-dropdown :notifications="userNotifications" />
      </li>
      <li class="toggle">
        <button @click="toggleMenu">
          <hamburger :is-open="isMenuOpen" />
        </button>
      </li>
    </ul>
  </nav>
</template>
<script>
  import Search from '@/layouts/components/page-header/search'
  import Hamburger from '@/layouts/components/page-header/hamburger'
  import ProfileDropdown from '@/layouts/components/page-header/profile-dropdown/index'
  import NotificationDropdown from '@/layouts/components/page-header/notification-dropdown/index'
  import trackingMixin from '@/mixins/tracking-mixin'
  import { breakpointGetters } from 'assets/javascript/responsive'
  import { IS_LARGE } from 'assets/javascript/responsive/enums'
  import { MAIN } from 'assets/javascript/enums/menus'
  const ITEM = 'ITEM'

  export default {
    MAIN,
    components: {
      Hamburger,
      NotificationDropdown,
      Search,
      ProfileDropdown
    },
    mixins: [trackingMixin],
    data () {
      return {
        searchVisible: false,
        isMenuOpen: false
      }
    },
    computed: {
      canBroadcast () {
        return (
          this.broadcastAgreement &&
          this.liveBroadcasting &&
          this.$route.name !== this.$dict.routes.TRANSMISSION &&
          this.$route.name !== this.$dict.routes.BROADCAST
        )
      },
      isLarge () {
        return breakpointGetters[IS_LARGE]()
      },
      menuItems () {
        const menu = this.$store.state.menu.menus['menu-web'][this.$options.MAIN]
        return menu.filter(item => !(item.isLogged && !this.isLoggedIn))
      },
      user () {
        return this.$store.state.user.data
      },
      userNotifications () {
        return this.$store.state.user.notifications.filter(
          item => item.type === ITEM
        )
      },
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      },
      broadcastAgreement () {
        return this.isLoggedIn
          ? this.$store.getters['user/BROADCAST_AGREEMENT']
          : false
      },
      liveBroadcasting () {
        return this.$store.getters['epg/LIVE_BROADCAST_CURRENT_EPG_PROGRAMME']
          .liveBroadcasting
      }
    },
    watch: {
      $route () {
        this.isMenuOpen = false
      }
    },
    methods: {
      toggleMenu () {
        this.isMenuOpen = !this.isMenuOpen
      }
    }
  }
</script>
<style src="./styles.scss" lang="scss" />
