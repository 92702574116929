<template>
  <section class="c-cmp__consents-view">
    <h1 class="c-cmp__title">
      {{ data.consentList.bannerTitle }}
      <button
        v-if="showBackButton"
        class="c-cmp__button c-cmp__button--success c-cmp__button--inline o-button"
        @click="$emit('back')"
      >
        {{ $t('buttons.goBack') }}
      </button>
    </h1>
    <div
      v-dompurify-html="data.consentList"
      class="c-cmp__content"
      @click="handleClick"
    />
    <button
      v-if="!showVendorsLocal"
      class="c-cmp__button c-cmp__button--success o-button"
      @click="toggleVendors"
    >
      {{ $t('cmp.trustedPartners') }}
    </button>
    <template v-else>
      <div class="c-cmp__partners">
        <h3>
          {{ $t('cmp.WOWPartners') }}
        </h3>
      </div>
      <div
        class="c-cmp__partners-close"
        @click="closeVendors"
      >
        X
      </div>
    </template>
    <vendor-list
      v-if="showVendorsLocal"
      :data="{
        purposes: data.purposes,
        vendors: data.vendors,
        vendorConsents: data.vendorConsents,
        vendorLegitimateInterest: data.vendorLegitimateInterest
      }"
      :save-button-disabled="saveButtonDisabled"
      @changed="enableSaveButton"
    />
    <template v-else>
      <purpose-list
        :data="{
          showTitle: true,
          subtitle: $t('cmp.purposes'),
          purposes: data.purposes,
          purposesConsents: data.purposesConsents,
          purposesLITransparencys: data.purposesLITransparencys,
          type: 'purposes',
          dataAttr: 'purposesConsents',
          showLIT: true,
          visibleData: data.purposes
        }"
        @updated="isCheckboxValueChange"
        @vendors="scrollAndShowVendors"
      />
      <checkbox
        v-model="checkboxValue"
        :label="specialAgreement"
        @clicked="isClickedByUser"
      />
      <feature-list
        :title="$t('cmp.specialPurposes')"
        :data="data.specialPurposes"
        type="special-purpose"
        @vendors="scrollAndShowVendors"
      />
      <feature-list
        :title="$t('cmp.features')"
        :data="data.features"
        type="features"
        @vendors="scrollAndShowVendors"
      />
      <purpose-list
        :data="{
          showTitle: false,
          subtitle: $t('cmp.specialFeatures'),
          specialFeatures: data.specialFeatures,
          specialFeaturesPurposes: data.specialFeaturesPurposes,
          purposesLITransparencys: data.purposesLITransparencys,
          type: 'specialFeatures',
          dataAttr: 'specialFeaturesPurposes',
          showLIT: false,
          visibleData: data.specialFeatures
        }"
        @vendors="scrollAndShowVendors"
      />
    </template>
    <div class="c-cmp__container">
      <div class="c-cmp__buttons-container c-cmp__buttons-container--margin">
        <button
          class="c-cmp__button c-cmp__button--fixed-width c-cmp__button--outline o-button"
          :class="{ 'c-cmp__button--fixed-width-small' : TCString }"
          @click="$emit('update', { acceptAll: false, saveButtonDisabled })"
        >
          {{ updateButtonText }}
        </button>
        <button
          v-if="TCString"
          class="c-cmp__button c-cmp__button--fixed-width-small c-cmp__button--outline c-cmp__button--reject o-button"
          @click="$emit('update', { rejectAll: true })"
        >
          {{ $t('cmp.rejectAll') }}
        </button>
        <button
          class="c-cmp__button c-cmp__button--success c-cmp__button--fixed-width o-button"
          @click="$emit('update', { acceptAll: true })"
        >
          {{ $t('cmp.acceptAll') }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
  import VendorList from '@/components/popups/cmp/components/vendor-list'
  import FeatureList from '@/components/popups/cmp/components/feature-list'
  import PurposeList from '@/components/popups/cmp/components/purpose-list'
  import Checkbox from '@/components/checkbox'
  import { PURPOSES_LI_TRANSPARENCYS } from '@/enums/purpose-type'

  const vendorsId = 'cmp-vendors'
  export default {
    components: {
      VendorList,
      FeatureList,
      PurposeList,
      Checkbox
    },
    props: {
      data: {
        type: Object,
        required: true
      },
      showBackButton: {
        type: Boolean,
        required: true
      },
      showVendors: {
        type: Boolean,
        required: true
      }
    },
    data () {
      return {
        saveButtonDisabled: true,
        showVendorsLocal: this.showVendors,
        checkboxValue: !this.$parent.purposesLITransparencys.size,
        isClicked: false,
        specialAgreement: 'Wyrażam sprzeciw dla przetwarzania moich danych na podstawie Uzasadnionego Interesu w odniesieniu do ' +
          'wszystkich wymienionych powyżej celów Zaufanych Partnerów z IAB'
      }
    },
    computed: {
      TCString () {
        return this.$store.state.cmp.TCString
      },
      updateButtonText () {
        return this.saveButtonDisabled
          ? this.$t('cmp.quit')
          : this.$t('cmp.save')
      }
    },
    watch: {
      checkboxValue () {
        if (this.checkboxValue && this.isClicked) {
          Object.keys(this.data.purposes).forEach((item) => {
            this.changeAllIntrest(item, false)
          })
          this.saveButtonDisabled = false
        } else if (this.isClicked) {
          Object.keys(this.data.purposes).forEach((item) => {
            this.changeAllIntrest(item, true)
          })
          this.saveButtonDisabled = false
        }
      }
    },
    methods: {
      changeAllIntrest (item, isChecked) {
        this.$parent.updateData({ id: this.data.purposes[item].id, isChecked: isChecked }, PURPOSES_LI_TRANSPARENCYS)
      },
      scrollAndShowVendors () {
        this.showVendorsLocal = true
        this.$emit('vendors', true)
        this.$nextTick(() => {
          document.querySelector('.c-popup').scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        })
      },
      closeVendors () {
        this.showVendorsLocal = false
        this.$emit('vendors', false)
      },
      toggleVendors () {
        this.showVendorsLocal = !this.showVendorsLocal
        this.$emit('vendors', this.showVendorsLocal)
      },
      toggleDetails (mode, id) {
        const ref = this.$refs[`${mode}-${id}`]?.[0]
        let refDisplay = ref.style.display

        if (!ref) {
          return
        }

        if (refDisplay === 'none' || refDisplay === '') {
          refDisplay = 'block'
          return
        }

        refDisplay = 'none'
      },
      enableSaveButton () {
        this.saveButtonDisabled = false
      },
      isCheckboxValueChange () {
        this.$parent.purposesLITransparencys.size > 0 ? this.checkboxValue = false : this.checkboxValue = true
      },
      isClickedByUser () {
        this.isClicked = true
      },
      handleClick (event) {
        if (event.target.id === vendorsId) {
          this.toggleVendors()
        }
      }
    }
  }
</script>
