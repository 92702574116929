<template>
  <div class="row expanded c-profiles-form">
    <div class="column c-profiles-form__section medium-12 large-6">
      <div class="row expanded">
        <div class="column small-3" />
        <div class="column small-9">
          <h2 class="c-profiles-form__title">
            {{ mainHeading }}
          </h2>
        </div>
      </div>
      <div class="row expanded c-profiles-form__group">
        <div class="column small-3">
          <avatar
            :edit="false"
            :profile="preProfile"
            class="c-profiles-form__avatar"
          />
        </div>
        <div class="column small-9">
          <div class="o-form__group">
            <label
              class="c-profiles-form__label"
              for="input-profile"
            >
              {{ $t('profiles.username') }}
            </label>
            <v-input
              id="input-profile"
              ref="profileInput"
              v-model="preProfile.name"
              type="text"
              :tooltip="$t('tooltips.name')"
              :tooltip-position="mobileOrTablet ? 'left' : 'right'"
              :max-length="20"
              class="o-input__wrapper--small-margin c-profiles-form__input-wrapper"
              :validation-rules="[validationRules.USERNAME]"
              @changedValidity="setFormValidity('name', $event)"
            />
          </div>
          <!--          <div class="o-form__group c-profiles-form__checkbox-container">-->
          <!--            <checkbox-->
          <!--              v-model="preProfile.kids"-->
          <!--              :label="$t('profiles.kid')"-->
          <!--            />-->
          <!--          </div>-->
        </div>
      </div>
      <div class="row expanded">
        <div class="column small-0 large-3" />
        <div class="column small-12 large-9">
          <avatar-list
            @setAvatar="setProfileAvatar"
            @resetAvatar="resetAvatarToEmpty"
          />
        </div>
      </div>
    </div>

    <div class="column c-profiles-form__section small-12 large-6">
      <div class="row expanded">
        <div class="column small-12">
          <h2 class="c-profiles-form__title">
            {{ $t('profiles.additionalInfo') }}
          </h2>
        </div>
      </div>
      <div class="row expanded collapse">
        <div class="column small-12">
          <div class="row">
            <div class="column small-12">
              <div class="row collapse">
                <div class="column small-12">
                  <div class="c-profiles-form__label">
                    {{ $t('profiles.aboutMe.birthdayLabel') }}:
                  </div>
                </div>
                <div class="column small-12 c-profiles-form__selects-container">
                  <v-multiselect
                    v-for="(option, index) in dateOfBirthSelectOptions"
                    :key="index"
                    v-model="preProfile[option.model]"
                    class="o-select"
                    :options="options[option.options]"
                    :placeholder="birthdayPlaceholders[option.placeholder]"
                    :show-labels="false"
                    :custom-label="dateOfBirthLabel"
                    @input="option.calculateDays && calculateDays()"
                  >
                    <span slot="noResult">
                      {{ $t('components.select.noResults') }}
                    </span>
                  </v-multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column small-12">
          <div class="row">
            <div class="column small-6">
              <div class="c-profiles-form__label">
                {{ $t('profiles.aboutMe.cityLabel') }}:
              </div>
              <v-input
                v-model="preProfile.city"
                type="text"
                :max-length="20"
                :placeholder="$t('profiles.aboutMe.cityLabel')"
                class="o-input__wrapper--small-margin c-profiles-form__input-wrapper"
                :validation-rules="[validationRules.USERNAME]"
                @changedValidity="setFormValidity('city', $event)"
              />
            </div>
            <div class="column small-6">
              <div class="c-profiles-form__label">
                {{ $t('profiles.aboutMe.zipCodeLabel') }}:
              </div>
              <masked-input
                v-model="preProfile.zipCode"
                mask="XX-XXX"
                placeholder="XX-XXX"
                class="o-input__wrapper--small-margin c-profiles-form__input-wrapper"
                :restriction-rules="[restrictionRules.ZIP_CODE]"
                :validation-rules="[validationRules.ZIP_CODE]"
              />
            </div>
          </div>
        </div>
        <div class="column small-12">
          <div class="row">
            <div class="column small-12">
              <div class="c-profiles-form__label">
                {{ $t('profiles.aboutMe.categoriesLabel') }}:
              </div>
            </div>
            <div
              v-for="(opts, type) in favourites"
              :key="type"
              class="column small-12 c-profiles-form__selects-container"
            >
              <v-multiselect
                v-model="favouritesModel[type]"
                class="o-select"
                :placeholder="favouritesNames[type]"
                :multiple="true"
                :options="opts"
                track-by="id"
                :show-labels="false"
                :custom-label="customLabel"
                @input="updateProfileData"
              >
                <span slot="noResult">
                  {{ $t('components.select.noResults') }}
                </span>
              </v-multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import restrictionRules from '@/assets/javascript/dictionaries/input-restriction-rules'
  import validationRules from '@/assets/javascript/dictionaries/input-validation-rules'

  import Avatar from '@/components/avatar'
  import VInput from '@/components/input'
  import MaskedInput from '@/components/masked-input'
  import VMultiselect from 'vue-multiselect'

  import formValidityMixin from '@/mixins/form-validity-mixin'
  import mobileOrTabletMixin from '@/mixins/mobile-or-tablet-mixin'

  import {
    FEMALE,
    MALE,
    TYPE_ACTOR,
    TYPE_TITLE,
    TYPE_CATEGORY,
    DAY,
    MONTH,
    YEAR
  } from './enum'
  import AvatarList from '@/components/profiles/avatar-list/index'

  export default {
    name: 'ProfilesForm',
    components: {
      AvatarList,
      Avatar,
      VInput,
      MaskedInput,
      VMultiselect
    },
    mixins: [
      formValidityMixin,
      mobileOrTabletMixin
    ],
    props: {
      profile: {
        type: Object,
        default: () => ({})
      },
      errors: {
        type: Object,
        default: () => ({})
      }
    },
    data () {
      return {
        favouritesModel: {
          [TYPE_ACTOR]: [],
          [TYPE_TITLE]: [],
          [TYPE_CATEGORY]: []
        },
        favouritesNames: {
          [TYPE_ACTOR]: this.$t('profiles.aboutMe.favourites.actors'),
          [TYPE_TITLE]: this.$t('profiles.aboutMe.favourites.titles'),
          [TYPE_CATEGORY]: this.$t('profiles.aboutMe.favourites.categories')
        },
        birthdayPlaceholders: {
          [DAY]: this.$t('profiles.aboutMe.birthday.day'),
          [MONTH]: this.$t('profiles.aboutMe.birthday.month'),
          [YEAR]: this.$t('profiles.aboutMe.birthday.year')
        },
        preProfile: {
          id: '',
          name: '',
          avatar: {},
          city: '',
          kids: '',
          birthDay: '',
          birthMonth: '',
          birthYear: '',
          sex: {
            value: '',
            description: ''
          },
          zipCode: '',
          favourites: []
        },
        validity: {
          name: false
        },
        error: '',
        options: {
          days: [],
          months: [
            { name: this.$t('common.months.january'), value: 1 },
            { name: this.$t('common.months.february'), value: 2 },
            { name: this.$t('common.months.march'), value: 3 },
            { name: this.$t('common.months.april'), value: 4 },
            { name: this.$t('common.months.may'), value: 5 },
            { name: this.$t('common.months.june'), value: 6 },
            { name: this.$t('common.months.july'), value: 7 },
            { name: this.$t('common.months.august'), value: 8 },
            { name: this.$t('common.months.september'), value: 9 },
            { name: this.$t('common.months.october'), value: 10 },
            { name: this.$t('common.months.november'), value: 11 },
            { name: this.$t('common.months.december'), value: 12 }
          ],
          years: [],
          sex: [
            {
              description: this.$t('views.aboutMe.female'),
              value: FEMALE
            },
            {
              description: this.$t('views.aboutMe.male'),
              value: MALE
            }
          ]
        },
        restrictionRules,
        validationRules,
        profileData: {}
      }
    },
    computed: {
      favourites () {
        return this.splitFavourites(this.$store.state.user.favourites)
      },
      user () {
        return this.$store.state.user.data
      },
      dateOfBirthSelectOptions () {
        return [
          {
            calculateDays: false,
            model: 'birthDay',
            options: 'days',
            placeholder: 'day'
          },
          {
            calculateDays: true,
            model: 'birthMonth',
            options: 'months',
            placeholder: 'month'
          },
          {
            calculateDays: true,
            model: 'birthYear',
            options: 'years',
            placeholder: 'year'
          }
        ]
      },
      mainHeading () {
        return this.$route.name === this.$dict.routes.ADD_PROFILE
          ? this.$t('profiles.add')
          : this.$t('profiles.edit')
      }
    },
    async created () {
      const { id } = this.profile

      if (id) {
        await this.fetchDetail(id)
        const profile = this.getDetail(id)
        Object.assign(this.preProfile, profile)
      }

      const sex = this.profile.sex
      this.preProfile.sex = {
        description: sex && this.$t(`views.aboutMe.${sex.toLowerCase()}`),
        value: sex
      }
      this.preProfile.birthMonth = this.options.months
        .find(item => item.value === parseInt(this.preProfile.birthMonth))
      if (this.preProfile.birthDay) {
        this.preProfile.birthDay = parseInt(this.preProfile.birthDay)
        this.preProfile.birthYear = parseInt(this.preProfile.birthYear)
      }
      this.getAvatars()
      this.getFavouritesOptions()
      this.fillDateOptions()
      this.favouritesModel = this.splitFavourites(this.preProfile.favourites)
    },
    mounted () {
      this.$nextTick(() => {
        this.$refs.profileInput.$refs.input.focus()
      })
    },
    methods: {
      customLabel ({ description }) {
        return description
      },
      dateOfBirthLabel (data) {
        return data?.name || data
      },
      splitFavourites (favourites) {
        const splitted = {
          [TYPE_ACTOR]: [],
          [TYPE_TITLE]: [],
          [TYPE_CATEGORY]: []
        }
        favourites.forEach((item) => {
          splitted[item.type].push(item)
        })
        return splitted
      },
      updateProfileData () {
        this.preProfile.favourites = [...this.favouritesModel[TYPE_ACTOR], ...this.favouritesModel[TYPE_CATEGORY], ...this.favouritesModel[TYPE_TITLE]]
      },
      setProfileAvatar (selectedAvatar) {
        this.preProfile.avatar = selectedAvatar
      },
      resetAvatarToEmpty () {
        this.preProfile.avatar = {}
      },
      fetchDetail (id) {
        return this.$store.dispatch('profiles/GET_PROFILE', id)
      },
      getDetail (id) {
        return this.$store.getters['user/PROFILE_BY_ID'](id)
      },
      getAvatars () {
        this.$store.dispatch('profiles/GET_AVATARS')
      },
      getFavouritesOptions () {
        this.$store.dispatch('user/GET_FAVOURITES_OPTIONS')
      },
      calculateDays () {
        this.options.days = []
        const daysInSelectedMonth = this.$time(`${this.preProfile.birthYear}-${this.preProfile.birthMonth?.value}`).daysInMonth()

        if (!daysInSelectedMonth) {
          this.fillArray(this.options.days, 1, 31)
        } else {
          this.fillArray(this.options.days, 1, daysInSelectedMonth)
        }

        if (!this.monthContainsDay() && daysInSelectedMonth) {
          this.preProfile.birthDay = 1
        }
      },
      fillArray (array, firstNumber, lastNumber) {
        for (let i = firstNumber; i <= lastNumber; i++) {
          array.push(i)
        }
      },
      fillDateOptions () {
        this.calculateDays()
        this.fillArray(this.options.years, 1900, this.$time().year())
        this.options.years.reverse()
      },
      monthContainsDay () {
        return this.options.days.includes(parseInt(this.preProfile.birthDay))
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
