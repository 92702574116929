<template functional>
  <div class="c-error c-error--default">
    <div class="c-error__content">
      <svg class="o-icon c-error__icon">
        <use xlink:href="#icon-error" />
      </svg>
      <h1 class="c-error__heading">
        {{ parent.$t('errors.typeDefault') }}
      </h1>
      <h2 class="c-error__description">
        {{ parent.$t('errors.messageDescription') }}
      </h2>
    </div>
    <nuxt-link
      :to="{ name: parent.$dict.routes.HOME }"
      class="o-button"
    >
      {{ parent.$t('buttons.goBackToHomePage') }}
    </nuxt-link>
  </div>
</template>
<script>
  export default {
    name: 'ErrorDefault'
  }
</script>
