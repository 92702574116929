<template functional>
  <div class="c-success">
    <slot />
  </div>
</template>
<script>
  export default {
    name: 'SuccessView'
  }
</script>
<style lang="scss" src="./styles.scss" />
