<template>
  <section class="c-account">
    <nuxt-link
      :to="{ name: $dict.routes.MY_ACCOUNT_PROFILE }"
      class="c-account__title"
    >
      {{ $t('views.myAccount.myAccount') }}
    </nuxt-link>
    <separator class="o-separator--rwd" />
    <nuxt-link
      :to="{ name: $dict.routes.TRANSACTIONS }"
      class="c-account__title"
    >
      {{ $t('views.myAccount.transactions') }}
    </nuxt-link>
    <separator class="o-separator--rwd" />
    <nuxt-link
      :to="{ name: $dict.routes.DEVICES }"
      class="c-account__title"
    >
      {{ $t('views.myAccount.devices') }}
    </nuxt-link>
    <template v-if="!mobileOrTablet">
      <separator class="o-separator--rwd" />
      <nuxt-link
        :to="{ name: $dict.routes.MESSAGES }"
        class="c-account__title"
      >
        {{ $t('views.myAccount.messages.title') }}
      </nuxt-link>
      <separator class="o-separator--rwd" />
      <nuxt-link
        :to="{ name: $dict.routes.ISSUES }"
        class="c-account__title"
      >
        {{ $t('views.myAccount.issues.title') }}
      </nuxt-link>
      <separator class="o-separator--rwd" />
      <nuxt-link
        :to="{ name: $dict.routes.BROADCAST }"
        class="c-account__title"
      >
        {{ $t('views.myAccount.broadcast.title') }}
      </nuxt-link>
    </template>
    <separator class="o-separator--horizontal" />
    <router-view />
  </section>
</template>

<script>
  import Separator from '@/components/separator/index'
  import mobileOrTabletMixin from '@/mixins/mobile-or-tablet-mixin'

  export default {
    components: { Separator },
    mixins: [mobileOrTabletMixin],

    head () {
      return {
        title: this.$t('views.myAccount.title')
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
