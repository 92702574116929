<template functional>
  <div>
    <div
      class="c-product-card__img-wrapper u-img-16-9"
      :class="{ 'c-product-card__img-loader' : parent.loaderActive }"
    >
      <img
        v-lazy="parent.thumbnail"
        :src="parent.thumbnail"
        :alt="parent.product.title"
        class="c-product-card__img u-abs-center"
        @load="parent.hideLoader"
      >
      <progress-bar
        :current-progress="parent.progress"
      />
      <nuxt-link
        class="o-button o-button--overlay u-abs-center"
        :to="{ name: parent.$dict.routes.WATCH, params: { type: parent.product.type.toLowerCase(), productId: parent.product.id } }"
      >
        <svg class="o-icon o-icon--visible-on-hover c-product-card__icon o-button--icon">
          <use xlink:href="#icon-play-circle" />
        </svg>
      </nuxt-link>
    </div>
    <div>
      <h3 class="c-product-card__title">
        {{ parent.product.title }}
      </h3>
      <div
        class="c-product-card__length-info"
      >
        <p class="c-product-card__number">
          {{ parent.$t('episode') }} {{ parent.product.episode }}
        </p>
        <p>
          {{ parent.product.duration | TimeFormatHourMinSec }}
        </p>
      </div>
      <p
        class="c-product-card__lead"
      >
        {{ parent.product.lead }}
      </p>
    </div>
  </div>
</template>

<script>
  import { TimeFormatHourMinSec } from 'atds-player-layout/src/filters/time-format'

  export default {
    filters: {
      TimeFormatHourMinSec
    }
  }
</script>
