<template>
  <section>
    <div
      v-dompurify-html="document"
      class="c-cmp__1st-layer"
    />
    <div class="c-cmp__buttons-container c-cmp__buttons-container--margin">
      <button
        class="c-cmp__button c-cmp__button--fixed-width c-cmp__button--outline o-button"
        @click="$emit('toggle')"
      >
        {{ $t('cmp.advancedSettings') }}
      </button>
      <button
        class="c-cmp__button c-cmp__button--success c-cmp__button--fixed-width o-button"
        @click="$emit('update', { acceptAll: true })"
      >
        {{ $t('cmp.acceptAll') }}
      </button>
    </div>
  </section>
</template>

<script>
  export default {
    props: {
      document: {
        type: String,
        required: true
      }
    }
  }
</script>
