<template>
  <div
    v-if="canToggle"
    class="c-action-buttons__button"
  >
    <div
      class="c-toggle-favourites__icon-link"
      @click="toggle"
    >
      <svg class="o-icon">
        <use :xlink:href="icon" />
      </svg>
      <div class="c-action-buttons__title">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
  import {
    PRODUCT_TYPE_TO_VIDEO_TYPE
  } from '@/assets/javascript/maps/product-type-to-video-type'

  import {
    LIVE,
    VOD,
    PROGRAMME,
    SERIAL
  } from '@/assets/javascript/enums/product-types'

  import {
    ACTION
  } from '@/assets/javascript/dictionaries/gtmEvents'

  import {
    ADD_TO_FAVOURITES,
    REMOVE_FROM_FAVOURITES
  } from '@/assets/javascript/dictionaries/gtmEventsActions'

  export default {
    name: 'ToggleFavourites',
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    computed: {
      canToggle () {
        return [LIVE, VOD, PROGRAMME, SERIAL].includes(this.product.type)
      },
      icon () {
        return `#icon-my-list${this.isFav ? '-fill' : ''}`
      },
      isFav () {
        return this.$store.getters['favourites/IS_FAVOURITE'](this.product.id)
      },
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      },
      title () {
        return this.isFav
          ? this.$t('buttons.removeFromFavourites')
          : this.$t('buttons.addToFavourites')
      }
    },
    methods: {
      toggle () {
        if (!this.isLoggedIn) {
          return this.$router.push({ name: this.$dict.routes.LOGIN, query: { redirect: this.$route.fullPath } })
        }

        const type = this.product.type
        const product = {
          ...this.product,
          videoType: PRODUCT_TYPE_TO_VIDEO_TYPE[type]
        }

        this.isFav
          ? this.removeFromFavourites(product)
          : this.addToFavourites(product)
      },
      addToFavourites (product) {
        const profile = this.$store.getters['user/ACTIVE_PROFILE']
        const payload = {
          ...product,
          profile
        }
        this.$tracking.events.send(ACTION, {
          product: {
            id: payload.id
          },
          action: ADD_TO_FAVOURITES
        })
        this.$store.dispatch('favourites/ADD_FAVOURITE', payload)
      },
      removeFromFavourites (product) {
        const { id, token } = this.$store.getters['user/ACTIVE_PROFILE']
        const payload = {
          ...product,
          profileId: id,
          profileToken: token
        }
        this.$tracking.events.send(ACTION, {
          product: {
            id: payload.id
          },
          action: REMOVE_FROM_FAVOURITES
        })
        this.$store.dispatch('favourites/REMOVE_FAVOURITE', payload)
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
