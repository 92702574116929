<template>
  <section>
    <div
      v-if="!mobileOrTablet"
      class="row expanded collapse"
    >
      <div class="column small-4">
        <div class="c-transactions__label">
          {{ $t('views.transactions.name') }}:
        </div>
      </div>
      <div class="column small-2">
        <div class="c-transactions__label">
          {{ $t('views.transactions.payment') }}:
        </div>
      </div>
      <div class="column small-2">
        <div class="c-transactions__label">
          {{ $t('views.transactions.since') }}:
        </div>
      </div>
      <div class="column small-2">
        <div class="c-transactions__label">
          {{ $t('views.transactions.till') }}:
        </div>
      </div>
      <div class="column small-2">
        <div class="c-transactions__label">
          {{ $t('views.transactions.status.title') }}:
        </div>
      </div>
      <div class="column small-12">
        <separator class="o-separator--horizontal o-separator--horizontal-navbar" />
      </div>
    </div>
    <pagination :callback="loadMore">
      <div
        v-for="transaction in transactions"
        :key="transaction.id"
      >
        <div
          class="c-transactions__row row expanded collapse"
          :class="{ 'c-transactions__row--confirmed' : isConfirmed(transaction) }"
        >
          <div class="column small-12 large-4">
            <div
              v-if="mobileOrTablet"
              class="c-transactions__label"
            >
              {{ $t('views.transactions.name') }}:
            </div>
            <div class="c-transactions__text">
              {{ transaction.product.title }}
            </div>
          </div>
          <div class="column small-12 large-2">
            <div class="c-transactions__text">
              <span
                v-if="mobileOrTablet"
                class="c-transactions__label"
              >
                {{ $t('views.transactions.payment') }}:
              </span>
              {{ $t(`views.transactions.channel.${transaction.channel.toLowerCase()}`) }}
            </div>
          </div>
          <div class="column small-12 large-2">
            <div
              v-if="transaction.since"
              class="c-transactions__text"
            >
              <span
                v-if="mobileOrTablet"
                class="c-transactions__label"
              >
                {{ $t('views.transactions.since') }}:
              </span>
              {{ transaction.since | dateFormat('DD.MM.YYYY, HH:mm') }}
            </div>
          </div>
          <div class="column small-12 large-2">
            <div
              v-if="transaction.till"
              class="c-transactions__text"
            >
              <span
                v-if="mobileOrTablet"
                class="c-transactions__label"
              >
                {{ $t('views.transactions.till') }}:
              </span>
              {{ transaction.till | dateFormat('DD.MM.YYYY, HH:mm') }}
            </div>
          </div>
          <div class="column small-12 large-2">
            <div class="c-transactions__status">
              <span
                v-if="mobileOrTablet"
                class="c-transactions__label"
              >
                {{ $t('views.transactions.status.title') }}:&nbsp;&nbsp;
              </span>
              <span
                class="c-transactions__status-icon"
                :class="{ 'c-transactions__status-icon--confirmed' : isConfirmed(transaction) }"
              />
              {{ $t(`views.transactions.status.${transaction.status.toLowerCase()}`) }}
            </div>
          </div>
        </div>
        <separator class="o-separator--horizontal o-separator--horizontal-no-margin" />
      </div>
    </pagination>
  </section>
</template>
<script>
  import { DEFAULT_MAX_PAYMENTS } from '@/store/user/actions'
  import { CONFIRMED } from '@/assets/javascript/enums/payment-status'

  import Pagination from '@/components/pagination'
  import Separator from '@/components/separator/index'

  import mobileOrTabletMixin from '@/mixins/mobile-or-tablet-mixin'

  export default {
    components: {
      Separator,
      Pagination
    },
    mixins: [mobileOrTabletMixin],
    data () {
      return {
        transactions: []
      }
    },
    created () {
      if (Notification.permission !== 'granted') {
        this.$toast.permissionDenied(this.$t('notifications.payments'))
      }
    },
    methods: {
      loadMore (loaded, completed) {
        return this.$store.dispatch('user/GET_PAYMENTS', { firstResult: this.transactions.length })
          .then((res) => {
            if (!res) {
              return completed()
            }
            const results = res.items
            loaded()
            if (this.noMoreResults(results)) {
              completed()
            }
            this.transactions = [...this.transactions, ...results]
          })
      },
      noMoreResults (results) {
        const length = results.length
        return !length || length < DEFAULT_MAX_PAYMENTS
      },
      isConfirmed (transaction) {
        return transaction.status === CONFIRMED
      }
    },
    head () {
      return {
        title: this.$t('views.transactions.title')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
