<template>
  <section>
    <h1 class="c-session-expired__title">
      {{ $t('httpSession.popup.title') }}
    </h1>
    <div class="c-session-expired__description">
      {{ $t('httpSession.popup.description') }}
    </div>
    <div class="c-session-expired__buttons-container">
      <button
        class="o-button"
        @click="login"
      >
        {{ $t('buttons.loginAgain') }}
      </button>
      <button
        class="o-button o-button--secondary"
        @click="cancel"
      >
        {{ $t('buttons.cancel') }}
      </button>
    </div>
  </section>
</template>

<script>
  export default {
    computed: {
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      }
    },
    methods: {
      login () {
        this.$router.push({
          name: this.$dict.routes.LOGIN,
          query: {
            redirect: this.$route.fullPath
          }
        })
        this.$emit('close')
      },
      cancel () {
        this.$router.push({ name: this.$dict.routes.HOME })
        this.$emit('close')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
