<template>
  <div class="c-epg-list">
    <product-rating
      v-if="liveProgramme"
      :product="liveProgramme"
    />
    <div
      class="c-epg-list__arrow-container"
      @click="toggle"
    >
      <svg
        class="o-icon"
        :class="isOpen ? 'c-epg-list__icon-down' : 'c-epg-list__icon-up'"
      >
        <use xlink:href="#icon-right" />
      </svg>
    </div>
    <drawer
      :open="isOpen"
      class="c-epg-list__programs"
    >
      <styled-scroll>
        <div class="c-epg-list__content">
          <div
            v-for="(program, key) in epgPrograms"
            :key="key"
            class="c-epg-list__item"
          >
            <epg-program :program="program" />
          </div>
        </div>
      </styled-scroll>
    </drawer>
  </div>
</template>
<script>
  import Drawer from '@/components/drawer/index'
  import EpgProgram from '@/components/epg-program'
  import StyledScroll from '@/components/styled-scroll'
  import ProductRating from '@/components/product-rating'

  export default {
    name: 'EpgList',
    components: {
      Drawer,
      EpgProgram,
      StyledScroll,
      ProductRating
    },
    props: {
      isOpen: {
        type: Boolean,
        default: false
      }
    },
    async asyncData ({ store }) {
      const epgPrograms = await store.dispatch('epg/GET', { id: process.env.DEFAULT_LIVE_CHANNEL })
      return { epgPrograms }
    },
    data () {
      return {
        epgPrograms: [],
        liveProgramme: {}
      }
    },
    computed: {
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      }
    },
    mounted () {
      this.getEpg()
    },
    methods: {
      toggle () {
        this.$emit('toggled')
      },
      getEpg () {
        return this.$store.dispatch('epg/GET', { id: process.env.DEFAULT_LIVE_CHANNEL })
          .then((live) => {
            this.epgPrograms = live
            this.liveProgramme = live.find(program => this.$time().isBetween(program.since, program.till))
            if (this.liveProgramme) {
              const playTime = this.isLoggedIn
                ? this.$store.getters['scores/RATING'](this.liveProgramme.id)?.playTime || 0
                : 0

              this.liveProgramme = { ...this.liveProgramme, playTime }
            }
          })
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
