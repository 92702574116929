<template>
  <div class="c-payment-failure">
    <div class="c-payment-failure__container-top">
      <h2 class="c-payment-failure__title">
        {{ $t('payments.popup.failure.title') }}
      </h2>
    </div>
    <div class="c-payment-failure__container-bottom">
      <div class="c-payment-failure__container-icon">
        <svg class="c-payment-failure__icon o-icon">
          <use xlink:href="#icon-failure" />
        </svg>
      </div>
      <div class="c-payment-failure__description">
        {{ $t('payments.popup.failure.description') }}
      </div>
      <div class="c-payment-failure__container-cta">
        <button
          class="o-button o-button--transparent o-button--failure"
          @click="redirectToPayments"
        >
          {{ $t('buttons.tryAgain') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PaymentFailure',
    methods: {
      redirectToPayments () {
        this.$router.push({
          name: this.$dict.routes.MY_ACCOUNT_PROFILE
        })
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
