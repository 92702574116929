<template>
  <section>
    <slot />
    <infinite-loading
      :identifier="infiniteId"
      @infinite="infiniteHandler"
    >
      <template #no-results>
        <slot name="test" />
      </template>
      <template #no-more>
        <span />
      </template>
      <template #spinner />
    </infinite-loading>
  </section>
</template>
<script>
  export default {
    props: {
      callback: {
        type: Function,
        required: true
      },
      infiniteId: {
        type: Number,
        default: new Date().getTime()
      }
    },
    methods: {
      infiniteHandler ($state) {
        this.callback($state.loaded, $state.complete)
      }
    }
  }
</script>
