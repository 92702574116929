<template>
  <section>
    <watch-future
      v-if="isFutureProgramme"
      :product="product"
      @countdownFinished="checkIfProgrammeIsFuture"
      @close="playerClosed"
    >
      <chat
        v-if="canShowChat"
        ref="chatRef"
        :product="product"
        @disable="disableChat"
        @disableIcon="disableChatIcon"
        @enable="enableChat"
        @enableIcon="enableChatIcon"
      />
      <button
        v-if="canShowChatIcon"
        class="c-watch__btn c-watch__chat-btn"
        @click="enableChat"
      >
        <svg class="c-watch__icon o-icon">
          <use xlink:href="#icon-chat" />
        </svg>
      </button>
    </watch-future>
    <div v-else>
      <player
        :product="product"
        :block-keyboard="blockKeyboard"
        @close="playerClosed"
        @ended="openRecommendations"
      >
        <div
          v-if="advisorsFromApi"
          v-dompurify-html="advisorsFromApi"
          class="c-watch__advisors"
        />
        <chat
          v-if="canShowChat"
          ref="chatRef"
          :product="product"
          @disable="disableChat"
          @disableIcon="disableChatIcon"
          @enable="enableChat"
          @enableIcon="enableChatIcon"
        />
        <button
          v-if="canShowChatIcon"
          class="c-watch__btn c-watch__chat-btn"
          @click.stop="enableChat"
        >
          <svg class="c-watch__icon o-icon">
            <use xlink:href="#icon-chat" />
          </svg>
        </button>
        <button
          v-if="canShowSeasonsIcon"
          class="c-watch__btn c-watch__recommendations-btn"
          @click.stop="toggleSeasons"
        >
          <svg class="c-watch__icon o-icon">
            <use xlink:href="#icon-episodes" />
          </svg>
        </button>
        <button
          v-if="canShowRecommendationsIcon"
          class="c-watch__btn c-watch__seasons-btn"
          @click.stop="toggleRecommendations"
        >
          <svg class="c-watch__icon o-icon">
            <use xlink:href="#icon-series" />
          </svg>
        </button>
        <div
          v-if="canShowSeasons"
          class="c-player__seasons row expanded align-middle"
        >
          <div class="column">
            <div
              class="c-player__seasons__close"
              @click.stop="toggleSeasons"
            >
              <svg class="o-icon u-abs-center">
                <use xlink:href="#icon-close" />
              </svg>
            </div>
            <seasons
              :product="product"
            />
          </div>
        </div>
        <div
          v-if="showRecommendations && isPlaying"
          class="c-player__seasons row expanded align-middle"
        >
          <div class="column">
            <div
              class="c-player__seasons__close"
              @click.stop="toggleRecommendations"
            >
              <svg class="o-icon u-abs-center">
                <use xlink:href="#icon-close" />
              </svg>
            </div>
            <recommendations
              :product="product"
            />
          </div>
        </div>
      </player>
      <secondary-events
        :product="product"
        :type="product.type"
      />
      <advert-player />
    </div>
  </section>
</template>

<script>
  import AdvisorsMixin from '@/mixins/advisors'
  import { LIVE } from 'assets/javascript/enums/video-types'
  import { EPISODE, PROGRAMME } from 'assets/javascript/enums/product-types'
  import Player from '@/components/player/index'
  import Chat from '@/components/chat/index'
  import SecondaryEvents from '@/components/secondary-events/index'
  import Recommendations from '@/components/recommendations/index'
  import Seasons from '@/components/seasons/index'
  import WatchFuture from '@/pages/watch/components/future/index'
  import AdvertPlayer from '@/components/advert-player/index'
  import isSafari from 'assets/javascript/helpers/is-safari'

  const IDLE = 'idle'

  export default {
    isSafari,
    inject: [
      'playerStore'
    ],
    components: {
      AdvertPlayer,
      WatchFuture,
      Recommendations,
      Seasons,
      SecondaryEvents,
      Chat,
      Player
    },
    mixins: [
      AdvisorsMixin
    ],
    props: {
      product: {
        type: Object,
        required: true
      },
      returnPath: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        playerState: this.playerStore.state,
        chatIconEnabled: false,
        showChat: false,
        showRecommendations: false,
        showSeasons: false,
        isFutureProgramme: false,
        blockKeyboard: false,
        overlayStatus: ''
      }
    },
    computed: {
      canShowChat () {
        return this.isPlaying
      },
      canShowChatIcon () {
        return this.chatIconEnabled &&
          !this.showChat &&
          !this.showSeasons &&
          !this.showRecommendations &&
          this.overlayStatus !== IDLE &&
          this.isPlaying
      },
      canShowSeasons () {
        return this.isEpisode && this.showSeasons && this.isPlaying
      },
      canShowSeasonsIcon () {
        return this.isEpisode &&
          !this.showSeasons &&
          !this.showChat &&
          !this.showRecommendations &&
          this.overlayStatus !== IDLE &&
          this.isPlaying
      },
      canShowRating () {
        return this.product.rating && this.isPlaying
      },
      canShowRecommendationsIcon () {
        return !this.showChat &&
          !this.showSeasons &&
          !this.showRecommendations &&
          this.overlayStatus !== IDLE &&
          this.isPlaying
      },
      isEpisode () {
        return this.product.type === EPISODE
      },
      isPlaying () {
        return !this.playerState.isAdvertRunning && this.playerState.playerStatus !== 'loading'
      }
    },
    watch: {
      'playerState.overlayStatus': function (status) {
        this.overlayStatus = status
      },
      'playerState.isAdvertRunning': function (status) {
        if (status && this.showChat) {
          return this.disableChat()
        }
      }
    },
    beforeDestroy () {
      this.$popup.close()
    },
    created () {
      this.isFutureProgramme = this.checkIfProgrammeIsFuture()

      if (Notification.permission !== 'granted' && this.$route.params.type === LIVE.toLowerCase()) {
        this.$toast.permissionDenied(this.$t('notifications.secondaryEvents'))
      }
    },
    methods: {
      disableChat () {
        this.$refs.chatRef.disable()
        this.blockKeyboard = this.showChat = false
      },
      disableChatIcon () {
        this.chatIconEnabled = false
      },
      enableChat () {
        this.$refs.chatRef.enable()
        this.blockKeyboard = this.showChat = true
      },
      enableChatIcon () {
        this.chatIconEnabled = true
      },
      openRecommendations () {
        this.showRecommendations = true
      },
      toggleRecommendations () {
        this.showRecommendations = !this.showRecommendations
      },
      toggleSeasons () {
        this.showSeasons = !this.showSeasons
      },
      playerClosed () {
        this.$router.push(this.returnPath)
          .catch(() => this.$router.push({ name: this.$dict.routes.HOME }))
      },
      checkIfProgrammeIsFuture () {
        const product = this.product
        const time = this.$time
        return product.type === PROGRAMME && time(product.since).diff(time()) >= 0
      },
      ratingIcon (rating) {
        const ratings = {
          0: '#icon-Y0',
          7: '#icon-Y7',
          12: '#icon-Y12',
          16: '#icon-Y16',
          18: '#icon-Y18'
        }
        return ratings[rating]
      }
    }
  }
</script>
