<template>
  <section class="c-add-device">
    <div class="row expanded collapse">
      <div class="column small-12">
        <h1 class="c-add-device__heading">
          {{ $t('views.addDevice.heading') }}
        </h1>
      </div>
    </div>
    <div class="row expanded">
      <div class="c-add-device__section column small-12 large-4">
        <div class="row expanded collapse">
          <div class="column small-12">
            <separator class="o-separator--horizontal o-separator--horizontal-device" />
          </div>
          <div class="column small-12">
            <h2 class="c-add-device__subheading">
              {{ $t('views.addDevice.subheading') }}
            </h2>
            <p>
              {{ $t('views.addDevice.description') }}
            </p>
          </div>
        </div>
      </div>
      <div
        ref="addDevice"
        class="c-add-device__section column small-12 large-6"
      >
        <div class="row expanded collapse">
          <div class="column small-12">
            <h2>
              {{ $t('views.addDevice.inputs.heading') }}
            </h2>
          </div>
          <div class="column small-12">
            <separator class="o-separator--horizontal o-separator--horizontal-device" />
          </div>
          <div class="column small-12">
            <ol class="c-add-device__list">
              <li class="c-add-device__list-item">
                {{ $t('views.addDevice.inputs.steps.one') }}
              </li>
              <li class="c-add-device__list-item">
                {{ $t('views.addDevice.inputs.steps.two') }}
              </li>
              <li class="c-add-device__list-item">
                {{ $t('views.addDevice.inputs.steps.three') }}
              </li>
            </ol>
          </div>
          <div class="column small-12">
            <div class="c-add-device__inputs-container">
              <pin-input
                type="text"
                :pin-length="8"
                @completed="check"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row expanded collapse">
      <div
        class="c-add-device__button-container column small-12"
        :class="{ 'c-devices__button-container--justify-end': mobileOrTablet }"
      >
        <nuxt-link
          :to="{ name: $dict.routes.DEVICES }"
          class="o-button o-button--secondary"
        >
          {{ $t('buttons.goBack') }}
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script>
  import errorMapper from '@/assets/javascript/helpers/error-mapper.js'

  import PinInput from '@/components/pin-input/index'
  import Separator from '@/components/separator/index'

  import mobileOrTabletMixin from '@/mixins/mobile-or-tablet-mixin'

  export default {
    name: 'AddDevice',
    components: {
      Separator,
      PinInput
    },
    mixins: [mobileOrTabletMixin],
    data () {
      return {
        values: [],
        errors: {}
      }
    },
    computed: {
      user () {
        return this.$store.state.user.data
      }
    },
    methods: {
      check (code) {
        this.$loader.show(this.$refs.addDevice)
        return this.$store.dispatch('user/CHECK_ONE_TIME_CODE', code)
          .then(() => {
            this.$toast.success(this.$t('views.devices.connectTv.success'))
          })
          .catch((error) => {
            errorMapper(this.$t.bind(this), error)
              .then((errors) => {
                this.$toast.error(errors.global)
              })
          })
          .finally(() => {
            this.$loader.hide(this.$refs.addDevice)
          })
      }
    },
    head () {
      return {
        title: this.$t('views.devices.addDevice')
      }
    }
  }
</script>
<style src="./styles.scss" lang="scss"/>
