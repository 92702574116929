<template>
  <section>
    <div
      v-for="message in messages"
      :key="message.id"
      class="c-chat__row row collapse"
    >
      <div
        v-if="showAvatarOnLeft(message)"
        class="column small-1"
      >
        <avatar
          :avatar-id="message.user.avatarId"
          :profile-color="message.user.profileColor"
          :username="message.user.username"
        />
      </div>
      <chat-message :message="message" />
      <div
        v-if="!showAvatarOnLeft(message)"
        class="column small-1"
      >
        <avatar
          :avatar-id="message.user.avatarId"
          :profile-color="message.user.profileColor"
          :username="message.user.username"
        />
      </div>
      <chat-replies
        v-if="message.id === currentMessageId"
        :message="message"
        class="column small-12"
      />
    </div>
  </section>
</template>

<script>
  import Avatar from '@/components/chat/avatar'
  import ChatReplies from '@/components/chat/messages/replies'
  import ChatMessage from '@/components/chat/messages/message'
  import {
    CONFIG,
    GET_CURRENT_MESSAGE_ID,
    SET_CURRENT_MESSAGE_ID
  } from '@/components/chat/enums/chat'

  export default {
    inject: [
      CONFIG,
      GET_CURRENT_MESSAGE_ID,
      SET_CURRENT_MESSAGE_ID
    ],
    components: {
      Avatar,
      ChatMessage,
      ChatReplies
    },
    props: {
      messages: {
        type: Array,
        required: true
      }
    },
    computed: {
      currentMessageId: {
        get () {
          return this.getCurrentMessageId()
        },
        set (currentMessageId) {
          this.setCurrentMessageId(currentMessageId)
        }
      },
      localConfig () {
        return this.config()
      },
      loggedUserId () {
        return this.$store.state.user.data.id
      }
    },
    methods: {
      showAvatarOnLeft (message) {
        return this.loggedUserId !== message.user.id || this.localConfig.allowComments
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
