<template>
  <div
    v-if="section.elements && section.elements.length"
    ref="container"
    class="c-one-line"
  >
    <section-with-product-detail
      :section="section"
    >
      <template v-slot="{ selectedProduct }">
        <header class="o-section__header">
          <h2 class="u-typo--headline2">
            {{ section.title }}
          </h2>
        </header>
        <slider-wrapper
          :section="section"
        >
          <template v-slot="{ data }">
            <product-card
              class="c-one-line__card"
              :product="{ ...data.item, sectionId: section.id, sectionPosition: section.sectionPosition }"
              :data-id="data.item.id"
              :data-type="data.item.type"
              :data-section-id="section.id"
              :class="{ 'is-active': data.item.id === selectedProduct.id }"
            />
          </template>
        </slider-wrapper>
      </template>
    </section-with-product-detail>
  </div>
</template>
<script>
  import ProductCard from '@/components/product-card'
  import SectionWithProductDetail from '@/components/section-with-product-detail/index'
  import SliderWrapper from '@/components/slider-wrapper/index'

  export default {
    name: 'OneLine',
    components: {
      SliderWrapper,
      SectionWithProductDetail,
      ProductCard
    },
    props: {
      section: {
        type: Object,
        required: true
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
