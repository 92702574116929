<template functional>
  <div class="c-mobile-app">
    <div class="c-mobile-app__overlay" />
    <div class="c-mobile-app__content">
      <img
        src="~assets/images/logo-festival-group.svg"
        :alt="parent.$t('imgAlt.logo')"
        class="c-mobile-app__logo"
      >
      <h1 class="c-mobile-app__heading">
        {{ parent.$t('views.mobileApp.heading') }}
      </h1>
      <div class="c-mobile-app__line" />
      <div class="c-mobile-app__button-container">
        <a
          href="https://apps.apple.com/pl/app/wow/id1543606989"
          class="c-mobile-app__link"
          target="_blank"
        >
          <img
            src="~assets/images/app-store.png"
            :alt="parent.$t('imgAlt.appStore')"
          >
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=pl.festival.mobile.android"
          class="c-mobile-app__link"
          target="_blank"
        >
          <img
            src="~assets/images/google-play.png"
            :alt="parent.$t('imgAlt.googlePlay')"
          >
        </a>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'MobileApp'
  }
</script>
<style lang="scss" src="./styles.scss"></style>
