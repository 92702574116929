<template>
  <form
    class="c-header-search"
    @submit.prevent="search"
  >
    <input
      ref="searchInput"
      v-model="form.search"
      class="c-header-search__input"
      type="text"
      :placeholder="$t('common.search')"
      @input="isSearchView && debounceSearch()"
    >
    <svg
      class="c-header-search__close o-icon"
      @click.stop="close"
    >
      <use xlink:href="#icon-close" />
    </svg>
    <svg
      class="c-header-search__search-icon o-icon"
      @click.stop="toggle"
    >
      <use xlink:href="#icon-search" />
    </svg>
  </form>
</template>
<script>
  import _debounce from 'lodash.debounce'

  const SEARCH = 'search'
  const DEBOUNCE_DELAY = 500
  export default {
    props: {
      searchVisible: Boolean
    },
    data () {
      return {
        debounceSearch: _debounce(this.search, DEBOUNCE_DELAY),
        form: {
          search: ''
        }
      }
    },
    computed: {
      canSearch () {
        return this.form.search.length > 2
      },
      isSearchView () {
        return this.$route.name === this.$dict.routes.SEARCH
      },
      routerMethod () {
        return this.isSearchView ? 'replace' : 'push'
      }
    },
    watch: {
      '$route' (to, from) {
        if (to.name !== SEARCH && to.name !== from.name) {
          this.form.search = ''
          this.$emit('close')
        } else if (to.query.searchQuery) {
          this.form.search = to.query.searchQuery
          if (!this.isSearchView) {
            this.$emit('toggle')
          }
        }
      },
      searchVisible (newValue) {
        if (newValue) {
          this.$refs.searchInput.focus()
        }
      }
    },
    mounted () {
      this.load()
    },
    methods: {
      close () {
        this.form.search = ''
      },
      load () {
        if (this.isSearchView && !this.searchVisible) {
          this.form.search = this.$route.query.searchQuery
          this.toggle()
        }
      },
      search () {
        if (this.canSearch) {
          this.$router[this.routerMethod]({
            name: SEARCH,
            query: { searchQuery: this.form.search }
          })
            .catch(() => {})
        }
      },
      toggle () {
        if (!this.searchVisible) {
          this.$emit('open')
        } else {
          this.$emit('close')
        }
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
