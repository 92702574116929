<template>
  <div>
    <section
      v-for="(section, index) in sections"
      :key="section.id"
      :class="getLayoutName(section)"
      class="o-section"
    >
      <component
        :is="getSectionByLayout(section.layout)"
        :section="applySectionStrategy({... section, sectionPosition: index + 1 })"
      />
    </section>
    <div
      v-if="!finished"
      class="c-sections__loader"
    >
      <infinite-loading
        force-use-infinite-wrapper="true"
        :distance="200"
        @infinite="infiniteHandler"
      >
        <template #spinner>
          <!--          <div class="c-pagination__spinner">-->
          <!--            <div class="o-spinner"></div>-->
          <!--          </div>-->
        </template>
      </infinite-loading>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Sections',
    props: {
      sections: {
        type: Array,
        required: true
      },
      loadMore: {
        type: Function,
        required: true
      },
      layouts: {
        type: Array,
        required: true
      },
      sectionStrategy: {
        type: Object,
        default: () => ({})
      }
    },
    data () {
      return {
        finished: false
      }
    },
    methods: {
      async infiniteHandler ($state) {
        await this.loadMore($state.loaded, () => {
          this.finished = true
          $state.complete()
        })
      },
      getLayoutName (section) {
        return section.layout?.toLowerCase()
      },
      getSectionByLayout (layoutName) {
        const component = this.layouts.find(layout => layout.name === layoutName)?.component
        if (component) {
          return component
        } else {
          console.error(`Unsupported section layout "${layoutName}"`)
        }
      },
      applySectionStrategy (section) {
        const strategy = this.sectionStrategy?.[section.contentType]
        if (strategy) {
          return strategy.call(this, section)
        }
        return section
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
