<template>
  <div
    class="page-header-dropdown"
    :class="{ 'page-header-dropdown--hoverable': isHoverable }"
  >
    <button
      type="button"
      class="page-header-dropdown__button"
      :class="{ 'page-header-dropdown__button--active': isOpen }"
      aria-haspopup="menu"
      :aria-expanded="isHoverable ? undefined : isOpen"
      @click="handleClick"
    >
      <slot name="title" />
    </button>
    <ul
      class="page-header-dropdown__menu"
      :class="{
        'page-header-dropdown__menu--hoverable': isHoverable,
        'page-header-dropdown__menu--visible': isOpen,
        [`page-header-dropdown__menu--${menuAlignment}`]: menuAlignment,
      }"
      @click="handleMenuClick"
    >
      <slot name="menu" />
    </ul>
  </div>
</template>
<script>
  import { getCurrentBreakpoint } from '@/assets/javascript/responsive'

  export default {
    props: {
      isHoverable: Boolean,
      keepOpenOnClick: Boolean,
      menuAlignment: {
        type: String,
        validator: (value) => ['left', 'right'].includes(value),
        default: 'left'
      }
    },
    data () {
      return {
        isOpen: false
      }
    },
    computed: {
      currentBreakpoint () {
        return getCurrentBreakpoint()
      }
    },
    watch: {
      currentBreakpoint () {
        this.isOpen = false
      }
    },
    methods: {
      handleClick (event) {
        this.blurTarget(event.currentTarget)
        if (!this.isHoverable) {
          this.isOpen = !this.isOpen
        }
      },
      handleMenuClick (event) {
        const parentElement = event.target.closest('button, a')
        this.blurTarget(parentElement)
        if (!this.isHoverable) {
          this.closeMenu()
        }
      },
      blurTarget (target) {
        if (!this.keepOpenOnClick && target) {
          target.blur()
        }
      },
      closeMenu () {
        this.isOpen = false
      }
    }

  }
</script>
<style src="./styles.scss" lang="scss" />
