<template>
  <section
    v-if="heading"
    class="c-categories"
  >
    <h2 class="c-categories__item">
      {{ heading }}
    </h2>
    <separator />
    <h2 class="c-categories__item c-categories__dropdown">
      {{ $t('categories.filter') }}
      <svg class="c-categories__icon o-icon">
        <use xlink:href="#icon-list-opened" />
      </svg>
      <dropdown>
        <div class="c-categories__dropdown-content">
          <div
            v-for="({id, name}, index) in categories"
            :key="id"
          >
            <nuxt-link
              :to="{ name: $dict.routes.CATALOG, params: { id: $route.params.id, categoryId: id } }"
              class="c-dropdown__link"
            >
              {{ name }}
            </nuxt-link>
            <separator
              v-if="index !== categories.length - 1"
              class="o-separator--horizontal o-separator--horizontal-no-margin o-separator--horizontal-darken"
            />
          </div>
        </div>
      </dropdown>
    </h2>
  </section>
</template>

<script>
  import Dropdown from '@/components/dropdown/index'
  import Separator from '@/components/separator/index'

  export default {
    name: 'Categories',
    components: {
      Dropdown,
      Separator
    },
    data () {
      return {
        categories: [],
        heading: undefined,
        title: undefined
      }
    },
    mounted () {
      return this.getCategories()
    },
    methods: {
      getCategories () {
        const title = 'WOW - '
        this.$store.dispatch('items/GET_CATEGORIES', {
          categoryId: this.$route.params.id,
          kids: this.$store.getters['user/ACTIVE_PROFILE']?.kids
        })
          .then(data => {
            const index = data.findIndex(item => {
              return item.id === parseInt(this.$route.params.id)
            })

            if (index !== -1) {
              this.title = title + data[index].name
              this.heading = data[index].name
              delete data[index]
            }

            this.categories = data.filter(item => item.id)

            if (this.$route.params.categoryId) {
              const item = this.categories.find(item => item.id === this.$route.params.categoryId)
              if (item) {
                this.title = title + item.name
              }
            }
          })
      }
    },
    head () {
      return {
        title: this.title
      }
    }
  }
</script>
<style src="./styles.scss" lang="scss" />
