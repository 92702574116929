<template>
  <div
    class="o-checkbox"
    tabindex="0"
    @click="toggle"
    @keyup.space="toggle"
  >
    <span
      class="o-checkbox__icon"
      :class="{ 'is-active': active }"
    />
    <div>
      <p
        v-dompurify-html="label"
        class="o-checkbox__label"
        :class="{ 'is-required': isRequired }"
      />
      <p
        v-if="description"
        v-dompurify-html="description"
        class="o-checkbox__label o-checkbox__label--small"
      />
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Checkbox',
    model: {
      prop: 'modelValue'
    },
    props: {
      description: {
        type: String,
        default: ''
      },
      isRequired: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        required: true
      },
      modelValue: {
        type: null,
        default: false
      },
      value: {
        type: null,
        default: false
      }
    },
    computed: {
      active () {
        if (this.modelValue instanceof Array) {
          return this.modelValue.includes(this.value)
        }

        return this.modelValue
      }
    },
    methods: {
      isLink (target) {
        return target.tagName.toLowerCase() === 'a'
      },
      toggle (event) {
        this.$emit('clicked')
        if (this.isLink(event.target)) {
          return
        }

        if (this.modelValue instanceof Array) {
          const newValue = [...this.modelValue]

          if (this.active) {
            newValue.splice(newValue.indexOf(this.value), 1)
          } else {
            newValue.push(this.value)
          }
          this.$emit('input', newValue)
          return
        }
        this.$emit('input', !this.active)
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
