<template>
  <section
    ref="container"
    class="c-catalog"
  >
    <div class="c-catalog__header">
      <categories class="c-categories--no-margin" />
      <filters @change="reset" />
    </div>
    <pagination
      :callback="loadMore"
      :infinite-id="timestamp"
    >
      <sections-chunked :elements="results" />
    </pagination>
    <div
      v-if="loaded && !results.length"
      class="c-catalog__empty-container"
    >
      <svg class="c-search__no-results-icon">
        <use xlink:href="#icon-no-results" />
      </svg>
      <h1 class="c-catalog__empty-heading">
        {{ $t('categories.empty') }}
      </h1>
    </div>
  </section>
</template>
<script>
  import {
    DEFAULT_MAX_ITEMS
  } from '@/store/products/actions'

  import Categories from '@/components/categories/index'
  import Filters from '@/components/filters/index'
  import SectionsChunked from '@/components/sections-chunked/index'
  import Pagination from '@/components/pagination'

  export default {
    components: {
      Categories,
      Filters,
      Pagination,
      SectionsChunked
    },
    data () {
      return {
        loaded: false,
        results: [],
        timestamp: new Date().getTime(),
        query: {},
        cancelSource: null
      }
    },
    methods: {
      cancelRequest () {
        if (this.cancelSource) {
          this.cancelSource.cancel()
        }
      },
      reset (query) {
        this.query = query
        this.results = []
        this.loaded = false
        this.timestamp = new Date().getTime()
      },
      loadMore (loaded, completed) {
        this.cancelRequest()
        this.cancelSource = this.$axios.CancelToken.source()
        return this.loadVods()
          .then((res) => {
            if (!res) {
              this.loaded = true
              return completed()
            }
            const results = res.items.map(item => ({ item }))
            loaded()
            if (this.noMoreResults(results)) {
              completed()
              this.loaded = true
            }
            this.results = [...this.results, ...results]
          })
      },
      loadVods () {
        const categoryId = this.$route.params.categoryId
        const mainCategoryId = this.$route.params.id
        const firstResult = this.results.length
        const fullQuery = { ...this.query, firstResult }
        return this.$store.dispatch('products/GET_VODS', {
          query: fullQuery,
          categoryId,
          mainCategoryId,
          kids: this.$store.getters['user/ACTIVE_PROFILE']?.kids,
          cancelToken: this.cancelSource?.token
        })
      },
      noMoreResults (results) {
        const length = results.length
        return !length || length < DEFAULT_MAX_ITEMS
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
