<template>
  <div class="row expanded collapse c-profiles-add-page">
    <form
      class="column small-12"
      @submit.prevent="add"
    >
      <profile-edit-form
        ref="form"
        :errors="errors"
      />
      <div class="c-profiles-add-page__buttons-container">
        <button
          type="submit"
          class="o-button"
          :class="{ 'o-button--disabled' : disabled }"
          :disabled="disabled"
        >
          {{ $t('buttons.add') }}
        </button>
        <nuxt-link
          :to="{ name: $dict.routes.SHOW_PROFILES }"
          class="o-button o-button--secondary"
        >
          {{ $t('buttons.cancel') }}
        </nuxt-link>
      </div>
    </form>
  </div>
</template>
<script>
  import errorMapper from '@/assets/javascript/helpers/error-mapper'

  import ProfileEditForm from '@/components/profiles/form/index.vue'
  const MIN_INPUT_LENGTH = 3
  const MIN_ZIP_CODE_LENGTH = 6

  export default {
    name: 'ProfileAddPage',
    components: {
      ProfileEditForm
    },
    data () {
      return {
        avatarEdit: false,
        disabled: false,
        isCityValid: true,
        isNameValid: true,
        isZipCodeValid: true,
        errors: {}
      }
    },
    computed: {
      isBirthDateValid () {
        const { birthDay, birthMonth, birthYear } = this.profileData
        return (birthDay && birthMonth && birthYear) || (!birthDay && !birthMonth && !birthYear)
      },
      profileData () {
        return this.$refs.form.preProfile
      }
    },
    created () {
      this.setEditMode()
    },
    methods: {
      add () {
        if (!this.isBirthDateValid) {
          this.$toast.error(this.$t('errors.dateOfBirth'))
          return this.tempDisableSubmit()
        }

        if (this.profileData.name.length < MIN_INPUT_LENGTH) {
          this.$toast.error(this.$t('errors.name'))
          this.isNameValid = false
          return this.tempDisableSubmit()
        }
        if (this.profileData.city.length && this.profileData.city.length < MIN_INPUT_LENGTH) {
          this.$toast.error(this.$t('errors.city'))
          this.isCityValid = false
          return this.tempDisableSubmit()
        }

        if (this.profileData.zipCode.length && this.profileData.zipCode.length < MIN_ZIP_CODE_LENGTH) {
          this.$toast.error(this.$t('errors.zipCode'))
          this.isZipCodeValid = false
          return this.tempDisableSubmit()
        }

        const profile = {
          ...this.profileData,
          birthMonth: this.profileData.birthMonth?.value || this.profileData?.birthMonth || '',
          sex: this.profileData.sex?.value
        }

        if (!profile.birthDay) {
          delete profile.birthDay
          delete profile.birthMonth
          delete profile.birthYear
        }

        this.$store.dispatch('profiles/ADD_PROFILE', { profile })
          .then(() => {
            this.$router.push({ name: this.$dict.routes.SHOW_PROFILES })
          })
          .catch(error => {
            return errorMapper(this.$t.bind(this), error)
              .then(errors => {
                const errorsValues = Object.values(errors)
                for (const error in errorsValues) {
                  this.$toast.error(errorsValues[error])
                }
              })
          })
      },
      tempDisableSubmit () {
        this.disabled = true
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.disabled = false
        }, 1000)
      },
      setEditMode () {
        this.$store.commit('profiles/SET_EDIT_MODE')
      }
    },
    head () {
      return {
        title: this.$t('profiles.add')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
