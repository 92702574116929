<template>
  <section
    ref="container"
    class="c-search"
  >
    <pagination
      :callback="loadMore"
      :infinite-id="infiniteId"
    >
      <template v-slot:default>
        <sections-chunked
          v-if="results.length"
          :elements="results"
        />
      </template>
      <template v-slot:test>
        <div
          class="row expanded"
        >
          <div
            class="c-search__no-results-container"
          >
            <div>
              <svg class="c-search__no-results-icon o-icon">
                <use xlink:href="#icon-no-results" />
              </svg>
              <h1 class="c-search__no-results-heading">
                {{ $t('views.search.heading') }}
              </h1>
              <div class="c-search__no-results-summary">
                <h2>{{ $t('views.search.suggestionHeading') }}:</h2>
                <ul class="c-search__no-results-list">
                  <li
                    v-for="(suggestion, index) in suggestions"
                    :key="index"
                    class="c-search__no-results-list-item"
                  >
                    {{ suggestion }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
    </pagination>
  </section>
</template>
<script>
  import {
    PROGRAM,
    VOD
  } from '@/assets/javascript/enums/product-types'

  import {
    DEFAULT_MAX_RESULTS
  } from '@/store/search/actions'

  import {
    SUBMISSION_SUCCESS
  } from '@/assets/javascript/dictionaries/gtmEventsActions'

  import {
    SEARCH
  } from '@/assets/javascript/dictionaries/gtmEvents'

  import Pagination from '@/components/pagination'
  import SectionsChunked from '@/components/sections-chunked/index'
  // import Separator from '@/components/separator/index'

  const ALL = 'all'

  export default {
    components: {
      SectionsChunked,
      Pagination
    },
    data () {
      return {
        query: '',
        results: [],
        suggestions: [
          this.$t('views.search.suggestions.first'),
          this.$t('views.search.suggestions.second'),
          this.$t('views.search.suggestions.third')
        ],
        searchOptions: [
          {
            name: this.$t('views.search.all'),
            type: ALL
          },
          {
            name: this.$t('views.search.vod'),
            type: VOD
          },
          {
            name: this.$t('views.search.program'),
            type: PROGRAM
          }
        ],
        selectedType: ALL,
        infiniteId: this.refreshPagination()
      }
    },
    computed: {
      activeProfile () {
        return this.$store.getters['user/ACTIVE_PROFILE']
      }
    },
    watch: {
      '$route.query.searchQuery': {
        handler (newQuery) {
          if (newQuery !== this.query) {
            this.refreshPagination()
            this.query = newQuery
            this.results = []
          }
        }
      }
    },
    methods: {
      isLast (index, searchOptions) {
        return index !== searchOptions.length - 1
      },
      loadMore (loaded, completed) {
        return this.prepareSearchPromise(this.$route.query.searchQuery, this.results.length)
          .then((res) => {
            if (!res) {
              return completed()
            }

            const results = this.parseResults(res)
            if (!results.length) {
              completed()
              return
            }

            this.results = [...this.results, ...results]
            loaded()

            if (results.length < DEFAULT_MAX_RESULTS) {
              completed()
            }
          })
      },
      noMoreResults (results) {
        return !results.length || results.length < DEFAULT_MAX_RESULTS
      },
      parseResults (results) {
        if (this.selectedType === ALL) {
          return results.reduce(
            (array, { items }) => [...array, ...items], []
          ).map((element) => ({ item: element }))
        }
        return results.items.map((element) => ({ item: element }))
      },
      performSearch (query = this.$route.query.searchQuery) {
        return this.prepareSearchPromise(query)
          .then((results) => {
            this.results = this.parseResults(results)
          })
      },
      prepareSearchPromise (query, firstResult = 0) {
        const searchData = {
          searchValue: query,
          action: SUBMISSION_SUCCESS
        }
        this.$tracking.events.send(SEARCH, { searchData })
        return this.selectedType === ALL
          ? this.searchAll(query, firstResult)
          : this.searchByType(query, firstResult)
      },
      searchAll (query, firstResult) {
        return this.$store.dispatch('search/GET_ALL', {
          query,
          kids: this.activeProfile?.kids,
          firstResult
        })
      },
      searchByType (query, firstResult) {
        return this.$store.dispatch('search/GET_BY_TYPE', {
          query,
          type: this.selectedType,
          kids: this.activeProfile?.kids,
          firstResult
        })
      },
      setType (type) {
        this.selectedType = type
        this.performSearch()
      },
      refreshPagination () {
        this.infiniteId = new Date().getTime()
      }
    },
    head () {
      return {
        title: this.$t('views.search.title')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
