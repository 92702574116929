<template>
  <section>
    <h1 class="c-profile-remove__title">
      {{ $t('profiles.removeProfile.heading') }}
    </h1>
    <h2 class="c-profile-remove__subtitle">
      {{ $t('profiles.removeProfile.subheading') }}
    </h2>
    <div class="c-profile-remove__buttons-container">
      <div
        class="o-button"
        @click="deleteProfile"
      >
        {{ $t('buttons.remove') }}
      </div>
      <div
        class="o-button"
        @click="close"
      >
        {{ $t('buttons.cancel') }}
      </div>
    </div>
  </section>
</template>
<script>
  export default {
    name: 'RemoveProfile',
    props: {
      profileToEdit: {
        type: Object,
        required: true
      }
    },
    methods: {
      close () {
        this.$emit('close')
      },
      deleteProfile () {
        this.$store.dispatch('profiles/DELETE_PROFILE', { profileId: this.profileToEdit.id })
          .then(() => {
            this.$emit('close')
            this.$router.push({ name: this.$dict.routes.SHOW_PROFILES })
          })
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
