<template>
  <div class="c-popup">
    <div
      class="c-popup__wrapper"
      :class="{ 'c-popup__wrapper--card' : isCardPopup, 'c-popup__wrapper--wide' : isWide }"
    >
      <svg
        v-if="!disableClose"
        class="c-popup__close o-icon o-icon--action"
        :class="{ 'c-popup__close--card' : isCardPopup }"
        @click="close"
      >
        <use xlink:href="#icon-close" />
      </svg>
      <slot />
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Popup',
    props: {
      isCardPopup: {
        type: Boolean,
        default: false
      },
      isWide: {
        type: Boolean,
        default: false
      },
      disableClose: {
        type: Boolean,
        default: false
      }
    },
    beforeDestroy () {
      this.close()
    },
    methods: {
      close () {
        this.$emit('close')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
