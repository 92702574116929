<template>
  <div class="row expanded c-details__content">
    <div class="column small-12 large-6 c-details__column-padding-top">
      <div class="c-details__column-padding-right">
        <h3 class="c-details__subheader">
          {{ $t('tabs.details.description') }}
        </h3>
        <section
          ref="container"
          v-dompurify-html="product.description"
          class="c-details__container c-details--text-muted"
        />
      </div>
    </div>
    <div class="column small-12 large-6 c-details__column-padding-top">
      <h3 class="c-details__subheader">
        {{ $t('tabs.details.additionalInfo') }}
      </h3>
      <p v-if="product.year">
        <span class="c-details--text-muted">
          {{ $t('tabs.details.year') }}:
        </span>
        {{ product.year }}
      </p>
      <div v-if="product.countries.length">
        <span class="c-details--text-muted">
          {{ $t('tabs.details.country') }}:
        </span>
        {{ countries }}
      </div>
      <terms-list
        v-if="product.actors.length"
        :array="actors"
        :category="$t('tabs.details.actors')"
      />
      <terms-list
        v-if="product.directors.length"
        :array="directors"
        :category="$t('tabs.details.directors')"
      />
      <p v-if="product.scriptwriters.length">
        <span class="c-details--text-muted">
          {{ $t('tabs.details.scriptwriters') }}:
        </span>
        {{ scriptwriters }}
      </p>
      <p class="c-details--margin-top">
        <span class="c-details--text-muted">
          {{ $t('tabs.details.availability') }}:
        </span>
        <span v-if="product.since">
          {{ $t('tabs.details.from') }}
          {{ product.since | dateFormat('DD.MM.YYYY') }}
        </span>
        <span v-if="product.till">
          {{ $t('tabs.details.to') }}
          {{ product.till | dateFormat('DD.MM.YYYY') }}
        </span>
      </p>
      <p v-if="qualities.length">
        <span class="c-details--text-muted">
          {{ $t('tabs.details.quality') }}:
        </span>
        {{ availableQualities }}
      </p>
      <p
        v-if="product.rating"
        class="c-details--margin-top"
      >
        <span class="c-details--text-muted c-details__icon">
          <svg><use :xlink:href="`#icon-Y${product.rating}`" /></svg>{{ $t('tabs.details.rating') }}:
        </span>
        {{ $t('tabs.details.ratingInfo', { rating: product.rating }) }}
      </p>
      <p
        v-for="advisor in advisorsWithDescription"
        :key="advisor.name"
      >
        <span class="c-details--text-muted">
          <i
            v-dompurify-html="advisor.icon"
            class="c-details__icon"
          />
          {{ advisor.description }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
  import { HD, UHD } from '@/components/product-detail/components/details/enums'
  import TermsList from '@/components/product-detail/components/details/terms-list/index'
  import AdvisorsMixin from '@/mixins/advisors'

  export default {
    name: 'Details',
    components: {
      TermsList
    },
    mixins: [
      AdvisorsMixin
    ],
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        qualities: []
      }
    },
    computed: {
      actors () {
        return this.product.actors
      },
      availableQualities () {
        return this.qualities.join(', ')
      },
      countries () {
        return this.joinByName(this.product.countries)
      },
      directors () {
        return this.product.directors
      },
      scriptwriters () {
        return this.joinByName(this.product.scriptwriters)
      }
    },
    mounted () {
      this.setQualities()
    },
    methods: {
      joinByName (array) {
        return array.map(({ name }) => name).join(', ')
      },
      setQualities () {
        const product = this.product
        const qualities = this.qualities

        if (product.hd) {
          qualities.push(HD)
        }

        if (product.uhd) {
          qualities.push(UHD)
        }
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
