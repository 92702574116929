<template>
  <section class="c-bundle">
    <div class="row expanded collapse">
      <div class="column small-12 large-4">
        <div class="row expanded collapse">
          <div class="c-profile__label column small-12">
            {{ $t('views.myAccount.bundleName') }}
          </div>
          <div class="c-profile__text column small-12">
            {{ bundleInfo }}
          </div>
        </div>
      </div>
      <div class="column small-12 large-2">
        <div class="row expanded collapse">
          <div class="c-profile__label column small-12">
            {{ bundleValidUntilTitle }}
          </div>
          <div class="c-profile__text column small-12">
            {{ bundleNextCharge }}
          </div>
        </div>
      </div>
      <div class="column small-12 large-2">
        <div class="row expanded collapse">
          <div class="c-profile__label column small-12">
            {{ $t('views.myAccount.payment') }}
          </div>
          <div class="c-profile__text column small-12">
            <img
              src="~assets/images/przelewy24.png"
              alt="Przelewy24"
              class="c-profile__payment-logo"
            >
            {{ $t('views.myAccount.paymentProvider') }}
          </div>
        </div>
      </div>
      <div class="c-profile__button-container column small-12 large-4">
        <button
          v-if="!subscriptionCancelled"
          class="o-button o-button--secondary o-button--fixed-width"
          @click="$popup.show($dict.popups.CANCEL_SUBSCRIPTION, {
            cyclicPaymentId: subscription.id,
            endDate: bundleNextCharge })"
        >
          {{ $t('views.myAccount.cancelSubscription') }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
  import priceFormat from '@/assets/javascript/filters/price-format'
  import { CANCELLED } from '@/assets/javascript/enums/cyclic-payment-status'

  export default {
    props: {
      subscription: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      bundleNextCharge () {
        return this.$options.filters.dateFormat(this.subscription.nextChargeAt, 'DD.MM.YYYY, HH:mm:ss')
      },
      bundleInfo () {
        return `${this.subscription.product.title} - ${this.$t('views.myAccount.paidMonthly')}
          ${priceFormat(this.subscription.price)} ${this.$t('currency.pln')}`
      },
      bundleValidUntilTitle () {
        if (this.subscriptionCancelled) {
          return this.$t('views.myAccount.subscriptionTermination')
        }

        return this.$t('views.myAccount.subscriptionRenewal')
      },
      subscriptionCancelled () {
        return this.subscription.status === CANCELLED
      }
    }
  }
</script>
