<template>
  <div>
    <div
      v-if="!showAvatarOnLeft(message)"
      class="column small-1"
    >
      <avatar
        :avatar-id="message.user.avatarId"
        :profile-color="message.user.profileColor"
        :username="message.user.username"
      />
    </div>
    <div
      v-if="replies"
      class="c-chat-message__replies"
      :data-parent="message.id"
    >
      <div
        v-for="reply in replies"
        :key="reply.id"
        class="c-chat__row row collapse"
      >
        <div class="column small-1">
          <avatar
            :avatar-id="reply.user.avatarId"
            :profile-color="reply.user.profileColor"
            :username="reply.user.username"
          />
        </div>
        <chat-message
          :message="reply"
          :is-reply="true"
          :parent-id="message.id"
          :reply-count="message.replyCount"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Avatar from '@/components/chat/avatar'
  import ChatMessage from '@/components/chat/messages/message'
  import {
    CONFIG,
    READ_REPLIES,
    UNSUBSCRIBE_REPLIES
  } from '@/components/chat/enums/chat'

  export default {
    inject: [
      CONFIG,
      READ_REPLIES,
      UNSUBSCRIBE_REPLIES
    ],
    components: {
      Avatar,
      ChatMessage
    },
    props: {
      message: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        replies: []
      }
    },
    beforeDestroy () {
      this.unsubscribeReplies()
    },
    mounted () {
      this.getReplies()
    },
    methods: {
      async getReplies () {
        this.replies = await this.readReplies(this.message.id)
      },
      showAvatarOnLeft ({ user }) {
        return this.loggedUserId !== user.id || this.localConfig.allowComments
      }
    }
  }
</script>
