<template>
  <section
    ref="container"
    class="c-watched"
  >
    <sections-chunked
      v-if="watched.length"
      :elements="watched"
    />
    <div
      v-else
      class="c-watched__empty-container"
    >
      <svg class="c-watched__empty-icon o-icon">
        <use xlink:href="#icon-like-fill" />
      </svg>
      <h1 class="c-watched__empty-heading">
        {{ $t('watched.empty.heading') }}
      </h1>
      <h2 class="c-watched__empty-subheading">
        {{ $t('watched.empty.subheading') }}
      </h2>
    </div>
  </section>
</template>
<script>
  import SectionsChunked from '@/components/sections-chunked/index'

  export default {
    components: {
      SectionsChunked
    },
    computed: {
      watched () {
        return this.$store.state.watched.watched
      }
    },
    head () {
      return {
        title: this.$t('views.myList.watched')
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
