<template>
  <div v-if="section.elements.length">
    <slider-wrapper
      :section="section"
    >
      <template v-slot="{ data }">
        <banner
          :product="{ ...data.item, sectionId: section.sectionId, sectionPosition: section.sectionPosition }"
          :single-banner="true"
        >
          <template v-slot:actionButtons>
            <action-buttons :product="data.item">
              <toggle-favourites :product="data.item" />
            </action-buttons>
          </template>
        </banner>
      </template>
    </slider-wrapper>
  </div>
</template>
<script>
  import Banner from '@/components/banner/index'
  import SliderWrapper from '@/components/slider-wrapper/index'
  import ActionButtons from '@/components/action-buttons/index'
  import ToggleFavourites from '@/components/action-buttons/components/toggle-favourites/index'

  export default {
    name: 'SectionBanner',
    components: {
      SliderWrapper,
      Banner,
      ActionButtons,
      ToggleFavourites
    },
    props: {
      section: {
        type: Object,
        required: true
      }
    }
  }
</script>
