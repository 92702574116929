<template>
  <section>
    <h1 class="c-broadcast-consent__title">
      {{ $t('broadcast.consent.title') }}
    </h1>
    {{ $t('broadcast.consent.description') }}
    <div class="c-broadcast-consent__buttons-container">
      <button
        class="c-broadcast-consent__button o-button"
        @click="accept"
      >
        {{ $t('broadcast.consent.accept') }}
      </button>
      <button
        class="c-broadcast-consent__button o-button o-button--secondary"
        @click="cancel"
      >
        {{ $t('buttons.cancel') }}
      </button>
    </div>
  </section>
</template>

<script>
  export default {
    props: {
      promise: {
        type: Object,
        default: null
      }
    },
    created () {
      if (!this.promise) {
        return this.cancel()
      }
    },
    methods: {
      accept () {
        this.promise.resolve()
        this.$emit('close')
      },
      cancel () {
        this.$router.push({ name: this.$dict.routes.HOME })

        this.$emit('close')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
