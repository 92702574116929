<template>
  <div class="c-chat__container c-chat__row row collapse">
    <div class="column small-1">
      <avatar
        :avatar-id="avatarId"
        :username="profile.name"
        :profile-color="profile.color"
      />
    </div>
    <div class="column small-11">
      <form class="c-chat-input">
        <textarea
          ref="message-input"
          v-model="message"
          class="c-chat-input__textarea"
          :placeholder="placeholder"
          :maxlength="maxLength"
          @keydown="inputHandler"
        />
        <emojis @insert="insertEmoji" />
      </form>
    </div>
  </div>
</template>

<script>
  import Avatar from '@/components/chat/avatar'
  import Emojis from '@/components/chat/emojis'
  import {
    CONFIG,
    ENTER_KEY_CODE,
    GET_BLOCK_SENDING,
    MESSAGE_LENGTH,
    STORE_MESSAGE
  } from '@/components/chat/enums/chat'

  export default {
    inject: [
      CONFIG,
      GET_BLOCK_SENDING,
      STORE_MESSAGE
    ],
    components: {
      Avatar,
      Emojis
    },
    props: {
      parentId: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        message: ''
      }
    },
    computed: {
      profile () {
        return this.$store.getters['user/ACTIVE_PROFILE']
      },
      avatarId () {
        return this.profile?.avatar?.id || 0
      },
      maxLength () {
        if (this.localConfig.readOnly || this.blockSending) {
          return 0
        }

        return this.localConfig.messageLength
      },
      blockSending: {
        get () {
          return this.getBlockSending()
        }
      },
      localConfig () {
        return this.config()
      },
      placeholder () {
        if (this.localConfig.readOnly) {
          return this.$t('chat.readOnly')
        }

        let message = this.$t('chat.writeMessage', { limit: this.localConfig.messageLength })

        if (this.localConfig.shouldModerate) {
          message += ` ${this.$t('chat.acceptance')}`
        }

        if (this.localConfig.threshold > 0) {
          message += ` ${this.$t('chat.threshold', { threshold: this.localConfig.threshold / 1000 })}`
        }

        return message
      }
    },
    methods: {
      clearForm () {
        this.message = ''
        this.$refs['message-input'].value = ''
        this.$refs['message-input'].focus()
      },
      inputHandler ($event) {
        if ($event.which === ENTER_KEY_CODE && !$event.shiftKey) {
          $event.preventDefault()
          this.storeMessage({ message: this.message, parentId: this.parentId })
          this.clearForm()
        }
      },
      insertEmoji (emoji) {
        if (this.message.length < MESSAGE_LENGTH - 1) {
          this.message += emoji
        }
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
