<template>
  <div class="c-recommendations">
    <div class="c-recommendations__info row expanded">
      <h2 class="c-recommendations__title column small-12">
        {{ $t('views.recommendations.recommendedItems') }}
      </h2>
    </div>
    <slider-wrapper
      v-if="recommendations.elements.length"
      :section="recommendations"
    >
      <template v-slot="{ data }">
        <product-card
          :redirect-to-home="true"
          :product="data"
        />
      </template>
    </slider-wrapper>
    <div v-else-if="loaded">
      <h3>
        {{ $t('views.recommendations.noItems') }}
      </h3>
    </div>
  </div>
</template>
<script>
  import ProductCard from '@/components/product-card/index'
  import SliderWrapper from '@/components/slider-wrapper/index'
  import { ONE_LINE } from '@/components/section-layouts/enums'
  import { LIVE } from '@/assets/javascript/enums/product-types'

  const RECOMMENDATION = 'recommendation'

  export default {
    name: 'Recommendations',
    inject: [
      'playerStore'
    ],
    components: {
      SliderWrapper,
      ProductCard
    },
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        loaded: false,
        playerState: this.playerStore.state,
        recommendations: {
          elements: []
        }
      }
    },
    async mounted () {
      this.$loader.show(this.$el, { noBackground: true })
      const recommendations = await this.$store.cache.dispatch('recommendations/GET_FROM_EDGE', {
        timestamp: this.$time().valueOf(),
        userId: this.$store.getters['user/GET_ACTIVE_PROFILE']
          ? this.$store.getters['user/GET_ACTIVE_PROFILE'].id
          : '',
        lastSeenItem: this.product.type === LIVE
          ? this.playerState.currentLiveProgram.id
          : this.product.id
      })

      this.recommendations = {
        elements: recommendations
          ? recommendations.map((item) => ({ ...item, layout: RECOMMENDATION }))
          : [],
        layout: ONE_LINE
      }
      this.loaded = true
      this.$loader.hide(this.$el)
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
