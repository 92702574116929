<template>
  <div class="c-payment-success">
    <div class="c-payment-success__container-top">
      <h2 class="c-payment-success__title">
        {{ $t('payments.popup.success.title') }}
      </h2>
    </div>
    <div class="c-payment-success__container-bottom">
      <div class="c-payment-success__container-icon">
        <svg class="c-payment-success__icon o-icon">
          <use xlink:href="#icon-ok_1" />
        </svg>
      </div>
      <div class="c-payment-success__description">
        {{ message }}
        <span class="c-payment-success__description-title">
          {{ productTitle }}.
        </span>
        {{ $t('payments.popup.success.description2') }}
      </div>
      <div class="c-payment-success__container-cta">
        <button
          class="o-button o-button--transparent o-button--success"
          @click="redirectToMyAccount"
        >
          {{ $t('buttons.close') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    ECOMMERCE
  } from '@/assets/javascript/dictionaries/gtmEvents'

  import priceFormat from '@/assets/javascript/filters/price-format'

  export default {
    name: 'PaymentSuccess',
    data () {
      return {
        product: undefined,
        paymentId: this.$route.query.paymentId
      }
    },
    computed: {
      cyclicPaymentId () {
        return this.$route.params.cyclicPaymentId
      },
      message () {
        return `${this.$t('payments.popup.success.description1')} ${this.cyclicPaymentId
          ? this.$t('payments.popup.success.bundle')
          : '' }: `
      },
      productTitle () {
        return this.product?.title
      }
    },
    watch: {
      '$route' () {
        this.findPaidProduct()
      }
    },
    mounted () {
      this.$loader.show(this.$el)
    },
    methods: {
      findPaidProduct () {
        if (this.cyclicPaymentId) {
          return this.getCyclicPayments()
        }
        return this.getPayments()
      },
      getCyclicPayments () {
        this.$store.dispatch('user/GET_CYCLIC_PAYMENTS', { firstResult: 0 })
          .then(({ items }) => {
            const getCyclicPayment = items.find(payment => payment.id === parseInt(this.cyclicPaymentId))
            const {
              product,
              price,
              nextChargeAt,
              createdAt
            } = getCyclicPayment
            const cycle = `${this.$time(`${new Date(nextChargeAt)}`).diff(`${new Date(createdAt)}`, 'day')} days`
            this.product = { ...product, price: priceFormat(price), cycle }
            this.$tracking.events.send(ECOMMERCE, { product: this.product })
            this.$loader.hide(this.$el)
          })
      },
      getPayments () {
        this.$store.dispatch('user/GET_PAYMENTS', { firstResult: 0 })
          .then(({ items }) => {
            const { product, price } = items.find(payment => payment.id === parseInt(this.paymentId))
            this.product = { ...product, price: priceFormat(price) }
            this.$tracking.events.send(ECOMMERCE, { product: this.product })
            this.$loader.hide(this.$el)
          })
      },
      redirectToMyAccount () {
        this.$router.push({
          name: this.$dict.routes.MY_ACCOUNT_PROFILE
        })
        this.$emit('close')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
