<template>
  <section>
    <h1 class="c-delete-phone__title">
      {{ $t('views.deletePhone.title') }}
    </h1>
    <message
      v-if="errors.global"
      :message="errors.global"
    />
    <p>
      {{ $t('views.deletePhone.description', { phone }) }}
    </p>
    <button
      ref="submit"
      type="submit"
      class="c-delete-phone__button o-button"
      @click="deletePhone"
    >
      {{ $t('buttons.remove') }}
    </button>
  </section>
</template>

<script>
  import { SUBMISSION_SUCCESS, VALIDATION_FAILED } from 'assets/javascript/dictionaries/gtmEventsActions'
  import errorMapper from 'assets/javascript/helpers/error-mapper'
  import trackingMixin from '@/mixins/tracking-mixin'
  import Message from '@/components/message'

  export default {
    components: {
      Message
    },
    mixins: [
      trackingMixin
    ],
    props: {
      phone: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        errors: []
      }
    },
    methods: {
      deletePhone () {
        this.$loader.show(this.$refs.submit, { small: true })
        this.$store.dispatch('user/DELETE_PHONE')
          .then(() => {
            this.sendFormTrackingData(SUBMISSION_SUCCESS)
            this.$toast.success(this.$t('views.deletePhone.deleted'))
            this.$emit('close')
          })
          .catch((error) => {
            this.$loader.hide(this.$refs.submit)
            return errorMapper(this.$t.bind(this), error)
              .then(errors => {
                this.trackingFormData.validationError = errors.code
                this.sendFormTrackingData(VALIDATION_FAILED)
                this.$set(this, 'errors', errors)
              })
          })
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss" />
