<template>
  <section>
    <h1 class="c-age-confirmation__title">
      {{ $t('views.ageConfirmation.title') }}
    </h1>
    <div v-dompurify-html="description" />
    <div class="c-age-confirmation__buttons-container">
      <button
        class="c-age-confirmation__button o-button"
        @click="accept"
      >
        {{ $t('buttons.watch') }}
      </button>
      <button
        class="c-age-confirmation__button o-button o-button--secondary"
        @click="cancel"
      >
        {{ $t('buttons.cancel') }}
      </button>
    </div>
  </section>
</template>

<script>
  const AGE_CONFIRMATION_DOCUMENT_ALIAS = 'age-confirmation'

  export default {
    props: {
      promise: {
        type: Object,
        default: null
      }
    },
    data () {
      return {
        description: ''
      }
    },
    created () {
      if (!this.promise) {
        return this.cancel()
      }
      this.getDescription()
    },
    methods: {
      accept () {
        this.promise.resolve()
        this.$emit('close')
      },
      cancel () {
        if (this.$route.params.redirectToHome) {
          this.$router.push({ name: this.$dict.routes.HOME })
        }

        this.$emit('close')
      },
      getDescription () {
        this.$loader.show(this.$root.$el)
        this.$store.dispatch('documents/GET_CONTENT', AGE_CONFIRMATION_DOCUMENT_ALIAS)
          .then((document) => {
            this.description = document
          })
          .finally(() => this.$loader.hide(this.$root.$el))
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
