<template>
  <div
    @click.stop=""
    @keyup.space.stop=""
  >
    <component
      :is="event.type"
      v-for="event in eventsToShow"
      :key="event.id"
      :event="event"
      :product="product"
    />
  </div>
</template>
<script>
  import {
    BANNER,
    QUESTION
  } from '@/components/secondary-events/enums/content-types'

  import { LIVE } from '@/assets/javascript/enums/product-types'
  import isSafari from '@/assets/javascript/helpers/is-safari'

  const LIVE_ITEM = LIVE.toLowerCase()

  export default {
    isSafari,
    name: 'SecondaryEvents',
    inject: {
      playerStore: { default: '' }
    },
    components: {
      [BANNER]: () => import('@/components/secondary-events/banner/index.vue'),
      [QUESTION]: () => import('@/components/secondary-events/question/index.vue')
    },
    props: {
      product: {
        type: Object,
        default: () => ({})
      },
      type: {
        type: String,
        default: LIVE_ITEM
      }
    },
    data () {
      return {
        fcmToken: '',
        liveEvents: [],
        unsubscribeMessageListener: () => ({}),
        playerState: this.playerStore?.state
      }
    },
    computed: {
      eventsToShow () {
        if (this.liveEvents.length) {
          return this.liveEvents
        }

        return this.playerState?.eventsToShow
          ?.filter(event => {
            return event.type === BANNER || event.type === QUESTION
          })
      },
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      },
      liveProgrammeId () {
        return this.$store.getters['epg/LIVE_BROADCAST_CURRENT_EPG_PROGRAMME']?.id
      }
    },
    async mounted () {
      if (await this.canShowEvents()) {
        const id = this.product.type === LIVE
          ? this.liveProgrammeId
          : this.product.id

        this.$store.dispatch('items/GET_SECONDARY_EVENTS', id)
          .then(events => {
            events.forEach((event) => {
              this.playerStore.addEventOnTimeline(event)
            })
          })
      }

      if (!this.liveProgrammeId || this.$options.isSafari()) {
        return
      }

      if (Notification.permission === 'granted') {
        this.$fireMess?.onMessage((message) => {
          const data = message.data || message.notification

          if (data.type) {
            this.liveEvents.push(data)
          }
        })
      }

      if (this.isLoggedIn) {
        return this.$store.dispatch('user/SUBSCRIBE_NOTIFICATION', {
          type: this.type,
          itemId: process.env.DEFAULT_LIVE_CHANNEL
        })
          .catch(() => {})
      }

      this.fcmToken = await this.$fireMess?.getToken()
      if (this.fcmToken) {
        return this.$store.dispatch('notifications/SUBSCRIBE', {
          type: LIVE_ITEM,
          itemId: process.env.DEFAULT_LIVE_CHANNEL,
          token: this.fcmToken
        })
          .catch(() => {})
      }
    },
    beforeDestroy () {
      if (!this.liveProgrammeId) {
        return
      }

      this.unsubscribeMessageListener()

      if (this.isLoggedIn) {
        return this.$store.dispatch('user/UNSUBSCRIBE_NOTIFICATION', {
          type: this.type,
          itemId: process.env.DEFAULT_LIVE_CHANNEL
        })
          .catch(() => {})
      }

      if (this.fcmToken) {
        return this.$store.dispatch('notifications/UNSUBSCRIBE', {
          type: LIVE_ITEM,
          itemId: process.env.DEFAULT_LIVE_CHANNEL,
          token: this.fcmToken
        })
          .catch(() => {})
      }
    },
    methods: {
      async canShowEvents () {
        if (this.type.toLowerCase() === LIVE_ITEM && !this.liveProgrammeId) {
          await this.$store.dispatch('epg/GET_LIVE_NOW', {
            id: process.env.DEFAULT_LIVE_CHANNEL
          })

          if (!this.liveProgrammeId) {
            return false
          }
        }

        return true
      }
    }
  }
</script>
