<template>
  <section
    class="c-notifications"
    :class="{ 'c-notifications--with-scroll': notifications.length > 5 }"
  >
    <styled-scroll
      ref="container"
      class="c-notifications__scroll"
    >
      <div
        v-for="(notification, index) in notifications"
        :key="notification.id"
      >
        <div class="c-notifications__row row">
          <div class="column small-4">
            <img
              :src="thumbnail(notification.item)"
              :alt="notification.item.title"
            >
          </div>
          <div class="column small-7">
            <div class="c-notifications__notification">
              <div class="c-notifications__title">
                {{ notification.item.title }}
              </div>
              <div class="c-notifications__subtitle">
                {{ notification.item.since | dateFormat('DD.MM.YYYY, HH:mm') }}
              </div>
            </div>
          </div>
          <div class="column small-1">
            <svg
              class="c-notifications__icon o-icon"
              @click="removeNotification(notification)"
            >
              <title>
                {{ $t('buttons.removeFromNotifications') }}
              </title>
              <use xlink:href="#icon-close" />
            </svg>
          </div>
        </div>
        <div
          v-if="index !== notifications.length - 1"
          class="c-notifications__separator"
        />
      </div>
    </styled-scroll>
  </section>
</template>

<script>
  import StyledScroll from '@/components/styled-scroll'
  import { ASPECT_16X9 } from '@/assets/javascript/enums/aspect-ratios'

  export default {
    components: {
      StyledScroll
    },
    props: {
      notifications: {
        type: Array,
        default: () => ([])
      }
    },
    watch: {
      notifications () {
        this.$refs.container.perfectScroll && this.$refs.container.perfectScroll.update()
      }
    },
    methods: {
      thumbnail (item) {
        return item.images?.[ASPECT_16X9]?.[0]?.mainUrl
      },
      removeNotification ({ item }) {
        this.$loader.show(this.$el)
        this.$store.dispatch('user/REMOVE_FROM_NOTIFICATIONS', item)
          .finally(() => this.$loader.hide(this.$el))
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
