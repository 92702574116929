<template>
  <div class="c-compatibility-page">
    <div class="c-compatibility-page__content">
      <h1 class="c-compatibility-page__heading">
        {{ $t('views.compabilityTest.heading') }}
      </h1>
      <test />
    </div>
  </div>
</template>
<script>
  import Test from '@/components/compatibility-test/index.vue'

  export default {
    name: 'CompatibilityTest',
    components: {
      Test
    },
    head () {
      return {
        title: this.$t('views.compabilityTest.title')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
