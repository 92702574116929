<template>
  <section class="c-verify-token">
    <div
      v-if="error"
      class="c-error c-error--default"
    >
      <svg class="c-error__icon  o-icon">
        <use xlink:href="#icon-error" />
      </svg>
      <h2 class="c-verify-token__heading">
        {{ $t('views.verifyToken.error') }}
      </h2>
    </div>
  </section>
</template>
<script>
  export default {
    data () {
      return {
        error: false
      }
    },
    mounted () {
      this.$loader.show(this.$el.parentElement)
      const token = this.$route.query.token

      if (token) {
        this.$store.$api
          .resources
          .subscribers
          .verify(token)
          .then(() => {
            this.$toast.success(this.$t('views.verify.success'))
            this.$router.push({ name: this.$dict.routes.HOME })
          })
          .catch(() => {
            this.error = true
          })
          .finally(() => this.$loader.hide(this.$el.parentElement))
      }
    },
    head () {
      return {
        title: this.$t('views.verifyToken.title')
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
