<template>
  <div
    v-if="progress"
    class="c-progress-bar"
  >
    <div
      class="c-progress-bar__progress"
      :style="{ transform: `translate3d(${progress}%, 0, 0)` }"
    />
  </div>
</template>
<script>
  export default {
    name: 'ProgressBar',
    props: {
      currentProgress: {
        type: Number,
        default: 0
      }
    },
    computed: {
      progress () {
        const scale = parseFloat(this.currentProgress, 10)
        if (scale >= 100) {
          return 100
        }
        return scale
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
