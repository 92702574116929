<template>
  <div
    v-autoplay="{
      callback: next,
      active: opts.autoplay,
      delay: opts.autoplay.delay
    }"
    class="my-slider"
  >
    <div
      class="my-slider__wrapper"
      :style="wrapperStyle"
    >
      <div
        v-for="{id, position, items} in carousel"
        :key="position"
        :data-id="id"
        class="my-slider__slide"
        :style="slideStyle(id)"
      >
        <div
          v-for="(item, itemIndex) in items"
          :key="item.id"
          :data-id="item.id"
          class="my-slider__item cardAnimation"
          :style="slideItemStyle(id)"
          @click="opts.moveToSlide ? goToSlide(id) : null"
        >
          <slot
            :class="slideItemClass"
            :data="item.item"
            :index="itemIndex"
          />
        </div>
      </div>
    </div>
    <buttons
      v-if="haveEnoughItems && !opts.hideArrows"
      :current-index="index"
      :on-prev-click="prev"
      :on-next-click="next"
      :on-go-to-index="goToIndex"
      :delay="opts.delayPerSlide"
      :loop="isLoopedSlider"
      :number-of-slides="numberOfSlides"
    />
  </div>
</template>
<script>
  import Autoplay from './directives/autoplay'
  import { Sliders, SlidersCallbacks, STANDARD, LOOPED } from './sliders'
  import Buttons from './components/buttons'

  export default {
    name: 'Slider',
    directives: {
      autoplay: Autoplay
    },
    components: {
      Buttons
    },
    props: {
      options: {
        type: Object,
        default: () => ({})
      },
      slideItemClass: {
        type: String,
        default: ''
      },
      hideSidesSlides: Boolean,
      items: {
        type: Array,
        default: () => ([])
      }
    },
    data () {
      return {
        centeringOffset: 0,
        delay: 0,
        offset: 0,
        slider: {},
        animationPromiseResolve: null,
        afterFirstAnimation: false
      }
    },
    defaultOptions: {
      sliderType: STANDARD,
      slideBufferPerSideSize: 2,
      itemsPerSlide: 1,
      startIndex: 0,
      alignSlidesToTheEnd: false,
      centerItem: null,
      delayPerSlide: 1000,
      splitAnimation: true,
      scale: 1.45,
      slideSelector: '.my-slider__slide',
      slideItemSelector: '.my-slider__item',
      autoplay: false,
      moveToSlide: false
    },
    computed: {
      wrapper () {
        return this.$el?.querySelector('.my-slider__wrapper')
      },
      opts () {
        return { ...this.$options.defaultOptions, ...this.options }
      },
      carousel () {
        return this.slider.carousel || []
      },
      index () {
        return this.slider.index || 0
      },
      wrapperStyle () {
        return {
          transform: `translate3d(${this.offset + this.centeringOffset}%, 0, 0)`,
          transition: `transform ${this.delay}ms`
        }
      },
      haveEnoughItems () {
        return this.items.length > this.opts.itemsPerSlide
      },
      splitOffset () {
        return this.opts.itemsPerSlide
      },
      offsetPerItem () {
        return (100 / this.opts.itemsPerSlide)
      },
      numberOfSlides () {
        return this.slider.slides.length || 0
      },
      isLoopedSlider () {
        return this.opts.sliderType === LOOPED
      }
    },
    watch: {
      'opts.sliderType': {
        immediate: true,
        handler (sliderType) {
          this.init(sliderType)
        }
      },
      'opts.startFromItemIndex' (index) {
        this.goToSlide(Math.floor(index / this.opts.itemsPerSlide))
      },
      'opts.startIndex' (id) {
        this.goToIndex(id)
      },
      items (items, prevItems) {
        this.slider.setItems(items)
        if (prevItems) {
          this.goToSlide(0)
        }
      },
      'opts.itemsPerSlide' () {
        this.init()
      }
    },
    mounted () {
      this.wrapper.removeEventListener('transitionend', this.handleTransitionEnd)
      this.wrapper.addEventListener('transitionend', this.handleTransitionEnd)
    },
    beforeDestroy () {
      this.wrapper.removeEventListener('transitionend', this.handleTransitionEnd)
    },
    methods: {
      handleTransitionEnd ({ target }) {
        if (target !== this.wrapper) {
          return
        }
        this.animationPromiseResolve?.()
        this.animationPromiseResolve = null
      },
      carouselChanged ({ beforeAnimation }) {
        const itemsOffsetBySlides = this.slider.constructor.getItemsDistanceOnTheCollection({
          collection: this.slider.carousel,
          indexFrom: 0,
          indexTo: this.slider.getCarouselIndex(this.slider.slideId)
        })

        const currSlideItemsAmount = this.slider.slides[this.slider.index].items.length
        const currCenterItemIndex = this.slider.centerItemIndexes?.curr
        const prevCenterItemIndex = this.slider.centerItemIndexes?.prev
        const curr = currCenterItemIndex <= currSlideItemsAmount ? currCenterItemIndex : 0
        const prev = prevCenterItemIndex <= currSlideItemsAmount ? prevCenterItemIndex : 0

        const itemOffsetByCenterItemIndex = this.haveEnoughItems
          ? (beforeAnimation ? prev : curr)
          : 0

        this.delay = 0
        this.offset = -(itemsOffsetBySlides + itemOffsetByCenterItemIndex) * this.offsetPerItem
        this.$emit('changed', this.slider.index)
      },
      animate ({
        index,
        itemsDistanceToAnimate
      }) {
        return new Promise((resolve, reject) => {
          if (!this.slider) {
            return reject(new Error('Slider was not proper initiated'))
          }

          this.$emit('animationStarted', index)

          window.requestAnimationFrame(() => {
            const {
              delayPerSlide,
              withoutInitialAnimation = false
            } = this.opts
            const offsetDelta = this.haveEnoughItems
              ? this.offsetPerItem * itemsDistanceToAnimate
              : 0
            const centeringOffset = this.haveEnoughItems && this.slider.isCentered?.()
              ? (this.slider.itemsPerSlide / 2 - 1 / 2) * this.offsetPerItem
              : 0
            const centeringItemsDistanceToAnimate = centeringOffset !== this.centeringOffset
              ? this.slider.itemsPerSlide / 2 - 1 / 2
              : 0

            const delay = delayPerSlide * Math.abs(itemsDistanceToAnimate + centeringItemsDistanceToAnimate) / this.slider.itemsPerSlide

            const theFirstAnimationToSkip = withoutInitialAnimation && !this.afterFirstAnimation
            if (!this.afterFirstAnimation) {
              this.afterFirstAnimation = true
            }

            if (theFirstAnimationToSkip) {
              this.delay = 0
              this.offset -= offsetDelta
              this.centeringOffset = centeringOffset
            }

            if (theFirstAnimationToSkip || !delay || (!offsetDelta && !centeringItemsDistanceToAnimate)) {
              this.animationPromiseResolve = null
              return resolve()
            }

            this.delay = delay
            this.offset -= offsetDelta
            this.centeringOffset = centeringOffset

            this.animationPromiseResolve = resolve
          })
        })
      },
      slideStyle (slideId) {
        return {
          width: `${this.getSlideLength(slideId) * this.offsetPerItem}%`
        }
      },
      slideItemStyle (slideId) {
        return {
          width: `${100 / this.getSlideLength(slideId)}%`
        }
      },
      getSlideLength (slideId) {
        return this.slider.getSlideById(slideId)?.items?.length
      },
      goToSlide (slideId) {
        this.goToIndex(this.slider.getSlideIndex(slideId))
      },
      goToIndex (index) {
        if (this.slider?.isReady()) {
          this.slider.goToSlide({ index })
        }
      },
      prev () {
        this.moveSlides(-1)
      },
      next () {
        this.moveSlides(1)
      },
      moveSlides (steps) {
        if (this.slider.isReady()) {
          this.slider.moveSlides(steps)
        }
      },
      isFirstVisibleElement (itemElement) {
        const firstVisibleItem = this.slider.getFirstVisibleItem()
        return parseInt(itemElement?.dataset.id, 10) === parseInt(firstVisibleItem?.id, 10)
      },
      isLastVisibleElement (itemElement) {
        const lastVisibleItem = this.slider.getLastVisibleItem()
        return parseInt(itemElement?.dataset.id, 10) === parseInt(lastVisibleItem?.id, 10)
      },
      init (sliderType = STANDARD) {
        const {
          slideBufferPerSideSize,
          itemsPerSlide,
          startIndex,
          startFromItemIndex,
          alignSlidesToTheEnd,
          centerItem,
          autoInit = true
        } = this.opts

        if (![STANDARD, LOOPED].includes(sliderType)) {
          return console.error(`Unsupported slider type: ${sliderType}`)
        }

        this.slider = new Sliders[sliderType]({
          slideBufferPerSideSize,
          itemsPerSlide,
          startIndex: Math.floor(startFromItemIndex / itemsPerSlide) || startIndex,
          alignSlidesToTheEnd,
          items: this.items || [],
          centerItem
        })

        this.slider.setCallback({ callbackType: SlidersCallbacks.CAROUSEL_CHANGED, callback: this.carouselChanged })
        this.slider.setCallback({ callbackType: SlidersCallbacks.ANIMATION, callback: this.animate })

        this.afterFirstAnimation = false
        if (autoInit) {
          this.slider.init()
        }
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
