<template>
  <div class="c-profile-navbar-list__profile-list">
    <div class="c-profile-navbar-list__profile-items">
      <div
        v-for="profile in user.profiles"
        :key="profile.id"
        class="c-profile-navbar-list__profile-item"
        :class="{ 'is-active': activeProfileId === profile.id }"
        @click="switchProfile(profile)"
      >
        <div class="c-profile-navbar-list__avatar-wrapper">
          <avatar
            :profile="profile"
            :edit="false"
            class="c-profiles-avatar--small"
          />
        </div>
        {{ profile.name }}
      </div>
    </div>
  </div>
</template>

<script>
  import Avatar from '@/components/avatar'
  import SwitchProfile from '@/mixins/switch-profile'

  export default {
    name: 'ProfileNavbarList',
    components: {
      Avatar
    },
    mixins: [
      SwitchProfile
    ],
    computed: {
      user () {
        return this.$store.state.user.data
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
