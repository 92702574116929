<template>
  <div
    class="c-rating-bar"
    @mouseleave="hoverValue = value"
  >
    <svg
      v-for="(star, index) in stars"
      :key="index"
      class="c-rating-bar__icon o-icon"
      :class="{ 'is-active': star <= hoverValue }"
      @mouseover="hoverValue = star"
      @click="setValue(star)"
    >
      <use :xlink:href="star <= hoverValue ? '#icon-rating-star' : '#icon-rating-star-empty'" />
    </svg>
  </div>
</template>
<script>
  export default {
    name: 'RatingBar',
    props: {
      value: {
        type: Number,
        default: 0
      },
      length: {
        type: Number,
        default: 5
      }
    },
    data () {
      return {
        hoverValue: 0
      }
    },
    computed: {
      stars () {
        return Array.from({ length: this.length }, (_, k) => k + 1)
      }
    },
    watch: {
      value: {
        immediate: true,
        handler () {
          this.hoverValue = this.value
        }
      }
    },
    methods: {
      setValue (value) {
        this.$emit('input', value)
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss" />
