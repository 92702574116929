<template>
  <div class="c-consents-notice">
    <div
      v-dompurify-html="document"
      class="c-consents-notice__content"
    />
    <div class="c-consents-notice__buttons-container">
      <button
        class="c-consents-notice__button o-button"
        @click="accept"
      >
        {{ $t('common.accept') }}
      </button>
    </div>
  </div>
</template>

<script>
  const LS_CONSENTS_NOTICE = 'consentsNotice'

  export default {
    props: {
      document: {
        type: String,
        default: ''
      }
    },
    methods: {
      accept () {
        window.localStorage.setItem(LS_CONSENTS_NOTICE, '1')
        this.$emit('close')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
