<template>
  <section class="c-contact o-page-form">
    <div v-if="!success">
      <h1 class="c-contact__title">
        {{ $t('views.contact.reportIssue') }}
      </h1>
      <message
        v-if="errors.global"
        :message="errors.global"
      />
      <form
        class="o-form"
        @submit.prevent="submit"
      >
        <div
          v-if="!email"
          class="c-contact__label"
        >
          {{ $t('common.email') }}
        </div>
        <v-input
          v-if="!email"
          v-model="contactForm.senderEmail"
          class="o-input__wrapper--small-margin"
          :placeholder="$t('common.email')"
          :restriction-rules="[restrictionRules.NO_SPACES]"
          :validation-rules="[validationRules.EMAIL]"
          @changedValidity="setFormValidity('senderEmail', $event)"
        />
        <message
          v-if="errors.senderEmail"
          :message="errors.senderEmail"
        />
        <div class="c-contact__label">
          {{ $t('views.contact.title') }}
        </div>
        <v-input
          v-model="contactForm.title"
          class="o-input__wrapper--small-margin"
          :placeholder="$t('views.contact.title')"
          :tooltip="$t('tooltips.title')"
          :tooltip-position="mobileOrTablet ? 'left' : 'right'"
          :validation-rules="[validationRules.MIN_LENGTH_5]"
          @changedValidity="setFormValidity('title', $event)"
        />
        <message
          v-if="errors.title"
          :message="errors.title"
        />
        <div class="c-contact__label">
          {{ $t('views.contact.content') }}
        </div>
        <textarea
          v-model="contactForm.content"
          rows="5"
          class="o-input o-input__wrapper--small-margin"
          :placeholder="$t('views.contact.content')"
        />
        <message
          v-if="errors.content"
          :message="errors.content"
        />
        <button
          ref="submit"
          type="submit"
          class="c-contact__submit o-button"
          :class="{ 'o-button--disabled' : !isFormValid }"
          :disabled="!isFormValid"
        >
          {{ $t('common.send') }}
        </button>
      </form>
    </div>
    <success-view v-else>
      <svg class="c-success__icon o-icon">
        <use xlink:href="#icon-mail" />
      </svg>
      <h1 class="c-success__heading">
        {{ $t('views.contact.messageSent') }}
      </h1>
      <nuxt-link
        :to="{ name: $dict.routes.HOME }"
        class="c-success__button o-button o-button--secondary"
      >
        {{ $t('buttons.goBackToHomePage') }}
      </nuxt-link>
    </success-view>
  </section>
</template>

<script>
  import {
    SUCCESS
  } from '@/assets/javascript/enums/message-types'

  import {
    SUBMISSION_SUCCESS,
    VALIDATION_FAILED
  } from '@/assets/javascript/dictionaries/gtmEventsActions'

  import errorMapper from '@/assets/javascript/helpers/error-mapper.js'
  import Message from '@/components/message'
  import SuccessView from '@/components/success-view'
  import VInput from '@/components/input'
  import restrictionRules from '@/assets/javascript/dictionaries/input-restriction-rules'
  import validationRules from '@/assets/javascript/dictionaries/input-validation-rules'
  import formValidityMixin from '@/mixins/form-validity-mixin'
  import trackingMixin from '@/mixins/tracking-mixin'
  import { CONTACT as CAPTCHA_CONTACT } from '@/assets/javascript/enums/captcha-types'
  import mobileOrTabletMixin from '@/mixins/mobile-or-tablet-mixin'

  export default {
    name: 'ContactPage',
    processing: false,
    successMessageType: SUCCESS,
    components: {
      Message,
      SuccessView,
      VInput
    },
    mixins: [
      formValidityMixin,
      mobileOrTabletMixin,
      trackingMixin
    ],
    data () {
      return {
        errors: [],
        contactForm: {
          captcha: '',
          content: '',
          senderEmail: '',
          title: ''
        },
        restrictionRules,
        validationRules,
        validity: {
          senderEmail: false,
          title: false
        },
        success: false
      }
    },
    computed: {
      email () {
        return this.$store.state.user.data.email
      }
    },
    mounted () {
      if (this.email) {
        this.validity.senderEmail = true
      }
    },
    methods: {
      async submit () {
        if (!this.isFormValid || this.$options.processing) {
          return
        }

        try {
          if (this.email) {
            this.contactForm.senderEmail = this.email
          }

          this.$loader.show(this.$refs.submit, { small: true })
          this.$options.processing = true
          this.contactForm.captcha = await this.$recaptcha(CAPTCHA_CONTACT)
          await this.$store.dispatch('contacts/SUBMIT_FORM', this.contactForm)
          this.errors = []
          this.success = true
          this.sendFormTrackingData(SUBMISSION_SUCCESS)
        } catch (error) {
          return errorMapper(this.$t.bind(this), error)
            .then(errors => {
              this.trackingFormData.validationError = errors.code
              this.sendFormTrackingData(VALIDATION_FAILED)
              this.$set(this, 'errors', errors)
            })
        } finally {
          this.$options.processing = false
          this.$loader.hide(this.$refs.submit)
        }
      }
    },
    head () {
      return {
        title: this.$t('views.contact.pageTitle')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
