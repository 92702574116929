<template>
  <div
    v-if="!broadcastAgreement"
    class="row expanded collapse"
  >
    <div class="c-broadcast__reminder-container column small-12">
      <h1 class="c-broadcast__heading c-broadcast__reminder-heading">
        {{ $t('views.myAccount.broadcast.reminderHeading') }}
      </h1>
      <svg class="c-broadcast__reminder-icon o-icon">
        <use xlink:href="#icon-check_mark" />
      </svg>
      <p class="c-broadcast__reminder-subheading">
        {{ $t('views.myAccount.broadcast.reminderSubheading') }}
      </p>
      <div class="c-broadcast__reminder-button-container">
        <nuxt-link
          :to="{ name: $dict.routes.MY_ACCOUNT_PROFILE }"
          class="c-broadcast__reminder-spacing o-button"
        >
          {{ $t('buttons.goToAgreements') }}
        </nuxt-link>
      </div>
    </div>
  </div>
  <div
    v-else
    class="c-broadcast"
  >
    <div class="row expanded collapse">
      <h1 class="c-broadcast__heading column small-12">
        {{ $t('broadcast.profile.heading') }}
      </h1>
      <div class="c-broadcast__column-spacing column small-12 large-1">
        <div class="row collapse">
          <div class="column small-12">
            <div
              class="c-broadcast__photo-container"
              @click="editProfile"
            >
              <broadcast-photo
                :url="profile.photoUrl"
                :alt="profile.name"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column small-12 large-3">
        <div class="row collapse">
          <div class="c-broadcast__label column small-12">
            {{ $t('broadcast.profile.name') }}
          </div>
          <div class="c-broadcast__text column small-12">
            {{ profile.name }}
          </div>
        </div>
      </div>
      <div class="column small-12 large-2">
        <div class="row expanded collapse">
          <div class="c-broadcast__label column small-12">
            {{ $t('broadcast.profile.birthDate') }}
          </div>
          <div class="c-broadcast__text column small-12">
            {{ profile.birthDateShort }}
          </div>
        </div>
      </div>
      <div class="column small-12 large-3">
        <div class="row expanded collapse">
          <div class="c-broadcast__label column small-12">
            {{ $t('broadcast.profile.city') }}
          </div>
          <div class="c-broadcast__text column small-12">
            {{ profile.city }}
          </div>
        </div>
      </div>
      <div class="column small-12 large-3">
        <div class="c-broadcast__button-container">
          <nuxt-link
            :to="{ name: $dict.routes.BROADCAST_PROFILE }"
            class="o-button o-button--secondary o-button--fixed-width"
          >
            {{ $t('broadcast.profile.edit') }}
          </nuxt-link>
        </div>
      </div>
    </div>
    <separator class="o-separator--horizontal" />
    <div class="c-broadcast__row-spacing row expanded collapse">
      <div class="c-broadcast__heading column small-12">
        {{ $t('views.myAccount.broadcast.liveProgrammeHeading') }}
      </div>
      <div class="column small-12 large-3">
        <div class="row collapse">
          <div class="c-broadcast__label column small-12">
            {{ $t('views.myAccount.broadcast.programmeName') }}
          </div>
          <div class="c-broadcast__text column small-12">
            {{ liveProgrammeTitle }}
          </div>
        </div>
      </div>
      <div class="column small-12 large-3">
        <div class="row collapse">
          <div class="c-broadcast__label column small-12">
            {{ $t('views.myAccount.broadcast.broadcastPossibility') }}
          </div>
          <div class="c-broadcast__text column small-12">
            {{ broadcastPossibility }}
          </div>
        </div>
      </div>
      <div class="column small-12 large-3">
        <div class="row collapse">
          <div class="c-broadcast__label column small-12">
            {{ $t('views.myAccount.broadcast.instructions') }}
          </div>
          <div class="c-broadcast__text column small-12">
            {{ $t('views.myAccount.broadcast.waitingRoom') }}
          </div>
        </div>
      </div>
      <div class="column small-12 large-3">
        <div class="c-broadcast__button-container">
          <nuxt-link
            :to="{ name: $dict.routes.TRANSMISSION, params: { canAccess: true } }"
            class="o-button"
            :class="{ 'o-button--disabled': !canJoin }"
          >
            {{ $t('broadcast.profile.accessLive') }}
          </nuxt-link>
        </div>
      </div>
    </div>
    <separator class="o-separator--horizontal" />
    <div class="c-broadcast__row-spacing row expanded collapse">
      <div class="c-broadcast__heading column small-12">
        {{ $t('views.myAccount.broadcast.rankingHeading') }}
      </div>
      <div class="column small-12 large-3">
        <div class="row collapse">
          <div class="c-broadcast__label column small-12">
            {{ $t('views.myAccount.broadcast.rankingTitle') }}
          </div>
          <div class="c-broadcast__text column small-12">
            {{ $t('views.myAccount.broadcast.rankingSubtitle') }}
          </div>
        </div>
      </div>
      <div class="column small-12 large-offset-6 large-3">
        <div class="c-broadcast__button-container">
          <button
            class="o-button o-button--secondary o-button--fixed-width"
            @click="showBroadcastList"
          >
            {{ $t('views.myAccount.broadcast.ranking') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BroadcastPhoto from '@/components/broadcast-photo/index'
  import Separator from '@/components/separator/index'

  import getBroadcastProfileMixin from '@/mixins/get-broadcast-profile'

  export default {
    name: 'Broadcast',
    components: {
      BroadcastPhoto,
      Separator
    },
    mixins: [
      getBroadcastProfileMixin
    ],
    data () {
      return {
        profile: {}
      }
    },
    computed: {
      canJoin () {
        return this.liveBroadcasting && this.profile.name
      },
      imgAlt () {
        return this.$store.getters['broadcast/BROADCAST_PROFILE'].name
      },
      liveProgramme () {
        return this.$store.getters['epg/LIVE_BROADCAST_CURRENT_EPG_PROGRAMME']
      },
      liveBroadcasting () {
        return this.liveProgramme.liveBroadcasting
      },
      broadcastPossibility () {
        return this.liveBroadcasting
          ? this.$t('views.myAccount.broadcast.yes')
          : this.$t('views.myAccount.broadcast.no')
      },
      liveProgrammeTitle () {
        return this.liveProgramme?.title || '-'
      },
      broadcastAgreement () {
        return this.$store.getters['user/BROADCAST_AGREEMENT']
      }
    },
    mounted () {
      if (this.broadcastAgreement) {
        this.$loader.show(this.$root.$el)
        this.getBroadcastProfile()
          .then(profile => {
            this.profile = profile
          })
          .catch(error => {
            this.$toast.error(error)
          })
          .finally(() => this.$loader.hide(this.$root.$el))
      }
    },
    methods: {
      editProfile () {
        this.$router.push({ name: this.$dict.routes.BROADCAST_PROFILE })
      },
      showBroadcastList () {
        this.$popup.show(this.$dict.popups.RANKING)
      }
    },
    head () {
      return {
        title: this.$t('views.myAccount.broadcast.title')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
