<template>
  <div
    class="c-avatar"
    :class="{ 'o-icon--user': showDefaultIcon }"
    :style="avatarStyle"
  >
    {{ avatarLetter }}
    <span
      v-if="edit"
      class="c-avatar__icon-edit"
    >
      <svg class="o-icon">
        <use xlink:href="#icon-edit" />
      </svg>
    </span>
  </div>
</template>
<script>
  export default {
    name: 'Avatar',
    props: {
      profile: {
        type: Object,
        default: () => ({})
      },
      edit: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      activeProfile () {
        return this.$store.getters['user/ACTIVE_PROFILE']
      },
      avatarStyle () {
        if (this.avatarUrl) {
          return { 'background-image': `url(${this.avatarUrl})` }
        }

        return this.profile.color
          ? { 'background-color': this.profile.color }
          : {}
      },
      avatarLetter () {
        if (this.avatarUrl) {
          return ''
        }
        return (this.profile.name?.charAt(0).toLocaleUpperCase()) || ''
      },
      avatarUrl () {
        return this.profile.avatar?.url
      },
      showDefaultIcon () {
        return !Object.keys(this.avatarStyle).length && this.avatarLetter === ''
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
