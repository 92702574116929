<template>
  <main>
    <categories v-if="hasCategoryId" />
    <sections
      :sections="sections"
      :load-more="loadMore"
      :layouts="$options.sectionLayouts"
      :section-strategy="$options.sectionStrategy"
    />
  </main>
</template>
<script>
  import {
    RECOMMENDATION_EDGE,
    SUBSCRIBER_FAVOURITE,
    SUBSCRIBER_WATCHED
  } from '@/assets/javascript/enums/section-types'

  import Sections from '@/components/sections/index'
  import { SectionLayouts } from '@/components/section-layouts'

  const DEFAULT_SECTION_LABEL = 'main'
  const KIDS_SECTION_LABEL = 'kids'

  export default {
    components: {
      Categories: () => import('@/components/categories/index'),
      Sections
    },
    asyncData ({ route, store }) {
      const isKid = store.getters['user/ACTIVE_PROFILE']?.kids
      const label = route.params.label || (isKid ? KIDS_SECTION_LABEL : DEFAULT_SECTION_LABEL)
      store.commit('sections/RESET_SECTIONS', label)

      return store.dispatch('sections/GET', { label, kids: isKid })
        .then(() => ({
          label
        }))
    },
    computed: {
      activeProfileId () {
        const activeProfile = this.$store.getters['user/ACTIVE_PROFILE']
        return activeProfile
          ? activeProfile.id
          : ''
      },
      view () {
        return this.$store.getters['sections/GET_BY_LABEL'](this.label)
      },
      sections () {
        return this.view?.sections || []
      },
      hasCategoryId () {
        return this.$route.params.id
      }
    },
    sectionLayouts: SectionLayouts,
    // @TODO: check why this is called multiple times
    sectionStrategy: {
      [RECOMMENDATION_EDGE] (section) {
        return {
          ...section,
          elements: this.$store.state.recommendations.edgeRecommendations
        }
      },
      [SUBSCRIBER_FAVOURITE] (section) {
        return {
          ...section,
          elements: this.$store.state.favourites.favourites
        }
      },
      [SUBSCRIBER_WATCHED] (section) {
        return {
          ...section,
          elements: this.$store.state.watched.watched
        }
      }
    },
    mounted () {
      this.$store.cache.dispatch('recommendations/GET_FROM_EDGE', {
        timestamp: this.$time().valueOf(),
        userId: this.activeProfileId
      })
    },
    methods: {
      loadMore (load, complete) {
        complete()
      }
    },
    beforeRouteLeave (_, __, next) {
      this.$store.commit('sections/RESET_SECTIONS', this.label)
      next()
    }
  }
</script>
