<template>
  <section>
    <h1 class="c-verify-phone__title">
      {{ $t('views.verifyPhone.title') }}
    </h1>
    <p>
      {{ $t('views.verifyPhone.description', { phone }) }}
    </p>
    <p v-if="codeResent">
      {{ $t('views.verifyPhone.codeResent') }}
    </p>

    <form
      class="o-form"
      @submit.prevent="verify"
    >
      <v-input
        v-model="form.verifyPhoneToken"
        :placeholder="$t('common.code')"
        :validation-rules="[validationRules.PHONE_CODE]"
        @changedValidity="setFormValidity('verifyPhoneToken', $event)"
      />
      <message
        v-if="errors.verifyPhoneToken || errors.global"
        :message="errors.verifyPhoneToken || errors.global"
      />
      <div class="c-verify-phone__buttons-container">
        <button
          ref="submit"
          type="submit"
          class="c-verify-phone__submit o-button"
          :class="{ 'o-button--disabled' : !isFormValid }"
          :disabled="!isFormValid"
        >
          {{ $t('common.save') }}
        </button>
        <button
          ref="resendButton"
          class="c-verify-phone__resend-button"
          @click="resendCode"
        >
          {{ $t('common.resendCode') }}
        </button>
      </div>
    </form>
    <separator class="o-separator--horizontal o-separator--horizontal-small-margin" />
  </section>
</template>

<script>
  import {
    SUBMISSION_SUCCESS,
    VALIDATION_FAILED
  } from '@/assets/javascript/dictionaries/gtmEventsActions'

  import errorMapper from '@/assets/javascript/helpers/error-mapper.js'
  import validationRules from '@/assets/javascript/dictionaries/input-validation-rules'

  import Message from '@/components/message'
  import VInput from '@/components/input'
  import Separator from '@/components/separator/index'

  import formValidityMixin from '@/mixins/form-validity-mixin'
  import trackingMixin from '@/mixins/tracking-mixin'

  const SUBSCRIBER_PHONE_TOKEN_EXPIRED = 'SUBSCRIBER_PHONE_TOKEN_EXPIRED'

  export default {
    processing: false,
    components: {
      Separator,
      Message,
      VInput
    },
    mixins: [
      formValidityMixin,
      trackingMixin
    ],
    props: {
      phone: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        codeResent: false,
        errors: [],
        form: {
          verifyPhoneToken: ''
        },
        validationRules,
        validity: {
          verifyPhoneToken: false
        }
      }
    },
    computed: {
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      }
    },
    methods: {
      resendCode () {
        if (this.$options.processing) {
          return
        }
        this.$options.processing = true
        this.$loader.show(this.$refs.resendButton, { small: true, noBackground: true })
        this.$store.dispatch('user/RESEND_PHONE_CODE', { phone: this.phone })
          .then(() => {
            this.codeResent = true
          })
          .catch((error) => {
            return errorMapper(this.$t.bind(this), error)
              .then((errors) => this.$set(this, 'errors', errors))
          })
          .finally(() => {
            this.$options.processing = false
            this.$loader.hide(this.$refs.resendButton)
          })
      },
      verify () {
        if (!this.isFormValid || this.$options.processing) {
          return
        }
        this.$options.processing = true
        this.$loader.show(this.$refs.submit, { small: true })
        this.$store.dispatch('user/VERIFY_PHONE', this.form)
          .then(async () => {
            if (!this.isLoggedIn) {
              this.$popup.close()
              this.$popup.show(this.$dict.popups.REGISTER_SUCCESS)
              return await this.$router.push({ name: this.$dict.routes.HOME })
            }

            this.errors = []
            this.sendFormTrackingData(SUBMISSION_SUCCESS)
            this.$toast.success(this.$t('views.verifyPhone.success'))
            this.$emit('close')
          })
          .catch((error) => {
            return errorMapper(this.$t.bind(this), error)
              .then((errors) => {
                if (errors && errors.code === SUBSCRIBER_PHONE_TOKEN_EXPIRED) {
                  this.$popup.close()
                  return this.$popup.show(this.$dict.popups.CHANGE_PHONE, { phone: this.phone, tokenExpired: true })
                }
                this.trackingFormData.validationError = errors.code
                this.sendFormTrackingData(VALIDATION_FAILED)
                this.$set(this, 'errors', errors)
              })
          })
          .finally(() => {
            this.$options.processing = false
            this.$loader.hide(this.$refs.submit)
          })
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
