<template>
  <div>
    <nuxt />
  </div>
</template>

<script>
  import seoMixin from '@/mixins/seo-mixin'

  export default {
    mixins: [seoMixin]
  }
</script>
