<template>
  <div
    v-if="isWatched"
    class="c-action-buttons__button"
  >
    <div
      class="c-remove-from-watched__icon-link"
      @click="remove"
    >
      <svg class="o-icon">
        <use xlink:href="#icon-like-fill" />
      </svg>
      <div class="c-action-buttons__title">
        {{ $t('buttons.removeFromWatched') }}
      </div>
    </div>
  </div>
</template>

<script>
  import { PRODUCT_TYPE_TO_VIDEO_TYPE } from '@/assets/javascript/maps/product-type-to-video-type'
  import { ACTION } from 'assets/javascript/dictionaries/gtmEvents'
  import { REMOVE_FROM_MY_LIST } from 'assets/javascript/dictionaries/gtmEventsActions'

  export default {
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    computed: {
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      },
      isWatched () {
        return this.$store.getters['watched/IS_WATCHED'](this.product.id)
      }
    },
    methods: {
      remove () {
        if (!this.isLoggedIn) {
          return this.$router.push({ name: this.$dict.routes.LOGIN, query: { redirect: this.$route.fullPath } })
        }

        const type = this.product.type
        const { id, token } = this.$store.getters['user/ACTIVE_PROFILE']
        const payload = {
          ...this.product,
          profileId: id,
          profileToken: token,
          videoType: PRODUCT_TYPE_TO_VIDEO_TYPE[type]
        }

        this.$tracking.events.send(ACTION, {
          product: {
            id: payload.id
          },
          action: REMOVE_FROM_MY_LIST
        })

        this.$store.dispatch('watched/REMOVE_FROM_WATCHED', payload)
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
