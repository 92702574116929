<template>
  <section>
    <div class="row expanded collapse">
      <div class="column small-4">
        <div class="c-issues__label">
          {{ $t('views.myAccount.issues.subject') }}:
        </div>
      </div>
      <div class="column small-2">
        <div class="c-issues__label">
          {{ $t('views.myAccount.issues.note') }}:
        </div>
      </div>
      <div class="column small-2">
        <div class="c-issues__label">
          {{ $t('views.myAccount.issues.createdAt') }}:
        </div>
      </div>
      <div class="column small-2">
        <div class="c-issues__label">
          {{ $t('views.myAccount.issues.modifiedAt') }}:
        </div>
      </div>
      <div class="column small-2">
        <div class="c-issues__label">
          {{ $t('views.myAccount.issues.status') }}:
        </div>
      </div>
      <div class="column small-12">
        <separator class="o-separator--horizontal o-separator--horizontal-small-margin" />
      </div>
    </div>
    <pagination :callback="loadMore">
      <div
        v-for="{id, title, note, createdAt, modifiedAt, status} in threads"
        :key="id"
        class="c-issues__row row expanded collapse"
      >
        <div class="column small-4">
          <div class="c-issues__text">
            {{ title }}
          </div>
        </div>
        <div class="column small-2">
          <div class="c-issues__text">
            {{ note || '-' }}
          </div>
        </div>
        <div class="column small-2">
          <div class="c-issues__text">
            {{ createdAt | dateFormat('DD.MM.YYYY, HH:mm') }}
          </div>
        </div>
        <div class="column small-2">
          <div class="c-issues__text">
            {{ modifiedAt | dateFormat('DD.MM.YYYY, HH:mm') }}
          </div>
        </div>
        <div class="column small-2">
          <div class="c-issues__status">
            {{ $t(`views.myAccount.issues.${status.toLowerCase()}`) }}
          </div>
        </div>
        <separator class="o-separator--horizontal o-separator--horizontal-small-margin" />
      </div>
    </pagination>
  </section>
</template>
<script>
  import {
    DEFAULT_MAX_THREADS
  } from '@/store/contacts/actions'

  import Pagination from '@/components/pagination'
  import Separator from '@/components/separator/index'

  export default {
    components: {
      Separator,
      Pagination
    },
    data () {
      return {
        threads: []
      }
    },
    methods: {
      loadMore (loaded, completed) {
        return this.$store.dispatch('contacts/GET_THREADS', { firstResult: this.threads.length })
          .then((res) => {
            if (!res) {
              return completed()
            }
            const results = res.items
            loaded()
            if (this.noMoreResults(results)) {
              completed()
            }
            this.threads = [...this.threads, ...results]
          })
      },
      noMoreResults (results) {
        const length = results.length
        return !length || length < DEFAULT_MAX_THREADS
      }
    },
    head () {
      return {
        title: this.$t('views.myAccount.issues.title')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
