<template>
  <section>
    <div v-if="!cardRemoved">
      <h1 class="c-remove-card__title">
        {{ $t('views.removeCard.title') }}
      </h1>
      <p class="c-remove-card__content">
        {{ $t('views.removeCard.content') }}
      </p>
      <button
        ref="submit"
        type="button"
        class="c-remove-card__submit o-button"
        @click="submit"
      >
        {{ $t('views.removeCard.submit') }}
      </button>
    </div>
    <success-view v-else>
      <svg class="c-success__icon o-icon">
        <use xlink:href="#icon-success" />
      </svg>
      <h1 class="c-success__heading">
        {{ $t('views.removeCard.success.heading') }}
      </h1>
      <p>{{ $t('views.removeCard.success.subheading') }}</p>
    </success-view>
  </section>
</template>

<script>
  import SuccessView from '@/components/success-view'

  export default {
    components: {
      SuccessView
    },
    data () {
      return {
        active: true,
        cardRemoved: false
      }
    },
    computed: {
      cards () {
        return this.$store.state.payments.cards
      }
    },
    created () {
      if (!this.cards.length) {
        return this.$emit('close')
      }
    },
    methods: {
      submit () {
        this.$loader.show(this.$refs.submit, { small: true })
        this.$store.dispatch('payments/REMOVE_CARD', this.cards[0].id)
          .then(() => {
            this.cardRemoved = true
          })
          .catch((e) => {
            console.warn(e)
            this.$loader.hide(this.$refs.submit, { small: true })
            this.$toast.error(this.$t('views.removeCard.error'))
          })
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
