<template>
  <div class="column small-11">
    <div
      :data-id="id"
      class="c-chat-message"
    >
      <div class="c-chat-message__content">
        <div class="c-chat-message__info-container">
          <span class="c-chat-message__username">
            {{ message.user.username }}
          </span>
          <span class="c-chat-message__time">
            {{ message.timestamp * 1000 | dateFormat('DD.MM.YYYY, HH:mm') }}
          </span>
        </div>
        <p
          v-dompurify-html="message.message"
          class="c-chat-message__wrapped"
        />
        <div class="c-chat-message__actions">
          <svg
            v-if="message.replyCount"
            class="c-chat-message__action c-chat-message__icon c-chat-message__icon--margin o-icon"
            @click="setMessageId(message.id)"
          >
            <title>
              {{ $t('chat.extend') }}
            </title>
            <use xlink:href="#icon-chat" />
          </svg>
          <svg
            v-if="localConfig.allowComments && isLoggedIn && !threadLimitExceeded && !message.replyCount"
            class="c-chat-message__action c-chat-message__icon c-chat-message__icon--resume o-icon"
            @click="setMessageId(message.id)"
          >
            <title>
              {{ $t('chat.reply') }}
            </title>
            <use xlink:href="#icon-resume" />
          </svg>
          <svg
            class="c-chat-message__action c-chat-message__icon o-icon"
            @click="like(message.id, parentId)"
          >
            <title>
              {{ $t('chat.like') }}
            </title>
            <use :xlink:href="likeIcon" />
          </svg>
          <span class="c-chat-message__counter">
            {{ message.likeCount }}
          </span>
          <svg
            class="c-chat-message__action c-chat-message__icon o-icon"
            @click="dislike(message.id, parentId)"
          >
            <title>
              {{ $t('chat.dislike') }}
            </title>
            <use :xlink:href="dislikeIcon" />
          </svg>
          <span class="c-chat-message__counter">
            {{ message.dislikeCount }}
          </span>
        </div>
        <chat-input
          v-if="canShowInput"
          :parent-id="parent"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ChatInput from '@/components/chat/input'
  import {
    CONFIG,
    DECREASE_LIKES,
    GET_CURRENT_MESSAGE_ID,
    GET_CURRENT_REPLY_ID,
    GET_USER_LIKES,
    INCREASE_LIKES,
    SET_CURRENT_MESSAGE_ID, SET_CURRENT_REPLY_ID
  } from '@/components/chat/enums/chat'

  const THREAD_LENGTH_LIMIT = 100

  export default {
    inject: [
      CONFIG,
      DECREASE_LIKES,
      GET_CURRENT_MESSAGE_ID,
      GET_CURRENT_REPLY_ID,
      GET_USER_LIKES,
      INCREASE_LIKES,
      SET_CURRENT_MESSAGE_ID,
      SET_CURRENT_REPLY_ID
    ],
    components: {
      ChatInput
    },
    props: {
      isReply: {
        type: Boolean,
        default: false
      },
      message: {
        type: Object,
        required: true
      },
      parentId: {
        type: String,
        default: ''
      },
      replyCount: {
        type: Number,
        default: 0
      }
    },
    computed: {
      canShowInput () {
        if (!this.isLoggedIn || this.threadLimitExceeded) {
          return false
        }

        if (this.isReply) {
          return this.message.id === this.currentReplyId
        }

        return this.message.id === this.currentMessageId
      },
      currentMessageId: {
        get () {
          return this.getCurrentMessageId()
        },
        set (currentMessageId) {
          this.setCurrentMessageId(currentMessageId)
        }
      },
      currentReplyId: {
        get () {
          return this.getCurrentReplyId()
        },
        set (currentMessageId) {
          this.setCurrentReplyId(currentMessageId)
        }
      },
      dislikeIcon () {
        let icon = '#icon-dislike'

        if (this.message?.likes?.like === -1) {
          icon += '-fill'
        }

        return icon
      },
      id () {
        if (!this.parent.id || this.parentId === this.message.id) {
          return this.message.id
        }

        return this.parentId
      },
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      },
      likeIcon () {
        let icon = '#icon-like'

        if (this.message?.likes?.like === 1) {
          icon += '-fill'
        }

        return icon
      },
      localConfig () {
        return this.config()
      },
      parent () {
        return this.parentId || this.message.id
      },
      threadLimitExceeded () {
        const replies = this.message.replyCount || this.replyCount || 0

        return replies >= THREAD_LENGTH_LIMIT
      }
    },
    mounted () {
      if (!this.message.likeCount) {
        this.$set(this.message, 'likeCount', 0)
      }

      if (!this.message.dislikeCount) {
        this.$set(this.message, 'dislikeCount', 0)
      }

      if (this.isLoggedIn && (this.message.likeCount || this.message.dislikeCount)) {
        this.getUserLikes(this.message.id, this.parentId).then((likes) => {
          this.$set(this.message, 'likes', likes)
        })
      }
    },
    methods: {
      // TODO REFAKTOR!
      dislike (messageId, parentId) {
        if (!this.isLoggedIn) {
          return this.$toast.error(this.$t('chat.likeDenied'))
        }

        if (!this.message.likes) {
          this.$set(this.message, 'likes', { like: -1 })
          this.message.dislikeCount++
        } else if (this.message.likes.like === -1) {
          this.$set(this.message, 'likes', { like: 0 })
          this.message.dislikeCount--
        } else if (this.message.likes.like === 0) {
          this.$set(this.message, 'likes', { like: -1 })
          this.message.dislikeCount++
        } else if (this.message.likes.like === 1) {
          this.$set(this.message, 'likes', { like: -1 })
          this.message.dislikeCount++
          this.message.likeCount--
        }

        this.decreaseLikes(messageId, parentId)

        this.$loader.show(this.$el.parentElement)
        setTimeout(() => {
          this.$loader.hide(this.$el.parentElement)
        }, 2000)
      },
      like (messageId, parentId) {
        if (!this.isLoggedIn) {
          return this.$toast.error(this.$t('chat.likeDenied'))
        }

        if (!this.message.likes) {
          this.$set(this.message, 'likes', { like: 1 })
          this.message.likeCount++
        } else if (this.message.likes.like === 1) {
          this.$set(this.message, 'likes', { like: 0 })
          this.message.likeCount--
        } else if (this.message.likes.like === 0) {
          this.$set(this.message, 'likes', { like: 1 })
          this.message.likeCount++
        } else if (this.message.likes.like === -1) {
          this.$set(this.message, 'likes', { like: 1 })
          this.message.likeCount++
          this.message.dislikeCount--
        }

        this.increaseLikes(messageId, parentId)

        this.$loader.show(this.$el.parentElement)
        setTimeout(() => {
          this.$loader.hide(this.$el.parentElement)
        }, 2000)
      },
      setMessageId (messageId) {
        if (this.parentId) {
          this.setCurrentReplyId(messageId)
        } else {
          this.setCurrentMessageId(messageId)
        }
      }
    }
  }
</script>
