<template>
  <footer class="c-footer">
    <div class="row collapse expanded">
      <div class="column large-5 small-12">
        <ui-menu
          class="c-menu__footer-nav"
          :state-name="$options.FOOTER"
        />
      </div>
      <div class="column large-7 small-12 c-menu__footer-nav c-menu__footer-nav--right">
        <ui-menu :state-name="$options.TERMS_AND_PRIVACY">
          <a
            class="c-menu__link"
            @click="$popup.show($dict.popups.CMP, { isWide: true, disableClose: true })"
          >
            {{ $t('cmp.settings') }}
          </a>
        </ui-menu>
      </div>
      <div class="c-footer__copy-container column small-12">
        <div
          v-dompurify-html="$t('footer.copyright', { year: new Date().getFullYear() })"
          class="c-footer__copy"
        />
      </div>
    </div>
  </footer>
</template>
<script>
  import UiMenu from '@/components/menu/index'
  import { FOOTER, TERMS_AND_PRIVACY } from 'assets/javascript/enums/menus'

  export default {
    components: { UiMenu },
    FOOTER,
    TERMS_AND_PRIVACY
  }
</script>
<style lang="scss" src="./styles.scss" />
