var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"autoplay",rawName:"v-autoplay",value:({
    callback: _vm.next,
    active: _vm.opts.autoplay,
    delay: _vm.opts.autoplay.delay
  }),expression:"{\n    callback: next,\n    active: opts.autoplay,\n    delay: opts.autoplay.delay\n  }"}],staticClass:"my-slider"},[_c('div',{staticClass:"my-slider__wrapper",style:(_vm.wrapperStyle)},_vm._l((_vm.carousel),function(ref){
  var id = ref.id;
  var position = ref.position;
  var items = ref.items;
return _c('div',{key:position,staticClass:"my-slider__slide",style:(_vm.slideStyle(id)),attrs:{"data-id":id}},_vm._l((items),function(item,itemIndex){return _c('div',{key:item.id,staticClass:"my-slider__item cardAnimation",style:(_vm.slideItemStyle(id)),attrs:{"data-id":item.id},on:{"click":function($event){_vm.opts.moveToSlide ? _vm.goToSlide(id) : null}}},[_vm._t("default",null,{"data":item.item,"index":itemIndex})],2)}),0)}),0),_vm._v(" "),(_vm.haveEnoughItems && !_vm.opts.hideArrows)?_c('buttons',{attrs:{"current-index":_vm.index,"on-prev-click":_vm.prev,"on-next-click":_vm.next,"on-go-to-index":_vm.goToIndex,"delay":_vm.opts.delayPerSlide,"loop":_vm.isLoopedSlider,"number-of-slides":_vm.numberOfSlides}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }