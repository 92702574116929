<template>
  <div
    v-if="active && question.id"
    class="c-se-question"
    :class="className"
  >
    <svg
      class="c-se-question__close o-icon o-icon--action"
      @click="close"
    >
      <use xlink:href="#icon-close" />
    </svg>
    <template v-if="question.title">
      <h2 class="c-se-question__title">
        {{ question.title }}
      </h2>
    </template>
    <div class="c-se-question__question-container">
      <h5 class="c-se-question__question">
        {{ question.content }}
      </h5>
      <separator class="o-separator--horizontal o-separator--horizontal-small-margin" />
      <div class="c-se-question__answers-container">
        <template v-if="isSingle">
          <div
            v-for="({id, content}, index) in question.answers"
            :key="id"
            class="c-se-question__answer"
          >
            <input
              :id="index"
              v-model="checkedRadio"
              type="radio"
              :value="id"
              class="c-se-question__radio"
            >
            <label
              :for="index"
              class="c-se-question__radio-label"
            >
              <span class="c-se-question__span" />
              {{ content }}
            </label>
          </div>
        </template>
        <template v-if="isOpen">
          <v-input
            v-model="openAnswer"
            class="o-input__wrapper--small-margin"
            @changedValidity="setFormValidity('openAnswer', $event)"
          />
        </template>
      </div>
    </div>
    <button
      class="c-se-question__submit o-button"
      @click="submit"
    >
      {{ $t('common.send') }}
    </button>
  </div>
</template>

<script>
  import {
    OPEN,
    SINGLE
  } from '@/components/secondary-events/enums/answers-type'

  import {
    CLICK,
    FOCUS,
    SECONDARY_START,
    SECONDARY_STOP
  } from '@/assets/javascript/dictionaries/gtmEventsActions'

  import {
    SECONDARY
  } from '@/assets/javascript/dictionaries/gtmEvents'

  import VInput from '@/components/input/index'
  import Separator from '@/components/separator/index'
  import trackingMixin from '@/mixins/tracking-mixin'
  import Viewport from '@/mixins/viewport-methods'

  export default {
    components: {
      Separator,
      VInput
    },
    inject: {
      playerStore: { default: '' }
    },
    mixins: [trackingMixin, Viewport],
    props: {
      event: {
        type: Object,
        default: () => ({})
      },
      product: {
        type: Object,
        default: () => ({})
      }
    },
    data () {
      return {
        active: true,
        currentAnswer: {},
        checkedRadio: undefined,
        openAnswer: '',
        question: {}
      }
    },
    computed: {
      activeProfile () {
        return this.$store.getters['user/ACTIVE_PROFILE']
      },
      className () {
        const positionClass = this.question.position.split('_').join('-').toLowerCase()
        return `c-se-question__${positionClass}`
      },
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      },
      isSingle () {
        return this.question.answerType === SINGLE
      },
      isOpen () {
        return this.question.answerType === OPEN
      }
    },
    created () {
      this.loadEvent()
    },
    mounted () {
      if (this.active && this.question.id) {
        this.observeElement()
      }
    },
    beforeDestroy () {
      this.sendSecondaryTrackingData({
        action: SECONDARY_STOP,
        ...this.product,
        pageCategory: this.$route.name
      })
    },
    methods: {
      getFullEvent (id, type) {
        this.$store.dispatch('items/GET_SECONDARY_EVENT', { id, type })
          .then(event => {
            this.question = event
            const {
              id: itemId,
              since: displayTime,
              content: value,
              urlWeb: url
            } = event
            this.trackingSecondaryData = {
              videoSessionId: this.trackingSecondaryData.videoSessionId,
              progress: this.playerStore?.state?.progress,
              itemId: itemId.toString(),
              displayTime,
              value,
              url
            }
            this.sendSecondaryTrackingData({
              action: SECONDARY_START,
              ...this.trackingSecondaryData
            })
            this.active = true
          })
      },
      loadEvent () {
        const id = this.event.id
        const type = this.event.type.toLowerCase()
        this.getFullEvent(id, type)
      },
      close () {
        this.active = false
        this.sendSecondaryTrackingData({
          action: CLICK,
          ...this.trackingSecondaryData
        })
        this.sendSecondaryTrackingData({
          action: SECONDARY_STOP,
          ...this.trackingSecondaryData
        })
        this.active = false
        this.unobserveElement()
      },
      submit () {
        if (!this.isLoggedIn) {
          sessionStorage.setItem('progress', JSON.stringify({
            productId: this.product.id,
            progress: this.playerStore?.state?.progress
          }))

          return this.$router.push({
            name: this.$dict.routes.LOGIN,
            query: {
              redirect: this.$route.fullPath
            }
          })
        }

        this.buildAnswer()
        this.$store.dispatch('secondaryEventAnswers/ADD_QUESTION_ANSWER', { eventId: this.question.id, answer: this.currentAnswer })
        this.active = false
        this.sendSecondaryTrackingData({
          action: CLICK,
          ...this.trackingSecondaryData
        })
      },
      buildAnswer () {
        let content, rank
        if (this.isSingle) {
          const clickedAnswer = this.question?.answers.find(answer => answer.id === this.checkedRadio)
          content = clickedAnswer.content
          rank = clickedAnswer.rank
        }
        if (this.isOpen) {
          content = this.openAnswer
          rank = 1
        }
        this.currentAnswer = {
          token: this.activeProfile.token,
          content,
          profileId: this.activeProfile.id,
          rank,
          eventId: this.question.id,
          type: this.question.answerType
        }
      },
      onMouseEnter () {
        this.sendTrackingEventWithTimeout(SECONDARY, {
          action: FOCUS,
          ...this.trackingSecondaryData
        })
      },
      onMouseLeave () {
        this.resetTrackingEventTimeout()
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
