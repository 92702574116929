<template>
  <section>
    <div v-if="!cardAdded">
      <h1 class="c-add-card__title">
        {{ $t('views.myAccount.addCard') }}
      </h1>
      <h2 class="c-add-card__subtitle">
        {{ $t('views.myAccount.addCardSubtitle') }}
        <a
          href="https://ottwow.com/page/terms"
          target="_blank"
        >
          {{ $t('views.myAccount.regulations') }}
        </a>
      </h2>
      <h3 class="c-add-card__subtitle">
        {{ $t('views.myAccount.addCardSubtitle2') }}
      </h3>
      <div
        v-if="cardData.sign"
        id="P24FormContainer"
        ref="P24card"
        :data-sign="cardData.sign"
        data-successCallback="p24Success"
        data-failureCallback="p24Failed"
        :data-dictionary="JSON.stringify(p24Translations)"
      />
    </div>
    <success-view v-else>
      <svg class="c-success__icon o-icon">
        <use xlink:href="#icon-check_mark" />
      </svg>
      <h1 class="c-success__heading">
        {{ $t('views.addCard.success.heading') }}
      </h1>
      <p>{{ $t('views.addCard.success.subheading') }}</p>
    </success-view>
  </section>
</template>

<script>
  import SuccessView from '@/components/success-view'

  const CARD_CHECK_RETRY_LIMIT = 30
  const ERROR_CLASS = 'P24_input_error'

  export default {
    components: {
      SuccessView
    },
    props: {
      promise: {
        type: Object,
        default: null
      },
      withResume: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        cardAdded: false,
        cardAddedRetryCounter: 0,
        cardData: {},
        p24Translations: {
          cardHolderLabel: this.$t('views.addCard.form.name'),
          cardNumberLabel: this.$t('views.addCard.form.cardNumber'),
          cvvLabel: this.$t('views.addCard.form.cvv'),
          expDateLabel: this.$t('views.addCard.form.expDate'),
          payButtonCaption: this.$t('views.addCard.submit'),
          threeDSAuthMessage: this.$t('views.addCard.form.threeDsAuth')
        },
        threeDSButtonPresenceInterval: undefined,
        P24PresenceInterval: undefined,
        P24submitButton: undefined,
        processing: false,
        validateTimeout: undefined,
        getCardsTimeout: undefined,
        cardHolderInput: undefined,
        cardNumberInput: undefined,
        cardCVVInput: undefined,
        cardMonthInput: undefined,
        cardYearInput: undefined,
        error: {
          card: false,
          number: false,
          cvv: false,
          monthYear: false
        }
      }
    },
    computed: {
      p24Script () {
        return this.cardData?.url
      }
    },
    watch: {
      cardAdded: function (added) {
        if (added && this.withResume) {
          this.$toast.success(this.$t('views.addCard.success.heading'))
          this.resume()
        }
      }
    },
    created () {
      if (this.$store.state.payments.cards.length) {
        return this.$emit('close')
      }

      window.p24Failed = (e) => {
        this.processing = false
        this.$loader.hide(this.$el.parentElement)
        console.warn(e)
        this.$toast.error(e.errorMessagePl || this.$t('views.addCard.error'))
      }
      window.p24Success = () => {
        this.processing = false
        this.getCards()
      }
    },
    beforeDestroy () {
      this.removeSubmitButtonEvent()
      this.remove3DSButtonEvent()
      clearInterval(this.threeDSButtonPresenceInterval)
      clearInterval(this.P24PresenceInterval)
      clearTimeout(this.validateTimeout)
      clearTimeout(this.getCardsTimeout)
    },
    mounted () {
      this.showLoader()
      this.$store.dispatch('payments/POST_CARD')
        .then(data => {
          this.cardData = data
          this.threeDSButtonPresenceInterval = setInterval(() => {
            const threeDSButtonSelector = this.$refs.P24card.querySelector('a')

            if (threeDSButtonSelector) {
              threeDSButtonSelector.addEventListener('click', this.showLoader)
              clearInterval(this.threeDSButtonPresenceInterval)
            }
          }, 1000)
          this.P24PresenceInterval = setInterval(() => {
            if (window.P24_Transaction) {
              clearInterval(this.P24PresenceInterval)
              window.P24_Transaction.init()
              this.$loader.hide(this.$el.parentElement)

              const P24form = this.$refs.P24card.querySelector('form')
              this.P24submitButton = P24form.querySelector('button')

              this.cardHolderInput = document.querySelector('#P24_cardHolder')
              this.cardNumberInput = document.querySelector('#P24_cardNumber')
              this.cardCVVInput = document.querySelector('#P24_cardCVV')
              this.cardMonthInput = document.querySelector('#P24_expMonth')
              this.cardYearInput = document.querySelector('#P24_expYear')

              this.P24submitButton.addEventListener('click', this.validate)
            }
          }, 1000)
        })
        .catch((e) => {
          return this.showErrorAndClose(e)
        })
    },
    methods: {
      resume () {
        this.promise.resolve()
        this.$emit('close')
      },
      removeSubmitButtonEvent () {
        return this.P24submitButton
          ?.removeEventListener('click', this.validate)
      },
      remove3DSButtonEvent () {
        return this.$refs.P24card
          ?.querySelector('a')
          ?.removeEventListener('click', this.showLoader)
      },
      showLoader () {
        return this.$loader.show(this.$el.parentElement)
      },
      validate () {
        this.processing = false

        this.validateTimeout = setTimeout(() => {
          this.error.card = this.cardHolderInput.classList.contains(ERROR_CLASS)
          this.error.number = this.cardNumberInput.classList.contains(ERROR_CLASS)
          this.error.cvv = this.cardCVVInput.classList.contains(ERROR_CLASS)
          this.error.monthYear = this.cardYearInput.classList.contains(ERROR_CLASS) ||
            this.cardMonthInput.classList.contains(ERROR_CLASS)

          this.processing = Object.values(this.error).every(value => !value)
          if (this.processing === true) {
            this.$loader.show(this.P24submitButton, { small: true })
            this.removeSubmitButtonEvent()
          }
        }, 100)
      },
      getCards () {
        this.$store.dispatch('payments/GET_CARDS')
          .then((cards) => {
            if (cards.length) {
              this.remove3DSButtonEvent()
              this.removeSubmitButtonEvent()
              this.cardAdded = true
              this.$loader.hide(this.$el.parentElement)
              return
            }

            this.cardAddedRetryCounter++
            if (this.cardAddedRetryCounter > CARD_CHECK_RETRY_LIMIT) {
              return this.showErrorAndClose('max card retry limit reached')
            }
            this.getCardsTimeout = setTimeout(this.getCards, 3000)
          })
      },
      showErrorAndClose (e = '') {
        this.processing = false
        console.warn(e)
        this.$loader.hide(this.$el.parentElement)
        this.$toast.error(this.$t('views.addCard.error'))
        return this.$emit('close')
      }
    },
    head () {
      return {
        script: [
          { src: 'https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js' },
          { src: this.p24Script }
        ]
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss" />
