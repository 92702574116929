<template>
  <section class="c-register o-page-form">
    <h1 class="c-register__title">
      {{ $t('common.register') }}
    </h1>
    <message
      v-if="errors.global"
      :message="errors.global"
    />
    <form
      class="o-form"
      @submit.prevent="register"
    >
      <input
        class="u-hidden"
        type="password"
        tabindex="-1"
      >
      <v-input
        v-model="form.name"
        type="text"
        :placeholder="$t('common.name')"
        :tooltip="$t('tooltips.name')"
        :tooltip-position="mobileOrTablet ? 'left' : 'right'"
        :restriction-rules="[restrictionRules.NO_SPACES]"
        :validation-rules="[validationRules.USERNAME]"
        @changedValidity="setFormValidity('name', $event)"
      />
      <message
        v-if="errors.name"
        :message="errors.name"
      />
      <v-input
        v-model="form.phone"
        type="text"
        :max-length="9"
        :placeholder="$t('common.phone')"
        :tooltip="$t('tooltips.phone')"
        :tooltip-position="mobileOrTablet ? 'left' : 'right'"
        :restriction-rules="[restrictionRules.NUMBERS_ONLY]"
        :validation-rules="[validationRules.POLISH_PHONE_OPTIONAL]"
        @changedValidity="setFormValidity('phone', $event)"
      />
      <message
        v-if="errors.phone"
        :message="errors.phone"
      />
      <v-input
        v-model="form.email"
        :placeholder="$t('common.email')"
        :restriction-rules="[restrictionRules.NO_SPACES]"
        :validation-rules="[validationRules.EMAIL]"
        @changedValidity="setFormValidity('email', $event)"
      />
      <message
        v-if="errors.email"
        :message="errors.email"
      />
      <v-input
        v-model="form.password"
        type="password"
        :placeholder="$t('common.password')"
        :tooltip="$t('tooltips.password')"
        :tooltip-position="mobileOrTablet ? 'left' : 'right'"
        :validation-rules="[validationRules.PASSWORD]"
        @changedValidity="setFormValidity('password', $event)"
      />
      <message
        v-if="errors.password"
        :message="errors.password"
      />
      <v-input
        v-model="form.repeatPassword"
        type="password"
        :placeholder="$t('common.repeatPassword')"
        :validation-rules="[validationRules.PASSWORD]"
        @changedValidity="setFormValidity('repeatPassword', $event)"
      />
      <h5 class="c-register__subtitle">
        {{ $t('views.register.processDataHeader') }}
      </h5>
      <div
        v-if="GDPRDoc"
        class="c-register__container-wrapper"
      >
        <styled-scroll
          ref="container"
          v-dompurify-html="GDPRDoc"
          class="c-register__container"
        />
      </div>
      <div class="c-register__wrapper-agreements">
        <div
          v-for="{id, content, agreement} in agreements"
          :key="id"
          class="c-register__agreements"
        >
          <v-checkbox
            v-model="checkedAgreements"
            :value="id"
            :label="content"
            :is-required="agreement.obligatory"
          />
          <message
            v-if="errors.agreements"
            :message="errors.agreements"
          />
        </div>
        <p class="c-register__text c-register__text--required">
          * {{ $t('common.requiredField') }}
        </p>
      </div>
      <button
        ref="submit"
        type="submit"
        class="o-button"
        :class="{ 'o-button--disabled' : !isFormValid }"
        :disabled="!isFormValid"
      >
        {{ $t('common.register') }}
      </button>
    </form>
  </section>
</template>

<script>
  import {
    REGISTER as CAPTCHA_REGISTER
  } from '@/assets/javascript/enums/captcha-types'

  import {
    SUBMISSION_SUCCESS,
    USER_REGISTER,
    VALIDATION_FAILED
  } from '@/assets/javascript/dictionaries/gtmEventsActions'

  import errorMapper from '@/assets/javascript/helpers/error-mapper.js'
  import restrictionRules from '@/assets/javascript/dictionaries/input-restriction-rules'
  import validationRules from '@/assets/javascript/dictionaries/input-validation-rules'
  import Message from '@/components/message'
  import StyledScroll from '@/components/styled-scroll'
  import VCheckbox from '@/components/checkbox'
  import VInput from '@/components/input'

  import formValidityMixin from '@/mixins/form-validity-mixin'
  import mobileOrTabletMixin from '@/mixins/mobile-or-tablet-mixin'
  import trackingMixin from '@/mixins/tracking-mixin'

  const GDPR_DOCUMENT_ALIAS = 'register_gdpr'

  export default {
    name: 'RegisterForm',
    processing: false,
    components: {
      Message,
      StyledScroll,
      VCheckbox,
      VInput
    },
    mixins: [
      formValidityMixin,
      mobileOrTabletMixin,
      trackingMixin
    ],
    data () {
      return {
        agreements: [],
        errors: [],
        form: {
          agreements: [],
          captcha: '',
          email: '',
          password: '',
          phone: '',
          repeatPassword: '',
          name: ''
        },
        checkedAgreements: [],
        GDPRDoc: '',
        restrictionRules,
        validationRules,
        validity: {
          email: false,
          password: false,
          phone: true,
          repeatPassword: false,
          name: false
        }
      }
    },
    mounted () {
      this.$loader.show(this.$el.parentElement)
      Promise.all([
        this.getUserAgreements(),
        this.getGDPRDoc()
      ])
        .finally(() => this.$loader.hide(this.$el.parentElement))
    },
    methods: {
      getUserAgreements () {
        return this.$store.dispatch('user/GET_AGREEMENTS')
          .then(agreements => {
            this.agreements = agreements
          })
      },
      getGDPRDoc () {
        return this.$store.dispatch('documents/GET_CONTENT', GDPR_DOCUMENT_ALIAS)
          .then(document => {
            this.GDPRDoc = document
          })
      },
      getCheckedAgreements () {
        return this.checkedAgreements.map(id => ({ id }))
      },
      async register () {
        if (!this.isFormValid || this.$options.processing) {
          return
        }

        try {
          this.$loader.show(this.$refs.submit, { small: true })
          this.$options.processing = true
          this.form.agreements = this.getCheckedAgreements()
          this.form.captcha = await this.$recaptcha(CAPTCHA_REGISTER)
          await this.$store.dispatch('user/REGISTER', this.form)
          if (this.form.phone) {
            return this.$popup.show(this.$dict.popups.VERIFY_PHONE, { phone: this.form.phone })
          }

          this.$popup.show(this.$dict.popups.REGISTER_SUCCESS)
          this.sendUserTrackingData(USER_REGISTER)
          this.sendFormTrackingData(SUBMISSION_SUCCESS)
          await this.$router.push({ name: this.$dict.routes.HOME })
        } catch (error) {
          return errorMapper(this.$t.bind(this), error)
            .then(errors => {
              this.trackingFormData.validationError = errors.code
              this.sendFormTrackingData(VALIDATION_FAILED)
              this.$set(this, 'errors', errors)
            })
        } finally {
          this.$options.processing = false
          this.$loader.hide(this.$refs.submit)
        }
      }
    },
    head () {
      return {
        title: this.$t('views.register.title')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
