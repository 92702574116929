import { render, staticRenderFns } from "./episode.vue?vue&type=template&id=09789d8e&functional=true&"
import script from "./episode.vue?vue&type=script&lang=js&"
export * from "./episode.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports