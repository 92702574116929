<template>
  <div class="app-wrapper u-typo">
    <template>
      <svg-icons />
      <popup-switch />
      <page-header />
      <nuxt
        class="page-content"
        :class="{'is-blurred': isBlurredLayout }"
      />
      <page-footer />
    </template>
  </div>
</template>
<script>
  import PageFooter from './components/page-footer'
  import PageHeader from './components/page-header'
  import PopupSwitch from '@/layouts/components/popup-switch/index'
  import SvgIcons from '@/layouts/components/svg-icons/index'
  import { CMP_COOKIE_NAME } from '@/assets/javascript/enums/cmp'
  import showCMPMixin from '@/mixins/show-cmp-mixin'
  import seoMixin from '@/mixins/seo-mixin'

  const LS_CONSENTS_NOTICE = 'consentsNotice'

  export default {
    components: {
      PageFooter,
      PageHeader,
      PopupSwitch,
      SvgIcons
    },
    mixins: [
      seoMixin,
      showCMPMixin
    ],
    data () {
      return {
        document: ''
      }
    },
    computed: {
      isBlurredLayout () {
        return this.$store.state.layout.isBlurredLayout
      }
    },
    created () {
      this.getCurrentEpg()
    },
    mounted () {
      const { $route } = this
      this.$tracking.events.eventsOnPageRefresh({ $route })

      if (this.canShowCMP) {
        this.$popup.show(this.$dict.popups.CMP, { isWide: true, disableClose: true })
      } else {
        const TCString = this.$store.state.cmp.TCString
        this.$cmp.update(TCString)
        this.$cookie.set(CMP_COOKIE_NAME, TCString)
      }
    },
    methods: {
      gdprAccepted () {
        return window.localStorage.getItem(LS_CONSENTS_NOTICE)
      },
      getCurrentEpg () {
        clearTimeout(this.programmeTimeout)

        this.$store.dispatch('epg/GET_LIVE_NOW', { id: process.env.DEFAULT_LIVE_CHANNEL })
          .then(programme => {
            if (!programme.till) {
              return
            }

            const programmeTill = this.$time(programme.till).valueOf()

            const now = this.$time().valueOf()
            const next = programmeTill - now
            this.programmeTimeout = setTimeout(() => this.getCurrentEpg(), next)
          })
          .catch(() => {
            this.$toast.error(this.$t('errors.epgFetchFailed'))
          })
      }
    }
  }
</script>
