<template>
  <section>
    <h3
      v-if="data.showTitle"
      class="c-cmp__partners"
    >
      {{ $t('cmp.IABPartners') }}
    </h3>
    <h4 class="c-cmp__subtitle c-cmp__subtitle--no-bottom-margin">
      {{ data.subtitle }}:
    </h4>
    <div
      v-for="(purpose, index) in data.visibleData"
      :key="index"
    >
      <purpose-detail
        :data="data"
        :purpose="purpose"
        @updateData="updateData"
      />
    </div>
  </section>
</template>

<script>
  import PurposeDetail from '@/components/popups/cmp/components/purpose-detail'

  export default {
    components: {
      PurposeDetail
    },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    methods: {
      toggleDetails (type, id) {
        const ref = this.$refs[`${type}-${id}`]?.[0]
        let refDisplay = ref.style.display

        if (!ref) {
          return
        }

        if (refDisplay === 'none' || refDisplay === '') {
          refDisplay = 'block'
          return
        }

        refDisplay = 'none'
      },
      updateData (data, attr) {
        // @TODO refactor
        this.$parent.saveButtonDisabled = false
        this.$parent.$parent.updateData(data, attr)
        this.$emit('updated')
      }
    }
  }
</script>
