<template>
  <section>
    <div
      v-if="!isMobile"
      class="c-player"
    >
      <div
        :id="$options.playerHolder"
        class="c-player__holder u-abs-center"
      />
      <div
        v-if="autoplayBlocked"
        class="u-abs-center"
        @click="unblockAutoplay"
      >
        <svg class="c-player__icon o-icon">
          <use xlink:href="#icon-play-circle" />
        </svg>
      </div>
      <player-buffering class="o-spinner" />
      <player-overlay
        v-if="canShowOverlay"
        player-wrapper-id="#js-player-wrapper"
        @back="close"
      >
        <slot />
        <player-overlay-paused v-if="showPaused">
          <div class="row">
            <h1 class="c-player__title column small-12 align-self-bottom">
              {{ productTitle }}
            </h1>
            <h3
              v-if="genre"
              class="column small-12"
            >
              {{ genre }}
            </h3>
            <div class="c-player__description-wrapper row">
              <h5
                v-if="advisorsFromApi"
                v-dompurify-html="advisorsFromApi"
                class="c-player__advisors"
              />
              <h2
                v-dompurify-html="description"
                class="c-player__description column small-12"
              />
            </div>
          </div>
        </player-overlay-paused>
        <player-options
          v-if="showOptions"
          @close="toggleOptions"
        />
        <component :is="controls" />
        <nuxt-link
          v-if="canShowHomepageButton"
          :to="{ name: $dict.routes.HOME }"
          class="c-player__homepage"
        >
          <svg class="o-icon back-icon">
            <use xlink:href="#icon-back" />
          </svg>
          {{ $t('buttons.goBackToHomePage2') }}
        </nuxt-link>
      </player-overlay>
    </div>
    <mobile-app v-else />
  </section>
</template>
<script>
  // WARNING $player.instance is not REACTIVE!!!
  import {
    EPISODE,
    LIVE,
    PROGRAMME,
    RECORDING
  } from '@/assets/javascript/enums/product-types'

  import {
    TIME_SHIFT_LIVE,
    TIME_SHIFT_LIVE_DELAYED,
    STATUS_PLAYING,
    STATUS_PAUSE,
    STATUS_ENDED,
    DISABLE_SUBTITLES_TRANSLATION,
    STATUS_ERROR,
    STATUS_LOADING
  } from 'atds-player-layout/src/enums'

  import MobileApp from '@/layouts/components/mobile-app/index.vue'
  import PlayerOptions from './components/options/index'
  import PlayerLiveControls from './components/controls/layout/live'
  import PlayerOverlayPaused from 'atds-player-layout/src/components/overlay/components/paused/index'
  import PlayerControls from './components/controls/layout/default'
  import PlayerOverlay from 'atds-player-layout/src/components/overlay/index'
  import PlayerBuffering from 'atds-player-layout/src/components/buffering/index'
  import PlayerKeyHandlers from 'atds-player-layout/src/mixins/player-key-handlers'

  import { updateLSSettings } from 'atds-player-layout/src/store/settings'
  import { PlayerMuteMixin } from '@/components/player/mixins/mute'
  import { PlayerWatchLiveProgressMixin } from '@/components/player/mixins/watchLiveProgress'
  import AdvisorsMixin from '@/mixins/advisors'
  import { PlayerGetVMAPMixin } from '@/components/player/mixins/getVMAPforCurrentProgramme'
  import AutoplayMixin from '@/mixins/autoplay-mixin'
  import { SERIAL } from '@/assets/javascript/enums/video-types'
  import MobileOrTabletMixin from '@/mixins/mobile-or-tablet-mixin'

  const LIVE_BUFFER = 10
  const ADULT_RATING_AGE = 18
  const ADBLOCK_DOCUMENT_ALIAS = 'adblock'
  const GEOIP_DOCUMENT_ALIAS = 'error-msg-geoip-failed'
  const PLAYER_ERROR_DOCUMENT_ALIAS = 'player-error'
  const GEOIP_ERROR = 'GEOIP_EEA_FILTER_FAILED'

  export default {
    name: 'Player',
    inject: [
      'playerStore'
    ],
    playerHolder: 'js-player-holder',
    components: {
      MobileApp,
      PlayerLiveControls,
      PlayerOptions,
      PlayerControls,
      PlayerOverlay,
      PlayerOverlayPaused,
      PlayerBuffering
    },
    mixins: [
      AdvisorsMixin,
      AutoplayMixin,
      MobileOrTabletMixin,
      PlayerKeyHandlers,
      PlayerMuteMixin,
      PlayerWatchLiveProgressMixin,
      PlayerGetVMAPMixin
    ],
    props: {
      product: {
        type: Object,
        required: true
      },
      blockKeyboard: {
        type: Boolean,
        default: false
      }
    },
    asyncData ({ store, route }) {
      return store.dispatch('products/GET', { id: route.params.productId, type: LIVE })
        .then(product => ({ product }))
    },
    data () {
      return {
        playerState: this.playerStore.state,
        adBlockPopupShown: false,
        isMobile: false
      }
    },
    computed: {
      canShowOverlay () {
        return this.isPlaying && !this.autoplayBlocked
      },
      canShowHomepageButton () {
        return this.playerState.overlayStatus === 'interaction'
      },
      controls () {
        return this.product.type === LIVE
          ? PlayerLiveControls
          : PlayerControls
      },
      isPlaying () {
        return !this.playerState.isAdvertRunning && this.playerState.playerStatus !== STATUS_LOADING
      },
      isProductOnlyInBundles () {
        return !this.product.paymentSchedules.length && this.product.bundles.length
      },
      showOptions () {
        return this.playerState.showOptions
      },
      showPaused () {
        return this.playerState.showPaused
      },
      productTitle () {
        const state = this.playerState
        return state.currentLiveProgram.title || state.product.title
      },
      playerInstance () {
        return this.$player.getPlayerInstance(this.$options.playerHolder)
      },
      genre () {
        const state = this.playerState
        return state.currentLiveProgram?.genres?.[0]?.name || state.product?.genres?.[0]?.name
      },
      description () {
        const state = this.playerState
        return state.currentLiveProgram?.description || state.product?.description
      },
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      }
    },
    watch: {
      blockKeyboard: function () {
        this.playerStore.toggleBlockKeyboard()
      },
      'playerState.settings': {
        deep: true,
        handler (settings) {
          updateLSSettings(settings, this.$options.playerHolder)
        }
      },
      'playerState.timeshiftMode': function (mode) {
        const player = this.playerInstance
        const timeShift = player?.timeShift
        if (timeShift?.isEnabled() && mode === TIME_SHIFT_LIVE) {
          player.head()
        }
      },
      'playerState.seekTo': function (seek) {
        const state = this.playerState
        const playerStore = this.playerStore
        const player = this.playerInstance
        if (seek === null) {
          return
        }
        // seek for live player
        if (state.currentLiveProgram.type === PROGRAMME) {
          const timeShiftStart = player.timeShift.getStart()
          const currentLiveStart = this.$time(state.currentLiveProgram.since).valueOf() / 1000
          const timeShiftOffset = currentLiveStart - timeShiftStart

          if (seek - timeShiftOffset >= state.head - LIVE_BUFFER) {
            playerStore.setTimeshiftMode(TIME_SHIFT_LIVE)
            playerStore.setProgress(state.head)
            playerStore.setSeeking(false)
            return player.head()
          }
          playerStore.setTimeshiftMode(TIME_SHIFT_LIVE_DELAYED)
          playerStore.setSeekTo(null)
          return player.seekTo(seek + timeShiftOffset)
        }
        if (seek / state.duration === 1) {
          // without this you can't seek to the end of video
          player.seekTo(seek * 0.999)
        }
        // seek for vod player
        player.seekTo(seek)
      },
      'playerState.volume': function (volume) {
        const player = this.playerInstance
        player.setVolume(volume)

        if (!volume) {
          player.mute()
        }

        if (volume && player.getRendererElm().muted) {
          player.unMute()
        }
      },
      'playerState.playerStatus': function (status, prevStatus) {
        const player = this.playerInstance
        if (!player) {
          // to prevent early errors
          return
        }
        if (status === prevStatus) {
          return
        }
        if (status === STATUS_ENDED) {
          this.$emit('ended')
          return player.pause()
        }
        if (status === STATUS_PAUSE) {
          player.pause()
          return this.playerStore.setTimeshiftMode(TIME_SHIFT_LIVE_DELAYED)
        }
        if (status === STATUS_PLAYING) {
          const volume = this.playerStore.state.volume

          player.setVolume(volume)

          if (!volume) {
            this.playerStore.toggleMute()
            player.mute()
          }

          if (volume && player.getRendererElm().muted) {
            this.playerStore.toggleMute()
            player.unMute()
          }

          return player.play()
        }
        if (status === STATUS_ERROR) {
          this.$emit('ended')
          player.pause()
          this.$toast.error(this.$t('errors.videoSessionLimitExceeded'))
          this.$router.push({ name: this.$dict.routes.HOME })
        }
      },
      'playerState.settings.audio': function (audio, currentAudio) {
        const player = this.playerInstance
        if (player && player.getRenderer() && currentAudio?.id && audio.id !== currentAudio?.id) {
          player.switchAudioTrack(audio.id)
        }
      },
      'playerState.settings.profile': function (profile, currentProfile) {
        const player = this.playerInstance
        if (player && profile.bandwidth !== currentProfile.bandwidth) {
          player.switchProfile(profile.bandwidth)
        }
      },
      'playerState.settings.subtitle': function (subtitle, currentSubtitle) {
        const player = this.playerInstance
        if (player && subtitle.language !== currentSubtitle.language) {
          if (subtitle.language === DISABLE_SUBTITLES_TRANSLATION) {
            player.disableSubtitle()
          } else {
            if (player.setActiveSubtitle && typeof player.setActiveSubtitle === 'function') {
              player.setActiveSubtitle(subtitle)
            }
          }
        }
      },
      'playerState.product': function (product) {
        if (product.duration && product?.type !== LIVE) {
          return
        }
        const { $route } = this
        const events = this.$tracking.events
        events.eventsOnPageRefresh({
          $route,
          priority: events.priorities.HIGH
        })
      },
      '$store.state.adblock.adblockDetected' (adblock) {
        if (adblock && !this.adBlockPopupShown) {
          return this.showPopup(ADBLOCK_DOCUMENT_ALIAS)
        }
      },
      '$store.state.player.error' (error) {
        if (error?.data?.code === GEOIP_ERROR) {
          return this.showPopup(GEOIP_DOCUMENT_ALIAS)
        }

        if (error?.data) {
          return this.showPopup(PLAYER_ERROR_DOCUMENT_ALIAS)
        }
      }
    },
    mounted () {
      if (this.product.programRecordingId) {
        this.product.type = RECORDING
        this.product.id = this.product.programRecordingId
      }
      this.$tracking.setPlayerStateAndRoute(this.$options.playerHolder, this.playerState, this.$route)

      if (this.product.payable && !this.isLoggedIn) {
        this.setCookie()
        return this.$router.push({ name: this.$dict.routes.LOGIN, query: { redirect: this.$route.fullPath } })
      }

      if (this.$store.state.adblock.adblockDetected) {
        this.adBlockPopupShown = true
        return this.showPopup(ADBLOCK_DOCUMENT_ALIAS)
      }

      this.isProductToBuy()
        .then(() => this.checkAge())
        .then(() => this.play())
        .catch(() => this.close())
    },
    beforeDestroy () {
      this.$player.destroy(this.$options.playerHolder)
    },
    methods: {
      setCookie () {
        this.$cookie.set('selected-product', this.$route.fullPath.replace('buy-and-watch', 'watch'), {
          expires: '30m'
        })
      },
      showPopup (documentAlias) {
        this.$loader.show(this.$el.parentElement)
        return this.$store.dispatch('documents/GET_CONTENT', documentAlias)
          .then(document => {
            if (document?.message) {
              this.$popup.show(this.$dict.popups.DOCUMENT, { disableClose: true, documentJSON: document })
            } else {
              this.$popup.show(this.$dict.popups.DOCUMENT, { disableClose: true, document })
            }

            this.$player.destroy(this.$options.playerHolder)
            this.$loader.hide(this.$el.parentElement)
          })
      },
      checkAge () {
        if (this.product.rating < ADULT_RATING_AGE || !this.product.rating || this.$route.meta?.buy) {
          return Promise.resolve()
        }
        return this.$popup.action(this.$dict.popups.AGE_CONFIRMATION)
      },
      async isProductToBuy () {
        if (!this.isLoggedIn || !this.product.payable) {
          return Promise.resolve()
        }

        const product = this.product.type === EPISODE
          ? await this.$store.dispatch('products/GET', {
            id: this.product.season.serial.id,
            type: SERIAL
          })
          : this.product

        if (!product) {
          return Promise.resolve()
        }

        if (!this.$store.getters['user/AVAILABLE_PRODUCTS'].includes(product.id)) {
          this.setCookie()

          if (this.isProductOnlyInBundles) {
            return this.$popup.action(this.$dict.popups.PRODUCT_IN_BUNDLE, {
              bundles: this.product.bundles,
              product: this.product
            })
              .finally(Promise.resolve())
          }

          return this.$popup.action(this.$dict.popups.BUY_PRODUCT, { product, isCardPopup: true, disableClose: true })
            .finally(Promise.resolve())
        }
        return Promise.resolve()
      },
      close () {
        this.$emit('close')
      },
      play () {
        if (this.$route.meta?.buy) {
          return this.$router.replace({ name: this.$dict.routes.WATCH })
        }

        if (this.mobileOrTablet) {
          this.isMobile = true
          return
        }

        this.$player.play({
          product: this.product,
          holderId: this.$options.playerHolder,
          playerStore: this.playerStore,
          mute: this.playerState.mute
        })
        this.playerStore.setIdle()
      },
      toggleOptions () {
        this.playerStore.toggleOptions()
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
