<template>
  <div>
    <span class="c-terms-list--text-muted">
      {{ category }}:
    </span>
    <span
      v-for="({name}, index) in array"
      :key="index"
    >
      <span
        class="u-typo--action"
        @click="searchTerm(name)"
      >
        {{ name }}
      </span>
      <span v-if="index !== (array.length - 1)">, </span>
    </span>
  </div>
</template>
<script>
  export default {
    name: 'TermsList',
    props: {
      array: {
        type: Array,
        default: () => ([])
      },
      category: {
        type: String,
        default: ''
      }
    },
    methods: {
      searchTerm (term) {
        this.$router.push({
          name: this.$dict.routes.SEARCH,
          query: { searchQuery: term }
        })
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
