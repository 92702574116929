<template>
  <div class="c-switch">
    <div class="c-switch__label">
      {{ label || $t('cmp.acceptance') }}
    </div>
    <label class="switch">
      <input
        type="checkbox"
        :checked="checked"
        @click="toggleCheckbox"
      >
      <div class="slider round" />
    </label>
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: ''
      },
      itemId: {
        type: Number,
        required: true
      },
      checked: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        checkValue: this.checked
      }
    },
    methods: {
      toggleCheckbox () {
        this.checkValue = !this.checkValue
        this.$emit('update', { id: this.itemId, isChecked: this.checkValue })
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
