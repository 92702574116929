<template>
  <div class="c-action-buttons__button">
    <div
      class="c-toggle-recordings__icon-link"
      @click="toggle"
    >
      <svg class="o-icon">
        <use xlink:href="#icon-downloads" />
      </svg>
      <div class="c-action-buttons__title">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
  import {
    PRODUCT_TYPE_TO_VIDEO_TYPE
  } from '@/assets/javascript/maps/product-type-to-video-type'

  export default {
    name: 'ToggleRecordings',
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    computed: {
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      },
      title () {
        return this.recordingId
          ? this.$t('buttons.removeFromRecordings')
          : this.$t('buttons.addToRecordings')
      },
      recordingId () {
        return this.$store.getters['recordings/RECORD_ID'](this.product.id)
      }
    },
    methods: {
      toggle () {
        if (!this.isLoggedIn) {
          return this.$router.push({ name: this.$dict.routes.LOGIN, query: { redirect: this.$route.fullPath } })
        }

        const type = this.product.type
        const product = {
          ...this.product,
          videoType: PRODUCT_TYPE_TO_VIDEO_TYPE[type]
        }

        this.recordingId
          ? this.removeFromList(product)
          : this.addToList(product)
      },
      addToList (product) {
        this.$store.dispatch('recordings/ADD_RECORDING', product.id)
      },
      removeFromList (product) {
        this.$store.dispatch('recordings/DELETE_RECORDING', {
          id: this.recordingId,
          title: product.title
        })
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
