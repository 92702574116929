<template>
  <div class="c-action-buttons">
    <div
      v-if="canWatch"
      class="c-action-buttons__button"
    >
      <button
        class="o-button"
        @click="redirect"
      >
        {{ label }}
      </button>
    </div>
    <div
      v-else-if="isBuyable"
      class="c-action-buttons__button"
    >
      <button
        class="o-button"
        @click="redirect"
      >
        {{ buttonText }}
      </button>
    </div>
    <div class="c-action-buttons__content">
      <share-on-fb
        v-if="url"
        :product="{ id }"
        :url="url"
      />
      <share-on-twitter
        v-if="url"
        :product="{ title, id, lead }"
        :url="url"
      />
      <share-on-email
        v-if="url"
        :product="{ title, id, lead }"
        :url="url"
      />
      <slot />
    </div>
  </div>
</template>
<script>
  import ShareOnEmail from '@/components/share-on-email/index'
  import ShareOnFb from '@/components/share-on-fb/index'
  import ShareOnTwitter from '@/components/share-on-twitter/index'
  import { BANNER } from '@/components/section-layouts/enums'
  import checkProductAvailability from 'assets/javascript/helpers/check-product-availability'
  import { EPISODE, SERIAL } from 'assets/javascript/enums/product-types'

  export default {
    name: 'ActionButtons',
    components: {
      ShareOnEmail,
      ShareOnFb,
      ShareOnTwitter
    },
    props: {
      product: {
        type: Object,
        required: true
      },
      detail: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      buttonText () {
        return this.$store.getters['user/LOGGED_IN']
          ? this.$t('buttons.buy')
          : this.$t('buttons.loginAndBuy')
      },
      isAvailable () {
        return (this.hasPaymentSchedules && this.isProductBought) || !this.hasPaymentSchedules
      },
      isProductBought () {
        return checkProductAvailability(this.$store.getters['user/AVAILABLE_PRODUCTS'], this.product.id)
      },
      hasPaymentSchedules () {
        return this.product.type === EPISODE
          ? this.product.serial?.paymentSchedules?.length
          : this.product.paymentSchedules?.length
      },
      canWatch () {
        const { type, programRecordingId, buttonLabel, urlWeb } = this.product || {}
        const hasButtonDetails = buttonLabel || urlWeb
        const isBanner = type === BANNER
        const isPromoBanner = (isBanner && !hasButtonDetails)
        const hasRecordingId = programRecordingId && this.$store.getters['recordings/RECORD_ID'](this.product.id) && this.$store.getters['recordings/IS_READY'](this.$store.getters['recordings/RECORD_ID'](this.product.id))
        const isAvailableAndNotSerial = this.isAvailable && type !== SERIAL

        return !isPromoBanner && (hasRecordingId || isAvailableAndNotSerial)
      },
      isBuyable () {
        return this.hasPaymentSchedules &&
          !this.isProductBought &&
          !this.product.programRecordingId &&
          !this.detail
      },
      label () {
        return this.product.buttonLabel || this.$t('buttons.watch')
      },
      routeLocation () {
        const { id, type, urlWeb } = this.product
        if (type === BANNER) {
          return urlWeb
        }

        return { name: this.$dict.routes.BUY_AND_WATCH, params: { productId: id, type: type && type.toLowerCase() } }
      },
      lead () {
        return this.product.lead
      },
      title () {
        return this.product.title
      },
      id () {
        return this.product.id
      },
      url () {
        if (!this.routeLocation) {
          return null
        }

        const { href } = this.$router.resolve(this.routeLocation)

        if (href.indexOf('http') !== -1) {
          return encodeURI(href.replace('/http', 'http'))
        }

        return encodeURI(window.location.origin + href)
      }
    },
    methods: {
      redirect () {
        const { externalView, type } = this.product

        if (type === BANNER) {
          return window.open(this.routeLocation, externalView ? '_blank' : '_self')
        }

        this.$router.push(this.routeLocation)
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
