<template>
  <div>
    <div
      id="play-button"
      class="u-abs-center"
    >
      <svg class="c-player__icon o-icon">
        <use xlink:href="#icon-play-circle" />
      </svg>
    </div>
    <div
      id="ad-container"
      :class="{isRunning: playerState.isAdvertRunning}"
    >
      <span class="c-advert-player__pod">
        {{ timeLeft }}s
      </span>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'AdvertPlayer',
    inject: [
      'playerStore'
    ],
    data () {
      return {
        playerState: this.playerStore.state
      }
    },
    computed: {
      timeLeft () {
        const pod = this.playerState.pod

        return Math.ceil(pod.remainingDuration || 0)
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
