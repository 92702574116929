<template>
  <div v-if="sections.length">
    <div
      v-for="(section, sectionIndex) in sections"
      :key="sectionIndex"
    >
      <section-with-product-detail
        :section="section"
      >
        <template v-slot="{ selectedProduct }">
          <div
            class="c-sections-chunked__row row expanded"
          >
            <product-card
              v-for="{ item, productIndex } in section.elements"
              :key="productIndex"
              class="c-sections-chunked__column column small-6 medium-4 large-3 xlarge-2"
              :class="{ 'is-active': item.id === selectedProduct.id }"
              :product="{ ...item, position: productIndex + 1, sectionPosition: sectionIndex + 1 }"
              :data-id="item.id"
              :data-section-id="section.id"
              :data-type="item.type"
            />
          </div>
        </template>
      </section-with-product-detail>
    </div>
  </div>
</template>
<script>
  import _chunk from 'lodash/chunk'
  import SectionWithProductDetail from '@/components/section-with-product-detail/index'
  import ProductCard from '@/components/product-card/index'

  export default {
    name: 'SectionsChunked',
    components: {
      ProductCard,
      SectionWithProductDetail
    },
    props: {
      elements: {
        type: Array,
        default: () => ([])
      },
      elementsInRow: {
        type: Number,
        default: 6
      }
    },
    computed: {
      sections () {
        return _chunk(this.elements, this.elementsInRow)
          .map((elements) => ({ elements }))
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
