<template>
  <div class="c-banner">
    <img
      v-if="background"
      class="c-banner__image"
      :src="background"
      alt="product.title"
    >
    <div class="c-banner__overlay row expanded">
      <div class="c-banner__container column small-12 large-9 align-self-bottom">
        <div class="c-banner__info">
          <h1 class="c-banner__title">
            {{ product.title }}
          </h1>
          <h5
            v-dompurify-html="advisors(product.lead)"
            class="c-banner__lead"
          />
          <div
            v-if="singleBanner && productPrice && !isProductBought"
            class="c-banner__container-single"
          >
            <h3 class="c-banner__product-price">
              {{ $t('banner.onlyNow') }}
              <span class="c-banner__product-currency">
                {{ productPrice }}
              </span>
              {{ $t('currency.pln') }}
            </h3>
            <buy-button :product="product" />
          </div>
          <slot name="actionButtons" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    SINGLE
  } from '@/assets/javascript/enums/payment-schedule-types'

  import {
    PAGE
  } from '@/assets/javascript/dictionaries/gtmEvents'

  import {
    FOCUS,
    VIEWPORT
  } from '@/assets/javascript/dictionaries/gtmEventsActions'

  import getLowestPrice from '@/assets/javascript/helpers/get-lowest-price'
  import Viewport from '@/mixins/viewport-methods'
  import trackingMixin from '@/mixins/tracking-mixin'
  import advisors from '@/mixins/advisors'
  import checkProductAvailability from '@/assets/javascript/helpers/check-product-availability'
  import { ASPECT_16X9 } from '@/assets/javascript/enums/aspect-ratios'
  import BuyButton from '@/components/buy-button'

  export default {
    name: 'Banner',
    components: {
      BuyButton
    },
    mixins: [
      Viewport,
      trackingMixin,
      advisors
    ],
    props: {
      product: {
        type: Object,
        default: () => ({})
      },
      singleBanner: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        timeoutId: null
      }
    },
    computed: {
      background () {
        return this.product.artworks?.[ASPECT_16X9]?.[0]?.mainUrl || this.product.images?.[ASPECT_16X9]?.[0]?.mainUrl
      },
      lead () {
        return this.advisorsFromApi
          ? this.advisorsFromApi + this.replaceAdvisors(this.product.lead)
          : this.advisors(this.product.lead)
      },
      productPrice () {
        return getLowestPrice(this.product, this.$time, SINGLE).price
      },
      isProductBought () {
        return checkProductAvailability(this.$store.getters['user/AVAILABLE_PRODUCTS'], this.product.id)
      }
    },
    mounted () {
      this.observeElement()
    },
    methods: {
      onViewportEnter () {
        this.sendPageTrackingData({
          action: VIEWPORT,
          ...this.product,
          pageCategory: this.$route.name
        })
      },
      onMouseEnter () {
        this.sendTrackingEventWithTimeout(PAGE, {
          product: {
            action: FOCUS,
            ...this.product,
            pageCategory: this.$route.name
          }
        })
      },
      onMouseLeave () {
        if (this.timeoutId) {
          window.clearTimeout(this.timeoutId)
        }
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
