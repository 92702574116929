<template>
  <div class="o-page">
    <h1 class="c-payments__heading">
      {{ $t('views.payments.chooseBundle') }}
    </h1>
    <h2
      v-if="mobileOrTablet"
      class="c-payments__subheading"
    >
      {{ $t('views.mobileApp.heading') }}
    </h2>
    <div class="c-payments__cards-wrapper">
      <payment-card
        v-for="bundle in bundles"
        :key="bundle.id"
        :product="bundle"
        :show-homepage-button="false"
      />
    </div>
  </div>
</template>
<script>
  import PaymentCard from '@/components/payment-card/index'
  import MobileOrTabletMixin from '@/mixins/mobile-or-tablet-mixin'

  export default {
    name: 'Payments',
    components: {
      PaymentCard
    },
    mixins: [MobileOrTabletMixin],
    async asyncData ({ store }) {
      return {
        bundles: await store.dispatch('products/GET_ALL_BUNDLES'),
        available: await store.dispatch('user/GET_AVAILABLE_PRODUCTS')
      }
    },
    data () {
      return {
        active: false
      }
    },
    head () {
      return {
        title: this.$t('views.payments.chooseBundle')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
