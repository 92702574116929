var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"row expanded collapse"},[_c('div',{staticClass:"column small-4"},[_c('div',{staticClass:"c-issues__label"},[_vm._v("\n        "+_vm._s(_vm.$t('views.myAccount.issues.subject'))+":\n      ")])]),_vm._v(" "),_c('div',{staticClass:"column small-2"},[_c('div',{staticClass:"c-issues__label"},[_vm._v("\n        "+_vm._s(_vm.$t('views.myAccount.issues.note'))+":\n      ")])]),_vm._v(" "),_c('div',{staticClass:"column small-2"},[_c('div',{staticClass:"c-issues__label"},[_vm._v("\n        "+_vm._s(_vm.$t('views.myAccount.issues.createdAt'))+":\n      ")])]),_vm._v(" "),_c('div',{staticClass:"column small-2"},[_c('div',{staticClass:"c-issues__label"},[_vm._v("\n        "+_vm._s(_vm.$t('views.myAccount.issues.modifiedAt'))+":\n      ")])]),_vm._v(" "),_c('div',{staticClass:"column small-2"},[_c('div',{staticClass:"c-issues__label"},[_vm._v("\n        "+_vm._s(_vm.$t('views.myAccount.issues.status'))+":\n      ")])]),_vm._v(" "),_c('div',{staticClass:"column small-12"},[_c('separator',{staticClass:"o-separator--horizontal o-separator--horizontal-small-margin"})],1)]),_vm._v(" "),_c('pagination',{attrs:{"callback":_vm.loadMore}},_vm._l((_vm.threads),function(ref){
var id = ref.id;
var title = ref.title;
var note = ref.note;
var createdAt = ref.createdAt;
var modifiedAt = ref.modifiedAt;
var status = ref.status;
return _c('div',{key:id,staticClass:"c-issues__row row expanded collapse"},[_c('div',{staticClass:"column small-4"},[_c('div',{staticClass:"c-issues__text"},[_vm._v("\n          "+_vm._s(title)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"column small-2"},[_c('div',{staticClass:"c-issues__text"},[_vm._v("\n          "+_vm._s(note || '-')+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"column small-2"},[_c('div',{staticClass:"c-issues__text"},[_vm._v("\n          "+_vm._s(_vm._f("dateFormat")(createdAt,'DD.MM.YYYY, HH:mm'))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"column small-2"},[_c('div',{staticClass:"c-issues__text"},[_vm._v("\n          "+_vm._s(_vm._f("dateFormat")(modifiedAt,'DD.MM.YYYY, HH:mm'))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"column small-2"},[_c('div',{staticClass:"c-issues__status"},[_vm._v("\n          "+_vm._s(_vm.$t(("views.myAccount.issues." + (status.toLowerCase()))))+"\n        ")])]),_vm._v(" "),_c('separator',{staticClass:"o-separator--horizontal o-separator--horizontal-small-margin"})],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }