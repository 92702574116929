<template>
  <div class="c-accept-consents">
    <template v-if="loaded">
      <h1 class="c-accept-consents__title">
        {{ $t('views.acceptConsents.title') }}
      </h1>
      <div class="c-accept-consents__agreement-wrapper">
        <div
          v-for="{id, agreement, content} in agreements"
          :key="id"
          class="column small-12"
        >
          <div class="c-accept-consents__agreement-container">
            <checkbox
              v-model="checkedAgreements"
              :value="id"
              :label="content"
              :is-required="agreement.obligatory"
            />
          </div>
        </div>
      </div>
      <div class="c-accept-consents__buttons-container">
        <button
          ref="submit"
          class="o-button"
          @click="saveAgreements(close)"
        >
          {{ $t('buttons.confirm') }}
        </button>
        <button
          class="o-button o-button--secondary"
          @click="close"
        >
          {{ $t('buttons.cancel') }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
  import trackingMixin from '@/mixins/tracking-mixin'
  import logoutUserMixin from '@/mixins/logout-user-mixin'
  import userAgreementsMixin from '@/mixins/user-agreements-mixin'
  import Checkbox from '@/components/checkbox'

  export default {
    components: {
      Checkbox
    },
    mixins: [
      logoutUserMixin,
      userAgreementsMixin,
      trackingMixin
    ],
    computed: {
      user () {
        return this.$store.state.user.data
      }
    },
    mounted () {
      this.getAgreements()
    },
    beforeDestroy () {
      if (this.user?.status?.missingAgreements) {
        this.logoutUser()
      }
    },
    methods: {
      close () {
        this.$emit('close')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
