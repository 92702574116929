<template>
  <div
    v-if="section.elements.length"
    class="c-banner-with-bullets"
  >
    <slider-wrapper
      :section="section"
      :options="{ startIndex }"
      @changed="setActiveIndex"
    >
      <template v-slot="{ data }">
        <banner :product="{ ...data.item, sectionPosition: section.sectionPosition }">
          <template v-slot:actionButtons>
            <action-buttons :product="data.item">
              <toggle-favourites :product="data.item" />
            </action-buttons>
          </template>
        </banner>
      </template>
    </slider-wrapper>
    <div
      v-if="isLarge"
      class="c-banner-with-bullets__bullets row"
    >
      <div class="column">
        <banner-bullets
          class="c-banner-bullets__bullets"
          :section="section"
          :selected-index="activeIndex"
          @selectProduct="receiveSelectProduct"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import ActionButtons from '@/components/action-buttons/index'
  import BannerBullets from '@/components/banner-bullets/index'
  import Banner from '@/components/banner/index'
  import SliderWrapper from '@/components/slider-wrapper/index'
  import ToggleFavourites from '@/components/action-buttons/components/toggle-favourites/index'
  import { breakpointGetters } from 'assets/javascript/responsive'
  import { IS_LARGE } from 'assets/javascript/responsive/enums'

  export default {
    name: 'BannerWithBullets',
    components: {
      ActionButtons,
      SliderWrapper,
      Banner,
      BannerBullets,
      ToggleFavourites
    },
    props: {
      section: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        startIndex: 0,
        activeIndex: 0
      }
    },
    computed: {
      isLarge () {
        return breakpointGetters[IS_LARGE]()
      }
    },
    methods: {
      receiveSelectProduct (index) {
        this.startIndex = index
      },
      setActiveIndex (index) {
        this.activeIndex = index
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
