var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"c-cmp__consents-view"},[_c('h1',{staticClass:"c-cmp__title"},[_vm._v("\n    "+_vm._s(_vm.data.consentList.bannerTitle)+"\n    "),(_vm.showBackButton)?_c('button',{staticClass:"c-cmp__button c-cmp__button--success c-cmp__button--inline o-button",on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v("\n      "+_vm._s(_vm.$t('buttons.goBack'))+"\n    ")]):_vm._e()]),_vm._v(" "),_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.data.consentList),expression:"data.consentList"}],staticClass:"c-cmp__content",on:{"click":_vm.handleClick}}),_vm._v(" "),(!_vm.showVendorsLocal)?_c('button',{staticClass:"c-cmp__button c-cmp__button--success o-button",on:{"click":_vm.toggleVendors}},[_vm._v("\n    "+_vm._s(_vm.$t('cmp.trustedPartners'))+"\n  ")]):[_c('div',{staticClass:"c-cmp__partners"},[_c('h3',[_vm._v("\n        "+_vm._s(_vm.$t('cmp.WOWPartners'))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"c-cmp__partners-close",on:{"click":_vm.closeVendors}},[_vm._v("\n      X\n    ")])],_vm._v(" "),(_vm.showVendorsLocal)?_c('vendor-list',{attrs:{"data":{
      purposes: _vm.data.purposes,
      vendors: _vm.data.vendors,
      vendorConsents: _vm.data.vendorConsents,
      vendorLegitimateInterest: _vm.data.vendorLegitimateInterest
    },"save-button-disabled":_vm.saveButtonDisabled},on:{"changed":_vm.enableSaveButton}}):[_c('purpose-list',{attrs:{"data":{
        showTitle: true,
        subtitle: _vm.$t('cmp.purposes'),
        purposes: _vm.data.purposes,
        purposesConsents: _vm.data.purposesConsents,
        purposesLITransparencys: _vm.data.purposesLITransparencys,
        type: 'purposes',
        dataAttr: 'purposesConsents',
        showLIT: true,
        visibleData: _vm.data.purposes
      }},on:{"updated":_vm.isCheckboxValueChange,"vendors":_vm.scrollAndShowVendors}}),_vm._v(" "),_c('checkbox',{attrs:{"label":_vm.specialAgreement},on:{"clicked":_vm.isClickedByUser},model:{value:(_vm.checkboxValue),callback:function ($$v) {_vm.checkboxValue=$$v},expression:"checkboxValue"}}),_vm._v(" "),_c('feature-list',{attrs:{"title":_vm.$t('cmp.specialPurposes'),"data":_vm.data.specialPurposes,"type":"special-purpose"},on:{"vendors":_vm.scrollAndShowVendors}}),_vm._v(" "),_c('feature-list',{attrs:{"title":_vm.$t('cmp.features'),"data":_vm.data.features,"type":"features"},on:{"vendors":_vm.scrollAndShowVendors}}),_vm._v(" "),_c('purpose-list',{attrs:{"data":{
        showTitle: false,
        subtitle: _vm.$t('cmp.specialFeatures'),
        specialFeatures: _vm.data.specialFeatures,
        specialFeaturesPurposes: _vm.data.specialFeaturesPurposes,
        purposesLITransparencys: _vm.data.purposesLITransparencys,
        type: 'specialFeatures',
        dataAttr: 'specialFeaturesPurposes',
        showLIT: false,
        visibleData: _vm.data.specialFeatures
      }},on:{"vendors":_vm.scrollAndShowVendors}})],_vm._v(" "),_c('div',{staticClass:"c-cmp__container"},[_c('div',{staticClass:"c-cmp__buttons-container c-cmp__buttons-container--margin"},[_c('button',{staticClass:"c-cmp__button c-cmp__button--fixed-width c-cmp__button--outline o-button",class:{ 'c-cmp__button--fixed-width-small' : _vm.TCString },on:{"click":function($event){return _vm.$emit('update', { acceptAll: false, saveButtonDisabled: _vm.saveButtonDisabled })}}},[_vm._v("\n        "+_vm._s(_vm.updateButtonText)+"\n      ")]),_vm._v(" "),(_vm.TCString)?_c('button',{staticClass:"c-cmp__button c-cmp__button--fixed-width-small c-cmp__button--outline c-cmp__button--reject o-button",on:{"click":function($event){return _vm.$emit('update', { rejectAll: true })}}},[_vm._v("\n        "+_vm._s(_vm.$t('cmp.rejectAll'))+"\n      ")]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"c-cmp__button c-cmp__button--success c-cmp__button--fixed-width o-button",on:{"click":function($event){return _vm.$emit('update', { acceptAll: true })}}},[_vm._v("\n        "+_vm._s(_vm.$t('cmp.acceptAll'))+"\n      ")])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }