<template>
  <div
    class="c-product-card"
    @click="sendTrackingDataOnClick"
  >
    <component :is="displayLayout" />
  </div>
</template>
<script>
  import ProgressBar from '@/components/progress-bar/index'
  import EpisodeLayout from './layouts/episode'
  import DefaultLayout from './layouts/default'
  import RecommendationLayout from './layouts/recommendation'
  import { EPISODE } from '@/assets/javascript/enums/product-types'
  import Viewport from '@/mixins/viewport-methods'
  import trackingMixin from '@/mixins/tracking-mixin'
  import { PAGE } from '@/assets/javascript/dictionaries/gtmEvents'
  import { FOCUS, VIEWPORT, CLICK } from '@/assets/javascript/dictionaries/gtmEventsActions'
  import { ASPECT_16X9 } from '@/assets/javascript/enums/aspect-ratios'

  const RECOMMENDATION = 'recommendation'

  export default {
    name: 'ProductCard',
    components: {
      ProgressBar
    },
    mixins: [Viewport, trackingMixin],
    props: {
      product: {
        type: Object,
        required: true
      },
      redirectToHome: {
        type: Boolean,
        default: false
      },
      layout: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        timeoutId: null,
        loaderActive: true
      }
    },
    layouts: {
      [EPISODE]: EpisodeLayout,
      [RECOMMENDATION]: RecommendationLayout
    },
    computed: {
      genre () {
        return this.product?.genres?.[0]?.name
      },
      progress () {
        const product = this.$store.getters['watched/IS_WATCHED'](this.product.id)?.item
        return product?.status.progressWatching || 0
      },
      thumbnail () {
        return this.product.images?.[ASPECT_16X9]?.[0]?.miniUrl
      },
      displayLayout () {
        if (!this.layout && this.product.type === EPISODE) {
          return DefaultLayout
        }

        const type = this.layout || this.product.layout || this.product.type
        return this.$options.layouts[type] || DefaultLayout
      }
    },
    mounted () {
      this.observeElement()
    },
    methods: {
      redirect () {
        if (this.$store.getters['user/LOGGED_IN']) {
          return this.$router.push({
            name: this.$dict.routes.BUY_AND_WATCH,
            params: {
              type: this.product.type.toLowerCase(),
              productId: this.product.id,
              redirectToHome: this.redirectToHome
            }
          })
        }

        return this.$router.push({ name: this.$dict.routes.LOGIN, query: { redirect: this.$route.fullPath } })
      },
      sendTrackingDataOnClick () {
        return this.sendPageTrackingData({
          action: CLICK,
          ...this.product,
          pageCategory: this.$route.name
        })
      },
      onViewportEnter () {
        this.sendPageTrackingData({
          action: VIEWPORT,
          ...this.product,
          pageCategory: this.$route.name
        })
      },
      onMouseEnter () {
        this.sendTrackingEventWithTimeout(PAGE, {
          product: {
            action: FOCUS,
            ...this.product,
            pageCategory: this.$route.name
          }
        })
      },
      onMouseLeave () {
        if (this.timeoutId) {
          window.clearTimeout(this.timeoutId)
        }
      },
      hideLoader () {
        this.loaderActive = false
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
