<template>
  <div class="c-reset-password">
    <div v-if="!hasSubmitData">
      <h1 class="c-reset-password__title">
        {{ $t('views.resetPassword.title') }}
      </h1>
      <message
        v-if="errors.global"
        :message="errors.global"
      />
      <p class="c-reset-password__content">
        {{ $t('views.resetPassword.content') }}
      </p>
      <form
        class="o-form"
        @submit.prevent="resetPassword"
      >
        <v-input
          v-model="resetPasswordForm.email"
          class="o-input__wrapper--small-margin"
          :placeholder="$t('common.email')"
          :restriction-rules="[restrictionRules.NO_SPACES]"
          :validation-rules="[validationRules.EMAIL]"
          @changedValidity="setFormValidity('email', $event)"
        />
        <message
          v-if="errors.email"
          :message="errors.email"
        />
        <div class="c-reset-password__buttons-container">
          <button
            ref="submit"
            type="submit"
            class="o-button"
            :class="{ 'o-button--disabled' : !isFormValid }"
            :disabled="!isFormValid"
          >
            {{ $t('views.resetPassword.resetPassword') }}
          </button>
          <nuxt-link
            :to="{ name: $dict.routes.HOME }"
            class="o-button o-button--secondary"
          >
            {{ $t('buttons.cancel') }}
          </nuxt-link>
        </div>
      </form>
    </div>
    <success-view v-else>
      <svg class="c-success__icon o-icon">
        <use xlink:href="#icon-mail" />
      </svg>
      <h1 class="c-success__heading">
        {{ $t('views.resetPassword.success.heading') }}
      </h1>
      <p>{{ $t('views.resetPassword.success.subheading') }}</p>
    </success-view>
  </div>
</template>

<script>
  import {
    RESET_PASSWORD
  } from '@/assets/javascript/enums/captcha-types'

  import {
    SUCCESS
  } from '@/assets/javascript/enums/message-types'

  import {
    SUBMISSION_SUCCESS,
    VALIDATION_FAILED
  } from '@/assets/javascript/dictionaries/gtmEventsActions'

  import errorMapper from '@/assets/javascript/helpers/error-mapper.js'
  import Message from '@/components/message'
  import SuccessView from '@/components/success-view'
  import restrictionRules from '@/assets/javascript/dictionaries/input-restriction-rules'
  import validationRules from '@/assets/javascript/dictionaries/input-validation-rules'
  import VInput from '@/components/input'
  import formValidityMixin from '@/mixins/form-validity-mixin'
  import trackingMixin from '@/mixins/tracking-mixin'

  export default {
    name: 'ResetPasswordForm',
    processing: false,
    successMessageType: SUCCESS,
    components: {
      Message,
      SuccessView,
      VInput
    },
    mixins: [
      formValidityMixin,
      trackingMixin
    ],
    data () {
      return {
        errors: [],
        hasSubmitData: false,
        resetPasswordForm: {
          captcha: '',
          email: ''
        },
        restrictionRules,
        validationRules,
        validity: {
          email: false
        }
      }
    },
    methods: {
      async resetPassword () {
        if (!this.isFormValid || this.$options.processing) {
          return
        }

        try {
          this.$loader.show(this.$refs.submit, { small: true })
          this.$options.processing = true
          this.resetPasswordForm.captcha = await this.$recaptcha(RESET_PASSWORD)
          await this.$store.dispatch('user/RESET_PASSWORD', this.resetPasswordForm)
          this.errors = []
          this.hasSubmitData = true
          this.sendFormTrackingData(SUBMISSION_SUCCESS)
        } catch (error) {
          return errorMapper(this.$t.bind(this), error)
            .then(errors => {
              this.trackingFormData = errors.code
              this.sendFormTrackingData(VALIDATION_FAILED)
              this.$set(this, 'errors', errors)
            })
        } finally {
          this.$options.processing = false
          this.$loader.hide(this.$refs.submit)
        }
      }
    },
    head () {
      return {
        title: this.$t('views.resetPassword.pageTitle')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
