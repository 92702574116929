<template>
  <div class="c-broadcast-profile row expanded">
    <div class="column small-offset-4 small-4">
      <div class="row expanded">
        <h1 class="c-broadcast-profile__heading column small-12">
          {{ $t('broadcast.editProfile.heading') }}
        </h1>
        <div class="column small-12">
          <form
            class="o-form row expanded"
            @submit.prevent="submit"
          >
            <div
              class="c-broadcast-profile__form-photo-container column small-12"
              @click="openFileViewer"
            >
              <broadcast-photo
                :url="photoUrl"
                :alt="imgAlt"
                :edit="false"
                class="c-broadcast-profile__form-photo"
              />
            </div>
            <div class="column small-12">
              <div>
                {{ $t('broadcast.profile.photo') }}:
              </div>
              <div class="c-broadcast-profile__form-upload-container">
                <input
                  id="file"
                  ref="file"
                  type="file"
                  class="c-broadcast-profile__form-upload-input"
                  @change="handleFileUpload"
                  @click="resetFileInput"
                >
                <label
                  for="file"
                  class="c-broadcast-profile__form-file-upload-label o-button o-button--secondary"
                >
                  <svg class="c-broadcast-profile__form-upload-icon o-icon">
                    <use xlink:href="#icon-upload" />
                  </svg>
                  {{ $t('broadcast.profile.uploadFile') }}
                </label>
                <tooltip
                  position="left"
                  class="c-tooltip--broadcast-profile"
                >
                  <template v-slot:tooltip>
                    {{ $t('tooltips.photoSize') }}
                  </template>
                </tooltip>
                <span class="c-broadcast-profile__form-file-name">
                  {{ file.name }}
                </span>
              </div>
            </div>
            <div class="column small-12">
              <div>
                {{ $t('broadcast.profile.name') }}:
              </div>
              <v-input
                v-model="form.name"
                type="text"
                :placeholder="$t('common.name')"
                class="c-broadcast-profile__form-input-wrapper o-input__wrapper--small-margin"
                :validation-rules="[validationRules.USERNAME]"
                @changedValidity="setFormValidity('name', $event)"
              />
            </div>
            <div class="column small-12">
              <div>
                {{ $t('broadcast.profile.city') }}:
              </div>
              <v-input
                v-model="form.city"
                type="text"
                :max-length="20"
                :placeholder="$t('profiles.aboutMe.cityLabel')"
                class="c-broadcast-profile__form-input-wrapper o-input__wrapper--small-margin"
                :validation-rules="[validationRules.USERNAME]"
                @changedValidity="setFormValidity('city', $event)"
              />
            </div>
            <div class="column small-12">
              <div>
                {{ $t('broadcast.profile.birthDate') }}:
              </div>
              <datepicker
                v-model="form.birthDate"
                name="birthDate"
                :language="pl"
                class="o-input__wrapper--small-margin c-broadcast-profile__form-input-wrapper"
                :input-class="['o-input', { 'o-input--success': activeClass, 'o-input--error': clearedInput }]"
                calendar-class="c-datepicker__calendar"
                :placeholder="$t('broadcast.profile.choose')"
                :monday-first="true"
                :format="customFormatter"
                @input="checkInput"
                @selected="checkInput"
                @cleared="clearedOrChanged"
              >
                <span
                  slot="afterDateInput"
                  class="o-input__icon"
                  :class="{ 'o-input__icon--success o-icon--check': activeClass, 'o-input__icon--error o-icon--caution': clearedInput }"
                />
              </datepicker>
            </div>
            <div class="column small-12">
              <div class="c-broadcast-profile__form-buttons">
                <button
                  ref="submit"
                  type="submit"
                  class="c-broadcast-profile__form-submit-button o-button"
                  :class="{ 'o-button--disabled' : !isFormValid }"
                  :disabled="!isFormValid"
                >
                  {{ $t('buttons.save') }}
                </button>
                <button
                  type="button"
                  class="o-button o-button--secondary"
                  @click="goBack"
                >
                  {{ $t('buttons.goBack') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import errorMapper from '@/assets/javascript/helpers/error-mapper.js'
  import restrictionRules from '@/assets/javascript/dictionaries/input-restriction-rules'
  import validationRules from '@/assets/javascript/dictionaries/input-validation-rules'

  import BroadcastPhoto from '@/components/broadcast-photo/index'
  import VInput from '@/components/input'
  import Datepicker from 'vuejs-datepicker'
  import { pl } from 'vuejs-datepicker/dist/locale'
  import Tooltip from '@/components/tooltip'

  import formValidityMixin from '@/mixins/form-validity-mixin'
  import getBroadcastProfileMixin from '@/mixins/get-broadcast-profile'

  const MAX_FILE_SIZE = 1048576

  export default {
    name: 'BroadcastProfile',
    processing: false,
    components: {
      BroadcastPhoto,
      Datepicker,
      Tooltip,
      VInput
    },
    mixins: [
      formValidityMixin,
      getBroadcastProfileMixin
    ],
    data () {
      return {
        form: {
          name: '',
          birthDate: '',
          city: ''
        },
        file: {},
        restrictionRules,
        validationRules,
        validity: {
          name: false,
          birthDate: undefined,
          city: false,
          photo: this.hasProfile
        },
        pl,
        activeClass: undefined,
        clearedInput: undefined,
        photoUrl: '',
        imgAlt: ''
      }
    },
    computed: {
      hasProfile () {
        return !!this.$store.getters['broadcast/BROADCAST_PROFILE'].subscriberId
      }
    },
    mounted () {
      this.$loader.show(this.$root.$el)
      this.getBroadcastProfile()
        .then(profile => {
          this.photoUrl = profile.photoUrl
          this.imgAlt = profile.name
          this.fillInForm(profile)
          this.$loader.hide(this.$root.$el)
        })
        .catch(error => {
          if (error !== this.$t('errors.broadcastProfileNotExists')) {
            this.$toast.error(error)
          }
        })
        .finally(() => {
          this.$loader.hide(this.$root.$el)
        })
    },
    methods: {
      customFormatter (date) {
        return this.$time(date).format('YYYY-MM-DD')
      },
      birthDateFormatter (date) {
        return this.$time(date).format('YYYY-MM-DD' + 'T' + 'HH:mm:ss' + 'Z')
      },
      checkInput (e) {
        if (e) {
          this.activeClass = true
          this.clearedInput = undefined
          this.validity.birthDate = true
        }
      },
      clearedOrChanged () {
        this.clearedInput = true
        this.activeClass = undefined
        this.validity.birthDate = undefined
      },
      handleFileUpload () {
        this.file = this.$refs.file.files[0]
        this.fileSizeValidation()
      },
      submit () {
        if (!this.isFormValid || this.$options.processing) {
          return
        }

        this.$loader.show(this.$refs.submit, { small: true })
        this.$options.processing = true
        this.form.birthDate = this.birthDateFormatter(this.form.birthDate)

        this.actionAddOrUpdate(this.form)
          .then(() => this.actionAddOrUpdatePhoto())
          .then(() => this.getBroadcastProfile())
          .then(() => {
            this.$toast.success(this.$t('broadcast.profile.success'))
            return this.$router.push({ name: this.$dict.routes.BROADCAST })
          })
          .catch(error => {
            return errorMapper(this.$t.bind(this), error)
              .then(errors => {
                this.$toast.error(errors.global)
              })
          })
          .finally(() => {
            this.$options.processing = false
            this.$loader.hide(this.$refs.submit, { small: true })
          })
      },
      goBack () {
        return this.$router.back()
      },
      actionAddOrUpdate (form) {
        return this.hasProfile
          ? this.$store.dispatch('broadcast/UPDATE_BROADCAST_PROFILE', form)
          : this.$store.dispatch('broadcast/ADD_BROADCAST_PROFILE', form)
      },
      actionAddOrUpdatePhoto () {
        return this.file.name
          ? this.$store.dispatch('broadcast/SUBMIT_FILE_PHOTO', this.file)
          : Promise.resolve()
      },
      fillInForm ({ name, city, birthDateShort }) {
        this.form = { name, city, birthDate: birthDateShort }
        this.validity = {
          name: !!name,
          city: !!city,
          birthDate: !!birthDateShort
        }
      },
      fileSizeValidation () {
        if (this.file.size > MAX_FILE_SIZE) {
          this.$toast.error(this.$t('errors.fileSizeTooBig'))
          this.$toast.error(this.$t('errors.uploadFileAgain'))
          this.file = {}
          return this.setFormValidity('photo', false)
        }

        this.setFormValidity('photo', true)
      },
      openFileViewer () {
        this.$refs.file.click()
      },
      resetFileInput () {
        this.$refs.file.value = null
      }
    },
    head () {
      return {
        title: this.$t('broadcast.profile.edit')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
