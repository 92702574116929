<template>
  <div class="c-seasons">
    <div class="c-seasons__info row expanded">
      <div class="c-info">
        <h2 class="c-info__title column small-12">
          {{ product.title }}
        </h2>
      </div>
      <h4 class="c-seasons__type column small-12">
        {{ $t(type.toLowerCase()) }}
      </h4>
    </div>
    <div class="c-seasons__select row expanded">
      <div
        v-for="season in seasons"
        :key="season.id"
        class="c-seasons__season column shrink"
        :class="{ 'is-active': season.id === activeSeason.id }"
        @click.stop="setActiveSeason(season)"
      >
        {{ $t('series.season') }} {{ season.number }}
      </div>
    </div>
    <slider-wrapper
      v-if="currentSeason.elements.length"
      :section="currentSeason"
    >
      <template v-slot="{ data }">
        <product-card
          :product="data"
          :layout="$options.EPISODE"
        />
      </template>
    </slider-wrapper>
  </div>
</template>
<script>
  import {
    ONE_LINE
  } from '@/components/section-layouts/enums'

  import ProductCard from '@/components/product-card/index'
  import SliderWrapper from '@/components/slider-wrapper/index'
  import { EPISODE } from 'assets/javascript/enums/product-types'

  export default {
    name: 'Seasons',
    EPISODE,
    components: {
      SliderWrapper,
      ProductCard
    },
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        currentSeason: {
          elements: []
        },
        seasons: [],
        activeSeason: {}
      }
    },
    computed: {
      type () {
        return this.product.type.toLowerCase()
      }
    },
    watch: {
      'activeSeason' (season) {
        this.getEpisodes(season.id)
      }
    },
    async mounted () {
      const product = this.product
      const season = product.season
      const id = season?.serial.id || product.id
      const seasons = await this.$store.dispatch('products/GET_SERIAL_SEASONS', id)
      if (season) {
        this.activeSeason = season
      } else {
        this.activeSeason = seasons[0]
      }
      this.seasons = seasons
    },
    methods: {
      getEpisodes (seasonId) {
        return this.$store.dispatch('products/GET_SERIAL_EPISODES', {
          serialId: this.product.type === EPISODE
            ? this.product?.season?.serial?.id
            : this.product.id,
          seasonId
        })
          .then((season) => {
            this.currentSeason = {
              elements: season,
              layout: ONE_LINE
            }
          })
          .finally(() => {
            this.$nextTick(() => {
              setTimeout(() => {
                this.$emit('loaded', this.currentSeason)
              // TODO TEMP jest problem z renderowaniem
              // trzeba rozwiązać problem kiedy wywołać event loaded
              // nie ma info kiedy wyrenderują się epizody :(
              }, 50)
            })
          })
      },
      setActiveSeason (season) {
        this.activeSeason = season
      }
    }
  }
</script>
<style lang="scss">
  @import "./styles.scss";
  @import "../../components/product-detail/components/info/styles.scss";
</style>
