<template>
  <nav>
    <nuxt-link
      v-for="(item, index) in menuItems"
      :key="index"
      :to="{ path: item.url }"
      class="c-menu__link"
    >
      {{ item.name }}
    </nuxt-link>
    <slot />
  </nav>
</template>
<script>
  export default {
    name: 'UiMenu',
    props: {
      stateName: {
        type: String,
        required: true
      }
    },
    computed: {
      menu () {
        return this.$store.state.menu.menus['menu-web'][this.stateName]
      },
      menuItems () {
        return this.menu
          .filter(item => {
            if (item.isLogged && !this.isLoggedIn) {
              return
            }
            return item
          })
      },
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      }
    }
  }
</script>
<style src="./styles.scss" lang="scss" />
