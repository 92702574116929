<template>
  <div class="c-product-rating">
    <div class="c-product-rating__score">
      <span class="c-product-rating__score-text">
        {{ $t('tabs.details.score') }}:
        <span>{{ rating || 0 }}/{{ stars }}</span>
      </span>
    </div>
    <separator class="c-product-rating__separator o-separator--vertical" />
    <div class="c-product-rating__bar">
      <rating-bar
        :value="rating"
        :length="stars"
        @input="sync"
      />
    </div>
  </div>
</template>

<script>
  import RatingBar from '@/components/product-rating/components/rating-bar'
  import Separator from '@/components/separator'
  import { ACTION } from 'assets/javascript/dictionaries/gtmEvents'
  import { ADD_REACTION } from 'assets/javascript/dictionaries/gtmEventsActions'

  export default {
    name: 'ProductRating',
    components: {
      Separator,
      RatingBar
    },
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        rating: 0,
        tempRating: 0,
        stars: 10
      }
    },
    computed: {
      productRating () {
        return this.product.playTime || 0
      },
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      },
      itemId () {
        return this.product.id
      },
      videoType () {
        return this.product.type
      }
    },
    watch: {
      product: {
        immediate: true,
        handler () {
          this.rating = this.productRating
        }
      }
    },
    methods: {
      sync (score) {
        const { itemId, videoType } = this
        if (!this.isLoggedIn) {
          this.$router.push({ name: this.$dict.routes.LOGIN })
          return
        }
        this.tempRating = score
        if (this.tempRating === this.rating) {
          this.rating = 0
          this.removeScore({ id: itemId })
        } else {
          this.addScore({
            id: itemId,
            playTime: score,
            videoType
          })
          this.rating = score
        }

        this.$tracking.events.send(ACTION, {
          product: {
            id: itemId
          },
          action: ADD_REACTION
        })
      },
      addScore (product) {
        const profile = this.$store.getters['user/ACTIVE_PROFILE']
        const payload = {
          ...product,
          profile
        }

        if (this.rating > 0) {
          payload.profileId = payload.profile.id
          payload.profileToken = payload.profile.token
          this.$store.dispatch('scores/REMOVE_SCORE', payload)
        }
        this.$store.dispatch('scores/ADD_SCORE', payload)
      },
      removeScore (product) {
        const { id, token } = this.$store.getters['user/ACTIVE_PROFILE']
        const payload = {
          ...product,
          profileId: id,
          profileToken: token
        }

        this.$store.dispatch('scores/REMOVE_SCORE', payload)
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss" />
