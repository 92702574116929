<template>
  <slider
    :items="sectionElements"
    :options="sliderOptions"
    v-on="$listeners"
  >
    <template v-slot="{ data }">
      <slot :data="data" />
    </template>
  </slider>
</template>
<script>
  import Slider from '@/components/slider/index'
  import { getCurrentBreakpoint } from '@/assets/javascript/responsive'
  import { optionsGetter } from './slider-options'

  export default {
    name: 'SliderWrapper',
    components: {
      Slider
    },
    props: {
      section: {
        type: Object,
        required: true,
        default: () => ({})
      },
      options: {
        type: Object,
        default: () => ({})
      }
    },
    data () {
      return {
        sliderOptions: {}
      }
    },
    computed: {
      sectionElements () {
        return this.section.elements.map((element, index) => ({
          ...element,
          item: {
            ...element.item,
            position: index + 1
          }
        })
        )
      }
    },
    watch: {
      options: {
        immediate: true,
        deep: true,
        handler (options, oldOptions) {
          if (oldOptions) {
            this.sliderOptions = { ...this.sliderOptions, ...options }
            return
          }
          const defaultOptions = this.getDefaultOptions() || {}

          this.sliderOptions = { ...defaultOptions, ...options }
        }
      }
    },
    methods: {
      getDefaultOptions () {
        const breakpoint = getCurrentBreakpoint()
        if (breakpoint) {
          return optionsGetter(this.section.layout)(breakpoint)
        }
      }
    }
  }
</script>
