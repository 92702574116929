<template>
  <div
    v-if="productPriceAndDuration.price"
    class="c-payment-card"
    :class="{ 'is-active': activeCard, 'c-payment-card--lighter': lighter }"
    @click="toggleActiveCard"
  >
    <div class="c-payment-card__container-top">
      <h2 class="c-payment-card__title">
        {{ productTitle }}
      </h2>
      <div
        v-if="product.description"
        v-dompurify-html="product.description"
        class="c-payment-card__description"
      />
    </div>
    <div class="c-payment-card__container-bottom">
      <p class="c-payment-card__subtitle">
        {{ crossBorderDescription }}
      </p>
      <p
        v-if="mobileOrTablet"
        class="c-payment-card__subtitle"
      >
        {{ $t('views.mobileApp.heading') }}
      </p>
      <div>
        <p class="c-payment-card__price">
          {{ productPriceAndDuration.price }} {{ expirationPeriod }}
        </p>
        <checkbox
          v-for="consent in consents"
          :key="consent.id"
          v-model="checkedConsents"
          :label="consent.description"
          :value="consent.id"
        />
        <div class="c-payment-card__container-cta">
          <button
            v-if="!isBought"
            ref="submit"
            class="o-button"
            :class="{ 'o-button--disabled' : !canSubmit }"
            :disabled="!canSubmit"
            @click="buy(product)"
          >
            {{ $t('buttons.buy') }}
          </button>
          <button
            v-else
            class="o-button o-button--transparent o-button--disabled"
            :disabled="true"
          >
            {{ $t('buttons.bought') }}
          </button>
        </div>
        <div
          v-if="showBackButton"
          class="c-payment-card__container-cta"
        >
          <button
            ref="submit"
            class="o-button o-button--transparent"
            @click="goBackToHomePage"
          >
            {{ $t('buttons.homepage') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    BUNDLE,
    SERIAL,
    VOD
  } from '@/assets/javascript/enums/product-types'
  import {
    CYCLIC,
    SINGLE
  } from '@/assets/javascript/enums/payment-schedule-types'
  import { CONFIRMED } from '@/assets/javascript/enums/payment-status'

  import errorMapper from '@/assets/javascript/helpers/error-mapper.js'
  import getLowestPrice from '@/assets/javascript/helpers/get-lowest-price'
  import getLowestSchedule from '@/assets/javascript/helpers/get-lowest-schedule'
  import truncate from '@/assets/javascript/filters/truncate'
  import Checkbox from '@/components/checkbox'

  import MobileOrTabletMixin from '@/mixins/mobile-or-tablet-mixin'

  const SPECIAL_AVAILABILITY = 'SPECIAL_AVAILABILITY'

  export default {
    name: 'PaymentCard',
    components: {
      Checkbox
    },
    filters: {
      truncate
    },
    mixins: [MobileOrTabletMixin],
    props: {
      product: {
        type: Object,
        required: true
      },
      disableClose: {
        type: Boolean,
        default: true
      },
      promise: {
        type: Object,
        default: null
      },
      showHomepageButton: {
        type: Boolean,
        default: true
      },
      lighter: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        checkedConsents: [],
        consents: [],
        errors: [],
        activeCard: false,
        intervalCalls: 0
      }
    },
    computed: {
      isBought () {
        return this.$store.state.user?.available?.includes(this.product.id)
      },
      canSubmit () {
        return this.checkedConsents.length === this.consents.length
      },
      crossBorderDescription () {
        return this.product.type === VOD
          ? this.$t('views.payments.crossBorder')
          : this.$t('views.payments.timing')
      },
      paymentType () {
        if (this.isSinglePaymentSpecialBundle) {
          return SINGLE
        }

        return this.product.type === BUNDLE ? CYCLIC : SINGLE
      },
      isSinglePaymentSpecialBundle () {
        const { type, elements = [] } = this.product

        return type === BUNDLE &&
          elements?.some(({ product }) => product.type === SPECIAL_AVAILABILITY)
      },
      productPriceAndDuration () {
        return getLowestPrice(this.product, this.$time, this.paymentType)
      },
      expirationPeriod () {
        const MAX_HOUR_DURATION = 48
        let duration = this.productPriceAndDuration.duration

        if (this.isSinglePaymentSpecialBundle) {
          const quantity = this.product.elements
            ?.find(({ product }) => product.type === SPECIAL_AVAILABILITY)
            ?.product
            ?.quantity

          if (quantity) {
            duration = quantity / 60
          }
        }

        const expiration = this.$t('views.payments.currencyPerDuration', {
          duration: duration > MAX_HOUR_DURATION
            ? Math.round(duration / 24)
            : duration
        })
        const dayOrHour = duration > MAX_HOUR_DURATION
          ? ` ${this.$t('views.payments.days')}`
          : this.$t('views.payments.hours')

        return expiration + dayOrHour
      },
      productDescription () {
        return this.$options.filters.truncate(this.description(this.product), 140)
      },
      productTitle () {
        return this.product.type === SERIAL
          ? this.$t('views.payments.series', { title: this.product.title })
          : this.product.title
      },
      showBackButton () {
        return this.disableClose && this.showHomepageButton
      },
      subscription () {
        return this.$store.state.user.activeSubscription
      }
    },
    created () {
      this.getConsents()
    },
    methods: {
      async buy (product) {
        if (!this.subscription?.id) {
          await this.$store.dispatch('user/GET_CYCLIC_PAYMENTS', {})
        }

        const productId = product.id
        let scheduleId

        if (this.isBought) {
          return this.$toast.error(this.$t('errors.productAlreadyBought'))
        }

        if (product.type === BUNDLE && this.paymentType === CYCLIC) {
          scheduleId = getLowestSchedule(product, this.$time, CYCLIC).id
          return this.checkCardAndBuyBundle(productId, scheduleId, this.checkedConsents)
        }

        scheduleId = getLowestSchedule(product, this.$time, SINGLE).id
        return this.buySingleProduct(productId, scheduleId, this.checkedConsents)
      },
      async buyBundle (opts = {}) {
        const { cardId, productId, scheduleId, agreements } = opts
        const dispatch = this.$store.dispatch
        const agreementIds = agreements.map(id => ({ id }))

        this.$loader.show(this.$refs.submit, { small: true })

        const { status } = await dispatch('payments/GET_CARD_STATUS', cardId)
        if (status !== CONFIRMED) {
          this.$loader.hide(this.$refs.submit)
          return this.$toast.error(this.$t('errors.creditCardInvalid'))
        }

        try {
          const { url } = await dispatch('payments/BUY_BUNDLE', { productId, scheduleId, cardId, agreementIds })
          window.open(url, '_self')
        } catch (error) {
          this.$loader.hide(this.$refs.submit)
          const { errors } = await errorMapper(this.$t.bind(this), error)
          return this.$toast.error(errors.global)
        }
      },
      checkCardAndBuyBundle (productId, scheduleId, agreements) {
        // for now we support one card only
        const cardId = this.$store.state.payments.cards?.[0]?.id

        if (!cardId) {
          this.$popup.action(this.$dict.popups.ADD_CARD, { withResume: true })
            .then(() => {
              this.buyBundle({
                cardId: this.$store.state.payments.cards[0].id,
                productId,
                scheduleId,
                agreements
              })
            })

          return this.$toast.error(this.$t('errors.addCreditCard'))
        }

        return this.buyBundle({ cardId, productId, scheduleId, agreements })
      },
      buySingleProduct (productId, scheduleId, agreements) {
        const agreementIds = agreements.map(id => ({ id }))

        this.$store.dispatch('payments/BUY_SINGLE_PRODUCT', { productId, scheduleId, agreementIds })
          .then((data) => {
            window.open(data.url, '_self')
          })
          .catch(error => {
            this.$loader.hide(this.$refs.submit)
            return errorMapper(this.$t.bind(this), error)
              .then(errors => {
                this.$toast.error(errors.global)
              })
          })
      },
      description ({ description, lead }) {
        return description || lead
      },
      getConsents () {
        this.scheduleId = getLowestSchedule(this.product, this.$time)?.id

        if (this.scheduleId) {
          const paymentSchedules = this.product.paymentSchedules
            ?.find(item => item.id === this.scheduleId)

          if (paymentSchedules) {
            this.consents = paymentSchedules?.agreementVersions || []
          }
        }
      },
      goBackToHomePage () {
        this.$parent.$emit('close')
        this.$router.replace({
          name: this.$dict.routes.HOME
        })
      },
      toggleActiveCard () {
        this.activeCard = !this.activeCard
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
