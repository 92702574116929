<template>
  <section>
    <div v-if="!success">
      <h1 class="c-change-password__title">
        {{ $t('views.changePassword.title') }}
      </h1>
      <message
        v-if="errors.global"
        :message="errors.global"
      />
      <form
        class="o-form"
        @submit.prevent="changePassword"
      >
        <div class="c-change-password__label">
          {{ $t('views.changePassword.currentPassword') }}
        </div>
        <v-input
          v-model="form.oldPassword"
          type="password"
          class="o-input__wrapper--small-margin"
          :placeholder="$t('views.changePassword.currentPassword')"
          :validation-rules="[validationRules.MIN_LENGTH_5]"
          @changedValidity="setFormValidity('oldPassword', $event)"
        />
        <message
          v-if="errors.oldPassword"
          :message="errors.oldPassword"
        />
        <div class="c-change-password__label">
          {{ $t('views.resetPassword.newPassword') }}
        </div>
        <v-input
          v-model="form.newPassword"
          type="password"
          class="o-input__wrapper--small-margin"
          :placeholder="$t('views.resetPassword.newPassword')"
          :tooltip="$t('tooltips.password')"
          :tooltip-position="mobileOrTablet ? 'left' : 'right'"
          :validation-rules="[validationRules.PASSWORD]"
          @changedValidity="setFormValidity('newPassword', $event)"
        />
        <message
          v-if="errors.newPassword"
          :message="errors.newPassword"
        />
        <button
          ref="submit"
          type="submit"
          class="c-change-password__submit o-button"
          :class="{ 'o-button--disabled' : !isFormValid }"
          :disabled="!isFormValid"
        >
          {{ $t('common.submit') }}
        </button>
      </form>
    </div>
    <success-view v-else>
      <svg class="c-success__icon o-icon">
        <use xlink:href="#icon-success" />
      </svg>
      <h1 class="c-success__heading">
        {{ $t('views.changePassword.success.heading') }}
      </h1>
      <p>{{ $t('views.changePassword.success.subheading') }}</p>
    </success-view>
  </section>
</template>

<script>
  import {
    SUBMISSION_SUCCESS,
    USER_CHANGE_PASSWORD,
    VALIDATION_FAILED
  } from '@/assets/javascript/dictionaries/gtmEventsActions'

  import {
    PASSWORD
  } from '@/assets/javascript/enums/captcha-types'

  import errorMapper from '@/assets/javascript/helpers/error-mapper.js'
  import restrictionRules from '@/assets/javascript/dictionaries/input-restriction-rules'
  import validationRules from '@/assets/javascript/dictionaries/input-validation-rules'

  import Message from '@/components/message'
  import SuccessView from '@/components/success-view'
  import VInput from '@/components/input'

  import formValidityMixin from '@/mixins/form-validity-mixin'
  import mobileOrTabletMixin from '@/mixins/mobile-or-tablet-mixin'
  import trackingMixin from '@/mixins/tracking-mixin'

  export default {
    name: 'ChangePassword',
    processing: false,
    components: {
      Message,
      SuccessView,
      VInput
    },
    mixins: [
      formValidityMixin,
      mobileOrTabletMixin,
      trackingMixin
    ],
    data () {
      return {
        active: true,
        errors: [],
        form: {
          captcha: '',
          oldPassword: '',
          newPassword: ''
        },
        restrictionRules,
        success: false,
        validationRules,
        validity: {
          oldPassword: false,
          newPassword: false
        }
      }
    },
    methods: {
      close () {
        this.active = false
      },
      async changePassword () {
        if (!this.isFormValid || this.$options.processing) {
          return
        }

        try {
          this.$loader.show(this.$refs.submit, { small: true })
          this.$options.processing = true
          this.form.captcha = await this.$recaptcha(PASSWORD)
          await this.$store.dispatch('user/CHANGE_PASSWORD', this.form)
          this.errors = []
          this.success = true
          this.sendUserTrackingData(USER_CHANGE_PASSWORD)
          this.sendFormTrackingData(SUBMISSION_SUCCESS)
        } catch (error) {
          return errorMapper(this.$t.bind(this), error)
            .then(errors => {
              this.trackingFormData.validationError = errors.code
              this.sendFormTrackingData(VALIDATION_FAILED)
              this.$set(this, 'errors', errors)
            })
        } finally {
          this.$options.processing = false
          this.$loader.hide(this.$refs.submit)
        }
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
