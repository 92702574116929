<template>
  <section class="c-document">
    <div v-dompurify-html="document.content" />
  </section>
</template>
<script>
  export default {
    layout: 'document',
    data () {
      return {
        document: {}
      }
    },
    created () {
      this.$loader.show(this.$root.$el)
      this.$store.dispatch('documents/GET', this.$route.params.alias)
        .then((document) => {
          this.document = document
        })
        .catch(() => {
          this.$toast.error(this.$t('common.error'))
        })
        .finally(() => this.$loader.hide(this.$root.$el))
    },
    head () {
      return {
        title: this.document?.title
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
