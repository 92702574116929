<template>
  <div
    ref="scroll"
    class="c-styled-scroll"
  >
    <slot />
  </div>
</template>
<script>
  import PerfectScrollbar from 'perfect-scrollbar'

  export default {
    props: {
      opts: {
        type: Object,
        default: () => ({})
      }
    },
    mounted () {
      this.perfectScroll = new PerfectScrollbar(this.$refs.scroll, {
        minScrollbarLength: 30,
        ...this.opts
      })
      this.$nextTick(() => {
        this.$emit('created', this.perfectScroll)
        this.perfectScroll.update()
      })
    },
    beforeDestroy () {
      this.perfectScroll.destroy()
      this.perfectScroll = null
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
