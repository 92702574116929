<template>
  <section>
    <div class="c-cmp__content">
      <span class="c-cmp__content--bold">
        {{ $t('cmp.privacyPolicy') }}:
      </span>
      <a
        :href="vendor.urls[0].privacy"
        class="c-cmp__link"
        target="_blank"
      >
        {{ vendor.urls[0].privacy }}
      </a>
    </div>
    <div class="c-cmp__content">
      <span class="c-cmp__content--bold">
        {{ $t('cmp.purposes') }}:
      </span>
      {{ $parent.showMore({ element: 'purposes', ids: vendor.purposes }) }}
    </div>
    <div class="c-cmp__content">
      <span class="c-cmp__content--bold">
        {{ $t('cmp.legIntPurposes') }}:
      </span>
      {{ $parent.showMore({ element: 'purposes', ids: vendor.legIntPurposes }) }}
    </div>
    <div class="c-cmp__content">
      <span class="c-cmp__content--bold">
        {{ $t('cmp.specialPurposes') }}:
      </span>
      {{ $parent.showMore({ element: 'specialPurposes', ids: vendor.specialPurposes }) }}
    </div>
    <div class="c-cmp__content">
      <span class="c-cmp__content--bold">
        {{ $t('cmp.flexiblePurposes') }}:
      </span>
      {{ $parent.showMore({ element: 'purposes', ids: vendor.flexiblePurposes }) }}
    </div>
    <div class="c-cmp__content">
      <span class="c-cmp__content--bold">
        {{ $t('cmp.features') }}:
      </span>
      {{ $parent.showMore({ element: 'features', ids: vendor.features }) }}
    </div>
    <div class="c-cmp__content">
      <span class="c-cmp__content--bold">
        {{ $t('cmp.specialFeatures') }}:
      </span>
      {{ $parent.showMore({ element: 'specialFeatures', ids: vendor.specialFeatures }) }}
    </div>
    <div
      v-if="vendor.cookieMaxAgeSeconds"
      class="c-cmp__content"
    >
      <span class="c-cmp__content--bold">
        {{ $t('cmp.cookieMaxAge') }}:
      </span>
      {{ cookieMaxAge }} {{ $t('cmp.hours') }}
    </div>
  </section>
</template>

<script>
  export default {
    props: {
      vendor: {
        type: Object,
        required: true
      }
    },
    computed: {
      cookieMaxAge () {
        return Math.round(this.vendor.cookieMaxAgeSeconds / 3600)
      }
    }
  }
</script>
