<template>
  <section class="c-product-in-bundle">
    <template v-if="loaded">
      <h1 class="c-product-in-bundle__title">
        {{ $t('views.productInBundle.title') }}
      </h1>
      <p>
        {{ $t('views.productInBundle.body', { title: product.title }) }}
      </p>
      <template>
        <div class="c-product-in-bundle__wrapper">
          <payment-card
            v-for="bundle in bundlesDetailed"
            :key="bundle.id"
            :product="bundle"
            :show-homepage-button="false"
            lighter
          />
        </div>
      </template>
    </template>
  </section>
</template>

<script>
  import PaymentCard from '@/components/payment-card/index'

  export default {
    components: {
      PaymentCard
    },
    props: {
      bundles: {
        type: Array,
        required: true
      },
      product: {
        type: Object,
        default: () => {}
      },
      promise: {
        type: Object,
        default: null
      }
    },
    data () {
      return {
        bundlesDetailed: [],
        loaded: false
      }
    },
    async created () {
      if (!this.promise) {
        return this.cancel()
      }

      try {
        this.$loader.show(this.$root.$el)
        const bundlesDetailed = await this.$store.dispatch('products/GET_ALL_BUNDLES')
        this.bundlesDetailed = bundlesDetailed.filter(bundle => this.bundles.some(({ id }) => id === bundle.id))
        this.loaded = true
      } catch {
        this.$toast.error(this.$t('errors.bundleFetchFailed'))
        await this.$router.push({ name: this.$dict.routes.HOME })
      } finally {
        this.$loader.hide(this.$root.$el)
      }
    },
    methods: {
      cancel () {
        if (this.$route.params.redirectToHome) {
          this.$router.push({ name: this.$dict.routes.HOME })
        }

        this.$emit('close')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
