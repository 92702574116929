<template>
  <section>
    <h4 class="c-cmp__subtitle">
      {{ title }}:
    </h4>
    <div
      v-for="{id, name, description, descriptionLegal} in data"
      :key="`${type}-${id}`"
    >
      <div
        class="c-cmp__content c-cmp__content--clickable"
        @click="toggleDetails(type, id)"
      >
        - {{ name }}
      </div>
      <div
        :ref="`${type}-${id}`"
        class="c-cmp__purpose-details"
      >
        <p class="c-cmp__content">
          {{ description }}
        </p>
        <p class="c-cmp__content">
          {{ descriptionLegal }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      data: {
        type: Object,
        required: true
      },
      type: {
        type: String,
        required: true
      }
    },
    methods: {
      toggleDetails (type, id) {
        const ref = this.$refs[`${type}-${id}`]?.[0]
        let refDisplay = ref.style.display

        if (!ref) {
          return
        }

        if (refDisplay === 'none' || refDisplay === '') {
          refDisplay = 'block'
          return
        }

        refDisplay = 'none'
      }
    }
  }
</script>
