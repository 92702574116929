<template>
  <div
    class="o-message"
    :class="[`o-message--${type}`]"
  >
    {{ message }}
  </div>
</template>
<script>
  import { ERROR } from '@/assets/javascript/enums/message-types'

  export default {
    props: {
      message: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ERROR
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
