<template>
  <div class="c-google-login">
    <div class="c-google-login__heading-wrapper">
      <span class="o-icon o-icon--google_login c-google-login__icon" />
      <h1 class="c-google-login__heading">
        {{ $t('googleLogin.heading') }}
      </h1>
    </div>
  </div>
</template>

<script>
  import {
    GOOGLE
  } from '@/assets/javascript/enums/login-types'

  import errorMapper from '@/assets/javascript/helpers/error-mapper.js'

  export default {
    name: 'GoogleLogin',
    computed: {
      user () {
        return this.$store.state.user.data
      }
    },
    beforeDestroy () {
      if (this.user?.status?.missingAgreements) {
        this.$popup.show(this.$dict.popups.ACCEPT_CONSENTS)
      }
    },
    mounted () {
      const code = this.$route.query.code
      this.$loader.show(this.$el)
      if (code) {
        this.$store.dispatch('user/LOGIN_SOCIAL', {
          externalToken: code,
          externalAuthProvider: GOOGLE
        })
          .then(() => {
            this.errors = []
            this.$store.cache.dispatch('user/GET_USER_CTX')
            this.$toast.success(this.$t('googleLogin.success'))

            if (this.user.profile?.forceEdit) {
              return this.$router.push({ name: this.$dict.routes.EDIT_PROFILE })
            }

            const redirect = sessionStorage.getItem('redirectURL')

            if (redirect) {
              return this.$router.push(redirect)
            }

            this.$router.push({ name: this.$dict.routes.HOME })
          })
          .catch((error) => {
            return errorMapper(this.$t.bind(this), error)
              .then((errors) => {
                this.$toast.error(errors.global)
                this.$router.push({ name: this.$dict.routes.LOGIN })
              })
          })
      } else {
        this.$toast.error(this.$t('googleLogin.error'))
        this.$router.push({ name: this.$dict.routes.LOGIN })
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
