<template>
  <section>
    <h1 class="c-broadcast-confirmation__title">
      {{ $t('broadcast.confirmation.title') }}
    </h1>
    <p>
      {{ $t('broadcast.confirmation.description') }}
    </p>
    <div class="c-broadcast-confirmation__buttons-container">
      <button
        class="o-button"
        @click="accept"
      >
        {{ $t('buttons.yes') }}
      </button>
      <button
        class="o-button o-button--secondary"
        @click="cancel"
      >
        {{ $t('buttons.no') }}
      </button>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'BroadcastConfirmation',
    methods: {
      accept () {
        this.$router.push({ name: this.$dict.routes.BROADCAST })
        this.$emit('close')
      },
      cancel () {
        this.$emit('close')
      }
    }
  }
</script>
<style src="./styles.scss" lang="scss" />
