<template>
  <div class="o-input__wrapper">
    <the-mask
      ref="mask"
      :value="value"
      autocomplete="off"
      class="o-input o-input--no-margin"
      :mask="mask"
      :placeholder="placeholder"
      type="tel"
      :class="{ 'o-input--success': isValid, 'o-input--error': hasError }"
      :masked="true"
      @input="checkInput($refs.mask.$el)"
      @paste="checkInput($refs.mask.$el)"
    />
    <span
      class="o-input__icon"
      :class="{ 'o-input__icon--success o-icon--check': isValid, 'o-input__icon--error o-icon--caution': hasError }"
    />
  </div>
</template>
<script>
  import { TheMask } from 'vue-the-mask'
  import inputMixin from '@/mixins/input-mixin'

  export default {
    name: 'MaskedInput',
    components: {
      TheMask
    },
    mixins: [
      inputMixin
    ],
    props: {
      placeholder: {
        type: String,
        required: true
      },
      mask: {
        type: String,
        required: true
      },
      value: {
        type: String,
        default: ''
      }
    },
    watch: {
      isValid: function () {
        this.$emit('changedValidity', this.isValid)
      }
    }
  }
</script>
<style lang="scss" src="../input/styles.scss"></style>
