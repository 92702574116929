<template>
  <div class="c-filters">
    <div class="c-filters__dropdowns">
      <h3 class="c-filters__heading">
        {{ $t('filters.ageCategory') }}:
      </h3>
      <v-multiselect
        v-model="form.ratingFilters"
        class="o-select"
        :custom-label="getLabelFromObject"
        :options="ratingFilters"
        :placeholder="$t('components.select.select')"
        :deselect-label="$t('components.select.deselect')"
        select-label=""
        selected-label=""
      >
        <span slot="noResult">
          {{ $t('components.select.noResults') }}
        </span>
      </v-multiselect>
    </div>
    <div class="c-filters__dropdowns">
      <h3 class="c-filters__heading">
        {{ $t('filters.sortBy') }}:
      </h3>
      <v-multiselect
        v-model="form.sortBy"
        class="o-select"
        :custom-label="getLabelFromObject"
        :options="sortingOptions"
        :placeholder="$t('components.select.select')"
        :deselect-label="$t('components.select.deselect')"
        select-label=""
        selected-label=""
      >
        <span slot="noResult">
          {{ $t('components.select.noResults') }}
        </span>
      </v-multiselect>
    </div>
  </div>
</template>

<script>
  import VMultiselect from 'vue-multiselect'
  import {
    FILTER_Y7,
    FILTER_Y12,
    FILTER_Y16,
    FILTER_Y18
  } from '@/components/filters/enums'

  export default {
    name: 'Filters',
    components: {
      VMultiselect
    },
    data () {
      return {
        items: [],
        form: {
          sortBy: [],
          checkedFilters: [],
          ratingFilters: []
        },
        sortingOptions: [
          {
            label: this.$t('filters.select.sortBy.byTitle'),
            sorting: 'title',
            order: 'asc'
          },
          {
            label: this.$t('filters.select.sortBy.newest'),
            sorting: 'year',
            order: 'desc'
          },
          {
            label: this.$t('filters.select.sortBy.oldest'),
            sorting: 'year',
            order: 'asc'
          }
        ],
        ratingFilters: [
          {
            label: this.$t('filters.select.byAge.Y7'),
            age: FILTER_Y7
          },
          {
            label: this.$t('filters.select.byAge.Y12'),
            age: FILTER_Y12
          },
          {
            label: this.$t('filters.select.byAge.Y16'),
            age: FILTER_Y16
          },
          {
            label: this.$t('filters.select.byAge.Y18'),
            age: FILTER_Y18
          }
        ]
      }
    },
    watch: {
      form: {
        handler () {
          this.$emit('change', this.buildQuery())
        },
        deep: true
      }
    },
    methods: {
      getLabelFromObject ({ label }) {
        return label
      },
      buildQuery () {
        return {
          sort: this.form.sortBy?.sorting,
          order: this.form.sortBy?.order,
          vodFilter: this.form.checkedFilters,
          ratingFilter: this.form.ratingFilters?.age
        }
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
