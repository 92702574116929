<template>
  <div
    class="c-product-detail"
    :class="{ 'is-loading': initialLoading }"
    :style="background"
  >
    <player-store-provider
      v-if="productDetail.trailer && !isMobile"
      ref="player"
      :holder-id="$options.trailerPlayerInstance"
    >
      <div>
        <div
          :id="$options.trailerPlayerInstance"
          class="c-product-detail__trailer u-abs-center"
        />
        <div class="c-product-detail__trailer-controls">
          <ui-player-volume
            :hide-volume-bar="true"
            :show-volume-icon="true"
          />
        </div>
        <div
          v-if="productDetail.id"
          class="c-product-detail__content"
        >
          <tabs
            :tabs="tabs"
            :product="productDetail"
            @changed="changed"
          />
        </div>
      </div>
    </player-store-provider>
    <div
      v-else-if="productDetail.id"
      class="c-product-detail__content"
    >
      <tabs
        :tabs="tabs"
        :product="productDetail"
        @changed="changed"
      />
    </div>
  </div>
</template>
<script>
  import {
    BANNER,
    EPISODE,
    SERIAL,
    TRAILER
  } from '@/assets/javascript/enums/product-types'

  import {
    DETAILS,
    EPISODES,
    INFO
  } from '@/assets/javascript/enums/tabs'

  import Tabs from '@/components/tabs/index'
  import Details from './components/details'
  import Seasons from '@/components/seasons'
  import Info from './components/info'
  import UiPlayerVolume from 'atds-player-layout/src/components/controls/components/volume/index'

  import PlayerStoreProvider from 'atds-player-layout/src/providers/player-store/index'

  import { PlayerMuteMixin } from '@/components/player/mixins/mute'
  import { ASPECT_16X9 } from '@/assets/javascript/enums/aspect-ratios'

  import { breakpointGetters } from '@/assets/javascript/responsive'
  import { IS_MOBILE } from 'assets/javascript/responsive/enums'

  export default {
    name: 'ProductDetail',
    components: {
      UiPlayerVolume,
      PlayerStoreProvider,
      Tabs
    },
    mixins: [
      PlayerMuteMixin
    ],
    props: {
      product: {
        type: Object,
        default: () => ({})
      }
    },
    data () {
      return {
        playerState: {},
        productDetail: {},
        initialLoading: false,
        playerInstance: {}
      }
    },
    trailerPlayerInstance: 'detail-trailer',
    computed: {
      isMobile () {
        return breakpointGetters[IS_MOBILE]()
      },
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      },
      background () {
        const url = this.productDetail?.artworks?.[ASPECT_16X9]?.[0].mainUrl
        return {
          ...(url && { backgroundImage: `url(${url})` })
        }
      },
      tabs () {
        const info = {
          name: INFO,
          title: this.$t('tabs.info'),
          component: Info
        }
        const episodes = {
          name: EPISODES,
          title: this.$t('tabs.episodes'),
          component: Seasons
        }
        const details = {
          name: DETAILS,
          title: this.$t('tabs.details.details'),
          component: Details
        }
        if (this.productDetail.type === SERIAL) {
          return [episodes, info, details]
        }

        if (this.productDetail.type === BANNER) {
          return [info]
        }

        return [info, details]
      }
    },
    watch: {
      product (product) {
        this.init(product)
      }
    },
    mounted () {
      this.init(this.product)
      this.initialLoading = true
      this.$emit('loaded')
    },
    beforeDestroy () {
      this.$player.destroy(this.$options.trailerPlayerInstance)
    },
    methods: {
      getDetail (id, type) {
        const { playTime } = this.isLoggedIn ? this.$store.getters['scores/RATING'](id) || 0 : 0
        this.$loader.show(this.$el)
        this.$store.dispatch('products/GET', { id, type })
          .then(async (product) => {
            const serial = product.type === EPISODE
              ? await this.$store.dispatch('products/GET', { id: product.season.serial.id, type: SERIAL })
              : {}

            this.productDetail = { ...product, playTime, serial }
            this.$emit('loaded', product.id)
            this.$nextTick(async () => {
              await this.setupTrailer()
              this.playerInstance = this.$player.getPlayerInstance(this.$options.trailerPlayerInstance)
            })
          })
          .finally(() => {
            this.$loader.hide(this.$el)
            this.initialLoading = false
          })
      },
      setupTrailer (productDetail = this.productDetail) {
        const holderId = this.$options.trailerPlayerInstance
        const player = this.$player
        if (!productDetail.trailer) {
          player.destroy(holderId)
          return
        }
        const playerStore = this.$refs.player.$data.store
        this.playerState = playerStore.state
        const product = {
          id: productDetail.id,
          type: TRAILER
        }
        return player.play({ product, holderId, playerStore })
      },
      init (product) {
        const id = product?.id
        const type = product?.type
        if (id && type && type !== BANNER) {
          return this.getDetail(id, type)
        }

        if (id && type && type === BANNER) {
          this.productDetail = this.product
          return
        }

        this.productDetail = {}
      },
      async changed (season = {}) {
        const { elements } = season
        const firstEpisodeWithTrailer = elements?.find(episode => episode.trailer)

        if (firstEpisodeWithTrailer) {
          this.$set(this.productDetail, 'trailer', true)
          await this.$nextTick(async () => {
            await this.setupTrailer(firstEpisodeWithTrailer)
            this.playerInstance = this.$player.getPlayerInstance(this.$options.trailerPlayerInstance)
          })
        }
        this.$emit('tabChanged')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
