<template>
  <section class="c-tooltip">
    <svg
      class="c-tooltip__icon o-icon"
      @mouseover="show"
      @mouseleave="hide"
    >
      <use xlink:href="#icon-success" />
    </svg>
    <div
      v-if="active"
      class="c-tooltip__tooltip"
      :class="tooltipPosition"
    >
      <slot name="tooltip" />
    </div>
  </section>
</template>

<script>
  export default {
    name: 'Tooltip',
    props: {
      position: {
        type: String,
        default: 'top'
      }
    },
    data () {
      return {
        active: false
      }
    },
    computed: {
      tooltipPosition () {
        return `c-tooltip__tooltip--${this.position}`
      }
    },
    methods: {
      show () {
        this.active = true
      },
      hide () {
        this.active = false
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
