<template>
  <div
    v-if="active"
    class="c-se-banner"
    :class="className"
  >
    <svg
      class="c-se-banner__close o-icon o-icon--action"
      @click="close"
    >
      <use xlink:href="#icon-close" />
    </svg>
    <a
      :href="banner.urlWeb"
      class="c-se-banner__link"
      target="_blank"
      @click="clicked"
    >
      <div
        v-if="banner.imageUrl"
        class="c-se-banner__image-container"
      >
        <img
          class="c-se-banner__image u-abs-center"
          :src="banner.imageUrl"
        >
      </div>
      <p
        v-if="banner.content"
        class="c-se-banner__content"
        :class="{ 'c-se-banner__content--only': !banner.imageUrl }"
      >
        {{ banner.content }}
      </p>
    </a>
  </div>
</template>

<script>
  import {
    BOTTOM,
    CENTER,
    TOP
  } from '@/components/secondary-events/enums/content-positions'

  import {
    STATUS_PAUSE
  } from 'atds-player-layout/src/enums'

  import {
    CLICK,
    FOCUS,
    SECONDARY_START,
    SECONDARY_STOP
  } from '@/assets/javascript/dictionaries/gtmEventsActions'

  import {
    SECONDARY
  } from '@/assets/javascript/dictionaries/gtmEvents'

  import Viewport from '@/mixins/viewport-methods'
  import trackingMixin from '@/mixins/tracking-mixin'

  export default {
    name: 'BannerSE',
    inject: {
      playerStore: { default: '' }
    },
    mixins: [trackingMixin, Viewport],
    props: {
      event: {
        type: Object,
        required: true
      },
      product: {
        type: Object,
        default: () => ({})
      }
    },
    data () {
      return {
        active: false,
        banner: {}
      }
    },
    computed: {
      activeProfile () {
        return this.$store.getters['user/ACTIVE_PROFILE']
      },
      className () {
        const position = this.banner.position
        if (position === TOP || position === CENTER || position === BOTTOM) {
          const positionClass = position.toLowerCase()
          return `c-se-banner__${positionClass}`
        } else {
          const positionClass = position.split('_').join('-').toLowerCase()
          return `c-se-banner__${positionClass}`
        }
      }
    },
    created () {
      this.loadEvent()
    },
    beforeDestroy () {
      this.sendSecondaryTrackingData({
        action: SECONDARY_STOP,
        ...this.trackingSecondaryData
      })
    },
    methods: {
      getFullEvent (id, type) {
        this.$store.dispatch('items/GET_SECONDARY_EVENT', { id, type })
          .then(event => {
            this.banner = event
            const {
              id: itemId,
              since: displayTime,
              content: value,
              urlWeb: url
            } = event
            this.trackingSecondaryData = {
              videoSessionId: this.trackingSecondaryData.videoSessionId,
              progress: this.playerStore?.state?.progress,
              itemId: itemId.toString(),
              displayTime,
              value,
              url
            }
            this.sendSecondaryTrackingData({
              action: SECONDARY_START,
              ...this.trackingSecondaryData
            })
            this.active = true
          })
      },
      loadEvent () {
        const id = this.event.id
        const type = this.event.type.toLowerCase()
        this.getFullEvent(id, type)
      },
      close () {
        this.sendSecondaryTrackingData({
          action: CLICK,
          ...this.trackingSecondaryData
        })
        this.sendSecondaryTrackingData({
          action: SECONDARY_STOP,
          ...this.trackingSecondaryData
        })
        this.active = false
        this.unobserveElement()
      },
      clicked () {
        this.$store.dispatch('secondaryEventAnswers/ADD_BANNER_CLICK', { eventId: this.banner.id, answer: this.buildAnswer() })
        this.active = false
        this.playerStore.setPlayerStatus(STATUS_PAUSE)
        this.sendSecondaryTrackingData({
          action: CLICK,
          ...this.trackingSecondaryData
        })
      },
      buildAnswer () {
        return {
          token: this.activeProfile.token,
          profileId: this.activeProfile.id,
          eventId: this.banner.id,
          type: this.banner.type
        }
      },
      onViewportEnter () {
        this.sendSecondaryTrackingData({
          action: SECONDARY_START,
          ...this.trackingSecondaryData
        })
      },
      onMouseEnter () {
        this.sendTrackingEventWithTimeout(SECONDARY, {
          action: FOCUS,
          ...this.trackingSecondaryData
        })
      },
      onMouseLeave () {
        this.resetTrackingEventTimeout()
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
