<template>
  <div class="c-action-buttons__button">
    <a
      class="c-action-buttons__icon-link c-action-buttons__icon-link--padding"
      :href="emailLink"
      target="_blank"
    >
      <svg class="o-icon">
        <use xlink:href="#icon-mail" />
      </svg>
      <div class="c-action-buttons__title">
        {{ $t('buttons.email') }}
      </div>
    </a>
  </div>
</template>

<script>
  // import { ACTION } from '@/assets/javascript/dictionaries/gtmEvents'
  // import { SHARE_EMAIL } from '@/assets/javascript/dictionaries/gtmEventsActions'

  export default {
    props: {
      product: {
        type: Object,
        required: true
      },
      url: {
        type: String,
        required: true
      }
    },
    computed: {
      productTitle () {
        return this.product.title
      },
      productId () {
        return this.product.id
      },
      productLead () {
        return this.product.lead
      },
      subject () {
        return this.$t('components.share.email.subject', { title: this.productTitle })
      },
      body () {
        return this.$t('components.share.email.body', { lead: this.productLead, url: this.url })
      },
      emailLink () {
        return `mailto:?subject=${this.subject}&body=${this.body}`
      }
    }
    // methods: {
    //   sendTrackingDataOnClick () {
    //     const product = {
    //       id: this.productId
    //     }
    //     this.$tracking.events.send(ACTION, {
    //       product,
    //       action: SHARE_EMAIL
    //     })
    //   }
    // }
  }
</script>
