<template>
  <main>
    <div
      id="meet"
      class="c-transmission"
    />
    <secondary-events :type="$options.STUDIO_ITEM" />
  </main>
</template>
<script>
  import isSafari from '@/assets/javascript/helpers/is-safari'
  import { loadExternalFile } from '@/assets/javascript/helpers/load-external-script'
  import SecondaryEvents from '@/components/secondary-events'
  import {
    PARTICIPANT,
    STUDIO,
    WAITING_ROOM
  } from '@/pages/transmission/enums'

  export default {
    STUDIO_ITEM: STUDIO.toLowerCase(),
    isSafari,
    components: {
      SecondaryEvents
    },
    data () {
      return {
        domain: process.env.JITSI_DOMAIN,
        loaded: false,
        options: {
          roomName: ''
        },
        roomNameHuman: this.$t('views.myAccount.broadcast.waitingRoom')
      }
    },
    computed: {
      avatar () {
        return this.$store.getters['broadcast/broadcastProfile']?.photoUrl
      },
      isBanned () {
        return this.$store.getters['broadcast/broadcastProfile']?.banned
      },
      isBroadcasting () {
        return this.$store.getters['epg/LIVE_BROADCAST_CURRENT_EPG_PROGRAMME']?.liveBroadcasting
      },
      liveProgrammeId () {
        return this.$store.getters['epg/LIVE_BROADCAST_CURRENT_EPG_PROGRAMME']?.id
      },
      userEmail () {
        return this.$store.state.user.data.email
      },
      userId () {
        return this.$store.state.user.data.id
      },
      userName () {
        return this.$store.getters['broadcast/broadcastProfile']?.name
      }
    },
    watch: {
      '$store.state.epg.currentProgramme' (newProgramme, oldProgramme) {
        if (oldProgramme.id && newProgramme.id !== oldProgramme.id) {
          this.$toast.success(this.$t('broadcast.thankYou'))
          return this.$router.push({ name: this.$dict.routes.HOME })
        }
      }
    },
    created () {
      this.$popup.action(this.$dict.popups.BROADCAST_CONSENT)
        .then(() => {
          this.loadJitsi()
        })
        .catch(() => this.$router.push({ name: this.$dict.routes.HOME }))
    },
    beforeDestroy () {
      const jitsiAPI = window.JITSI_API

      if (jitsiAPI) {
        jitsiAPI.dispose()
      }

      this.$loader.hide(this.$el.parentElement)
    },
    methods: {
      async checkAccess () {
        if ((!this.$route.params.canAccess || !this.isBroadcasting) && this.liveProgrammeId) {
          return await this.$store.dispatch('broadcast/IS_USER_ALLOWED', this.liveProgrammeId)
        }

        return true
      },
      getAccessToken (room) {
        return this.$store.dispatch('authorizations/GET_ACCESS_TOKEN', {
          itemId: this.liveProgrammeId,
          type: STUDIO,
          room
        })
      },
      async loadJitsi () {
        try {
          if (!this.liveProgrammeId) {
            await this.$store.dispatch('epg/GET_LIVE_NOW', { id: process.env.DEFAULT_LIVE_CHANNEL, since: this.$time() })
          }

          if (!this.userName) {
            await this.$store.dispatch('broadcast/GET_BROADCAST_PROFILE')
          }

          if (!await this.checkAccess()) {
            this.$toast.error(this.$t('broadcast.errors.directAccess'))
            return this.$router.push({ name: this.$dict.routes.HOME })
          }

          if (this.isBanned) {
            this.$toast.error(this.$t('broadcast.errors.banned'))
            return this.$router.push({ name: this.$dict.routes.HOME })
          }

          if (!this.$options.isSafari() && Notification.permission !== 'granted') {
            this.$toast.permissionDenied(this.$t('notifications.secondaryEvents'))
          }

          this.$loader.show(this.$el.parentElement)
          this.options = {
            onload: () => {
              this.loaded = true
              this.$loader.hide(this.$el.parentElement)
              const executeCommand = window.Jitsi_API.executeCommand
              executeCommand('displayName', this.userName)
              executeCommand('avatarUrl', this.avatar)
            },
            roomName: WAITING_ROOM,
            parentNode: document.querySelector('#meet'),
            userInfo: {
              email: JSON.stringify({
                email: this.userEmail,
                name: this.userName,
                id: `${this.userId}_usr`,
                programmeId: this.liveProgrammeId,
                role: PARTICIPANT
              }),
              displayName: this.userName
            },
            noSSL: false,
            jwt: await this.getAccessToken(WAITING_ROOM)
          }
          loadExternalFile(process.env.JITSI_EXTERNAL_API_FILE)
            .then(() => {
              window.Jitsi_API = new window.JitsiMeetExternalAPI(this.domain, this.options)
            })
        } catch {
          this.$toast.error(this.$t('broadcast.errors.cannotFetchProfile'))
        }
      },
      reloadJitsi () {
        window.Jitsi_API.dispose()
        window.Jitsi_API = new window.JitsiMeetExternalAPI(this.domain, this.options)
      }
    },
    head () {
      return {
        title: this.$t('views.myAccount.broadcast.liveRoom')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
