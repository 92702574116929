<template>
  <form
    class="c-pin-input"
    @keyup="inputFocusHelper"
  >
    <div
      v-for="index in pinLength"
      :key="index"
      class="c-pin-input__container"
    >
      <v-input
        v-model="values[index - 1]"
        :max-length="maxLength"
        :type="type"
        pin-input-class="o-input__pin"
        class="c-pin-input--no-margin"
        @focus="inputFocusSelection"
      />
    </div>
  </form>
</template>

<script>
  import VInput from '@/components/input'
  import {
    BACKSPACE,
    ARROW_LEFT,
    ARROW_RIGHT,
    LEFT,
    RIGHT
  } from '@/assets/javascript/enums/key-codes'

  export default {
    name: 'PinInput',
    components: {
      VInput
    },
    props: {
      pinLength: {
        type: Number,
        default: 8
      },
      type: {
        type: String,
        default: 'text'
      }
    },
    data () {
      return {
        maxLength: 1,
        values: []
      }
    },
    computed: {
      filteredValues () {
        return this.values.filter((n) => n).map((val) => val.trim())
      }
    },
    watch: {
      values: 'checkCompleted'
    },
    mounted () {
      this.$el.querySelector('input').focus()
    },
    methods: {
      checkCompleted () {
        const values = this.filteredValues
        if (values.length === this.pinLength) {
          this.$emit('completed', values.join(''))
        }
      },
      inputFocusSelection (e) {
        const target = e.target
        target.select()
      },
      inputFocusHelper (e) {
        const target = e.target
        const length = target.value.length
        const keyCode = e.key

        let sibling

        const backward = [BACKSPACE, ARROW_LEFT, LEFT]
        const forward = [ARROW_RIGHT, RIGHT]

        const targetParent = target.parentNode.parentNode

        if (backward.includes(keyCode)) {
          sibling = targetParent.previousElementSibling
        } else if (forward.includes(keyCode) || length) {
          sibling = targetParent.nextElementSibling
        }

        if (sibling) {
          const input = sibling.querySelector('input')

          if (input) {
            input.focus()
            input.select()
          }
        }
      }
    }
  }
</script>
<style src="./styles.scss" lang="scss"/>
