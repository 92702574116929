<template>
  <div class="c-payment-status">
    <div
      v-if="confirmed"
      class="c-payment-success"
    >
      <div class="c-payment-success__container-top">
        <h2 class="c-payment-success__title">
          {{ $t('payments.popup.success.title') }}
        </h2>
      </div>
      <div class="c-payment-success__container-bottom">
        <div class="c-payment-success__container-icon">
          <svg class="c-payment-success__icon o-icon">
            <use xlink:href="#icon-ok_1" />
          </svg>
        </div>
        <div class="c-payment-success__description">
          {{ message }}
          <span class="c-payment-success__description-title">
            {{ product.title }}.
          </span>
          {{ $t('payments.popup.success.description2') }}
        </div>
        <div class="c-payment-success__container-cta">
          <button
            v-if="canShowRedirect"
            class="c-payment-success__button o-button o-button--transparent o-button--success"
            @click="redirectToContent"
          >
            {{ $t('buttons.watch') }}
          </button>
          <button
            class="o-button o-button--transparent o-button--success"
            @click="redirectToHome"
          >
            {{ $t('buttons.homepage') }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="error"
      class="c-payment-failure"
    >
      <div class="c-payment-failure__container-top">
        <h2 class="c-payment-failure__title">
          {{ $t('payments.popup.failure.title') }}
        </h2>
      </div>
      <div class="c-payment-failure__container-bottom">
        <div class="c-payment-failure__container-icon">
          <svg class="c-payment-failure__icon o-icon">
            <use xlink:href="#icon-failure" />
          </svg>
        </div>
        <div class="c-payment-failure__description">
          {{ $t('payments.popup.failure.description') }}
        </div>
        <div class="c-payment-failure__container-cta">
          <button
            class="o-button o-button--transparent o-button--failure"
            @click="redirectToHome"
          >
            {{ $t('buttons.homepage') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    ECOMMERCE
  } from '@/assets/javascript/dictionaries/gtmEvents'
  import {
    BLOCKED,
    CONFIRMED,
    WAITING,
    REJECTED
  } from '@/assets/javascript/enums/payment-status'
  import { BUNDLE, EPISODE, SERIAL } from 'assets/javascript/enums/product-types'

  import priceFormat from '@/assets/javascript/filters/price-format'

  export default {
    name: 'PaymentConfirmed',
    data () {
      return {
        product: {},
        cyclicPaymentId: this.$route.params.cyclicPaymentId,
        paymentId: this.$route.query.paymentId,
        confirmed: false,
        error: false,
        intervalStatusCheck: 0
      }
    },
    computed: {
      canShowRedirect () {
        return this.product.type !== BUNDLE ||
          (this.product.type === BUNDLE && this.$cookie.get('selected-product'))
      },
      message () {
        return `${this.$t('payments.popup.success.description1')} ${this.cyclicPaymentId
          ? this.$t('payments.popup.success.bundle')
          : '' }: `
      },
      productTitle () {
        return this.product ? `${this.product.title}` : `${this.$t('payments.popup.success.titlePlaceholder')}`
      }
    },
    created () {
      this.findPaidProduct()
      this.getPayment()
    },
    mounted () {
      this.$nextTick(() => {
        this.$loader.show(this.$root.$el)
      })
    },
    methods: {
      findPaidProduct () {
        if (this.cyclicPaymentId) {
          return this.getCyclicPayments()
        }
        return this.getPayments()
      },
      getCyclicPayments () {
        return this.$store.dispatch('user/GET_CYCLIC_PAYMENTS', { firstResult: 0 })
          .then(({ items }) => {
            const getCyclicPayment = items.find(payment => payment.id === parseInt(this.cyclicPaymentId))
            const {
              product,
              price,
              nextChargeAt,
              createdAt
            } = getCyclicPayment

            if (!getCyclicPayment) {
              this.error = true
              this.$nextTick(() => {
                this.$loader.hide(this.$root.$el)
              })
              return
            }

            const cycle = `${this.$time(this.$time(nextChargeAt)).diff(this.$time(createdAt), 'day')} days`
            this.product = { ...product, price: priceFormat(price), cycle }
            this.$tracking.events.send(ECOMMERCE, { product: this.product })
          })
      },
      getPayments () {
        return this.$store.dispatch('user/GET_PAYMENTS', { firstResult: 0 })
          .then(({ items }) => {
            const { product, price } = items?.find(payment => payment.id === parseInt(this.paymentId)) || {}

            if (!product) {
              this.error = true
              this.$nextTick(() => {
                this.$loader.hide(this.$root.$el)
              })
              return
            }

            this.product = { ...product, price: priceFormat(price) }
            this.$tracking.events.send(ECOMMERCE, { product: this.product })
          })
      },
      getPayment () {
        const checkingPaymentStatus = setTimeout(() => {
          this.intervalStatusCheck++
          this.$store.dispatch('payments/GET_PAYMENT', this.paymentId)
            .then(async ({ status }) => {
              this.status = status
              if (status === CONFIRMED && this.intervalStatusCheck < 10) {
                const dispatch = this.$store.dispatch
                clearTimeout(checkingPaymentStatus)
                this.confirmed = true
                this.intervalStatusCheck = 0

                await Promise.all([
                  dispatch('user/GET_DETAILS'),
                  dispatch('user/GET_AVAILABLE_PRODUCTS')
                ])

                this.$nextTick(() => {
                  this.$loader.hide(this.$root.$el)
                })
              } else if (status === BLOCKED || status === REJECTED || (status === WAITING && this.intervalStatusCheck >= 10)) {
                clearTimeout(checkingPaymentStatus)
                this.error = true
                this.intervalStatusCheck = 0
                this.$loader.hide(this.$root.$el)
                this.$nextTick(() => {
                  this.$loader.hide(this.$root.$el)
                })
              } else {
                this.getPayment()
              }
            })
            .catch(() => {
              clearTimeout(checkingPaymentStatus)
              this.intervalStatusCheck = 0
              this.error = true
              this.$nextTick(() => {
                this.$loader.hide(this.$root.$el)
              })
            })
        }, 700)
        return checkingPaymentStatus
      },
      async redirectToContent () {
        const selectedProduct = this.$cookie.get('selected-product')

        if (selectedProduct) {
          this.$cookie.delete('selected-product')
          return this.$router.replace(selectedProduct)
        }

        if (this.product.type === SERIAL) {
          const serialId = this.product.id
          const seasons = await this.$store.dispatch('products/GET_SERIAL_SEASONS', serialId)
          if (seasons[0]) {
            const episodes = await this.$store.dispatch('products/GET_SERIAL_EPISODES', {
              serialId,
              seasonId: seasons[0].id
            })

            if (episodes.length) {
              return this.$router.replace({
                name: this.$dict.routes.WATCH,
                params: {
                  type: EPISODE,
                  productId: episodes[0].id
                }
              })
            }
          }
        }

        await this.$router.replace({
          name: this.$dict.routes.WATCH,
          params: {
            type: this.product.type.toLowerCase(),
            productId: this.product.id
          }
        })
      },
      redirectToHome () {
        this.$router.replace({
          name: this.$dict.routes.HOME
        })
      }
    },
    head () {
      return {
        title: this.$t('views.payments.paymentStatus')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
