<template>
  <popup
    v-if="active"
    :is-card-popup="isCardPopup"
    :is-wide="isWide"
    :disable-close="disableClose"
    @close="close"
  >
    <component
      :is="popup"
      :promise="promise"
      v-bind="props"
      @close="close"
    />
  </popup>
</template>
<script>
  import {
    PopupsDictionary
  } from '@/assets/javascript/dictionaries/popups'

  import Vue from 'vue'
  import Popup from '@/components/popup'
  import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

  import AcceptConsents from '@/components/popups/accept-consents'
  import AddCard from '@/components/popups/add-card'
  import AgeConfirmation from '@/components/popups/age-confirmation'
  import AddVoucher from '@/components/popups/add-voucher'
  import BroadcastConfirmation from '@/components/popups/broadcast-confirmation'
  import BroadcastConsent from '@/components/popups/broadcast-consent'
  import CancelSubscription from '@/components/popups/cancel-subscription'
  import ChangePassword from '@/components/popups/change-password'
  import ChangePhone from '@/components/popups/change-phone'
  import ConsentsNotice from '@/components/popups/consents-notice'
  import DeletePhone from '@/components/popups/delete-phone'
  import Document from '@/components/popups/document'
  import PaymentSuccess from '@/components/popups/payment-success'
  import PaymentFailure from '@/components/popups/payment-failure'
  import ProductInBundle from '@/components/popups/product-in-bundle'
  import Ranking from '@/components/popups/ranking'
  import RegisterSuccess from '@/components/popups/register-success'
  import RemoveCard from '@/components/popups/remove-card'
  import RemoveDevice from '@/components/popups/remove-device'
  import RemoveProfile from '@/components/popups/remove-profile'
  import SessionExpired from '@/components/popups/session-expired'
  import VerifyPhone from '@/components/popups/verify-phone'
  import BuyProduct from '@/components/popups/buy-product'
  import CMP from '@/components/popups/cmp'

  export default {
    components: {
      Popup,
      [PopupsDictionary.ACCEPT_CONSENTS]: AcceptConsents,
      [PopupsDictionary.ADD_CARD]: AddCard,
      [PopupsDictionary.AGE_CONFIRMATION]: AgeConfirmation,
      [PopupsDictionary.ADD_VOUCHER]: AddVoucher,
      [PopupsDictionary.BROADCAST_CONFIRMATION]: BroadcastConfirmation,
      [PopupsDictionary.BROADCAST_CONSENT]: BroadcastConsent,
      [PopupsDictionary.CANCEL_SUBSCRIPTION]: CancelSubscription,
      [PopupsDictionary.CHANGE_PASSWORD]: ChangePassword,
      [PopupsDictionary.CHANGE_PHONE]: ChangePhone,
      [PopupsDictionary.CONSENTS_NOTICE]: ConsentsNotice,
      [PopupsDictionary.DELETE_PHONE]: DeletePhone,
      [PopupsDictionary.DOCUMENT]: Document,
      [PopupsDictionary.PAYMENT_SUCCESS]: PaymentSuccess,
      [PopupsDictionary.PAYMENT_FAILURE]: PaymentFailure,
      [PopupsDictionary.PRODUCT_IN_BUNDLE]: ProductInBundle,
      [PopupsDictionary.RANKING]: Ranking,
      [PopupsDictionary.REGISTER_SUCCESS]: RegisterSuccess,
      [PopupsDictionary.REMOVE_CARD]: RemoveCard,
      [PopupsDictionary.REMOVE_DEVICE]: RemoveDevice,
      [PopupsDictionary.REMOVE_PROFILE]: RemoveProfile,
      [PopupsDictionary.SESSION_EXPIRED]: SessionExpired,
      [PopupsDictionary.VERIFY_PHONE]: VerifyPhone,
      [PopupsDictionary.BUY_PRODUCT]: BuyProduct,
      [PopupsDictionary.CMP]: CMP
    },
    data () {
      return {
        active: false,
        props: {},
        promise: null,
        popup: false,
        queue: []
      }
    },
    computed: {
      isCardPopup () {
        return this.props.isCardPopup
      },
      isWide () {
        return this.props.isWide
      },
      disableClose () {
        return this.props.disableClose
      }
    },
    beforeCreate () {
      Vue.prototype.$popup = this
    },
    methods: {
      action (popupName, props) {
        let promiseHandler

        const promise = new Promise((resolve, reject) => {
          promiseHandler = { resolve, reject }
        })

        this.show(popupName, props, promiseHandler)

        return promise
      },
      show (popupName, props = {}, promise = null) {
        if (this.active) {
          this.queue.push({ popupName, props, promise })
          return
        }

        this.props = props
        this.popup = popupName
        this.promise = promise
        this.active = true
        this.$nextTick(() => this.active && disableBodyScroll(this.$el))
      },
      close () {
        this.reset()
        enableBodyScroll(this.$el)
        this.$store.commit('layout/SET_LAYOUT_BLUR', false)
        this.active = false
        this.buy = false
        this.popup = false

        if (this.queue.length > 0) {
          const { popupName, props, promise } = this.queue.shift()
          this.show(popupName, props, promise)
        }
      },
      reset () {
        this.props = {}

        if (!this.promise) {
          return
        }

        this.promise.reject()
        this.promise = null
      }
    }
  }
</script>
