<template>
  <div
    v-if="section.elements.length"
    class="c-banner-with-bullets"
  >
    <slider-wrapper
      :section="section"
      :options="{ startIndex }"
      @changed="setActiveIndex"
    >
      <template v-slot="{ data }">
        <banner :product="{ ...data.item, sectionId: section.id, sectionPosition: section.sectionPosition }">
          <template v-slot:actionButtons>
            <action-buttons
              v-if="data.item"
              :product="data.item"
            />
          </template>
        </banner>
      </template>
    </slider-wrapper>
    <div
      v-if="isLarge"
      class="c-banner-with-bullets__bullets row align-bottom expanded collapse"
    >
      <div class="column small-8">
        <banner-bullets
          class="c-banner-bullets__bullets"
          :section="section"
          :selected-index="activeIndex"
          @selectProduct="receiveSelectProduct"
        />
      </div>
      <div
        v-if="hasCurrentProgramme"
        class="c-banner__player-wrapper column small-4"
      >
        <div class="c-banner__player-column">
          <epg-list
            :key="componentKey"
            :is-open="isEpgOpen"
            @toggled="toggleEpg"
          />
          <player-store-provider
            ref="player"
            holder-id="js-live-window"
            :modules="$options.playerStoreModules"
          >
            <small-player />
          </player-store-provider>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import BannerBullets from '@/components/banner-bullets/index'
  import SmallPlayer from '@/components/small-player/index'
  import EpgList from '@/components/epg-list/index'
  import Banner from '@/components/banner/index'
  import SliderWrapper from '@/components/slider-wrapper/index'
  import ActionButtons from '@/components/action-buttons/index'
  import PlayerStoreProvider from 'atds-player-layout/src/providers/player-store'
  import { LiveStore } from 'atds-player-layout/src/store/live'
  import { PROGRAMME } from '@/assets/javascript/enums/product-types'
  import { breakpointGetters } from 'assets/javascript/responsive'
  import { IS_LARGE } from 'assets/javascript/responsive/enums'

  export default {
    PROGRAMME,
    name: 'BannerWithBulletsAndPlayer',
    components: {
      ActionButtons,
      SliderWrapper,
      Banner,
      BannerBullets,
      EpgList,
      SmallPlayer,
      PlayerStoreProvider
    },
    playerStoreModules: [
      LiveStore
    ],
    props: {
      section: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        componentKey: 0,
        isEpgOpen: false,
        startIndex: 0,
        activeIndex: 0,
        polling: null,
        timeout: null
      }
    },
    computed: {
      hasCurrentProgramme () {
        return this.$store.state.epg.currentProgramme.id
      },
      isLarge () {
        return breakpointGetters[IS_LARGE]()
      }
    },
    beforeDestroy () {
      clearTimeout(this.timeout)
    },
    created () {
      this.rerenderEpg()
    },
    methods: {
      receiveSelectProduct (index) {
        this.startIndex = index
      },
      rerenderEpg () {
        this.timeout = setTimeout(() => this.rerenderEpg(), (60 - new Date().getSeconds()) * 1000)
        this.componentKey += 1
      },
      setActiveIndex (index) {
        this.activeIndex = index
      },
      toggleEpg () {
        this.isEpgOpen = !this.isEpgOpen
      }
    }
  }
</script>
