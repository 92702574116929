<template>
  <div class="c-watch-future">
    <span
      class="c-player__btn--back o-icon o-icon--back"
      @click="close"
    />
    <div class="c-watch-future__time">
      <h2>{{ $t('player.watchFuture') }}</h2>
      <h1>{{ timeLeft }}</h1>
    </div>
    <banner
      :product="product"
      :single-banner="true"
    />
    <slot />
  </div>
</template>
<script>
  import Banner from '@/components/banner/index'
  export default {
    name: 'WatchFuture',
    components: { Banner },
    props: {
      product: {
        type: Object,
        default: () => ({}),
        required: true
      }
    },
    data () {
      return {
        timeLeft: ''
      }
    },
    mounted () {
      this.interval = setInterval(() => {
        this.timeLeft = this.getTimeToProgramme()
      }, 1000)
    },
    beforeDestroy () {
      clearInterval(this.interval)
    },
    methods: {
      close () {
        this.$emit('close')
      },
      getTimeToProgramme () {
        const since = this.product.since
        const time = this.$time
        const timeToEvent = time(since).diff(time())
        const daysLeft = time(since).format('DD') - time().format('DD')
        const timeLeft = time()
          .startOf('day')
          .add(timeToEvent)
          .format('H[h] mm[min.]')

        if (timeToEvent <= 0) {
          this.$emit('countdownFinished')
          return
        }

        return ` ${(daysLeft && this.$t('player.days') + daysLeft + ' i ') || ''}  ${timeLeft}`
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
