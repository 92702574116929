<template>
  <success-view>
    <svg class="c-success__icon o-icon">
      <use xlink:href="#icon-mail" />
    </svg>
    <h1 class="c-success__heading">
      {{ $t('views.register.heading') }}
    </h1>
    <p>{{ $t('views.register.subheading') }}</p>
  </success-view>
</template>

<script>
  import SuccessView from '@/components/success-view/index'

  export default {
    components: { SuccessView }
  }
</script>
