<template>
  <div
    class="o-button"
    @click="redirect"
  >
    {{ buttonText }}
  </div>
</template>

<script>
  export default {
    props: {
      product: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      buttonText () {
        return this.$store.getters['user/LOGGED_IN']
          ? this.$t('buttons.buy')
          : this.$t('buttons.loginAndBuy')
      }
    },
    methods: {
      redirect () {
        const { id, type } = this.product

        return this.$router.push({
          name: this.$dict.routes.BUY_AND_WATCH,
          params: { productId: id, type: type?.toLowerCase() }
        })
      }
    }
  }
</script>
