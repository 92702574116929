<template>
  <section>
    <div
      v-if="documentJSON.message"
      class="c-popup-document"
    >
      <h1 class="c-popup-document__heading">
        {{ documentJSON.title }}
      </h1>
      <p v-dompurify-html="documentJSON.message" />
    </div>
    <div
      v-else
      v-dompurify-html="document"
      class="c-popup-document"
    />
  </section>
</template>

<script>
  export default {
    props: {
      document: {
        type: String,
        default: ''
      },
      documentJSON: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss" />
