<template>
  <div class="c-ranking row expanded collapse">
    <h1 class="c-ranking__heading column small-12">
      {{ $t('broadcast.ranking.heading') }}
    </h1>
    <separator class="o-separator--horizontal o-separator--horizontal-small-margin" />
    <div
      v-for="{id, photoUrl, name, broadcastParticipationCount} in users"
      :key="id"
      class="column small-12"
    >
      <div class="row expanded collapse c-ranking__user-container">
        <div class="column small-3">
          <broadcast-photo
            :url="photoUrl"
            :alt="name"
            :edit="false"
            class="c-ranking__photo"
          />
        </div>
        <div class="column small-9">
          <h3 class="c-ranking__name">
            {{ name }}
          </h3>
          <div class="c-ranking__count">
            {{ $t('broadcast.ranking.broadcastCount') }}: {{ broadcastParticipationCount }}
          </div>
        </div>
      </div>
      <separator class="o-separator--horizontal o-separator--horizontal-small-margin" />
    </div>
    <div class="column small-12">
      <div class="c-ranking__button-container">
        <button
          class="o-button o-button--secondary"
          @click="cancel"
        >
          {{ $t('buttons.close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import BroadcastPhoto from '@/components/broadcast-photo/index'
  import Separator from '@/components/separator/index'

  export default {
    name: 'Ranking',
    components: { Separator, BroadcastPhoto },
    data () {
      return {
        users: []
      }
    },
    mounted () {
      this.$loader.show(this.$el.parentElement)
      this.$store.dispatch('broadcast-profiles/GET_BROADCAST_PROFILES', {})
        .then(users => {
          this.users = users
        })
        .catch(() => {})
        .finally(() => this.$loader.hide(this.$el.parentElement))
    },
    methods: {
      cancel () {
        this.$emit('close')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
