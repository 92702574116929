<template>
  <section class="c-profile">
    <div class="row expanded collapse">
      <div class="c-profile__subtitle column small-12">
        {{ $t('views.myAccount.profile') }}
      </div>
      <div
        v-if="!mobileOrTablet"
        class="column large-1"
      >
        <div class="row collapse">
          <div class="column small-12">
            <div @click="editProfile">
              <avatar
                :profile="activeProfile"
                class="c-profile__avatar"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column small-12 large-3">
        <div class="row expanded collapse">
          <div class="c-profile__label column small-12">
            {{ $t('views.myAccount.username') }}
          </div>
          <div class="c-profile__text column small-12">
            {{ userName }}
          </div>
        </div>
      </div>
      <div class="column small-12 large-4">
        <div class="row expanded collapse">
          <div class="c-profile__label column small-12 c-profile__email">
            {{ $t('common.email') }}
          </div>
          <div class="c-profile__text column small-12">
            {{ userEmail }}
          </div>
        </div>
      </div>
      <div class="c-profile__button-container column small-12 large-4">
        <div
          class="o-button o-button--secondary o-button--fixed-width"
          @click="editProfile"
        >
          {{ $t('views.myAccount.editProfile') }}
        </div>
      </div>
      <div class="c-profile__column column small-12 large-1" />
      <div class="c-profile__column column small-12 large-7">
        <div class="row expanded collapse">
          <div class="c-profile__label column small-12">
            {{ $t('common.password') }}
          </div>
          <div class="c-profile__text column small-12">
            &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
          </div>
        </div>
      </div>
      <div class="c-profile__button-container column small-12 large-4">
        <button
          class="o-button o-button--secondary o-button--fixed-width"
          @click="$popup.show($dict.popups.CHANGE_PASSWORD)"
        >
          {{ $t('views.myAccount.changePassword') }}
        </button>
      </div>
      <div class="c-profile__column column small-12 large-1" />
      <div class="c-profile__column column small-12 large-5">
        <div class="row expanded collapse">
          <div class="c-profile__label column small-12">
            {{ $t('common.phone') }}
          </div>
          <div class="c-profile__text column small-12">
            {{ userPhone || '-' }}
          </div>
          <div
            v-if="isPhoneTimeout"
            class="c-profile__error"
          >
            {{ $t('views.myAccount.phoneVerificationTimeout') }}
          </div>
        </div>
      </div>
      <div class="c-profile__button-container column small-12 large-6">
        <button
          v-if="userPhone && !isPhoneVerified && !isPhoneTimeout"
          class="c-profile__verify-phone o-button o-button--secondary o-button--fixed-width"
          @click="$popup.show($dict.popups.VERIFY_PHONE, { phone: userPhone })"
        >
          {{ $t('views.myAccount.verifyPhone') }}
        </button>
        <button
          v-if="!isPhoneTimeout"
          class="o-button o-button--secondary o-button--fixed-width"
          @click="$popup.show($dict.popups.CHANGE_PHONE)"
        >
          {{ phoneButtonText }}
        </button>
        <button
          v-if="isPhoneVerified || isPhoneTimeout"
          class="c-profile__delete-phone o-button o-button--secondary o-button--fixed-width"
          :class="{ 'c-profile__delete-phone--no-margin': isPhoneTimeout }"
          @click="$popup.show($dict.popups.DELETE_PHONE, { phone: userPhone })"
        >
          {{ $t('buttons.remove') }}
        </button>
      </div>
      <template v-if="bundleEnabled">
        <div class="c-profile__column column small-12 large-1" />
        <div class="c-profile__column column small-12 large-3">
          <div class="row expanded collapse">
            <div class="c-profile__label column small-12">
              {{ $t('common.creditCard') }}
            </div>
            <div
              v-dompurify-html="userCard"
              class="c-profile__text column small-12"
            />
          </div>
        </div>
        <div class="c-profile__column column small-12 large-4">
          <div class="row expanded collapse">
            <div class="c-profile__label column small-12">
              {{ $t('views.myAccount.validUntil') }}
            </div>
            <div class="c-profile__text column small-12">
              {{ validUntil }}
            </div>
          </div>
        </div>
        <div class="c-profile__button-container c-profile__column column small-12 large-4">
          <button
            v-if="userCard !== '-'"
            class="o-button o-button--secondary o-button--fixed-width"
            @click="$popup.show($dict.popups.REMOVE_CARD)"
          >
            {{ $t('views.myAccount.deleteCard') }}
          </button>
          <button
            v-else
            class="o-button o-button--secondary o-button--fixed-width"
            @click="$popup.show($dict.popups.ADD_CARD)"
          >
            {{ $t('views.myAccount.addCard') }}
          </button>
        </div>
      </template>
    </div>
    <template v-if="bundleEnabled">
      <separator class="o-separator--horizontal" />
      <div class="row expanded collapse align-middle">
        <div class="c-profile__subtitle c-profile__subtitle--no-margin column small-12 large-8">
          {{ $t('views.myAccount.subscription') }}
        </div>
        <div class="c-profile__button-container column small-12 large-4">
          <nuxt-link
            :to="{ name: $dict.routes.PAYMENTS }"
            class="o-button o-button--secondary o-button--fixed-width"
          >
            {{ $t('views.myAccount.addSubscription') }}
          </nuxt-link>
        </div>
        <bundle
          v-for="item in activeSubscriptions"
          :key="item.id"
          :subscription="item"
        />
      </div>
      <separator class="o-separator--horizontal" />
      <div class="row expanded collapse align-middle">
        <div class="c-profile__subtitle c-profile__subtitle--no-margin column small-12 large-8">
          {{ $t('views.myAccount.bundles.heading') }}
        </div>
        <div class="c-profile__button-container column small-12 large-4">
          <nuxt-link
            :to="{ name: $dict.routes.PAYMENTS }"
            class="o-button o-button--secondary o-button--fixed-width"
          >
            {{ $t('views.myAccount.bundles.show') }}
          </nuxt-link>
        </div>
      </div>
    </template>
    <separator class="o-separator--horizontal" />
    <div class="row expanded collapse align-middle">
      <div class="c-profile__subtitle c-profile__subtitle--no-margin column small-12 large-8">
        {{ $t('views.myAccount.vouchers') }}
      </div>
      <div class="c-profile__button-container column small-12 large-4">
        <button
          class="o-button o-button--secondary o-button--fixed-width"
          @click="$popup.show($dict.popups.ADD_VOUCHER)"
        >
          {{ $t('views.myAccount.addVoucher') }}
        </button>
      </div>
    </div>
    <separator class="o-separator--horizontal" />
    <div class="row expanded collapse">
      <div class="c-profile__subtitle column small-12">
        {{ $t('views.myAccount.compabilityTest') }}
      </div>
      <div class="column small-12 large-8">
        <div class="row expanded collapse">
          <div class="c-profile__label column small-12">
            {{ $t('common.status') }}
          </div>
          <div class="c-profile__text column small-12">
            {{ testStatus }}
          </div>
        </div>
      </div>
      <div class="c-profile__button-container column small-12 large-4">
        <nuxt-link
          :to="{ name: $dict.routes.COMPATIBILITY_TEST }"
          class="o-button o-button--secondary o-button--fixed-width"
        >
          {{ $t('views.myAccount.executeCompabilityTest') }}
        </nuxt-link>
      </div>
    </div>
    <separator class="o-separator--horizontal" />
    <div class="row expanded collapse align-middle">
      <div class="c-profile__subtitle column small-12 large-8">
        {{ $t('views.myAccount.agreements.heading') }}
      </div>
      <div class="c-profile__button-container column small-12 large-4">
        <div
          ref="submit"
          class="o-button o-button--secondary o-button--fixed-width"
          @click="saveAgreements"
        >
          {{ $t('views.myAccount.saveAgreements') }}
        </div>
      </div>
      <div class="c-profile__agreement-wrapper">
        <div
          v-for="{id, content, agreement} in agreements"
          :key="agreement.id"
          class="column small-12 large-8"
        >
          <div class="c-profile__agreement-container">
            <checkbox
              v-model="checkedAgreements"
              :value="id"
              :label="content"
              :is-required="agreement.obligatory"
            />
          </div>
        </div>
      </div>
    </div>
    <separator class="o-separator--horizontal" />
    <div class="row expanded collapse align-middle">
      <div class="c-profile__subtitle column small-12">
        {{ $t('views.myAccount.documents.heading') }}
      </div>
      <div class="c-profile__document-link-container column small-12">
        <nuxt-link
          v-for="{id, alias, title} in documents"
          :key="id"
          :to="{ name: $dict.routes.STATIC_PAGE, params: { alias: alias }}"
          class="c-profile__document-link"
        >
          {{ title }}
        </nuxt-link>
      </div>
    </div>
  </section>
</template>
<script>
  import Avatar from '@/components/avatar/index'
  import Checkbox from '@/components/checkbox'
  import Separator from '@/components/separator/index'
  import Bundle from '@/pages/my-account/profile/bundle'

  import compatibilityTestStatusMixin from '@/mixins/compatibility-test-status'
  import mobileOrTabletMixin from '@/mixins/mobile-or-tablet-mixin'
  import userAgreementsMixin from '@/mixins/user-agreements-mixin'

  export default {
    components: {
      Separator,
      Avatar,
      Bundle,
      Checkbox
    },
    mixins: [
      compatibilityTestStatusMixin,
      mobileOrTabletMixin,
      userAgreementsMixin
    ],
    async asyncData ({ store }) {
      return {
        cyclicPayments: await store.dispatch('user/GET_CYCLIC_PAYMENTS', {}),
        documents: await store.dispatch('documents/GET_CONTENT', 'user-documents')
      }
    },
    computed: {
      activeProfile () {
        return this.$store.getters['user/ACTIVE_PROFILE']
      },
      card () {
        return this.$store.state.payments.cards[0]
      },
      isPhoneVerified () {
        return this.user.verifiedPhone
      },
      isPhoneTimeout () {
        return this.user.phoneVerifyTimeout
      },
      phoneButtonText () {
        return this.userPhone
          ? this.$t('views.myAccount.changeNumber')
          : this.$t('views.myAccount.addNumber')
      },
      subscription () {
        return this.$store.state.user.activeSubscription
      },
      user () {
        return this.$store.state.user.data
      },
      userCard () {
        if (this.card) {
          return `${this.card.mask.replace(/(.{4})/g, '$1 ').replace(/x/g, '&bull;')}`
        }

        return '-'
      },
      userEmail () {
        return this.user.email
      },
      userName () {
        return this.activeProfile?.name
      },
      userPhone () {
        return this.user.phone
      },
      validUntil () {
        if (this.card) {
          return this.card.expiration.replace(/(.{2})/, '$1/')
        }

        return '-'
      },
      bundleEnabled () {
        return this.$store.state.user?.data?.showCard
      },
      activeSubscriptions () {
        return this.$store.state.user?.activeSubscriptions
      }
    },
    created () {
      this.getAgreements()
    },
    methods: {
      editProfile () {
        this.$router.push({ name: this.$dict.routes.EDIT_PROFILE, params: { selectedProfile: this.activeProfile } })
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
