<template>
  <div
    class="c-drawer"
    :style="{maxHeight}"
    @transitionend.self="slideWindow"
  >
    <div ref="content">
      <slot />
    </div>
  </div>
</template>
<script>
  import smoothscroll from 'smoothscroll-polyfill'
  // https://developer.mozilla.org/en-US/docs/Web/API/ScrollToOptions#Browser_compatibility
  smoothscroll.polyfill()

  export default {
    name: 'Drawer',
    props: {
      open: {
        type: Boolean,
        default: false,
        required: true
      },
      identifier: {
        // It triggers update to compute maxHeight
        type: null,
        default: null
      }
    },
    data () {
      return {
        maxHeight: '0',
        rAFid: null
      }
    },
    watch: {
      open: {
        immediate: true,
        handler (val) {
          if (!val) {
            this.maxHeight = '0'
            return
          }
          this.getMaxHeight()
        }
      },
      'identifier' (val) {
        if (val) {
          this.getMaxHeight()
          this.slideWindow()
        }
      }
    },
    methods: {
      getMaxHeight () {
        const element = this.$refs.content
        if (element) {
          this.$nextTick(() => {
            this.maxHeight = `${element.offsetHeight}px`
          })
        } else {
          this.maxHeight = 'none'
        }
      },
      slideWindow () {
        const rect = this.$el.getBoundingClientRect()
        const windowHeight = window.innerHeight
        const initialPageY = window.pageYOffset
        const scrollLimit = rect.bottom - windowHeight
        if (rect.height > 0 && scrollLimit + initialPageY >= window.pageYOffset) {
          window.scrollBy({ top: scrollLimit, left: 0, behavior: 'smooth' })
        }
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
