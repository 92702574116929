<template>
  <div class="c-action-buttons__button">
    <a
      class="c-action-buttons__icon-link c-action-buttons__icon-link--padding"
      :href="twitterLink"
      target="_blank"
    >
      <svg class="o-icon o-icon--twitter">
        <use xlink:href="#icon-twitter" />
      </svg>
      <div class="c-action-buttons__title">
        {{ $t('buttons.twitter') }}
      </div>
    </a>
  </div>
</template>
<script>
  export default {
    props: {
      product: {
        type: Object,
        required: true
      },
      url: {
        type: String,
        required: true
      }
    },
    computed: {
      productTitle () {
        return this.product.title
      },
      productId () {
        return this.product.id
      },
      productLead () {
        return this.product.lead
      },
      text () {
        return this.$t('components.share.twitter.text', { title: this.productTitle, lead: this.productLead })
      },
      twitterLink () {
        return `https://twitter.com/intent/tweet?text=${this.text}&url=${this.url}`
      }
    }
  }
</script>
