<template functional>
  <div class="c-broadcast-photo">
    <img
      v-if="props.url"
      :src="props.url"
      :alt="props.alt"
      class="c-broadcast-photo__img"
    >
    <img
      v-else
      src="~assets/images/no-avatar.jpg"
      :alt="props.alt"
      class="c-broadcast-photo__img"
    >
    <span
      v-if="props.edit"
      class="c-broadcast-photo__icon-edit"
    >
      <svg class="o-icon">
        <use xlink:href="#icon-edit" />
      </svg>
    </span>
  </div>
</template>
<script>
  export default {
    name: 'BroadcastPhoto',
    props: {
      url: {
        type: String,
        default: ''
      },
      edit: {
        type: Boolean,
        default: true
      },
      alt: {
        type: String,
        default: ''
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
