<template>
  <div
    v-if="TCString"
    id="js-player-wrapper"
    class="c-watch"
  >
    <div
      v-if="additionalMedia.length"
      class="c-watch__media"
    >
      <nuxt-link
        v-for="{id, live, title} in additionalMedia"
        :key="id"
        :to="{ name: $dict.routes.WATCH, params: { ...$route.params, productId: live.id } }"
        class="c-watch__media-item"
      >
        {{ title }}
      </nuxt-link>
    </div>
    <player-store-provider
      :modules="$options.playerStoreModules"
      holder-id="js-player-holder"
    >
      <watch
        :product="product"
        :return-path="returnPath"
      />
    </player-store-provider>
  </div>
</template>
<script>
  import Watch from '@/pages/watch/components/watch'
  import PlayerStoreProvider from 'atds-player-layout/src/providers/player-store'
  import { SettingsStore } from 'atds-player-layout/src/store/settings'
  import { LiveStore } from 'atds-player-layout/src/store/live'
  import { SeekingStore } from 'atds-player-layout/src/store/seeking'
  import { TimelineEventsStore } from 'atds-player-layout/src/store/timeline-events'
  import { AdvertStore } from '@/components/advert-player/store'
  import { ASPECT_16X9 } from '@/assets/javascript/enums/aspect-ratios'
  import truncate from '@/assets/javascript/filters/truncate'
  import RoutesDictionary from 'assets/javascript/dictionaries/routes'
  import { getCurrentBreakpoint } from 'assets/javascript/responsive'
  import { DESKTOP } from 'assets/javascript/responsive/enums'
  import { CMP_COOKIE_NAME } from '@/assets/javascript/enums/cmp'
  import showCMPMixin from '@/mixins/show-cmp-mixin'
  import { EPISODE, SERIAL } from 'assets/javascript/enums/product-types'

  const WATCH = 'watch'

  export default {
    name: 'WatchPage',
    components: {
      PlayerStoreProvider,
      Watch
    },
    filters: {
      truncate
    },
    mixins: [showCMPMixin],
    playerStoreModules: [
      SettingsStore,
      LiveStore,
      SeekingStore,
      TimelineEventsStore,
      AdvertStore
    ],
    layout: 'empty',
    async asyncData ({ store, redirect, route }) {
      const { productId, type } = route.params
      const product = await store.dispatch('products/GET', { id: productId, type: type.toUpperCase() })

      if (product.type === SERIAL) {
        const serialId = product.id
        const seasons = await store.dispatch('products/GET_SERIAL_SEASONS', serialId)

        if (seasons[0]) {
          const episodes = await store.dispatch('products/GET_SERIAL_EPISODES', {
            serialId,
            seasonId: seasons[0].id
          })

          if (episodes.length) {
            return redirect({
              name: WATCH,
              params: {
                type: EPISODE.toLowerCase(),
                productId: episodes[0].id
              }
            })
          }
        }
      }

      const bookmark = store.getters['watched/IS_WATCHED'](product.id)?.item
      let additionalMedia = []
      if (product.additionalMediaEnabled) {
        additionalMedia = await store.dispatch('items/GET_ADDITIONAL_MEDIA', product.id)
      }

      return {
        additionalMedia,
        product: {
          ...product,
          progress: bookmark?.status.bookmark || 0
        }
      }
    },
    beforeRouteEnter (to, from, next) {
      let returnPath = {}
      if ([RoutesDictionary.PAYMENT_CONFIRMED, RoutesDictionary.BUY_AND_WATCH].includes(from.name)) {
        returnPath = { name: RoutesDictionary.HOME }
      }
      next(vm => {
        vm.returnPath = returnPath
      })
    },

    data () {
      return {
        product: {},
        returnPath: {}
      }
    },
    computed: {
      TCString () {
        return this.$store.state.cmp.TCString
      },
      desktop () {
        return getCurrentBreakpoint() === DESKTOP
      }
    },
    mounted () {
      if (this.canShowCMP) {
        this.$popup.show(this.$dict.popups.CMP, { isWide: true, disableClose: true })
      } else {
        this.$cmp.update(this.TCString)
        this.$cookie.set(CMP_COOKIE_NAME, this.TCString)
      }
    },
    head () {
      const { title, lead, artworks, trailers } = this.product
      const description = this.$options.filters.truncate(lead, 120)
      const miniUrl = 'https:' + artworks?.[ASPECT_16X9]?.[0]?.miniUrl?.replace('&amp;', '&')
      const trailer = trailers?.MP4?.[0]?.url
      const productUrl = window.location.href.replace('https:', 'ottwow:')
      const productUrlIOS = window.location.href.replace('https:', process.env.IOS_PACKAGE_NAME + ':')
      const appName = 'WOW'

      const meta = [
        {
          property: 'al:android:app_name',
          content: appName
        },
        {
          property: 'al:android:package',
          content: process.env.ANDROID_PACKAGE_NAME
        },
        {
          property: 'al:android:url',
          content: productUrl
        },
        {
          property: 'twitter:app:name:googleplay',
          content: appName
        },
        {
          property: 'twitter:app:id:googleplay',
          content: process.env.ANDROID_PACKAGE_NAME
        },
        {
          property: 'twitter:app:url:googleplay',
          content: productUrl
        },
        {
          property: 'al:ios:app_name',
          content: appName
        },
        {
          property: 'al:ios:app_store_id',
          content: process.env.IOS_APP_STORE_ID
        },
        {
          property: 'al:ios:url',
          content: productUrlIOS
        },
        {
          property: 'twitter:app:name:iphone',
          content: appName
        },
        {
          property: 'twitter:app:id:iphone',
          content: process.env.IOS_APP_STORE_ID
        },
        {
          property: 'twitter:app:url:iphone',
          content: productUrlIOS
        },
        {
          property: 'og:url',
          content: window.location.href
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: description
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: miniUrl
        },
        {
          property: 'og:site_name',
          content: 'WOW'
        },
        {
          property: 'twitter:title',
          content: title
        },
        {
          property: 'twitter:description',
          content: description
        },
        {
          property: 'twitter:image',
          content: miniUrl
        },
        {
          property: 'twitter:card',
          content: 'app'
        }
      ]
      if (trailer) {
        meta.push(
          {
            property: 'og:video',
            content: trailer
          },
          {
            property: 'og:video:type',
            content: 'application/mp4'
          },
          {
            property: 'og:video:width',
            content: '398'
          },
          {
            property: 'og:video:height',
            content: '264'
          }
        )
      }

      return {
        title: this.product.title,
        meta
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
