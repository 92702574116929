<template>
  <div
    class="c-dropdown"
    :class="{ 'c-dropdown--narrow': narrow, 'c-dropdown--left': positionLeft }"
  >
    <slot />
  </div>
</template>
<script>
  export default {
    name: 'Dropdown',
    props: {
      narrow: {
        type: Boolean,
        default: false
      },
      positionLeft: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
<style src="./styles.scss" lang="scss"></style>
