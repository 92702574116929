<template>
  <span
    class="c-label"
    :class="layoutClass"
  >
    <slot />
  </span>
</template>
<script>
  export default {
    props: {
      layout: {
        type: String,
        default: function () {
          return this.$dict.labelLayouts.LIVE
        }
      }
    },
    computed: {
      layoutClass () {
        if (this.hasValidLayoutClass()) {
          return `c-label--${this.layout}`
        }
        return ''
      }
    },
    methods: {
      hasValidLayoutClass () {
        return Object.values(this.$dict.labelLayouts).includes(this.layout)
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
