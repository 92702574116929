<template>
  <div>
    <svg-icons />
    <component :is="errorTemplate" />
  </div>
</template>
<script>
  import SvgIcons from '@/layouts/components/svg-icons/index'
  import seoMixin from '@/mixins/seo-mixin'

  export default {
    components: {
      SvgIcons
    },
    errorCodes: [404],
    mixins: [seoMixin],
    props: {
      error: {
        type: Object,
        default: () => ({})
      }
    },
    data () {
      return {
        errorTemplate: null
      }
    },
    created () {
      if (this.$options.errorCodes.includes(this.error.statusCode)) {
        this.errorTemplate = () => import(`@/layouts/templates/error${this.error.statusCode}.vue`).then(m => m.default || m)
      } else {
        this.errorTemplate = () => import('@/layouts/templates/default.vue').then(m => m.default || m)
      }
    },
    mounted () {
      const events = this.$tracking.events
      const { $route } = this
      events.eventsOnPageRefresh({ $route })
    }
  }
</script>
