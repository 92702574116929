<template>
  <section>
    <h1 class="c-remove-device__title">
      {{ $t('views.devices.remove.heading') }}
    </h1>
    <h2 class="c-remove-device__subtitle">
      {{ $t('views.devices.remove.subheading') }}
    </h2>
    <div class="c-remove-device__buttons-container">
      <div
        class="o-button"
        @click="deleteDevice"
      >
        {{ $t('buttons.remove') }}
      </div>
      <div
        class="o-button"
        @click="close"
      >
        {{ $t('buttons.cancel') }}
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'RemoveDevice',
    props: {
      deviceUid: {
        type: String,
        required: true
      }
    },
    methods: {
      close () {
        this.$emit('close')
      },
      deleteDevice () {
        const uid = this.deviceUid
        this.$store.dispatch('user/DELETE_DEVICE', uid)
          .then(() => {
            this.$emit('close')
            this.$store.commit('user/DELETE_DEVICE', uid)
            this.$router.push({ name: this.$dict.routes.DEVICES })
          })
      }
    }
  }
</script>
<style src="./styles.scss" lang="scss" />
