<template>
  <div
    v-if="section.elements.length"
    ref="container"
    class="js-section-with-product-detail"
  >
    <div @click="getProductId">
      <slot
        :section="section"
        :selected-product="selectedProduct"
      />
    </div>
    <drawer
      :open="!!drawerIdentifier"
      :identifier="drawerIdentifier"
      @transitionend.self.native="smoothDrawerHiding"
    >
      <product-detail
        v-if="selectedProduct.id"
        :product="selectedProduct"
        @loaded="setDrawerIdentifier"
        @tabChanged="setDrawerIdentifier"
      />
    </drawer>
  </div>
</template>
<script>
  import ProductDetail from '@/components/product-detail'
  import Drawer from '@/components/drawer'

  export default {
    name: 'SectionWithProductDetail',
    components: {
      Drawer,
      ProductDetail
    },
    props: {
      section: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        selectedProduct: {},
        drawerIdentifier: undefined
      }
    },
    computed: {
      isProductSelected () {
        return !!this.selectedProduct.id
      }
    },
    watch: {
      'section.elements.length' () {
        const selectedItem = this.section.elements
          .find(({ id }) => id === this.selectedProduct.id)
        if (!selectedItem) {
          this.setProduct()
        }
      }
    },
    mounted () {
      document.addEventListener('click', this.clickHandler)
    },
    beforeDestroy () {
      if (this.isProductSelected) {
        this.$player.destroy('detail-trailer') // TODO destroy selected player
      }
      document.removeEventListener('click', this.clickHandler)
    },
    methods: {
      setDrawerIdentifier (id = this.$time().valueOf()) {
        this.drawerIdentifier = id
      },
      smoothDrawerHiding (event) {
        const height = event.target.offsetHeight
        if (height === 0) {
          this.selectedProduct = {}
        }
      },
      clickHandler (event) {
        if (!this.isProductSelected) {
          return
        }

        if (!event.target.closest('.js-section-with-product-detail')) {
          // TO PREVENT CLOSE ON CLICK FOR EXAMPLE IN SEARCH
          // CLOSE ONLY WHEN PRESSED ON ANOTHER DRAWER
          return
        }

        if (this.$refs.container?.contains(event.target)) {
          return
        }

        this.closeDrawer()
      },
      closeDrawer () {
        this.drawerIdentifier = undefined
      },
      getProductId (event) {
        const element = event.target.closest('[data-id]')
        if (!element) {
          return
        }

        const { id, sectionId, type } = element.dataset
        this.setProduct({ id: parseInt(id, 10), type, sectionId })
      },
      setProduct (product) {
        const id = product?.id
        if (id && this.selectedProduct.id !== id) {
          this.selectedProduct = product
        } else {
          this.closeDrawer()
        }
      }
    }
  }
</script>
