<template>
  <div class="c-player__settings">
    <div class="row expanded c-player__preferences">
      <div
        v-if="options.audioTracks.length"
        class="column"
      >
        <h3>
          {{ $t('views.watch.options.audio') }}
        </h3>
        <ul>
          <li
            v-for="track in options.audioTracks"
            :key="track.id"
            class="c-player__preferences--item"
            :class="{ 'isActive': isCurrentTrack(track) }"
            @click="setAudio(track)"
          >
            {{ track.name }}
          </li>
        </ul>
      </div>
      <div
        v-if="options.subtitles.length"
        class="column"
      >
        <h3>
          {{ $t('views.watch.options.subtitles') }}
        </h3>
        <ul>
          <li
            v-for="subtitle in options.subtitles"
            :key="subtitle.lang"
            class="c-player__preferences--item"
            :class="{ 'isActive': isCurrentSubtitle(subtitle) }"
            @click="setSubtitle(subtitle)"
          >
            {{ subtitle.name }}
          </li>
        </ul>
      </div>
      <div
        v-if="options.profiles.length && !$options.isSafari()"
        class="column"
      >
        <h3>
          {{ $t('views.watch.options.quality') }}
        </h3>
        <div>
          <ul>
            <li
              v-for="profile in profiles"
              :key="profile.bandwidth"
              class="c-player__preferences--item"
              :class="{ 'isActive': isCurrentProfile(profile) }"
              @click="setProfile(profile)"
            >
              {{ profile.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { PROFILE_AUTO } from 'atds-player-layout/src/store/settings'
  import isSafari from '@/assets/javascript/helpers/is-safari'

  export default {
    isSafari,
    name: 'PlayerOptions',
    inject: [
      'playerStore'
    ],
    computed: {
      options () {
        return this.playerStore.state.options
      },
      settings () {
        return this.playerStore.state.settings
      },
      profiles () {
        const profiles = [...this.options.profiles, PROFILE_AUTO]
        return profiles
          .map(profile => {
            if (profile.name === 'Auto') {
              return {
                ...profile,
                name: this.$t('views.watch.options.auto')
              }
            }
            return profile
          })
          .reverse()
      }
    },
    methods: {
      setProfile (profile) {
        this.playerStore.setProfile(profile)
      },
      setAudio (track) {
        this.playerStore.setAudio(track)
      },
      setSubtitle (subtitle) {
        this.playerStore.setSubtitle(subtitle)
      },
      isCurrentTrack (track) {
        return this.settings?.audio?.id === track.id
      },
      isCurrentProfile (profile) {
        return this.settings?.profile?.height === profile.height
      },
      isCurrentSubtitle (subtitle) {
        return this.settings?.subtitle?.language === subtitle.language
      }
    }
  }
</script>
