<template>
  <div class="c-add-voucher">
    <div v-if="!voucherAdded">
      <h1 class="c-add-voucher__title">
        {{ $t('views.addVoucher.heading') }}
      </h1>
      <form @submit.prevent="send">
        <v-input
          v-model="code"
          :placeholder="$t('common.voucherCode')"
          :restriction-rules="[restrictionRules.NO_SPACES]"
          :validation-rules="[validationRules.MIN_LENGTH_3]"
          @changedValidity="setFormValidity('code', $event)"
        />
        <div class="c-add-voucher__buttons-container">
          <button
            ref="send"
            class="o-button"
            :class="{ 'o-button--disabled' : !isFormValid }"
            :disabled="!isFormValid"
          >
            {{ $t('buttons.confirm') }}
          </button>
          <button
            class="o-button o-button--secondary"
            @click="cancel"
          >
            {{ $t('buttons.cancel') }}
          </button>
        </div>
      </form>
    </div>
    <success-view v-else>
      <svg class="c-success__icon o-icon">
        <use xlink:href="#icon-check-mark" />
      </svg>
      <h1 class="c-success__heading">
        {{ $t('views.addVoucher.success.heading') }}
      </h1>
      <p>{{ $t('views.addVoucher.success.subheading') }}</p>
    </success-view>
  </div>
</template>

<script>
  import errorMapper from '@/assets/javascript/helpers/error-mapper.js'
  import SuccessView from '@/components/success-view'
  import VInput from '@/components/input/index'
  import restrictionRules from '@/assets/javascript/dictionaries/input-restriction-rules'
  import validationRules from '@/assets/javascript/dictionaries/input-validation-rules'
  import formValidityMixin from '@/mixins/form-validity-mixin'

  export default {
    name: 'AddVoucher',
    processing: false,
    components: {
      VInput,
      SuccessView
    },
    mixins: [
      formValidityMixin
    ],
    data () {
      return {
        voucherAdded: false,
        code: undefined,
        restrictionRules,
        validationRules,
        validity: {
          code: false
        }
      }
    },
    methods: {
      send () {
        if (!this.isFormValid || this.$options.processing) {
          return
        }
        this.$loader.show(this.$refs.send, { small: true })
        this.$options.processing = true
        this.$store.dispatch('user/ADD_VOUCHER', { value: this.code })
          .then(() => {
            this.voucherAdded = true
            this.$toast.success(this.$t('views.addVoucher.success.heading'))
          })
          .catch(error => {
            return errorMapper(this.$t.bind(this), error)
              .then(errors => {
                this.$toast.error(errors.global)
              })
          })
          .finally(() => {
            this.$options.processing = false
            this.$loader.hide(this.$refs.send)
          })
      },
      cancel () {
        this.$emit('close')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
