<template>
  <section>
    <div class="row">
      <div class="column small-6 small-offset-3">
        <div class="c-messages__title">
          {{ $t('views.myAccount.messages.selectThread') }}:
        </div>
        <multiselect
          v-model="currentThread"
          :custom-label="customLabel"
          :deselect-label="$t('components.select.deselect')"
          :options="threads"
          :placeholder="$t('components.select.select')"
          :select-label="$t('components.select.select')"
          :selected-label="$t('components.select.selected')"
          class="o-select"
          @input="selectThread"
        >
          <span slot="noResult">
            {{ $t('components.select.noResults') }}
          </span>
          <span slot="noOptions">
            {{ $t('views.myAccount.messages.empty') }}
          </span>
        </multiselect>
        <pagination
          v-if="currentThread"
          :callback="loadMore"
          :infinite-id="infiniteId"
        >
          <div
            v-for="{id, timestamp, message} in messages"
            :key="id"
          >
            <div class="c-messages__wrapper">
              <div class="c-chat-message__content">
                <div class="c-chat-message__info-container">
                  <span class="c-chat-message__time">
                    {{ parseTimestamp(timestamp) }}
                  </span>
                </div>
                <p
                  v-dompurify-html="message"
                  class="c-messages__wrapped"
                />
              </div>
            </div>
          </div>
        </pagination>
        <div
          v-if="loaded && !messages.length"
          class="row"
        >
          <div class="column small-8 small-offset-2">
            <div class="c-messages__subtitle">
              {{ $t('views.myAccount.messages.noResults') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import {
    CHAT,
    DESC,
    MESSAGE_COLLECTION,
    OWN_MESSAGES_COLLECTION,
    TIMESTAMP,
    USER_COLLECTION
  } from '@/components/chat/enums/chat'

  import Multiselect from 'vue-multiselect'
  import Pagination from '@/components/pagination'

  const LIMIT_PER_PAGE = 25

  export default {
    components: {
      Multiselect,
      Pagination
    },
    data () {
      return {
        userMsgRef: this.$fireStore.collection(USER_COLLECTION),
        currentThread: '',
        currentThreadId: '',
        infiniteId: this.refreshPagination(),
        lastVisible: null,
        loaded: false,
        messages: [],
        threads: []
      }
    },
    computed: {
      activeProfileId () {
        return this.$store.getters['user/ACTIVE_PROFILE']?.id?.toString()
      }
    },
    watch: {
      currentThread: {
        handler () {
          this.refreshPagination()
        }
      }
    },
    async mounted () {
      this.$loader.show(this.$el)

      try {
        const token = this.$store.state.user.firebaseAccessToken ||
          await this.$store.dispatch('authorizations/GET_ACCESS_TOKEN', {
            type: CHAT,
            room: '*'
          })

        await this.$fireAuth.signInWithCustomToken(token)

        this.userMsgRef
          .doc(this.activeProfileId)
          .collection(OWN_MESSAGES_COLLECTION)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              this.threads.push({ ...doc.data(), id: doc.id })
            })
          })
      } catch (e) {
        this.$toast.error(this.$t('views.myAccount.messages.couldNotFetchThreads'))
      } finally {
        this.$loader.hide(this.$el)
      }
    },
    methods: {
      customLabel (thread) {
        return thread.itemTitle || thread.id
      },
      getMessages () {
        let query = this.userMsgRef
          .doc(this.activeProfileId)
          .collection(OWN_MESSAGES_COLLECTION)
          .doc(this.currentThreadId)
          .collection(MESSAGE_COLLECTION)
          .orderBy(TIMESTAMP, DESC)

        if (this.lastVisible) {
          query = query.startAfter(this.lastVisible)
        }

        return query
          .limit(LIMIT_PER_PAGE)
          .get()
      },
      loadMore (loaded, completed) {
        return this.getMessages()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              const data = doc.data()
              this.messages.push({ ...data })
            })

            loaded()
            this.lastVisible = snapshot.docs[snapshot.docs.length - 1]

            if (!this.lastVisible) {
              this.loaded = true
              completed()
            }
          })
      },
      parseTimestamp (timestamp) {
        return this.$options.filters.dateFormat(timestamp * 1000, 'DD.MM.YYYY, HH:mm')
      },
      refreshPagination () {
        this.infiniteId = new Date().getTime()
      },
      selectThread (thread) {
        this.loaded = false
        this.messages = []

        if (thread) {
          this.currentThreadId = thread.id
        } else {
          this.currentThreadId = ''
          this.lastVisible = null
        }
      }
    },
    head () {
      return {
        title: this.$t('views.myAccount.messages.title')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
