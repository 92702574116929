<template>
  <dropdown-button
    :is-hoverable="!isMobile"
    menu-alignment="right"
  >
    <template #title>
      <button class="notification-dropdown__button">
        <div class="row align-center">
          <svg class="o-icon">
            <use xlink:href="#icon-dingdong" />
          </svg>
        </div>
      </button>
    </template>
    <template #menu>
      <notifications
        class="notification-dropdown__menu"
        :notifications="notifications"
      />
    </template>
  </dropdown-button>
</template>
<script>
  import DropdownButton from '@/layouts/components/page-header/dropdown/index'
  import Notifications from '@/components/notifications/index'
  import { breakpointGetters } from '@/assets/javascript/responsive'
  import { IS_MOBILE } from 'assets/javascript/responsive/enums'
  export default {
    components: {
      DropdownButton,
      Notifications
    },
    props: {
      notifications: { type: Array, default: () => [] }
    },
    computed: {
      isMobile () {
        return breakpointGetters[IS_MOBILE]()
      }
    }
  }
</script>
<style src="./styles.scss" lang="scss"></style>
