<template>
  <section>
    <div class="c-show-profiles-page">
      <div class="c-show-profiles-page__list">
        <div
          v-for="profile in user.profiles"
          :key="profile.id"
          @click="editOrSwitchProfile(profile)"
        >
          <profiles-item
            class="c-show-profiles-page__item"
            :edit-mode="profilesEditMode"
            :profile="profile"
          />
        </div>
        <nuxt-link
          v-if="canAddProfile"
          :to="{ name: $dict.routes.ADD_PROFILE }"
          class="c-show-profiles-page__item c-show-profiles-page__item--add"
        >
          <div class="c-show-profiles-page__add-wrapper">
            <div class="c-show-profiles-page__add">
              +
            </div>
          </div>
          <div class="c-show-profiles-page__add-text">
            {{ $t('profiles.addNew') }}
          </div>
        </nuxt-link>
      </div>
    </div>
    <div class="c-show-profiles-page__button-container">
      <h1
        class="o-button o-button--secondary"
        @click="setEditMode"
      >
        {{ profilesEditable }}
      </h1>
    </div>
  </section>
</template>
<script>
  import ProfilesItem from '@/components/profiles/item/index.vue'

  import SwitchProfile from '@/mixins/switch-profile'

  export default {
    name: 'Profiles',
    components: { ProfilesItem },
    mixins: [
      SwitchProfile
    ],
    computed: {
      user () {
        return this.$store.state.user.data || {}
      },
      profilesEditMode () {
        return this.$store.state.profiles.editMode
      },
      profilesEditable () {
        return this.profilesEditMode ? this.$t('profiles.editEnd') : this.$t('profiles.manage')
      },
      canAddProfile () {
        return this.user.profiles?.length < this.$store.state.api.maxProfilesCount
      }
    },
    methods: {
      setEditMode () {
        this.$store.commit('profiles/SET_EDIT_MODE')
      },
      editOrSwitchProfile (profile) {
        if (this.profilesEditMode) {
          return this.$router.push({ name: this.$dict.routes.EDIT_PROFILE, params: { selectedProfile: profile } })
        } else {
          return this.switchProfile(profile)
        }
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
