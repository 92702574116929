<template>
  <section class="c-login o-page-form">
    <h1 class="c-login__title">
      {{ $t('common.login') }}
    </h1>
    <message
      v-if="errors.global"
      :message="errors.global"
    />
    <form
      class="o-form"
      @submit.prevent="login"
    >
      <v-input
        v-model="form.email"
        :placeholder="$t('common.email')"
        :restriction-rules="[restrictionRules.NO_SPACES]"
        :validation-rules="[validationRules.EMAIL]"
        @changedValidity="setFormValidity('email', $event)"
      />
      <message
        v-if="errors.email"
        :message="errors.email"
      />
      <v-input
        v-model="form.password"
        type="password"
        :placeholder="$t('common.password')"
        :validation-rules="[validationRules.MIN_LENGTH_5]"
        @changedValidity="setFormValidity('password', $event)"
      />
      <message
        v-if="errors.password"
        :message="errors.password"
      />
      <div class="c-login__remember-me">
        <checkbox
          v-model="form.rememberMe"
          :label="$t('views.login.rememberMe')"
        />
      </div>
      <div class="c-login__buttons-container">
        <button
          ref="submit"
          type="submit"
          class="o-button"
          :class="{ 'o-button--disabled' : !isFormValid }"
          :disabled="!isFormValid"
        >
          {{ $t('common.login') }}
        </button>
        <nuxt-link
          :to="{ name: $dict.routes.RESET_PASSWORD }"
          class="c-login__forgot-password"
        >
          {{ $t('common.forgotPassword') }}
        </nuxt-link>
      </div>
    </form>
    <div class="c-login__social-buttons-container">
      <div
        v-if="isFbAvailable"
        class="o-button o-button__social o-button__social--fb"
        @click="facebookLogin"
      >
        <svg class="o-icon o-icon__inside-button">
          <use xlink:href="#icon-facebook_login" />
        </svg>
        {{ $t('buttons.login.facebook') }}
      </div>
      <a
        :href="googleLoginURL"
        class="o-button o-button__social o-button__social--google"
        @click="setGoogleAsTrackingLoginProvider"
      >
        <svg class="o-icon o-icon__inside-button">
          <use xlink:href="#icon-google_login" />
        </svg>
        {{ $t('buttons.login.google') }}
      </a>
    </div>
    <div class="c-login__buttons-container c-login__buttons-container--margin-top">
      <h4 class="c-login__subtitle">
        {{ $t('views.login.createAccount') }}
      </h4>
      <nuxt-link
        :to="{ name: $dict.routes.REGISTER }"
        class="o-button o-button--secondary"
      >
        {{ $t('common.register') }}
      </nuxt-link>
    </div>
  </section>
</template>

<script>
  import {
    SUBMISSION_SUCCESS,
    USER_FB_LOGIN,
    USER_GOOGLE_LOGIN,
    USER_LOGIN,
    USER_SOCIAL_LOGIN,
    VALIDATION_FAILED
  } from '@/assets/javascript/dictionaries/gtmEventsActions'

  import {
    FACEBOOK
  } from '@/assets/javascript/enums/login-types'

  import errorMapper from '@/assets/javascript/helpers/error-mapper.js'
  import restrictionRules from '@/assets/javascript/dictionaries/input-restriction-rules'

  import validationRules from '@/assets/javascript/dictionaries/input-validation-rules'
  import Checkbox from '@/components/checkbox'
  import Message from '@/components/message'

  import VInput from '@/components/input'
  import formValidityMixin from '@/mixins/form-validity-mixin'
  import trackingMixin from '@/mixins/tracking-mixin'
  import updateFCMTokenMixin from '@/mixins/update-fcm-token-mixin'

  export default {
    name: 'Login',
    processing: false,
    components: {
      Checkbox,
      Message,
      VInput
    },
    mixins: [
      formValidityMixin,
      updateFCMTokenMixin,
      trackingMixin
    ],
    data () {
      return {
        errors: [],
        form: {
          email: '',
          password: '',
          rememberMe: false
        },
        restrictionRules,
        validationRules,
        validity: {
          email: false,
          password: false
        }
      }
    },
    computed: {
      activeProfileId () {
        const activeProfile = this.$store.getters['user/ACTIVE_PROFILE']
        return activeProfile
          ? activeProfile.id
          : ''
      },
      googleLoginURL () {
        const url = new URL('https://accounts.google.com/o/oauth2/v2/auth')
        const searchParams = url.searchParams
        searchParams.set('client_id', process.env.GOOGLE_CLIENT_ID)
        searchParams.set('response_type', 'code')
        searchParams.set('redirect_uri', process.env.GOOGLE_REDIRECT_URL)
        searchParams.set('scope', 'https://www.googleapis.com/auth/userinfo.email')
        searchParams.set('access_type', 'online')
        searchParams.set('state', 'GOOGLE')
        searchParams.set('prompt', 'consent')

        return url.toString()
      },
      user () {
        return this.$store.state.user.data || {}
      },
      isFbAvailable () {
        return window.FB
      }
    },
    mounted () {
      const redirect = this.$route.query.redirect
      if (redirect && redirect !== '/') {
        sessionStorage.setItem('redirectURL', redirect)
      }
    },
    beforeDestroy () {
      if (this.user?.status?.missingAgreements) {
        this.$popup.show(this.$dict.popups.ACCEPT_CONSENTS)
      }
    },
    methods: {
      login () {
        this.errors = []
        if (!this.isFormValid || this.$options.processing) {
          return
        }
        this.$loader.show(this.$refs.submit, { small: true })
        this.$options.processing = true
        this.$store.dispatch('user/LOGIN', this.form)
          .then(() => {
            this.$store.cache.dispatch('user/GET_USER_CTX')
              .then(() => {
                this.$options.processing = false
                this.$loader.hide(this.$refs.submit)
                const profile = this.user.profiles[0]

                if (this.user.firstLogin || this.user.profile?.forceEdit) {
                  return this.$router.push({ name: this.$dict.routes.EDIT_PROFILE, params: { selectedProfile: profile } })
                }
                this.$router.push(this.$cookie.get('selected-product') ||
                  this.$route.query.redirect ||
                  { name: this.$dict.routes.HOME })
              })
            this.sendUserTrackingData(USER_LOGIN)
            this.sendFormTrackingData(SUBMISSION_SUCCESS)
            this.$store.cache.dispatch('recommendations/GET_FROM_EDGE', {
              timestamp: this.$time().valueOf(),
              userId: this.activeProfileId
            })
            this.updateFCMToken()
          })
          .catch(error => {
            if (error.status === 403 && error.data.code === 'HTTP_SESSION_LIMIT_EXCEEDED') {
              this.$options.processing = false
              this.$loader.hide(this.$refs.submit)
              return this.$toast.error(this.$t('errors.httpSessionLimitExceeded'))
            }

            return errorMapper(this.$t.bind(this), error)
              .then(errors => {
                this.$set(this, 'errors', errors)
                this.trackingFormData.validationError = errors.code
                this.sendFormTrackingData(VALIDATION_FAILED)
                this.$options.processing = false
                this.$loader.hide(this.$refs.submit)
              })
          })
      },
      facebookLogin () {
        const fb = window.FB
        this.errors = []

        return fb.login(res => {
          if (res.authResponse) {
            this.$loader.show(this.$el)
            this.$options.processing = true
            this.$store.dispatch('user/LOGIN_SOCIAL', {
              externalToken: res.authResponse.accessToken,
              externalAuthProvider: FACEBOOK
            })
              .then(() => {
                this.$store.cache.dispatch('user/GET_USER_CTX')
                this.$tracking.data.setLoginProvider({
                  loginProvider: USER_FB_LOGIN
                })
                this.sendUserTrackingData(USER_SOCIAL_LOGIN)
                this.$router.push(this.$route.query.redirect || { name: this.$dict.routes.HOME })
                this.$toast.success(this.$t('facebookLogin.success'))
              })
              .catch((error) => {
                return errorMapper(this.$t.bind(this), error)
                  .then(errors => {
                    this.$toast.error(errors.global)
                  })
              })
              .finally(() => {
                this.$options.processing = false
                this.$loader.hide(this.$el)
              })
          }
        }, { scope: 'email' })
      },
      setGoogleAsTrackingLoginProvider () {
        this.$tracking.data.setLoginProvider({
          loginProvider: USER_GOOGLE_LOGIN
        })
      }
    },
    head () {
      return {
        title: this.$t('views.login.title')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
