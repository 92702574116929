<template>
  <section>
    <template v-if="!complete">
      <h2 class="c-compatibility-test__subheading">
        {{ $t('views.compabilityTest.subheading') }}
      </h2>
      <ui-progress-circle :progress="progress">
        <template v-if="!runningTests">
          <h2 class="c-progress-circle__headline">
            {{ $t('buttons.runTest') }}
          </h2>
          <button
            class="o-button"
            @click="start"
          >
            {{ $t('buttons.run') }}
          </button>
        </template>
        <template v-else>
          <div
            v-if="currentTest"
            class="c-progress-circle__test-name"
          >
            {{ currentTest.nameKey }}
          </div>
          <div class="c-progress-circle__number">
            {{ progress }}
            <span> %</span>
          </div>
        </template>
      </ui-progress-circle>
      <div class="c-compatibility-test__button-container">
        <div
          v-if="runningTests"
          class="o-button o-button--secondary o-button--fixed-width"
          @click="resetTest"
        >
          {{ $t('buttons.cancelTest') }}
        </div>
        <nuxt-link
          v-else
          :to="{ name: $dict.routes.MY_ACCOUNT_PROFILE }"
          class="o-button o-button--secondary o-button--fixed-width"
        >
          {{ $t('buttons.cancelTest') }}
        </nuxt-link>
      </div>
    </template>
    <template
      v-else
      class="c-compatibility-test__results-container"
    >
      <div v-if="success">
        <svg class="o-icon c-compatibility-test__icon c-compatibility-test__icon--large">
          <use xlink:href="#icon-computer_ok" />
        </svg>
        <h2 class="c-compatibility-test__heading c-compatibility-test__heading--success u-typo--headline2">
          {{ $t('views.compabilityTest.success') }}
        </h2>
        <p class="c-compatibility-test__result">
          {{ $t('views.compabilityTest.deviceApproved') }}
        </p>
        <div class="c-compatibility-test__summary">
          <p class="c-compatibility-test__test-id">
            {{ $t('views.compabilityTest.testId') }}: {{ testId }}
          </p>
          <ul class="c-compatibility-test__list">
            <ui-test-item
              v-for="test in tests"
              :key="$t(test.nameKey)"
              :test="test"
            />
          </ul>
        </div>
        <div class="c-compatibility-test__button-container">
          <nuxt-link
            :to="{ name: $dict.routes.MY_ACCOUNT_PROFILE }"
            class="o-button o-button--fixed-width"
          >
            {{ $t('buttons.endTest') }}
          </nuxt-link>
        </div>
      </div>
      <div v-else>
        <svg class="o-icon c-compatibility-test__icon c-compatibility-test__icon--large">
          <use xlink:href="#icon-error" />
        </svg>
        <h2 class="c-compatibility-test__heading c-compatibility-test__heading--failure u-typo--headline2">
          {{ $t('views.compabilityTest.failure') }}
        </h2>
        <p class="c-compatibility-test__result">
          {{ $t('views.compabilityTest.noInternet') }}
        </p>
        <p class="c-compatibility-test__test-id">
          {{ $t('views.compabilityTest.testId') }}: {{ testId }}
        </p>
        <div v-if="resultSpeed">
          <p class="u-typo--bold">
            {{ $t('views.compabilityTest.tryAgain') }}
          </p>
        </div>
        <div class="c-compatibility-test__button-container">
          <div
            class="o-button"
            @click="restart"
          >
            {{ $t('buttons.runTestAgain') }}
          </div>
        </div>
      </div>
    </template>
  </section>
</template>
<script>
  import {
    DeviceInfo,
    uaString
  } from './device-info'

  import {
    DesktopTests,
    MobileTests,
    REQUIRED_MB_SPEED,
    TestCase
  } from './index'

  import { generateHashCode } from './hash-code'
  import bandwidthTest from './bandwidth-test'

  import UiProgressCircle from './components/ui-progress-circle'
  import UiTestItem from './components/ui-test-item'

  export default {
    name: 'CompatibilityTest',
    components: {
      UiProgressCircle,
      UiTestItem
    },
    data () {
      return {
        runningTests: false,
        complete: false,
        currentTest: undefined,
        progress: 0,
        intervalId: 0,
        cdnNode: '',
        testHash: ''
      }
    },
    computed: {
      isMobile () {
        return this.$root.isMobileDevice
      },
      resultSpeed () {
        return TestCase.SPEED.result
      },
      success () {
        this.updateTestStatus()
        return this.tests.every((item) => item.result)
      },
      testId () {
        return this.cdnNode
          ? `${this.testHash}@${this.cdnNode}`
          : this.testHash
      }
    },
    mounted () {
      this.tests = this.$root.isMobileDevice ? MobileTests : DesktopTests
    },
    methods: {
      restart () {
        this.runningTests = false
        this.complete = false
        this.currentTest = undefined
        this.progress = 0
        this.intervalId = 0
        this.cdnNode = ''
        this.testHash = ''
        this.tests.forEach((test) => {
          test.result = false
        })
        this.start()
      },
      start () {
        this.runningTests = true
        this.complete = false

        this.tests.forEach((test) => {
          test.result = true
        })

        const percentPerTest = 100 / this.tests.length
        const tests = this.tests.slice()
        let percent = 0

        const seed = uaString.substring((Math.floor(Math.random() * uaString.length)), (Math.floor(Math.random() * uaString.length)))
        this.testHash = Math.abs(generateHashCode(`${new Date().getTime() + seed}`))

        bandwidthTest(this.testHash).then((resp) => {
          TestCase.SPEED.result = resp.speed > REQUIRED_MB_SPEED
          if (resp.speed) {
            TestCase.SPEED.resultSpeed = resp.speed.toFixed(1)
          }
          this.cdnNode = resp.cdnNode
        })

        TestCase.OS.result = !DeviceInfo.isUnsupportedOS()

        this.currentTest = tests.shift()

        this.intervalId = setInterval(() => {
          this.progress += 1
          percent += 1

          if (percent >= percentPerTest) {
            this.currentTest = tests.shift()
            percent = 0
          }

          if (this.progress >= 100) {
            this.progress = 100
            clearInterval(this.intervalId)
            setTimeout(() => {
              this.complete = true
            }, 700)
          }
        }, 50)
      },
      updateTestStatus () {
        this.$store.dispatch('compatibility-test/UPDATE_STATUS', true)
      },
      resetTest () {
        this.complete = false
        this.runningTests = false
        clearInterval(this.intervalId)
        this.progress = 0
        this.intervalId = 0
        this.currentTest = undefined
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
