<template>
  <section class="c-my-list">
    <nuxt-link
      :to="{ name: $dict.routes.FAVOURITES }"
      class="c-my-list__title"
    >
      {{ $t('views.myList.favourites') }}
    </nuxt-link>
    <separator class="o-separator--rwd" />
    <nuxt-link
      :to="{ name: $dict.routes.WATCHED }"
      class="c-my-list__title"
    >
      {{ $t('views.myList.watched') }}
    </nuxt-link>
    <separator class="o-separator--rwd" />
    <!--    <nuxt-link-->
    <!--      :to="{ name: $dict.routes.RECORDINGS }"-->
    <!--      class="c-my-list__title"-->
    <!--    >-->
    <!--      {{ $t('views.myList.recordings') }}-->
    <!--    </nuxt-link>-->
    <!--    <separator />-->
    <nuxt-link
      :to="{ name: $dict.routes.BOUGHT }"
      class="c-my-list__title"
    >
      {{ $t('views.myList.bought') }}
    </nuxt-link>
    <separator class="o-separator--horizontal" />
    <router-view />
  </section>
</template>

<script>
  import Separator from '@/components/separator/index'

  export default {
    components: {
      Separator
    },
    head () {
      return {
        title: this.$t('views.myList.title')
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
