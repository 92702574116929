<template>
  <section
    ref="container"
    class="c-favourites"
  >
    <sections-chunked
      v-if="favourites.length"
      :elements="favourites"
    />
    <div
      v-else
      class="c-favourites__empty-container"
    >
      <svg class="c-favourites__empty-icon o-icon">
        <use xlink:href="#icon-my-list" />
      </svg>
      <h1 class="c-favourites__empty-heading">
        {{ $t('favourites.empty.heading') }}
      </h1>
      <h2 class="c-favourites__empty-subheading">
        {{ $t('favourites.empty.subheading') }}
      </h2>
    </div>
  </section>
</template>
<script>
  import SectionsChunked from '@/components/sections-chunked/index'

  export default {
    components: {
      SectionsChunked
    },
    computed: {
      favourites () {
        return this.$store.state.favourites.favourites
      }
    },
    head () {
      return {
        title: this.$t('views.myList.favourites')
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
