<template>
  <div
    class="c-chat-avatar"
    :style="[avatarStyle]"
  >
    {{ avatarLetter }}
  </div>
</template>
<script>
  export default {
    props: {
      avatarId: {
        type: Number,
        default: 0
      },
      profileColor: {
        type: String,
        default: ''
      },
      username: {
        type: String,
        required: true
      }
    },
    computed: {
      avatarStyle () {
        if (this.avatarId) {
          const avatar = this.$store.state.profiles.avatars
            .find((elem) => elem.id === this.avatarId)

          if (avatar) {
            return { 'background-image': `url(${avatar.url})` }
          }
        }

        return this.profileColor
          ? { 'background-color': this.profileColor }
          : {}
      },
      avatarLetter () {
        if (this.avatarId) {
          return ''
        }

        return (this.username?.charAt(0).toLocaleUpperCase()) || ''
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss"></style>
