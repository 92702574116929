<template>
  <section>
    <pagination :callback="loadMore">
      <sections-chunked
        v-if="results.length"
        :elements="results"
      />
    </pagination>
    <div
      v-if="loaded && !results.length"
      class="c-bought__empty-container"
    >
      <svg class="c-search__no-results-icon o-icon">
        <use xlink:href="#icon-no-results" />
      </svg>
      <h1 class="c-bought__empty-heading">
        {{ $t('favourites.empty.heading') }}
      </h1>
    </div>
  </section>
</template>
<script>
  import {
    CONFIRMED
  } from '@/assets/javascript/enums/payment-status'

  import {
    BUNDLE,
    N_PVR
  } from '@/assets/javascript/enums/product-types'

  import SectionsChunked from '@/components/sections-chunked/index'
  import Pagination from '@/components/pagination'
  import isSafari from '@/assets/javascript/helpers/is-safari'

  export default {
    isSafari,
    components: {
      Pagination,
      SectionsChunked
    },
    data () {
      return {
        loaded: false,
        results: [],
        totalCount: 0,
        loadedItemCount: 0
      }
    },
    created () {
      if (Notification.permission !== 'granted') {
        this.$toast.permissionDenied(this.$t('notifications.payments'))
      }
    },
    methods: {
      filterResults (items) {
        const results = items.filter((item) => {
          return this.$time().isBetween(item.since, item.till) &&
            item.status === CONFIRMED &&
            item.product.type !== BUNDLE &&
            item.product.type !== N_PVR
        }).map(item => ({ item: item.product }))

        return results
      },
      removeDuplicates (array) {
        return array.filter((currentElement, index, self) => {
          return index === self.findIndex((compareElement) => (
            compareElement.item.id === currentElement.item.id
          ))
        })
      },
      loadMore (loaded, completed) {
        return this.$store.dispatch('user/GET_PAYMENTS', { firstResult: this.loadedItemCount })
          .then((res) => {
            this.totalCount = res.meta.totalCount
            this.loadedItemCount = res.meta.firstResult + res.meta.maxResults

            if (!res) {
              this.loaded = true
              return completed()
            }

            const results = this.filterResults(res.items)

            loaded()

            if (this.loadedItemCount >= this.totalCount) {
              this.loaded = true
              completed()
            }

            this.results = this.removeDuplicates([...this.results, ...results])
          })
      }
    },
    head () {
      return {
        title: this.$t('views.myList.bought')
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
