<template>
  <div class="c-tabs">
    <div class="row expanded">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="c-tabs__tab column shrink"
        :class="{ 'is-active': activeTab.name === tab.name }"
        @click="setActiveTab(tab)"
      >
        <slot :tab="tab">
          {{ tab.title }}
        </slot>
      </div>
    </div>
    <div class="c-tabs__bottom-container">
      <component
        :is="activeTab.component"
        v-bind="$attrs"
        @loaded="$emit('changed', $event)"
      />
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Tabs',
    props: {
      tabs: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        activeTab: this.tabs[0]
      }
    },
    watch: {
      'tabs' () {
        this.setActiveTab(this.tabs[0])
      }
    },
    methods: {
      setActiveTab (tab) {
        this.activeTab = tab
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
