<template>
  <div class="row expanded collapse c-avatar-list">
    <div class="column small-12 c-avatar-list__label">
      <div>{{ $t('profiles.choseAvatar') }}:</div>
    </div>
    <div class="c-avatar-list__avatars">
      <div
        v-for="avatar in avatars"
        :key="avatar.id"
        class="c-avatar-list__avatar-img-container"
      >
        <img
          :src="avatar.url"
          :alt="avatar.name"
          class="c-avatar-list__avatar-img"
          @click="setProfileAvatar(avatar)"
        >
      </div>
      <div class="c-avatar-list__avatar-img-container">
        <img
          src="~assets/images/no-avatar.jpg"
          :alt="$t('profiles.noAvatarAlt')"
          class="c-avatar-list__avatar-img"
          @click="resetProfileAvatar"
        >
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'AvatarList',
    props: {
      profile: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      avatars () {
        return this.$store.state.profiles.avatars
      }
    },
    methods: {
      setProfileAvatar (selectedAvatar) {
        this.$emit('setAvatar', selectedAvatar)
      },
      resetProfileAvatar () {
        this.$emit('resetAvatar')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
