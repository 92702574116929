<template>
  <div>
    <svg-icons />
    <popup-switch />
    <nuxt />
  </div>
</template>
<script>
  import PopupSwitch from '@/layouts/components/popup-switch/index'
  import SvgIcons from '@/layouts/components/svg-icons/index'
  import mobileOrTabletMixin from '@/mixins/mobile-or-tablet-mixin'

  export default {
    components: {
      PopupSwitch,
      SvgIcons
    },
    mixins: [mobileOrTabletMixin],
    mounted () {
      const { $route } = this
      this.$tracking.events.eventsOnPageRefresh({ $route })
    }
  }
</script>
