<template>
  <div class="c-info row">
    <template v-if="isBanner">
      <banner
        :product="banner.item"
        :single-banner="true"
      >
        <template v-slot:actionButtons>
          <action-buttons
            v-if="banner.item"
            :product="banner.item"
          />
        </template>
      </banner>
    </template>
    <template v-else>
      <div class="column small-12">
        <h2 class="c-info__title">
          {{ product.title }}
        </h2>
        <h4 class="c-info__type">
          {{ genres }}
        </h4>
        <p
          v-dompurify-html="getLead"
          class="c-info__lead"
        />
      </div>
      <div class="column small-12">
        <product-rating :product="product" />
      </div>
      <div class="column c-info__content">
        <div
          v-if="productPrice && !isProductBought"
          class="c-info__container-single"
        >
          <h3 class="c-info__product-price">
            {{ $t('banner.onlyNow') }}
            <span class="c-info__product-currency">
              {{ productPrice }}
            </span>
            {{ $t('currency.pln') }}
          </h3>
          <buy-button :product="product" />
        </div>
        <action-buttons
          :product="product"
          :detail="true"
        >
          <toggle-favourites :product="product" />
          <remove-from-watched :product="product" />
          <toggle-recordings
            v-if="product.programRecordingId"
            :product="product"
          />
        </action-buttons>
      </div>
    </template>
  </div>
</template>
<script>
  import { SINGLE } from '@/assets/javascript/enums/payment-schedule-types'

  import truncate from '@/assets/javascript/filters/truncate'
  import getLowestPrice from '@/assets/javascript/helpers/get-lowest-price'
  import checkProductAvailability from '@/assets/javascript/helpers/check-product-availability'

  import ActionButtons from '@/components/action-buttons/index'
  import RemoveFromWatched from '@/components/action-buttons/components/remove-from-watched/index'
  import ToggleFavourites from '@/components/action-buttons/components/toggle-favourites/index'
  import ToggleRecordings from '@/components/action-buttons/components/toggle-recordings/index'
  import ProductRating from '@/components/product-rating/index'
  import { BANNER, EPISODE } from 'assets/javascript/enums/product-types'
  import Banner from '@/components/banner/index'
  import BuyButton from '@/components/buy-button'
  import AdvisorsMixin from '@/mixins/advisors'

  export default {
    name: 'Info',
    components: {
      ActionButtons,
      Banner,
      BuyButton,
      RemoveFromWatched,
      ToggleFavourites,
      ToggleRecordings,
      ProductRating
    },
    filters: {
      truncate
    },
    mixins: [
      AdvisorsMixin
    ],
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        banner: {}
      }
    },
    computed: {
      lead () {
        return this.advisorsFromApi
          ? this.advisorsFromApi + this.replaceAdvisors(truncate(this.product.lead, 220))
          : this.advisors(truncate(this.product.lead, 220))
      },
      isBanner () {
        return this.product.type === BANNER
      },
      productPrice () {
        const product = this.product.type === EPISODE
          ? this.product.serial
          : this.product

        return getLowestPrice(product, this.$time, SINGLE).price
      },
      isProductBought () {
        const availableProducts = this.$store.getters['user/AVAILABLE_PRODUCTS']
        const productId = this.product.type === EPISODE
          ? this.product.serial.id
          : this.product.id

        return checkProductAvailability(availableProducts, productId)
      },
      genres () {
        return this.product.genres
          ?.map(({ name }) => name.toLowerCase())
          .join(', ')
      }
    },
    mounted () {
      if (this.isBanner) {
        this.banner = this.getBannerData()
      }
      this.$emit('loaded')
    },
    methods: {
      getBannerData () {
        const sectionId = parseInt(this.product.sectionId)
        const section = this.$store.state.sections.collection?.[0]?.sections
          .find(item => item.id === sectionId)

        if (section) {
          const elementId = parseInt(this.product.id)
          return section.elements.find(element => element.item.id === elementId)
        }
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
