<template>
  <section>
    <h1 class="c-change-phone__title">
      {{ $t('views.changePhone.title') }}
    </h1>
    <message
      v-if="tokenExpired"
      :message="$t('views.changePhone.retry')"
    />
    <message
      v-if="errors.global"
      :message="errors.global"
    />
    <form
      class="o-form"
      @submit.prevent="changePhone"
    >
      <div class="c-change-phone__label">
        {{ $t('common.phone') }}
      </div>
      <v-input
        v-model="form.phone"
        :max-length="9"
        :tooltip="$t('tooltips.phone')"
        :tooltip-position="mobileOrTablet ? 'left' : 'right'"
        :restriction-rules="[restrictionRules.NUMBERS_ONLY]"
        :validation-rules="[validationRules.POLISH_PHONE]"
        @changedValidity="setFormValidity('phone', $event)"
      />
      <message
        v-if="errors.phone"
        :message="errors.phone"
      />
      <input
        class="u-hidden"
        tabindex="-1"
      >
      <div class="c-change-phone__label">
        {{ $t('common.password') }}
      </div>
      <v-input
        v-model="form.password"
        type="password"
        class="o-input__wrapper--small-margin"
        :validation-rules="[validationRules.MIN_LENGTH_5]"
        @changedValidity="setFormValidity('password', $event)"
      />
      <message
        v-if="errors.password"
        :message="errors.password"
      />
      <button
        ref="submit"
        type="submit"
        class="c-change-phone__button o-button"
        :class="{ 'o-button--disabled' : !isFormValid }"
        :disabled="!isFormValid"
      >
        {{ $t('common.submit') }}
      </button>
    </form>
  </section>
</template>

<script>
  import errorMapper from '@/assets/javascript/helpers/error-mapper.js'
  import Message from '@/components/message'
  import restrictionRules from '@/assets/javascript/dictionaries/input-restriction-rules'
  import validationRules from '@/assets/javascript/dictionaries/input-validation-rules'
  import VInput from '@/components/input'

  import formValidityMixin from '@/mixins/form-validity-mixin'
  import mobileOrTabletMixin from '@/mixins/mobile-or-tablet-mixin'
  import trackingMixin from '@/mixins/tracking-mixin'
  import { SUBMISSION_SUCCESS, VALIDATION_FAILED } from '@/assets/javascript/dictionaries/gtmEventsActions'

  export default {
    name: 'ChangePhoneForm',
    processing: false,
    components: {
      Message,
      VInput
    },
    mixins: [
      formValidityMixin,
      mobileOrTabletMixin,
      trackingMixin
    ],
    props: {
      phone: {
        type: String,
        default: ''
      },
      tokenExpired: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        errors: [],
        form: {
          password: '',
          phone: this.phone
        },
        restrictionRules,
        validationRules,
        validity: {
          password: false,
          phone: false
        }
      }
    },
    methods: {
      changePhone () {
        if (!this.isFormValid || this.$options.processing) {
          return
        }
        this.$loader.show(this.$refs.submit, { small: true })
        this.$options.processing = true
        this.$store.dispatch('user/CHANGE_PHONE', this.form)
          .then(() => {
            this.sendFormTrackingData(SUBMISSION_SUCCESS)
            this.$popup.close()
            this.$popup.show(this.$dict.popups.VERIFY_PHONE, { phone: this.form.phone })
          })
          .catch((error) => {
            this.$options.processing = false
            this.$loader.hide(this.$refs.submit)
            return errorMapper(this.$t.bind(this), error)
              .then(errors => {
                this.trackingFormData.validationError = errors.code
                this.sendFormTrackingData(VALIDATION_FAILED)
                this.$set(this, 'errors', errors)
              })
          })
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss" />
