<template>
  <div class="my-slider__buttons">
    <div
      v-show="showPrev"
      class="my-slider-button-prev"
      tabindex="0"
      role="button"
      aria-label="Previous slide"
      aria-disabled="false"
      @click.stop="debouncePrev"
    />
    <div
      v-show="showNext"
      class="my-slider-button-next"
      tabindex="0"
      role="button"
      aria-label="Next slide"
      aria-disabled="false"
      @click.stop="debounceNext"
    />
  </div>
</template>
<script>
  import _debounce from 'lodash.debounce'
  import _throttle from 'lodash.throttle'

  export default {
    props: {
      onPrevClick: {
        type: Function,
        default: () => ({})
      },
      onNextClick: {
        type: Function,
        default: () => ({})
      },
      onGoToIndex: {
        type: Function,
        default: () => ({})
      },
      delay: {
        type: Number,
        default: 0
      },
      currentIndex: {
        type: Number,
        default: 0
      },
      numberOfSlides: {
        type: Number,
        default: 0
      },
      loop: Boolean
    },
    computed: {
      showPrev () {
        if (this.loop) {
          return true
        }
        return this.currentIndex > 0
      },
      showNext () {
        if (this.loop) {
          return true
        }
        return this.currentIndex < Math.ceil(this.numberOfSlides) - 1
      }
    },
    created () {
      const opts = {
        leading: true,
        trailing: false
      }
      this.debouncePrev = _throttle(this.onPrevClick, this.delay, opts)
      this.debounceNext = _throttle(this.onNextClick, this.delay, opts)
      this.debounceGoToIndex = _debounce(this.onGoToIndex, 500, opts)
    }
  }
</script>
