<template>
  <dropdown-button
    ref="dropdownButton"
    :is-hoverable="!isMobile"
    menu-alignment="right"
  >
    <template #title>
      <div class="profile-dropdown__profile-button">
        <avatar
          :profile="activeProfile"
          :edit="false"
          class="c-profiles-avatar--small"
        />
        <div
          class="profile-dropdown__profile-name"
          :class="{ 'is-active': myAccountRoute }"
        >
          {{ profileName }}
        </div>
      </div>
    </template>
    <template #menu>
      <div class="profile-dropdown__menu">
        <ProfileNavbarList class="row align-center" />
        <separator class="o-separator--horizontal o-separator--horizontal-no-margin o-separator--horizontal-darken" />
        <nuxt-link
          :to="{ name: $dict.routes.SHOW_PROFILES }"
          class="c-dropdown__link profile-dropdown__link"
        >
          {{ $t('header.dropdown.manageProfiles') }}
        </nuxt-link>
        <separator class="o-separator--horizontal o-separator--horizontal-no-margin o-separator--horizontal-darken" />
        <nuxt-link
          :to="{ name: $dict.routes.MY_ACCOUNT_PROFILE }"
          class="c-dropdown__link profile-dropdown__link"
        >
          {{ $t('header.dropdown.seeMyAccountProfile') }}
        </nuxt-link>
        <separator class="o-separator--horizontal o-separator--horizontal-no-margin o-separator--horizontal-darken" />
        <button
          class="c-dropdown__link u-typo--action profile-dropdown__menu-logout-button"
          @click="logoutUser"
        >
          {{ $t('header.dropdown.logout') }}
        </button>
      </div>
    </template>
  </dropdown-button>
</template>
<script>
  import Avatar from '@/components/avatar'
  import ProfileNavbarList from '@/components/profiles/profile-navbar-list/index'
  import Separator from '@/components/separator/index'
  import DropdownButton from '@/layouts/components/page-header/dropdown/index'
  import LogoutUserMixin from '@/mixins/logout-user-mixin'
  import TrackingMixin from '@/mixins/tracking-mixin'
  import { breakpointGetters } from '@/assets/javascript/responsive'
  import { IS_MOBILE } from 'assets/javascript/responsive/enums'
  export default {
    components: {
      Avatar,
      ProfileNavbarList,
      Separator,
      DropdownButton
    },
    mixins: [
      LogoutUserMixin,
      TrackingMixin
    ],
    props: { isParentMenuOpen: Boolean },
    data () {
      return {
      }
    },
    computed: {
      isMobile () {
        return breakpointGetters[IS_MOBILE]()
      },
      activeProfile () {
        return this.isLoggedIn ? this.$store.getters['user/ACTIVE_PROFILE'] : false
      },
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      },
      profileName () {
        return this.activeProfile.name
      },
      myAccountRoute () {
        const routes = this.$dict.routes
        return [
          routes.ADD_DEVICE,
          routes.ADD_PROFILE,
          routes.DEVICES,
          routes.EDIT_PROFILE,
          routes.MY_ACCOUNT_PROFILE,
          routes.SHOW_PROFILES,
          routes.TRANSACTIONS
        ].includes(this.$route.name)
      }
    },
    watch: {
      isParentMenuOpen (value) {
        if (!value) {
          this.$nextTick(() => {
            this.$refs.dropdownButton.closeMenu()
          })
        }
      }
    }
  }
</script>
<style src="./styles.scss" lang="scss" />
