<template>
  <mobile-app />
</template>

<script>
  import MobileApp from '@/layouts/components/mobile-app/index.vue'

  export default {
    components: { MobileApp },
    layout: 'empty'
  }
</script>
