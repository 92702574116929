<template>
  <div class="c-profiles-page">
    <router-view />
  </div>
</template>
<script>
  export default {
    name: 'ProfilesPage',
    head () {
      return {
        title: this.$t('header.dropdown.manageProfiles')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
