<template>
  <div>
    <div
      class="c-cmp__content c-cmp__purpose c-cmp__purpose--1st-col"
      @click="toggleDetails"
    >
      <svg
        class="c-categories__icon o-icon"
        :class="{'c-cmp__icon--rotate': isDescriptionVisible}"
      >
        <use
          xlink:href="#icon-list-opened"
        />
      </svg> {{ purpose.name }}
    </div>
    <div class="c-cmp__purpose c-cmp__purpose--2nd-col">
      <v-switch
        v-if=" purposeHasInterests(purpose.id) && data.showLIT"
        :item-id="purpose.id"
        :label="$t('cmp.legitimateInterest')"
        :checked="data.purposesLITransparencys.has(purpose.id)"
        @update="updateData($event, 'purposesLITransparencys')"
      />
    </div>
    <div
      :id="`${data.type}-consent-${purpose.id}`"
      class="c-cmp__purpose c-cmp__purpose--3rd-col"
    >
      <v-switch
        :item-id="purpose.id"
        :checked="data[data.dataAttr].has(purpose.id)"
        @update="updateData($event, data.dataAttr)"
      />
    </div>
    <div
      v-show="isDescriptionVisible"
      class="c-cmp__purpose-details"
    >
      <p class="c-cmp__content">
        {{ purpose.description }}
      </p>
      <p class="c-cmp__content">
        {{ purpose.descriptionLegal }}
      </p>
    </div>
  </div>
</template>
<script>
  import VSwitch from '@/components/switch'
  export default {
    components: {
      VSwitch
    },
    props: {
      data: {
        type: Object,
        required: true
      },
      purpose: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        isDescriptionVisible: false
      }
    },
    methods: {
      toggleDetails () {
        this.isDescriptionVisible = !this.isDescriptionVisible
      },
      updateData (switchState, attr) {
        this.$emit('updateData', switchState, attr)
      },
      purposeHasInterests (id) {
        const notLegitmateInterests = [1, 3, 4, 5, 6]
        return !notLegitmateInterests.includes(id)
      }
    }
  }
</script>
