<template>
  <section>
    <div class="c-buy-product">
      <payment-card
        :product="product"
        :disable-close="disableClose"
        :promise="promise"
      />
    </div>
  </section>
</template>

<script>
  import PaymentCard from '@/components/payment-card/index'

  export default {
    name: 'BuyProductPopup',
    components: { PaymentCard },
    props: {
      product: {
        type: Object,
        required: true
      },
      promise: {
        type: Object,
        default: null
      },
      disableClose: {
        type: Boolean,
        default: false
      }
    },
    created () {
      this.$emit('cardPopup')
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
