<template>
  <div class="c-set-new-password">
    <div v-if="!newPasswordSet">
      <h1 class="c-set-new-password__title">
        {{ $t('views.setNewPassword.password') }}
      </h1>
      <message
        v-if="message"
        :message="message"
      />
      <p class="c-set-new-password__content">
        {{ $t('views.setNewPassword.passwordContent') }}
      </p>
      <form
        class="o-form"
        @submit.prevent="setNewPassword"
      >
        <v-input
          v-model="form.newPassword"
          class="o-input__wrapper--small-margin"
          type="password"
          :placeholder="$t('views.setNewPassword.password')"
          :tooltip="$t('tooltips.password')"
          tooltip-position="right"
          :validation-rules="[validationRules.PASSWORD]"
          @changedValidity="setFormValidity('newPassword', $event)"
        />
        <message
          v-if="errors.password"
          :message="errors.password"
        />
        <v-input
          v-model="form.newPasswordConfirm"
          type="password"
          :placeholder="$t('views.setNewPassword.passwordConfirm')"
          :validation-rules="[validationRules.PASSWORD]"
          @changedValidity="setFormValidity('newPasswordConfirm', $event)"
        />
        <div class="c-set-new-password__buttons-container">
          <button
            ref="submit"
            type="submit"
            class="o-button"
            :class="{ 'o-button--disabled' : !isFormValid }"
            :disabled="!isFormValid"
          >
            {{ $t('common.submit') }}
          </button>
          <nuxt-link
            :key="$route.fullPath"
            :to="{ name: $dict.routes.RESET_PASSWORD }"
            class="c-set-new-password__forgot-password"
          >
            {{ $t('common.repeatForgotPassword') }}
          </nuxt-link>
        </div>
      </form>
    </div>
    <success-view v-else>
      <svg class="c-success__icon o-icon">
        <use xlink:href="#icon-mail" />
      </svg>
      <h1 class="c-success__heading">
        {{ $t('views.setNewPassword.success.heading') }}
      </h1>
      <p>{{ $t('views.setNewPassword.success.subheading') }}</p>
      <nuxt-link
        :to="{ name: $dict.routes.LOGIN }"
        class="c-success__button o-button"
      >
        {{ $t('common.login') }}
      </nuxt-link>
    </success-view>
  </div>
</template>

<script>
  import { SUCCESS } from '@/assets/javascript/enums/message-types'

  import {
    SUBMISSION_SUCCESS,
    VALIDATION_FAILED
  } from '@/assets/javascript/dictionaries/gtmEventsActions'

  import errorMapper from '@/assets/javascript/helpers/error-mapper.js'
  import Message from '@/components/message/index'
  import SuccessView from '@/components/success-view/index'
  import restrictionRules from '@/assets/javascript/dictionaries/input-restriction-rules'
  import validationRules from '@/assets/javascript/dictionaries/input-validation-rules'
  import VInput from '@/components/input/index'
  import formValidityMixin from '@/mixins/form-validity-mixin'
  import trackingMixin from '@/mixins/tracking-mixin'

  export default {
    name: 'SetNewPassword',
    processing: false,
    successMessageType: SUCCESS,
    components: {
      Message,
      SuccessView,
      VInput
    },
    mixins: [
      formValidityMixin,
      trackingMixin
    ],
    data () {
      return {
        errors: [],
        newPasswordSet: false,
        form: {
          newPassword: '',
          newPasswordConfirm: '',
          passwordResetToken: this.$route.query.token
        },
        restrictionRules,
        validationRules,
        validity: {
          newPassword: false,
          newPasswordConfirm: false
        }
      }
    },
    computed: {
      message () {
        return this.errors.global ? this.errors.global : this.errors?.[0]
      }
    },
    methods: {
      setNewPassword () {
        this.errors = []
        if (!this.isFormValid || this.$options.processing) {
          return
        }
        if (this.form.newPassword !== this.form.newPasswordConfirm) {
          this.errors.push(this.$t('errors.passwordRepeatNotTheSame'))
          return
        }

        this.$loader.show(this.$refs.submit, { small: true })
        this.$options.processing = true
        this.$store.dispatch('user/SET_NEW_PASSWORD', this.form)
          .then(() => {
            this.errors = []
            this.newPasswordSet = true
            this.sendFormTrackingData(SUBMISSION_SUCCESS)
          })
          .catch(error => {
            return errorMapper(this.$t.bind(this), error)
              .then(errors => {
                this.trackingFormData.validationError = errors.code
                this.sendFormTrackingData(VALIDATION_FAILED)
                this.$set(this, 'errors', errors)
              })
          })
          .finally(() => {
            this.$options.processing = false
            this.$loader.hide(this.$refs.submit)
          })
      }
    },
    head () {
      return {
        title: this.$t('views.setNewPassword.title')
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
