<template>
  <section
    v-if="canShowChat"
    class="c-chat"
    :class="{ 'c-chat--logged-out': !showInput }"
    @click.stop=""
  >
    <button
      v-if="messagesLoaded"
      class="c-chat__close-btn"
      @click.stop="$emit('disable')"
    >
      <svg class="c-chat__close-icon n o-icon">
        <use xlink:href="#icon-close" />
      </svg>
    </button>
    <div
      v-if="messagesLoaded && !messages.length"
      class="c-chat--background"
    >
      {{ $t('chat.noMessages') }}
      <span v-if="!isLoggedIn">
        {{ $t('chat.login') }}
      </span>
    </div>
    <styled-scroll
      ref="container"
      class="c-chat__container"
    >
      <chat-messages
        v-if="messages.length"
        :messages="messages"
      />
    </styled-scroll>
    <chat-input v-if="showInput && messagesLoaded" />
  </section>
</template>

<script>
  import {
    ADDED,
    CHAT,
    CHAT_COLLECTION,
    CONFIG,
    DECREASE_LIKES,
    DELETED_AT,
    DESC,
    DOCUMENT_REF,
    GET_BLOCK_SENDING,
    GET_CURRENT_MESSAGE_ID,
    GET_CURRENT_REPLY_ID,
    GET_USER_LIKES,
    INCREASE_LIKES,
    LIKE_COLLECTION,
    MESSAGE_COLLECTION,
    MESSAGE_LENGTH,
    MODIFIED,
    PERMISSION_DENIED,
    READ_REPLIES,
    REMOVED,
    REPLY_COLLECTION,
    SET_CURRENT_MESSAGE_ID,
    SET_CURRENT_REPLY_ID,
    SHOULD_BE_MODERATED,
    STORE_MESSAGE,
    TIMESTAMP,
    UNSUBSCRIBE_REPLIES
  } from '@/components/chat/enums/chat'

  import {
    LIVE
  } from '@/assets/javascript/enums/product-types'

  import ChatInput from '@/components/chat/input'
  import ChatMessages from '@/components/chat/messages'
  import StyledScroll from '@/components/styled-scroll'
  import { ACTION } from 'assets/javascript/dictionaries/gtmEvents'
  import { ADD_COMMENT, LIKE as COMMENT_LIKE, UNLIKE as COMMENT_UNLIKE } from 'assets/javascript/dictionaries/gtmEventsActions'

  const MESSAGE_READ_LIMIT = 100
  const LIKE = 'like'
  const DISLIKE = 'dislike'

  export default {
    inject: [
      'playerStore'
    ],
    provide: function () {
      return {
        [CONFIG]: () => this.settings,
        [DECREASE_LIKES]: this.decreaseLikes,
        [GET_BLOCK_SENDING]: this.getBlockSending,
        [GET_CURRENT_MESSAGE_ID]: this.getCurrentMessageId,
        [GET_CURRENT_REPLY_ID]: this.getCurrentReplyId,
        [GET_USER_LIKES]: this.getUserLikes,
        [INCREASE_LIKES]: this.increaseLikes,
        [DOCUMENT_REF]: this.documentRef,
        [READ_REPLIES]: this.readReplies,
        [SET_CURRENT_MESSAGE_ID]: this.setCurrentMessageId,
        [SET_CURRENT_REPLY_ID]: this.setCurrentReplyId,
        [STORE_MESSAGE]: this.storeMessage,
        [UNSUBSCRIBE_REPLIES]: this.unsubscribeReplies
      }
    },
    components: {
      ChatInput,
      ChatMessages,
      StyledScroll
    },
    props: {
      product: {
        type: Object,
        default: () => ({})
      }
    },
    data () {
      return {
        blockSending: false,
        canShowChat: false,
        config: {
          thread: '',
          messageLength: MESSAGE_LENGTH,
          messageReadLimit: MESSAGE_READ_LIMIT
        },
        currentMessageId: '',
        currentReplyId: '',
        documentRef: {},
        messages: [],
        messagesLoaded: false,
        playerState: this.playerStore.state,
        settings: {},
        unsubscribeMessages: () => {},
        unsubscribeReplies: () => {},
        unsubscribeSettings: () => {}
      }
    },
    computed: {
      activeProfile () {
        return this.$store.getters['user/ACTIVE_PROFILE']
      },
      isLoggedIn () {
        return this.$store.getters['user/LOGGED_IN']
      },
      messageContainer () {
        return this.$refs.container
      },
      productId () {
        return this.product.type === LIVE
          ? this.playerState.currentLiveProgram.id
          : this.product.id
      },
      showInput () {
        return !!(this.isLoggedIn && !this.settings.readOnly)
      }
    },
    watch: {
      'playerState.currentLiveProgram': function (newProgram, oldProgram) {
        if (oldProgram.id && oldProgram.id !== newProgram.id) {
          this.$emit('disable')
        }

        this.checkRoomName(newProgram)
      }
    },
    created () {
      this.checkRoomName()
    },
    beforeDestroy () {
      this.$fireAuth.signOut()
        .finally(() => {
          this.unsubscribeMessages()
          this.unsubscribeSettings()
        })
    },
    methods: {
      checkRoomName () {
        const product = this.product.type === LIVE
          ? this.playerState.currentLiveProgram
          : this.product

        if (product.chatEnabled && product.chatRoomName) {
          return this.$emit('enableIcon')
        }

        return this.$emit('disableIcon')
      },
      disable () {
        this.canShowChat = false
        this.unsubscribeMessages()
        this.unsubscribeSettings()
        this.unsubscribeMessages = () => {}
        this.unsubscribeSettings = () => {}
      },
      async enable () {
        this.$loader.show(this.$el.parentElement)
        this.messages = []
        this.messagesLoaded = false
        this.currentMessageId = ''
        this.currentReplyId = ''
        this.canShowChat = true
        this.config.thread = this.product.type === LIVE
          ? this.playerState.currentLiveProgram.chatRoomName
          : this.product.chatRoomName

        try {
          let token = this.$store.state.user.firebaseAccessToken
          if (!token) {
            const dispatch = this.$store.dispatch
            const params = {
              itemId: this.productId,
              room: this.config.thread,
              type: CHAT
            }

            token = this.isLoggedIn
              ? await dispatch('authorizations/GET_ACCESS_TOKEN', params)
              : await dispatch('authorizations/GET_ACCESS_TOKEN_FOR_NOT_LOGGED_IN', params)
          }
          await this.$fireAuth.signInWithCustomToken(token)
        } catch (e) {
          this.$toast.error(this.$t('chat.authError'))
          this.$loader.hide(this.$el.parentElement)
          return this.disable()
        }
        this.unsubscribeMessages()
        this.unsubscribeSettings()
        await this.$store.dispatch('profiles/GET_AVATARS')
        this.documentRef = this.$fireStore
          .collection(CHAT_COLLECTION)
          .doc(this.config.thread)

        this.documentRef.get().then((doc) => {
          this.updateSettings(doc.data())
          this.readMessages()
        })

        this.unsubscribeSettings = this.documentRef
          .onSnapshot((doc) => {
            this.updateSettings(doc.data())
          })
      },
      decreaseLikes (messageId, parentId) {
        return this.updateLikes(DISLIKE, messageId, parentId)
      },
      getBlockSending () {
        return this.blockSending
      },
      getCurrentMessageId () {
        return this.currentMessageId
      },
      getCurrentReplyId () {
        return this.currentReplyId
      },
      getUserLikes (messageId, parentId) {
        let messageRef
        if (parentId) {
          messageRef = this.documentRef
            .collection(MESSAGE_COLLECTION)
            .doc(parentId)
            .collection(REPLY_COLLECTION)
            .doc(messageId)
        } else {
          messageRef = this.documentRef
            .collection(MESSAGE_COLLECTION)
            .doc(messageId)
        }
        return messageRef
          .collection(LIKE_COLLECTION)
          .doc(this.activeProfile.id.toString())
          .get()
          .then((doc) => {
            if (doc.exists) {
              return doc.data()
            }
          })
      },
      increaseLikes (messageId, parentId) {
        return this.updateLikes(LIKE, messageId, parentId)
      },
      async updateLikes (mode, messageId, parentId) {
        try {
          if (!this.isLoggedIn) {
            this.$toast.error(this.$t('chat.likeDenied'))
            return false
          }

          let data = {
            roomId: this.settings.thread,
            userId: this.activeProfile.id.toString()
          }

          if (parentId) {
            data = {
              ...data,
              messageId: parentId,
              replyId: messageId
            }
          } else {
            data = {
              ...data,
              messageId
            }
          }

          await this.$fireFunc.httpsCallable(mode)(data)

          this.$tracking.events.send(ACTION, {
            product: {
              id: this.productId
            },
            action: mode === LIKE
              ? COMMENT_LIKE
              : COMMENT_UNLIKE
          })
          return true
        } catch (e) {
          this.$toast.error(this.$t('chat.likeError'))
          return false
        }
      },
      setCurrentMessageId (id) {
        this.currentMessageId = this.currentMessageId === id
          ? ''
          : id

        this.setCurrentReplyId('', false)

        if (id) {
          this.$nextTick(() => {
            this.scrollToPoint(id)
          })
        }
      },
      setCurrentReplyId (id, scroll = true) {
        this.currentReplyId = this.currentReplyId === id
          ? ''
          : id

        if (scroll && id) {
          this.$nextTick(() => {
            this.scrollToPoint(id)
          })
        }
      },
      tempBlockSending () {
        this.blockSending = true
        setTimeout(() => {
          this.blockSending = false
        }, this.settings.threshold)
      },
      async storeMessage ({ message, parentId }) {
        if (!message.length || this.blockSending) {
          return
        }

        const config = this.settings

        try {
          if (config.threshold) {
            this.tempBlockSending()
          }

          const userData = this.$store.state.user.data
          const { name, color, id, avatar } = this.activeProfile

          const data = {
            message: message.replace(/\n/g, '<br>'),
            user: {
              id: userData.id,
              username: name,
              profileColor: color || '',
              profileId: id,
              avatarId: avatar?.id || 0
            },
            deletedAt: -1,
            timestamp: this.$time().unix(),
            [SHOULD_BE_MODERATED]: this.settings.shouldModerate
          }

          if (parentId) {
            this.documentRef
              .collection(MESSAGE_COLLECTION)
              .doc(parentId)
              .collection(REPLY_COLLECTION)
              .add(data)
              .then(() => {
                this.setCurrentReplyId('')
                this.$tracking.events.send(ACTION, {
                  product: {
                    id: this.productId
                  },
                  action: ADD_COMMENT
                })

                if (this.settings.shouldModerate) {
                  return this.$toast.success(this.$t('chat.messageWaiting'))
                }

                this.$nextTick(() => {
                  this.scrollToPoint(parentId, { toBottom: true })
                })
                this.$toast.success(this.$t('chat.messageStored'))
              })
              .catch((e) => {
                if (e.code === PERMISSION_DENIED) {
                  this.$toast.error(this.$t('chat.permissionDenied'))
                } else {
                  this.$toast.error(this.$t('chat.error'))
                }
              })
          } else {
            await this.documentRef
              .collection(MESSAGE_COLLECTION)
              .add(data)
              .then(() => {
                this.setCurrentMessageId('')
                this.$tracking.events.send(ACTION, {
                  product: {
                    id: this.productId
                  },
                  action: ADD_COMMENT
                })

                if (this.settings.shouldModerate) {
                  return this.$toast.success(this.$t('chat.messageWaiting'))
                }

                this.$nextTick(() => {
                  this.scrollToBottom()
                })
                this.$toast.success(this.$t('chat.messageStored'))
              })
              .catch((e) => {
                if (e.code === PERMISSION_DENIED) {
                  this.$toast.error(this.$t('chat.permissionDenied'))
                } else {
                  this.$toast.error(this.$t('chat.error'))
                }
              })
          }
        } catch (e) {
          this.$toast.error(this.$t('chat.error'))
        }
      },
      async readReplies (messageId) {
        const replyRef = this.documentRef.collection(MESSAGE_COLLECTION)
          .doc(messageId)
          .collection(REPLY_COLLECTION)
          .where(SHOULD_BE_MODERATED, '==', false)
          .where(DELETED_AT, '==', -1)
          .orderBy(TIMESTAMP)
          .limit(this.config.messageReadLimit)

        const replies = []

        this.unsubscribeReplies = replyRef.onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
            const { doc, type } = change
            if (type === ADDED) {
              const data = doc.data()
              data.id = doc.id

              replies.push(data)
            }

            if (type === REMOVED) {
              const replyIndex = replies.findIndex(({ id }) => id === doc.id)
              return replies.splice(replyIndex, 1)
            }
          })
        })

        return replies
      },
      async readMessages () {
        const messageRef = this.documentRef.collection(MESSAGE_COLLECTION)
          .where(SHOULD_BE_MODERATED, '==', false)
          .where(DELETED_AT, '==', -1)
          .orderBy(TIMESTAMP, DESC)
          .limit(this.config.messageReadLimit)

        this.unsubscribeMessages = messageRef.onSnapshot(snapshot => {
          snapshot.docChanges().forEach(change => {
            const { doc, type } = change

            if (type === ADDED) {
              const data = doc.data()
              data.id = doc.id

              this.messagesLoaded
                ? this.messages.push(data)
                : this.messages.unshift(data)
            }

            if (change.type === MODIFIED) {
              const index = this.messages.findIndex(elem => elem.id === change.doc.id)
              this.$set(this.messages, index, {
                ...change.doc.data(),
                id: change.doc.id,
                shouldBeModerated: change.doc.data().shouldBeModerated || false,
                likeCount: this.messages[index]?.likeCount,
                dislikeCount: this.messages[index]?.dislikeCount,
                likes: this.messages[index]?.likes
              })
            }

            if (type === REMOVED) {
              const messageIndex = this.messages.findIndex(({ id }) => id === doc.id)
              return this.messages.splice(messageIndex, 1)
            }
          })

          if (!this.messagesLoaded) {
            this.messagesLoaded = true
            this.$loader.hide(this.$el.parentElement)
            this.$nextTick(() => {
              this.scrollToBottom()
            })
          }
        })
      },
      updateSettings (data) {
        this.settings = Object.assign({}, { ...data, ...this.config })
      },
      scrollToBottom () {
        this.messageContainer.$el.scrollTop = this.messageContainer.$el.scrollHeight
        this.messageContainer.perfectScroll && this.messageContainer.perfectScroll.update()
      },
      scrollToPoint (id, opts = {}) {
        const parentTop = this.messageContainer.$el.getBoundingClientRect().top
        const message = document.querySelector('div[data-id="' + id + '"]')
        const replies = document.querySelector('div[data-parent="' + id + '"]')
        const repliesLastChild = document.querySelector('div[data-parent="' + id + '"] .c-chat__row:last-of-type')
        const scrollPosition = opts.toBottom
          ? message.getBoundingClientRect().bottom - parentTop + replies.getBoundingClientRect().height - repliesLastChild.getBoundingClientRect().height
          : message.getBoundingClientRect().top - parentTop
        this.messageContainer.$el.scrollTop = this.messageContainer.$el.scrollTop + scrollPosition
        this.messageContainer.perfectScroll && this.messageContainer.perfectScroll.update()
      }
    }
  }
</script>

<style lang="scss" src="./styles.scss"></style>
