<template>
  <div
    class="c-player__controls row expanded align-middle"
    @click.stop.prevent=""
  >
    <div class="column shrink">
      <player-toggle-play />
    </div>
    <div class="column">
      <player-live-time-shift
        class="c-player__progress-bar"
        :current-program-since="currentProgramSinceObj"
      />
    </div>
    <div class="column shrink">
      {{ startTime }} - {{ endTime }}
    </div>
    <div class="column shrink">
      <player-volume
        class="c-player__progress-bar row align-middle"
        direction="vertical"
        :show-volume-icon="true"
      />
    </div>
    <div
      v-if="showSettingsButton"
      class="column shrink"
      @click.stop=""
    >
      <player-options-btn class="c-player__option-btn" />
    </div>
    <div class="column shrink">
      <player-full-screen class="c-player__fullscreen-btn" />
    </div>
  </div>
</template>
<script>
  import PlayerFullScreen from 'atds-player-layout/src/components/controls/components/fullscreen'
  import PlayerVolume from 'atds-player-layout/src/components/controls/components/volume'
  import PlayerOptionsBtn from 'atds-player-layout/src/components/controls/components/options'
  import PlayerLiveTimeShift from 'atds-player-layout/src/components/controls/components/live-progress'
  import PlayerTogglePlay from 'atds-player-layout/src/components/controls/components/toggle-play'
  import showPlayerSettingsMixin from '@/mixins/show-player-settings-mixin'

  export default {
    name: 'PlayerLiveControls',
    inject: [
      'playerStore'
    ],
    components: {
      PlayerTogglePlay,
      PlayerLiveTimeShift,
      PlayerOptionsBtn,
      PlayerVolume,
      PlayerFullScreen
    },
    mixins: [
      showPlayerSettingsMixin
    ],
    data () {
      return {
        currentProgram: this.playerStore.state.currentLiveProgram
      }
    },
    computed: {
      startTime () {
        return this.$time(this.currentProgram.since)
          .format('HH:mm')
      },
      endTime () {
        return this.$time(this.currentProgram.till)
          .format('HH:mm')
      },
      currentProgramSinceObj () {
        return this.$time(this.currentProgram.since)
      }
    },
    watch: {
      'playerStore.state.currentLiveProgram' (newProgramme, oldProgramme) {
        if (oldProgramme.id && newProgramme.id !== oldProgramme.id) {
          this.currentProgram = newProgramme
        }
      }
    }
  }
</script>
