<template>
  <div
    class="container"
    :class="{ change: isOpen }"
  >
    <div class="bar1" />
    <div class="bar2" />
    <div class="bar3" />
  </div>
</template>
<script>
  export default {
    props: {
      isOpen: Boolean
    }
  }
</script>
<style src="./styles.scss" lang="scss"></style>
