<template>
  <section>
    <div
      v-if="!mobileOrTablet"
      class="row expanded collapse"
    >
      <div class="column small-2">
        <div class="c-devices__label">
          {{ $t('views.devices.platform') }}
        </div>
      </div>
      <div class="column small-3">
        <div class="c-devices__label">
          {{ $t('views.devices.name') }}
        </div>
      </div>
      <div class="column small-3">
        <div class="c-devices__label">
          {{ $t('views.devices.added') }}
        </div>
      </div>
      <div class="column small-3">
        <div class="c-devices__label">
          {{ $t('views.devices.lastLoggedAt') }}
        </div>
      </div>
      <separator class="o-separator--horizontal o-separator--horizontal-navbar" />
    </div>
    <div
      v-for="device in devices"
      :key="device.id"
    >
      <div class="c-devices__row row expanded collapse">
        <div class="column small-12 large-2">
          <div class="c-devices__icon">
            <span
              v-if="mobileOrTablet"
              class="c-devices__label"
            >
              {{ $t('views.devices.platform') }}:
            </span>
            <svg class="o-icon">
              <use :xlink:href="getIcon(device)" />
            </svg>
          </div>
        </div>
        <div class="column small-12 large-3">
          <div class="c-devices__text">
            <span
              v-if="mobileOrTablet"
              class="c-devices__label"
            >
              {{ $t('views.devices.name') }}:
            </span>
            {{ getName(device) }}
          </div>
          <div class="c-devices__text">
            <span
              v-if="mobileOrTablet"
              class="c-devices__label"
            >
              UID:
            </span>
            {{ device.uid }}
          </div>
        </div>
        <div class="column small-12 large-3">
          <div class="c-devices__text">
            <span
              v-if="mobileOrTablet"
              class="c-devices__label"
            >
              {{ $t('views.devices.added') }}:
            </span>
            {{ device.createdAt | dateFormat('DD MMM YYYY, HH:mm:ss') }}
          </div>
        </div>
        <div class="column small-12 large-3">
          <div class="c-devices__text">
            <span
              v-if="mobileOrTablet"
              class="c-devices__label"
            >
              {{ $t('views.devices.lastLoggedAt') }}:
            </span>
            {{ device.lastLoggedAt | dateFormat('DD MMM YYYY, HH:mm:ss') }}
          </div>
        </div>
        <div class="column small-12 large-1">
          <button
            v-if="!device.active"
            class="c-devices__text c-devices__link u-typo--action"
            @click="$popup.show($dict.popups.REMOVE_DEVICE, { deviceUid: device.uid })"
          >
            {{ $t('buttons.remove') }}
          </button>
        </div>
      </div>
      <separator class="o-separator--horizontal o-separator--horizontal-no-margin" />
    </div>
    <div
      class="c-devices__button-container"
      :class="{ 'c-devices__button-container--justify-end': mobileOrTablet }"
    >
      <nuxt-link
        :to="{ name: $dict.routes.ADD_DEVICE }"
        class="o-button o-button--secondary"
      >
        {{ $t('views.devices.addDevice') }}
      </nuxt-link>
    </div>
  </section>
</template>
<script>
  import {
    PLATFORM_BROWSER,
    PLATFORM_ANDROID,
    PLATFORM_IOS,
    PLATFORM_SMART_TV
  } from '@/assets/javascript/enums/device-type'

  import Separator from '@/components/separator/index'

  import mobileOrTabletMixin from '@/mixins/mobile-or-tablet-mixin'

  const Icons = {
    [PLATFORM_BROWSER]: 'pc',
    [PLATFORM_ANDROID]: 'android',
    [PLATFORM_IOS]: 'apple',
    [PLATFORM_SMART_TV]: 'live-tv'
  }

  export default {
    name: 'Devices',
    components: { Separator },
    mixins: [mobileOrTabletMixin],
    asyncData ({ store }) {
      return store.dispatch('user/GET_DEVICES')
    },
    data () {
      return {
        errors: {}
      }
    },
    computed: {
      devices () {
        return this.$store.state.user.devices
      }
    },
    methods: {
      getIcon (device) {
        return `#icon-${Icons[device.platform]}`
      },
      getName (device) {
        return `${device.agent} ${device.agentVersion} [${device.os}]`
      }
    },
    head () {
      return {
        title: this.$t('views.devices.title')
      }
    }
  }
</script>
<style src="./styles.scss" lang="scss"/>
