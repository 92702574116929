<template>
  <div class="c-profiles-item">
    <div
      class="c-profiles-item__avatar-wrapper"
      :class="isActive"
    >
      <avatar
        :profile="profile"
        :edit="editMode"
      />
    </div>
    <div
      class="c-profiles-item__profile-name"
      :class="isActive"
    >
      {{ profile.name }}
    </div>
  </div>
</template>
<script>
  import Avatar from '@/components/avatar'

  export default {
    name: 'ProfilesItem',
    components: {
      Avatar
    },
    props: {
      profile: {
        type: Object,
        default: () => ({})
      },
      editMode: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      activeProfile () {
        return this.$store.getters['user/ACTIVE_PROFILE']
      },
      user () {
        return this.$store.state.user.data
      },
      isActive () {
        return {
          'is-active': this.activeProfile.id === this.profile.id
        }
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
