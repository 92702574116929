<template>
  <section class="c-static-page">
    <h1
      v-dompurify-html="document.title"
      class="c-static-page__title"
    />
    <div
      v-dompurify-html="document.content"
      class="c-static-page__content"
    />
  </section>
</template>
<script>
  export default {
    data () {
      return {
        document: {}
      }
    },
    created () {
      this.$loader.show(this.$root.$el)
      this.$store.dispatch('documents/GET', this.$route.params.alias)
        .then((document) => {
          this.document = document
        })
        .catch(() => {
          this.$toast.error(this.$t('common.error'))
          this.$router.push({ name: this.$dict.routes.HOME })
        })
        .finally(() => this.$loader.hide(this.$root.$el))
    },
    head () {
      return {
        title: this.document?.title
      }
    }
  }
</script>
<style lang="scss" src="./styles.scss" />
