<template>
  <div class="c-small-live-player">
    <div :id="$options.playerHolderId" />
    <div class="c-small-live-player__controls row align-middle align-justify">
      <div
        v-if="autoplayBlocked"
        class="u-abs-center"
        @click="unblockAutoplay"
      >
        <svg class="c-player__icon o-icon">
          <use xlink:href="#icon-play-circle" />
        </svg>
      </div>
      <div class="column shrink">
        <nuxt-link
          :to="{ name: $dict.routes.WATCH, params: routeParams }"
        >
          <svg class="o-icon">
            <use xlink:href="#icon-maximize" />
          </svg>
        </nuxt-link>
      </div>
      <div class="column shrink">
        <ui-player-volume
          class="c-player__volume c-player__progress-bar"
          :hide-volume-bar="true"
          :show-volume-icon="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import {
    LIVE
  } from '@/assets/javascript/enums/video-types'

  import {
    PRODUCT_TYPE_TO_VIDEO_TYPE
  } from '@/assets/javascript/maps/product-type-to-video-type'

  import { PlayerEvent } from 'redgalaxy-player-web'

  import UiPlayerVolume from 'atds-player-layout/src/components/controls/components/volume/index'

  import { PlayerMuteMixin } from '@/components/player/mixins/mute'
  import { PlayerWatchLiveProgressMixin } from '@/components/player/mixins/watchLiveProgress'
  import { PlayerGetVMAPMixin } from '@/components/player/mixins/getVMAPforCurrentProgramme'

  import AutoplayMixin from '@/mixins/autoplay-mixin'

  export default {
    name: 'SmallPlayer',
    components: { UiPlayerVolume },
    playerHolderId: 'js-live-window',
    inject: [
      'playerStore'
    ],
    mixins: [
      AutoplayMixin,
      PlayerMuteMixin,
      PlayerWatchLiveProgressMixin,
      PlayerGetVMAPMixin
    ],
    data () {
      return {
        playerState: this.playerStore.state
      }
    },
    computed: {
      playerInstance () {
        return this.$player.getPlayerInstance(this.$options.playerHolderId)
      },
      routeParams () {
        return { type: LIVE, productId: process.env.DEFAULT_LIVE_CHANNEL }
      }
    },
    watch: {
      'playerState.product': function (product) {
        if (product.duration && product?.type !== LIVE) {
          return
        }
        // → Executed to get information about CREDITS, for tracking data purposes
        this.getEventsOnTimeline(product)
      },
      'playerState.volume': function (volume) {
        const player = this.playerInstance
        player.setVolume(volume)

        if (!volume) {
          player.mute()
        }

        if (volume && player.getRendererElm().muted) {
          player.unMute()
        }
      }
    },
    mounted () {
      const element = this.$el
      const loader = this.$loader
      const player = this.$player
      const playerStore = this.playerStore
      this.playerState = playerStore.state
      this.$tracking.setPlayerStateAndRoute(this.$options.playerHolderId, this.playerState, this.$route)
      const holderId = this.$options.playerHolderId
      loader.show(element, { small: true })
      player.play({
        product: {
          id: process.env.DEFAULT_LIVE_CHANNEL,
          type: LIVE
        },
        holderId,
        playerStore,
        mute: true
      })
        .then(() => {
          this.playerStore.toggleMute(true)
          player.getPlayerInstance(holderId)
            .on(PlayerEvent.MANIFEST_LOADED, () => {
              loader.hide(element)
            })
        })
        .catch(() => {
          loader.hide(element)
        })
    },
    beforeDestroy () {
      this.$player.destroy(this.$options.playerHolderId)
    },
    methods: {
      getEventsOnTimeline (product) {
        const { type, id: productId } = product
        const videoType = PRODUCT_TYPE_TO_VIDEO_TYPE[type]
        this.$store.dispatch('player/GET', { productId, videoType })
          .then(({ playerEvents = [] }) => {
            playerEvents.forEach(event =>
              this.playerStore.addEventOnTimeline(event))
          })
      }
    }
  }
</script>
<style src="./styles.scss" lang="scss"></style>
