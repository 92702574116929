<template>
  <section class="c-emojis__wrapper">
    <span
      v-for="(emoji, index) in $options.emojis"
      :key="index"
      class="c-emojis__icon"
      @click="$emit('insert', emoji)"
    >
      {{ emoji }}
    </span>
  </section>
</template>

<script>
  import { emojis } from '@/components/chat/emojis/emojis'

  export default {
    emojis
  }
</script>

<style lang="scss" src="./styles.scss"></style>
