<template>
  <div class="c-action-buttons__button">
    <div
      class="c-action-buttons__icon-link c-action-buttons__icon-link--padding"
      @click="share"
    >
      <svg class="o-icon">
        <use xlink:href="#icon-facebook" />
      </svg>
      <div class="c-action-buttons__title">
        {{ $t('buttons.facebook') }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ShareOnFb',
    props: {
      url: {
        type: String,
        required: true
      },
      product: {
        type: Object,
        required: true
      }
    },
    methods: {
      share () {
        const params = {
          method: 'share',
          display: 'popup',
          href: this.url
        }
        const auth = window.FB.getAuthResponse()

        if (auth) {
          return window.FB.ui(params, (response) => {
            // eslint-disable-next-line camelcase
            if (response?.error_message) {
              throw new Error(`Facebook error: ${response.error_message}`)
            }
          })
        }

        return window.FB.login((res) => {
          if (res.authResponse) {
            window.FB.ui(params)
          }
        })
      }
    }
  }
</script>
